/**
 * These utils are for handling field paths in node params (DynList filters and CondVis).
 * For basic fields the fieldPath is currently just the field slug. For fields of an
 * item-reference the fieldPaths are for example `author:post` (separated with SEPARATOR).
 *
 * Encapsulating the internals of fieldPath inside these utils it is easier to refactor the
 * structure of a fieldPath if needed.
 */

const SEPARATOR = ':';
const EMPTY_STRING = '';

export const isFieldOfItemRef = (fieldPath: string): boolean =>
  fieldPath.indexOf(SEPARATOR) !== -1;

export const getValueFieldSlug = (fieldPath: string): string => {
  const splitPath = fieldPath.split(SEPARATOR);
  // @ts-expect-error - TS2322 - Type 'string | undefined' is not assignable to type 'string'.
  return splitPath[splitPath.length - 1];
};

export const getItemRefSlug = (fieldPath: string): string | null => {
  if (isFieldOfItemRef(fieldPath)) {
    const splitPath = fieldPath.split(SEPARATOR);
    // @ts-expect-error - TS2322 - Type 'string | undefined' is not assignable to type 'string | null'.
    return splitPath[0];
  }
  return null;
};

export const createFieldPath = (...fieldSlugs: Array<string>): string =>
  fieldSlugs.join(SEPARATOR);

export const isEmptyFieldPath = (a: string): boolean => a === EMPTY_STRING;

export const fieldPathsEqual = (a: string, b: string): boolean => a === b;

export const isFauxDynContextField = (fieldPath: string) =>
  !isFieldOfItemRef(fieldPath) && getValueFieldSlug(fieldPath) === '_id';
