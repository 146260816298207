/*
 * WARNING
 *
 * This file is included in the `webflow.js` bundle, so
 * please refrain from adding dependencies that might
 * have an outsized impact on bundle size.
 *
 * Thank you!
 */

type LightboxJson = {
  items: Array<LightboxJsonItem>;
  group: string | null | undefined;
};

type LightboxJsonItem = {
  url: string;
  type: string;
  html?: string;
  height?: number;
  width?: number;
  thumbnailUrl?: string;
};

const createJsonItemFromBoundMedia = (
  binding: any
): LightboxJsonItem | null => {
  if (binding) {
    if (binding.metadata) {
      const {
        html,
        height,
        width,
        thumbnail_url: thumbnailUrl,
      } = binding.metadata;
      return {
        url: binding.url,
        html,
        height,
        width,
        thumbnailUrl,
        type: 'video',
      };
    } else {
      return {url: binding.url, type: 'image'};
    }
  }
  return null;
};

export const createJsonFromBoundMedia = (
  binding: any,
  nodeJsonData?: LightboxJson | null
): LightboxJson | null => {
  const group = nodeJsonData ? nodeJsonData.group : undefined;

  if (Array.isArray(binding)) {
    const items = binding.reduce((memo, subBinding) => {
      const jsonItem = createJsonItemFromBoundMedia(subBinding);
      if (jsonItem) {
        memo.push(jsonItem);
      }
      return memo;
    }, []);

    return items.length > 0 ? {items, group} : null;
  }

  const jsonItem = createJsonItemFromBoundMedia(binding);
  return jsonItem !== null
    ? {
        items: [jsonItem],
        group,
      }
    : null;
};
