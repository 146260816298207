import get from 'lodash/get';
import isInteger from 'lodash/isInteger';
import {formatMoney} from 'accounting';
import {simpleReplaceTokens} from '@packages/systems/core/utils/EmbedUtils/simpleReplaceTokens';
import {type Price} from '@packages/systems/commerce/core';
import {
  type FormattedPrice,
  validatePrice,
  _invalid,
  renderPrice,
} from './CurrencyUtils';
import {weakMemo} from '@packages/utilities/memo';

/**
 * Will convert a Price ({ value, unit }) into a FormattedPrice ({ value, unit, string }) with the string
 * formatted based on currency settings.
 *
 * @param  {Price} price A basic price object.
 * @return {FormattedPrice} That same price object, extended with a string version.
 */
export function formatPriceFromSettings(
  price: Price,
  currencySettings: CurrencySettings
): FormattedPrice {
  price = validatePrice(price) ? price : _invalid();
  const string = renderPriceFromSettings(price, currencySettings);
  return {
    unit: price.unit,
    value: price.value,
    string,
  };
}

export type CurrencySettings = {
  hideDecimalForWholeNumbers: boolean;
  fractionDigits: number;
  template: string;
  decimal: string;
  group: string;
  symbol: string;
  currencyCode: string;
};

export const getCurrencySettingsFromCommerceSettings = weakMemo(
  (commerceSettings: {
    getIn?: (arg1: string[], arg2?: any) => any;
  }): CurrencySettings => {
    const getTheStuff =
      typeof commerceSettings.getIn === 'function'
        ? (keyPath: Array<string>, defaultValue: number | string | boolean) =>
            // @ts-expect-error - TS2722 - Cannot invoke an object which is possibly 'undefined'.
            commerceSettings.getIn(keyPath, defaultValue)
        : (keyPath: Array<string>, defaultValue: number | string | boolean) =>
            get(commerceSettings, keyPath, defaultValue);

    return {
      hideDecimalForWholeNumbers: getTheStuff(
        ['defaultCurrencyFormat', 'hideDecimalForWholeNumbers'],
        false
      ),
      fractionDigits: getTheStuff(
        ['defaultCurrencyFormat', 'fractionDigits'],
        2
      ),
      template: getTheStuff(['defaultCurrencyFormat', 'template'], ''),
      decimal: getTheStuff(['defaultCurrencyFormat', 'decimal'], '.'),
      group: getTheStuff(['defaultCurrencyFormat', 'group'], ','),
      symbol: getTheStuff(['defaultCurrencyFormat', 'symbol'], '$'),
      currencyCode: getTheStuff(['defaultCurrency'], 'USD'),
    };
  }
);

const _nonBreakingSpace = String.fromCharCode(160);
const _replaceAllSpaceWithNBSP = (str: string) =>
  str.replace(/\s/g, _nonBreakingSpace);

export function renderAmountFromSettings(
  amount?: number | string,
  // @ts-expect-error - TS2739 - Type '{}' is missing the following properties from type '{ fractionDigits: number; hideDecimalForWholeNumbers: boolean; decimal: string; group: string; }': fractionDigits, hideDecimalForWholeNumbers, decimal, group
  amountSettings: {
    fractionDigits: number;
    hideDecimalForWholeNumbers: boolean;
    decimal: string;
    group: string;
  } = {}
) {
  if (typeof amount === 'undefined') {
    return '';
  }

  if (typeof amount === 'string') {
    if (amount === '∞') {
      return amount;
    }

    // This should most likely never happen, but it's a flow guard for the rest of the function
    throw new Error(`amount has type string: got ${amount}, expected ∞`);
  }

  // Price.value is always whole number. For example, USD is represented in cents
  // this is because fractionDigits = 2. To convert to a jsNumber we need to move
  // the decimal to the left fractionDigits number of times. (we can do this
  // with division)
  const jsValue =
    amount / parseFloat(`1${'0'.repeat(amountSettings.fractionDigits || 0)}`);

  const precision =
    isInteger(jsValue) && amountSettings.hideDecimalForWholeNumbers
      ? 0
      : amountSettings.fractionDigits;

  return formatMoney(jsValue, {
    symbol: '',
    decimal: amountSettings.decimal,
    precision,
    thousand: amountSettings.group,
  });
}

type RenderOpts = {
  breakingWhitespace?: boolean;
};

/**
 * Will convert a Price object (ie: object with value and unit) into a formatted
 * string based on the currencySettings passed in
 */
export function renderPriceFromSettings(
  price: Price,
  // @ts-expect-error - TS2740 - Type '{}' is missing the following properties from type 'CurrencySettings': hideDecimalForWholeNumbers, fractionDigits, template, decimal, and 3 more.
  currencySettings: CurrencySettings = {},
  renderOpts: RenderOpts = {}
): string {
  const {template, currencyCode} = currencySettings;
  // fall back to old renderPrice if some currency settings don't exist
  // snapshots > ecommerce > ecommerceIsOn for Cypress tests currently doesn't have currency settings
  // we also want to fallback to the old renderPrice in the event that a price's unit
  // does not match the currencyCode for the currency settings
  if (!template || price.unit !== currencyCode) {
    return renderPrice(price);
  }

  return (
    (price.value < 0 ? '−' : '') + // negative sign to appear before currency symbol e.g., -$ 5.00 USD
    simpleReplaceTokens(
      (renderOpts.breakingWhitespace
        ? currencySettings.template
        : _replaceAllSpaceWithNBSP(currencySettings.template)) || '',
      {
        amount: renderAmountFromSettings(
          Math.abs(price.value),
          currencySettings
        ),
        symbol: currencySettings.symbol,
        currencyCode: currencySettings.currencyCode,
      }
    )
  );
}
