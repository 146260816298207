/* globals window, document, HTMLElement */

import {transformers} from '@packages/systems/dynamo/utils/Transformers';
import type {BindingTransformer} from '@packages/systems/dynamo/binding-types';
import escape from 'lodash/escape';
import cloneDeep from 'lodash/cloneDeep';
import transform from 'lodash/transform';
import {
  WF_BINDING_DATA_KEY,
  WF_CONDITION_DATA_KEY,
  WF_COLLECTION_DATA_KEY,
  WF_TEMPLATE_ID_DATA_KEY,
} from '@packages/systems/commerce/constants';
import {SHARED_ALLOWED_FIELD_TYPES} from '@packages/systems/dynamo/constants';
import {
  applyConditionToNode,
  removeWDynBindEmptyClass,
  walkDOM,
} from '@packages/systems/dynamo/utils/RenderingUtils';
import {safeParseJson} from './commerceUtils';
import {
  formatEmail,
  formatPhone,
} from '@packages/systems/dynamo/utils/DynamoFormattingUtils';
import {
  simplifySkuValues,
  getProductOptionValueName,
} from '@packages/shared/site';
import {type ConditionsWithTypeT} from '@packages/systems/dynamo/types';

type Bindings = [Partial<Record<BindingProperty, Binding>>];
type BindingProperty = keyof typeof mutators;
type Binding = {
  filter: BindingTransformer;
  type: FieldType;
  dataPath: string;
  timezone?: string;
  pageLinkHrefPrefix?: string;
  collectionSlugMap?: {
    [key: string]: string;
  };
  emailLinkSubject?: string;
};
type FieldType = string;
type TreeElementWithInputProperties = Element & {
  value?: string;
  type?: string;
  hasRendered?: boolean;
};
type TreeElementWithCheckedProperty = Element & {
  checked?: boolean;
};

type OrderData = {
  data?: {
    database?: {
      commerceOrder?: {
        availableShippingMethods?: [];
      };
    };
  };
};

const allowedFieldTypes = {
  ...SHARED_ALLOWED_FIELD_TYPES,
  'data-commerce-sku-id': ['ItemRef'],
} as const;

const isBindingPropToFieldTypeAllowed = (
  // @ts-expect-error - TS7006 - Parameter 'bindingProperty' implicitly has an 'any' type.
  bindingProperty,
  type: string | FieldType
) => {
  // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ readonly 'data-commerce-sku-id': readonly ["ItemRef"]; readonly innerHTML: { PlainText: string; HighlightedText: string; RichText: string; Number: string; Video: string; Option: string; Date: string; Phone: string; ... 6 more ...; CommercePropValues: string; }; ... 11 more ...; readonly dataWHref: string[]; }'.
  const allowedTypes = allowedFieldTypes[bindingProperty];
  return allowedTypes instanceof Array
    ? allowedTypes.indexOf(type) > -1
    : allowedTypes && type in allowedTypes;
};

// @ts-expect-error - TS7023 - 'getIn' implicitly has return type 'any' because it does not have a return type annotation and is referenced directly or indirectly in one of its return expressions. | TS7006 - Parameter 'object' implicitly has an 'any' type.
const getIn = (object, path: string) => {
  const nextDotIndex = path.indexOf('.');
  if (object == null) {
    return null;
  }
  if (nextDotIndex !== -1) {
    const pathPart = path.slice(0, nextDotIndex);
    const restOfPath = path.slice(nextDotIndex + 1, path.length);
    return getIn(object[pathPart], restOfPath);
  }
  return object[path];
};

const applyBindingsMutation = ({
  // @ts-expect-error - TS7031 - Binding element 'bindingProperty' implicitly has an 'any' type.
  bindingProperty,
  // @ts-expect-error - TS7031 - Binding element 'type' implicitly has an 'any' type.
  type,
  // @ts-expect-error - TS7031 - Binding element 'filter' implicitly has an 'any' type.
  filter,
  // @ts-expect-error - TS7031 - Binding element 'path' implicitly has an 'any' type.
  path,
  // @ts-expect-error - TS7031 - Binding element 'timezone' implicitly has an 'any' type.
  timezone,
  // @ts-expect-error - TS7031 - Binding element 'pageLinkHrefPrefix' implicitly has an 'any' type.
  pageLinkHrefPrefix,
  collectionSlugMap = {},
  // @ts-expect-error - TS7031 - Binding element 'data' implicitly has an 'any' type.
  data,
  // @ts-expect-error - TS7031 - Binding element 'node' implicitly has an 'any' type.
  node,
  emailLinkSubject = '',
}) => {
  if (!isBindingPropToFieldTypeAllowed(bindingProperty, type)) {
    return;
  }
  const prefix = 'data.';
  let suffix = '';
  if (type === 'ImageRef' && bindingProperty === 'src') {
    suffix = '.url';
  }

  let rawValue;

  if (type === 'CommercePropValues') {
    rawValue = getCommercePropValue(data, `${prefix}${path}`);
  } else {
    rawValue = getIn(data, `${prefix}${path}${suffix}`);
  }
  const transformedValue = transformers(rawValue, filter, {
    timezone,
    pageLinkHrefPrefix,
    collectionSlugMap,
    // @ts-expect-error - TS2339 - Property '__WEBFLOW_CURRENCY_SETTINGS' does not exist on type 'Window & typeof globalThis'.
    currencySettings: window.__WEBFLOW_CURRENCY_SETTINGS,
  });

  const propertyMutator = getPropertyMutator(bindingProperty, emailLinkSubject);
  if (typeof propertyMutator === 'function') {
    propertyMutator(node, type, transformedValue);
  }
};

const applyBindings = (
  bindings: Bindings | null,
  data: OrderData,
  node: Element
) => {
  if (bindings == null) {
    return;
  }
  bindings.forEach((binding) => {
    Object.keys(binding).forEach((bindingProperty) => {
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Partial<Record<"value" | "id" | "alt" | "data-commerce-sku-id" | "innerHTML" | "src" | "checked" | "for" | "style.color" | "style.background-color" | "style.border-color" | "style.background-image", Binding>>'.
      const bindingValue = binding[bindingProperty];
      const {
        type,
        filter,
        dataPath: path,
        timezone,
        pageLinkHrefPrefix,
        collectionSlugMap,
        emailLinkSubject,
      } = bindingValue;
      applyBindingsMutation({
        bindingProperty,
        type,
        filter,
        path,
        timezone,
        pageLinkHrefPrefix,
        collectionSlugMap,
        data,
        node,
        emailLinkSubject,
      });
    });
  });
};

const applyConditionalVisibility = (
  // @ts-expect-error - TS7006 - Parameter 'conditionData' implicitly has an 'any' type.
  conditionData,
  data: OrderData,
  node: Element
) => {
  if (!conditionData) {
    return;
  }

  const {dataPath, meta} = conditionData;
  const prefixedDataPath = `data.${dataPath}`;

  // manually construct item when the condition is bound to Product Options
  const item =
    meta && meta.type === 'CommercePropValues'
      ? {
          name: getIn(data, `${prefixedDataPath}.name`),
          value: getCommercePropValue(data, prefixedDataPath),
        }
      : getIn(data, prefixedDataPath);

  applyConditionToNode(node, item, conditionData, true);
};

export const applySkuBoundConditionalVisibility = ({
  conditionData,
  newSkuItem,
  node,
}: {
  conditionData: {
    condition: {
      fields: {
        [key: string]: any | ConditionsWithTypeT;
      };
    };
    timezone: string;
  };
  newSkuItem: {
    inventory: {
      type: string;
      quantity: string;
    };
  };
  node: Element;
}) => {
  const {condition} = conditionData;
  const skuConditionData = transform(condition.fields, (data, val, field) => {
    const skuField = field.split('default-sku:');
    if (skuField.length > 1) {
      // @ts-expect-error - TS18046 - 'data' is of type 'unknown'.
      data[skuField[1]] = val;
      return data;
    }
  });

  // Need to flatten the inventory quantity to allow cond vis bound to inventory counts
  const inventoryQuantity =
    newSkuItem.inventory.type === 'infinite'
      ? null
      : newSkuItem.inventory.quantity;
  const itemWithFlattenedInventory = {
    ...newSkuItem,
    ecSkuInventoryQuantity: inventoryQuantity,
  } as const;

  applyConditionToNode(
    node,
    itemWithFlattenedInventory,
    {
      ...conditionData,
      condition: {fields: skuConditionData},
    },
    true
  );
};

const createStyleMutator =
  // @ts-expect-error - TS7006 - Parameter 'node' implicitly has an 'any' type. | TS7006 - Parameter 'type' implicitly has an 'any' type.
  (property: string) => (node, type, value: unknown) => {
    if (!(node instanceof HTMLElement && typeof value === 'string')) {
      return;
    }
    if (type === 'ImageRef') {
      node.style.setProperty(property, `url(${value})`);
    }
    node.style.setProperty(property, value);
  };

const createAttributeMutator =
  // @ts-expect-error - TS7006 - Parameter 'node' implicitly has an 'any' type. | TS7006 - Parameter 'type' implicitly has an 'any' type.
  (attribute: string) => (node, type, value: unknown) => {
    const sanitizedString = value != null ? String(value) : '';
    node.setAttribute(attribute, sanitizedString);

    if (attribute === 'src' && sanitizedString) {
      removeWDynBindEmptyClass(node);
    }
  };

const valueMutator = (
  node: TreeElementWithInputProperties,
  type: any,
  value: any
) => {
  if (node.hasRendered) {
    return;
  }

  let sanitizedString;
  // if it's a select element, and we have no value, we default to the last value
  // this was added for the case of the first render of the country select field
  // so that it isn't a blank select box when it's a new order without a country set
  if (node.tagName === 'SELECT') {
    sanitizedString = value != null ? String(value) : node.value || '';
  } else {
    sanitizedString = value != null ? String(value) : '';
  }
  node.setAttribute('value', sanitizedString);
  if (node.tagName === 'INPUT' && String(node.type).toLowerCase() === 'text') {
    node.hasRendered = true;
  }
  node.value = sanitizedString;
};

const checkedMutator = (
  node: TreeElementWithCheckedProperty,
  type: any,
  value: any
) => {
  node.checked = Boolean(value);
};

// @ts-expect-error - TS7031 - Binding element 'height' implicitly has an 'any' type. | TS7031 - Binding element 'width' implicitly has an 'any' type.
const aspectRatio = ({height, width}) => {
  return height && width ? height / width : 0;
};

const mutators = {
  // @ts-expect-error - TS7006 - Parameter 'node' implicitly has an 'any' type. | TS7006 - Parameter 'type' implicitly has an 'any' type. | TS7006 - Parameter 'value' implicitly has an 'any' type.
  innerHTML: (node, type, value) => {
    const originalValue: any = value;
    if (type === 'Video') {
      /*
      TODO handle also innerHTML Video Links
      For example,
      innerHTML: [
        {
          id: 'video-id',
          slug: 'video',
          type: 'Video',
        },
        {
          id: 'url',
          slug: 'url',
          type: 'Link',
        },
      ],
       */
      value =
        value != null &&
        value.metadata != null &&
        typeof value.metadata.html === 'string'
          ? value.metadata.html
          : null;
    }
    const valueString = value != null ? String(value) : '';
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ PlainText: string; HighlightedText: string; RichText: string; Number: string; Video: string; Option: string; Date: string; Phone: string; Email: string; CommercePrice: string; Link: string; ImageRef: boolean; FileRef: boolean; ItemRef: boolean; CommercePropValues: string; }'.
    if (allowedFieldTypes.innerHTML[type] === 'innerHTML') {
      node.innerHTML = valueString;
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ PlainText: string; HighlightedText: string; RichText: string; Number: string; Video: string; Option: string; Date: string; Phone: string; Email: string; CommercePrice: string; Link: string; ImageRef: boolean; FileRef: boolean; ItemRef: boolean; CommercePropValues: string; }'.
    } else if (allowedFieldTypes.innerHTML[type] === 'innerText') {
      node.innerHTML = escape(valueString);
    }

    // Videos have their `padding-top` style set automatically to make them responsive, and are a locked style.
    // This is usually done in `shared/render/plugins/Embed/Video.jsx` on the server render. However, the render-time value
    // when rendering the cart is `0`, as the server does not have the associated data from the binding passed to it at
    // render-time, since we're not getting that data until we do the client-side render, after fetching the cart data.
    // So, along with setting the proper innerHTML binding for the embed itself, we also have to do an exception here
    // and set the `padding-top` to the video's aspect ratio (height over width).
    if (
      type === 'Video' &&
      originalValue &&
      originalValue.metadata &&
      node instanceof HTMLElement
    ) {
      node.style.setProperty(
        'padding-top',
        `${aspectRatio(originalValue.metadata) * 100}%`
      );
    }

    if (node.innerHTML) {
      removeWDynBindEmptyClass(node);
    }
  },
  'style.color': createStyleMutator('color'),
  'style.background-color': createStyleMutator('background-color'),
  'style.border-color': createStyleMutator('border-color'),
  'style.background-image': createStyleMutator('background-image'),
  src: createAttributeMutator('src'),
  alt: createAttributeMutator('alt'),
  id: createAttributeMutator('id'),
  for: createAttributeMutator('for'),
  value: valueMutator,
  checked: checkedMutator,
  'data-commerce-sku-id': createAttributeMutator('data-commerce-sku-id'),
} as const;

const hrefMutator =
  (emailLinkSubject: string) =>
  (node: Element, type: string | FieldType, value: unknown) => {
    if (value) {
      const href = String(value);
      switch (type) {
        case 'Phone': {
          node.setAttribute('href', formatPhone(href, 'href'));
          break;
        }
        case 'Email': {
          let subject;
          try {
            subject = encodeURIComponent(emailLinkSubject);
          } catch (e: any) {
            subject = '';
          }
          const formattedEmail = formatEmail(href, subject, 'href');
          node.setAttribute('href', formattedEmail || '#');
          break;
        }
        default: {
          node.setAttribute('href', href);
          break;
        }
      }
    } else {
      node.setAttribute('href', '#');
    }
  };

// @ts-expect-error - TS7006 - Parameter 'bindingProperty' implicitly has an 'any' type.
const getPropertyMutator = (bindingProperty, emailLinkSubject: string) => {
  if (bindingProperty === 'href' || bindingProperty === 'dataWHref') {
    return hrefMutator(emailLinkSubject);
  }
  // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ readonly innerHTML: (node: any, type: any, value: any) => void; readonly 'style.color': (node: any, type: any, value: unknown) => void; readonly 'style.background-color': (node: any, type: any, value: unknown) => void; ... 8 more ...; readonly 'data-commerce-sku-id': (node: any, type: any, value: unknown) => void; }'.
  if (typeof mutators[bindingProperty] === 'function') {
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ readonly innerHTML: (node: any, type: any, value: any) => void; readonly 'style.color': (node: any, type: any, value: unknown) => void; readonly 'style.background-color': (node: any, type: any, value: unknown) => void; ... 8 more ...; readonly 'data-commerce-sku-id': (node: any, type: any, value: unknown) => void; }'.
    return mutators[bindingProperty];
  }
  return null;
};

const getCommercePropValue = (data: OrderData, path: string) => {
  const option = getIn(data, path);

  if (option) {
    const pathToOptionAsArray = path.split('.');
    const pathToCommercePropValues = pathToOptionAsArray
      .slice(0, pathToOptionAsArray.indexOf('product'))
      .concat(['sku', 'f_sku_values_3dr'])
      .join('.');

    const skuValues = getIn(data, pathToCommercePropValues);

    if (Array.isArray(skuValues)) {
      return getProductOptionValueName(option, simplifySkuValues(skuValues));
    }
  }
  return '';
};

const getTemplateScript = (node: Element) => {
  const templateId = node.getAttribute(WF_TEMPLATE_ID_DATA_KEY);
  const templateScript =
    templateId &&
    node.parentElement &&
    node.parentElement.querySelector(`#${templateId}`);
  return templateScript;
};

const createDomFragment = (html: string) => {
  const div = document.createElement('div');
  div.innerHTML = html;
  return div.children[0];
};

const getTemplateString = (node: Element, index: number) => {
  const templateScript = getTemplateScript(node);
  const rawTemplateContent = templateScript && templateScript.textContent;
  const instanceRegEx =
    /([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}_instance-)\d+/gi;
  const decodedTemplate =
    rawTemplateContent &&
    decodeURIComponent(rawTemplateContent).replace(instanceRegEx, `$1${index}`);
  if (Boolean(decodedTemplate) && node.hasAttribute(WF_COLLECTION_DATA_KEY)) {
    const collectionPath = node.getAttribute(WF_COLLECTION_DATA_KEY);
    if (collectionPath && typeof collectionPath === 'string') {
      const searchTerm = encodeURIComponent(
        `${escape(collectionPath)}[]`
      ).replace(/\./g, '\\.');
      const templateSearchTerm = encodeURIComponent(
        `${escape(collectionPath)}${encodeURIComponent('[]')}`
      ).replace(/\./g, '\\.');
      const collectionPathRegExp = new RegExp(
        `${searchTerm}|${templateSearchTerm}`,
        'g'
      );
      return (
        decodedTemplate &&
        decodedTemplate.replace(
          collectionPathRegExp,
          `${collectionPath}.${index}`
        )
      );
    }
  }
  return decodedTemplate;
};

const getTemplateCollection = (node: Element, data: OrderData) => {
  const collectionPath =
    node.hasAttribute(WF_COLLECTION_DATA_KEY) &&
    node.getAttribute(WF_COLLECTION_DATA_KEY);
  return collectionPath ? getIn(data, `data.${collectionPath}`) : [];
};

const checkForAndApplyTemplateCollection = (node: Element, data: OrderData) => {
  if (node && node.hasAttribute(WF_TEMPLATE_ID_DATA_KEY)) {
    const collection = getTemplateCollection(node, data);
    node.innerHTML = '';

    if (collection != null && collection.length > 0) {
      for (let index = 0; index < collection.length; index++) {
        const templateString = getTemplateString(node, index);
        const template = templateString && createDomFragment(templateString);
        if (template instanceof Element) {
          if (typeof node.append === 'function') {
            node.append(renderTree(template, data));
          } else if (typeof node.appendChild === 'function') {
            node.appendChild(renderTree(template, data));
          } else {
            throw new Error('Could not append child to node');
          }
        }
      }
    }
  }
};

const checkForAndApplyBindings = (node: Element, data: OrderData) => {
  if (node && node.hasAttribute(WF_BINDING_DATA_KEY)) {
    const bindingData = safeParseJson(node.getAttribute(WF_BINDING_DATA_KEY));
    applyBindings(bindingData, data, node);
  }
};

const checkForAndApplyConditionalVisibility = (
  node: Element,
  data: OrderData
) => {
  if (node && node.hasAttribute(WF_CONDITION_DATA_KEY)) {
    const conditionData = safeParseJson(
      node.getAttribute(WF_CONDITION_DATA_KEY)
    );
    applyConditionalVisibility(conditionData, data, node);
  }
};

export const renderTree = (tree: Element, data: OrderData) => {
  data = flattenOrderData(data);

  return walkDOM(tree, (node) => {
    checkForAndApplyTemplateCollection(node, data);
    checkForAndApplyBindings(node, data);
    checkForAndApplyConditionalVisibility(node, data);
  });
};

const shippingDataReplacementPaths = {
  cardProvider: ['customerInfo', 'stripePayment', 'card', 'provider'],
  cardLastFour: ['customerInfo', 'stripePayment', 'card', 'last4'],
  cardExpiresMonth: [
    'customerInfo',
    'stripePayment',
    'card',
    'expires',
    'month',
  ],
  cardExpiresYear: ['customerInfo', 'stripePayment', 'card', 'expires', 'year'],
  customerEmail: ['customerInfo', 'identity', 'email'],
  shippingAddressAddressee: ['customerInfo', 'shippingAddress', 'addressee'],
  shippingAddressLine1: ['customerInfo', 'shippingAddress', 'line1'],
  shippingAddressLine2: ['customerInfo', 'shippingAddress', 'line2'],
  shippingAddressCity: ['customerInfo', 'shippingAddress', 'city'],
  shippingAddressState: ['customerInfo', 'shippingAddress', 'state'],
  shippingAddressCountry: ['customerInfo', 'shippingAddress', 'country'],
  shippingAddressPostalCode: ['customerInfo', 'shippingAddress', 'postalCode'],
  billingAddressAddressee: ['customerInfo', 'billingAddress', 'addressee'],
  billingAddressLine1: ['customerInfo', 'billingAddress', 'line1'],
  billingAddressLine2: ['customerInfo', 'billingAddress', 'line2'],
  billingAddressCity: ['customerInfo', 'billingAddress', 'city'],
  billingAddressPostalCode: ['customerInfo', 'billingAddress', 'postalCode'],
  billingAddressState: ['customerInfo', 'billingAddress', 'state'],
  billingAddressCountry: ['customerInfo', 'billingAddress', 'country'],
  requiresShipping: ['statusFlags', 'requiresShipping'],
  hasDownloads: ['statusFlags', 'hasDownloads'],
} as const;

const flattenCustomData = (customData: unknown) =>
  // @ts-expect-error - Flow 0.121.0
  customData.reduce<Record<string, any>>((flattenedData, data) => {
    if (data.textArea) {
      flattenedData.additionalTextArea = data.textArea;
    } else if (data.textInput) {
      flattenedData.additionalTextInput = data.textInput;
    } else if (data.checkbox !== null) {
      flattenedData.additionalCheckbox = data.checkbox;
    }
    return flattenedData;
  }, {});

const flattenOrderData = (data: OrderData) => {
  const orderExists =
    data &&
    data.data &&
    data.data.database &&
    data.data.database.commerceOrder !== null;

  if (!orderExists) {
    return data;
  }

  // @ts-expect-error - TS2339 - Property 'commerceOrder' does not exist on type '{ commerceOrder?: { availableShippingMethods?: [] | undefined; } | undefined; } | undefined'. | TS18048 - 'data.data' is possibly 'undefined'.
  const {commerceOrder} = data.data.database;
  const paymentProcessor = commerceOrder.paymentProcessor;
  const availableShippingMethods = commerceOrder.availableShippingMethods || [];
  const selectedShippingMethod = availableShippingMethods.find(
    // @ts-expect-error - TS7006 - Parameter 'shippingMethod' implicitly has an 'any' type.
    (shippingMethod) => shippingMethod.selected === true
  );
  const flattenedCustomData = commerceOrder.customData
    ? flattenCustomData(commerceOrder.customData)
    : {};

  const flattenedOrderData = {
    ...commerceOrder,
    shippingMethodName: selectedShippingMethod && selectedShippingMethod.name,
    shippingMethodDescription:
      selectedShippingMethod && selectedShippingMethod.description,
    ...flattenedCustomData,
  } as const;

  // We have to deep clone the data here, as the properties from the data
  // returned by Apollo are read-only.
  const clonedData = cloneDeep(data);

  // @ts-expect-error - TS18048 - 'clonedData.data' is possibly 'undefined'. | TS18048 - 'clonedData.data.database' is possibly 'undefined'.
  clonedData.data.database.commerceOrder = Object.keys(
    shippingDataReplacementPaths
  ).reduce((updatedData: Record<any, any>, flattenPath: string) => {
    // Override cardProvider for PayPal order
    if (flattenPath === 'cardProvider' && paymentProcessor === 'paypal') {
      updatedData = {
        ...updatedData,
        cardProvider: 'PayPal',
      };
      return updatedData;
    }

    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly cardProvider: readonly ["customerInfo", "stripePayment", "card", "provider"]; readonly cardLastFour: readonly ["customerInfo", "stripePayment", "card", "last4"]; readonly cardExpiresMonth: readonly [...]; ... 17 more ...; readonly hasDownloads: readonly [...]; }'.
    const replacementFrom: string[] = shippingDataReplacementPaths[flattenPath];
    const replacementData = replacementFrom.reduce(
      (acc, key) => acc && acc[key],
      updatedData
    );
    updatedData[flattenPath] = replacementData;

    return updatedData;
  }, flattenedOrderData);

  return clonedData;
};
