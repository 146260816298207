/*
  globals
  document,
  HTMLFormElement,
  HTMLInputElement,
  window
*/

import {
  userSystemsRequestClient,
  showAndFocusElement,
  hideElement,
  handleErrorNode,
} from './utils';
import {
  USYS_DATA_ATTRS,
  USYS_DOM_CLASS_NAMES,
  USYS_FORM_TYPES,
  USYS_INPUT_TYPES,
  UPDATE_PASSWORD_UI_ERROR_CODES,
  updatePasswordErrorStates,
  ERROR_MSG_CLASS,
  ERROR_ATTRIBUTE_PREFIX,
} from '@packages/systems/users/constants';
import {updatePasswordMutation} from './mutations';

const updatePasswordFormQuerySelector = `form[${USYS_DATA_ATTRS.formType}="${USYS_FORM_TYPES.updatePassword}"]`;

// error handling
const errorState = document.querySelector(`[${USYS_DATA_ATTRS.formError}]`);
const defaultErrorCopy =
  // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
  updatePasswordErrorStates[UPDATE_PASSWORD_UI_ERROR_CODES.GENERAL_ERROR].copy;
const errorMsgNode = document.querySelector(`.${ERROR_MSG_CLASS}`);

const getUpdatePasswordErrorCode = (error: string) => {
  let errorCode;
  switch (error) {
    case 'UsysInvalidPassword':
      errorCode = UPDATE_PASSWORD_UI_ERROR_CODES.WEAK_PASSWORD;
      break;
    default:
      errorCode = UPDATE_PASSWORD_UI_ERROR_CODES.GENERAL_ERROR;
  }
  return errorCode;
};

function getUpdatePasswordForms() {
  const updatePasswordForms = document.querySelectorAll(
    updatePasswordFormQuerySelector
  );
  return Array.prototype.slice
    .call(updatePasswordForms)
    .filter(
      (updatePasswordForm) => updatePasswordForm instanceof HTMLFormElement
    );
}

export function handleUpdatePasswordForms() {
  getUpdatePasswordForms().forEach((updatePasswordForm) => {
    updatePasswordForm.addEventListener('submit', (event: Event) => {
      event.preventDefault();

      const form = event.currentTarget;
      const successMessage = document.querySelector(
        `.${USYS_DOM_CLASS_NAMES.formSuccess}`
      );

      if (!(form instanceof HTMLFormElement)) {
        return;
      }

      const errorElement = document.querySelector(
        `[${USYS_DATA_ATTRS.formError}]`
      );

      // @ts-expect-error - TS2345 - Argument of type 'Element | null' is not assignable to parameter of type 'HTMLElement | null | undefined'.
      hideElement(errorElement);

      const passwordInput = form.querySelector(
        `input[${USYS_DATA_ATTRS.inputType}="${USYS_INPUT_TYPES.password}"]`
      );

      if (!(passwordInput instanceof HTMLInputElement)) {
        return;
      }

      const params = new URLSearchParams(window.location.search);
      const token = params.get('token') || '';

      asyncRequestUpdatePassword(passwordInput.value, token)
        .then(() => {
          hideElement(form);
          // @ts-expect-error - TS2345 - Argument of type 'Element | null' is not assignable to parameter of type 'HTMLElement | null | undefined'.
          showAndFocusElement(successMessage);
        })
        .catch((error) => {
          if (errorState) {
            // if there isn't an error code, send an empty string so a generic error message appears
            const elementErrorCode = error?.graphQLErrors?.[0]?.code ?? '';
            const errorCode = getUpdatePasswordErrorCode(elementErrorCode);
            handleErrorNode(
              errorMsgNode,
              // @ts-expect-error - TS2345 - Argument of type 'Element' is not assignable to parameter of type 'HTMLElement'.
              errorState,
              errorCode,
              ERROR_ATTRIBUTE_PREFIX.UPDATE_PASSWORD,
              defaultErrorCopy
            );
          }
        });
    });
  });
}

export function asyncRequestUpdatePassword(
  authPassword: string,
  token: string
) {
  return userSystemsRequestClient.mutate({
    mutation: updatePasswordMutation,
    variables: {
      authPassword,
      token,
    },
  });
}
