function setSplashScreenStyles(element: HTMLElement) {
  element.style.display = 'flex';
  element.style.position = 'fixed';
  element.style.top = '0';
  element.style.left = '0';
  element.style.width = '100%';
  element.style.height = '100%';
  element.style.background = 'rgba(255, 255, 255, 0.8)';
  element.style.justifyContent = 'center';
  element.style.alignItems = 'center';
  element.style.textAlign = 'center';
  element.style.zIndex = '1000';
  element.style.opacity = '0';
  element.style.transition = 'opacity 1s ease-in-out';
}

const addSplashScreen = () => {
  const splashScreen = document.createElement('div');
  setSplashScreenStyles(splashScreen);

  const captchaContainer = document.createElement('div');
  splashScreen.appendChild(captchaContainer);
  document.body.appendChild(splashScreen);

  setTimeout(() => {
    splashScreen.style.opacity = '1';
  }, 10);
  return splashScreen;
};

export const renderTurnstileCaptcha = (
  captchaSiteKey: string,
  captchaMode: string,
  cb: any
) => {
  let splashScreen: HTMLDivElement | undefined;
  if (captchaMode !== 'invisible') {
    splashScreen = addSplashScreen();
  }

  const captchaContainer = document.createElement('div');
  if (splashScreen) {
    splashScreen.appendChild(captchaContainer);
  } else {
    document.body.appendChild(captchaContainer);
  }

  // Render the captcha
  // @ts-expect-error - TS2304: Cannot find name 'turnstile'.
  turnstile.render(captchaContainer, {
    sitekey: captchaSiteKey,
    callback: function (token: string) {
      setTimeout(function () {
        if (splashScreen) {
          splashScreen.remove();
        }
      }, 1000);
      return cb(token);
    },
  });
};
