/* globals
 window,
 HTMLElement,
 */

import {createApolloClient} from '@packages/utilities/apolloClient';
import {
  ERROR_ATTRIBUTE_PREFIX,
  ERROR_MSG_CLASS,
  SERVER_DATA_VALIDATION_ERRORS,
  SIGNUP_UI_ERROR_CODES,
  USYS_DATA_ATTRS,
  signUpErrorStates,
} from '@packages/systems/users/constants';

/**
 * SECURITY NOTE:
 * This route is under anti-bot protection via rate limiting and/or PerimeterX. If you deprecate or
 * migrate this route, or modify the path name for this route, notify the #security channel. Also update
 * the file https://github.com/webflow/webflow-proxy/blob/dev/etc/perimeterx-enabled-routes.txt
 * Failing to do the above can result in a security incident
 */
const GQL_QUERY_PATH = '/.wf_graphql/usys/apollo';

export const userSystemsRequestClient = createApolloClient({
  path: GQL_QUERY_PATH,
  useCsrf: true,
  retryConfig: {
    maxAttempts: 5,
  },
});

// use to work with legacy form atoms
export function addHiddenClass(el: HTMLElement | null) {
  if (el) {
    el.classList.add('w-hidden');
  }
}

// use to work with legacy form atoms
export function removeHiddenClass(el: HTMLElement | null) {
  if (el) {
    el.classList.remove('w-hidden');
  }
}

/** If you are hiding a previous element that could have focus please use showAndFocusElement instead. */
export function showElement(el?: HTMLElement | null) {
  if (el) {
    el.style.display = 'block';
  }
}

/** Show and focus an element, helpful for our common pattern of hiding form inputs when we show success states */
export function showAndFocusElement(el?: HTMLElement | null) {
  if (el) {
    el.style.display = 'block';
    el.focus();
  }
}

export function hideElement(el?: HTMLElement | null) {
  if (el) {
    el.style.display = 'none';
  }
}

/**
 * Returns an API used for parsing strings into DOM nodes
 */
export type DomParserType = {
  getHtmlFromString: (arg1: string) => Node | null;
};

export function getDomParser(): DomParserType {
  const domParser: DOMParser = new window.DOMParser();

  return {
    /**
     * Returns an html node for an encoded string
     * @param {string} str - Encoded string to parse
     */
    getHtmlFromString(str: string) {
      const decodedString = decodeURIComponent(str);
      const parsedHtml = domParser.parseFromString(decodedString, 'text/html');

      if (!parsedHtml || !parsedHtml.body || !parsedHtml.body.firstChild)
        return null;

      return parsedHtml.body.firstChild;
    },
  };
}

export const getErrorAttrName = (errorAttr: string, errorCode: string) => {
  const formattedErrorCode = errorCode.replace('_', '-').toLowerCase();
  return `${errorAttr}-${formattedErrorCode}-error`;
};

export const handleErrorNode = (
  errorMsgNode: any,
  errorStateNode: HTMLElement,
  errorCode: string,
  errorAttrPrefix: string,
  defaultErrorCopy: string
) => {
  // get the error copy from the data attribute
  const errorAttr = getErrorAttrName(errorAttrPrefix, errorCode);
  const errorCopy = errorMsgNode && errorMsgNode.getAttribute(errorAttr);
  // announce the contents of the div to ATs
  errorMsgNode.setAttribute('aria-live', 'assertive');
  // set the message text to the error message or default copy if it is null
  errorMsgNode.textContent = errorCopy ? errorCopy : defaultErrorCopy;
  // show the error state Element
  showElement(errorStateNode);
};

export function disableSubmit(submit: Element | null): string {
  if (!submit) return '';

  // Disable submit
  submit.setAttribute('disabled', 'true');
  // Store previous value
  const value = submit.getAttribute('value');
  // Show wait text
  const waitText = submit.getAttribute('data-wait');
  if (waitText) submit.setAttribute('value', waitText);

  return value ?? '';
}

export function resetSubmit(submit: HTMLElement | null, text: string) {
  if (!submit) return;
  // Reenable submit
  submit.removeAttribute('disabled');
  // Reset text
  submit.setAttribute('value', text);
}

export function getRedirectPath() {
  const queryString = window.location.search;
  const redirectParam = queryString.match(/[?|&]usredir=([^@&?=]+)/g);

  if (!redirectParam) return undefined;

  const encodedPath = redirectParam[0].substring('?usredir='.length);
  return decodeURIComponent(encodedPath);
}

export function redirectWithUsrdir(location: string) {
  const redirectParam = getRedirectPath();
  let encodedPath;
  if (redirectParam) {
    // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
    encodedPath = redirectParam[0].substring('?usredir='.length);
  } else {
    encodedPath = encodeURIComponent(window.location.pathname);
  }
  // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'Location | (string & Location)'.
  window.location = location + `?usredir=${encodedPath}`;
}

// Test if path has a /, ., or @ at the beginning. If so, remove it.
function normalizedRedirectPath(path: string) {
  // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
  const hasLeadingSymbol = /\/|\.|\@/g.test(path[0]);
  return hasLeadingSymbol ? path.substring(1) : path;
}

export function handleRedirect(
  defaultRedirectPath?: string | null,
  includeDelay: boolean = false
) {
  const redirectPath = getRedirectPath();

  const redirectUrl = (() => {
    if (redirectPath) {
      return `${window.location.origin}/${normalizedRedirectPath(
        redirectPath
      )}`;
    }
    if (defaultRedirectPath) {
      return `${window.location.origin}/${normalizedRedirectPath(
        defaultRedirectPath
      )}`;
    }
    return;
  })();

  if (!redirectUrl) return;

  return includeDelay
    ? // @ts-expect-error - TS2339 - Property 'Webflow' does not exist on type 'Window & typeof globalThis'.
      setTimeout(() => window.Webflow.location(redirectUrl), 3000)
    : // @ts-expect-error - TS2339 - Property 'Webflow' does not exist on type 'Window & typeof globalThis'.
      window.Webflow.location(redirectUrl);
}

type FailedValidations = {
  fieldId: string;
  name: keyof typeof SERVER_DATA_VALIDATION_ERRORS;
}[];

const wrapperSelectors = ['.w-file-upload-error'];

const setErrorMsg = (
  // @ts-expect-error - TS7006 - Parameter 'wrapper' implicitly has an 'any' type.
  wrapper,
  // @ts-expect-error - TS7006 - Parameter 'fieldElements' implicitly has an 'any' type.
  fieldElements,
  name:
    | 'DefaultError'
    | 'ExtensionsError'
    | 'MaxSizeError'
    | 'MinSizeError'
    | 'RequiredError'
) => {
  for (let i = 0; i < fieldElements.length; ++i) {
    const errorText = fieldElements[i].getAttribute(name);
    if (errorText) {
      fieldElements[i].innerHTML = errorText;
      removeHiddenClass(wrapper);
      return true;
    }
  }
};

const handleValidationErrors = (
  form: HTMLElement,
  failedValidations: FailedValidations
) => {
  const wrappers: Array<HTMLElement | any> = [];
  wrapperSelectors.forEach((wrapperSelector) => {
    const _wrappers = form.querySelectorAll(wrapperSelector);

    for (let i = 0; i < _wrappers.length; ++i) {
      wrappers.push(_wrappers[i]);
    }
  });
  wrappers.forEach((wrapper) => {
    for (let i = 0; i < failedValidations.length; ++i) {
      const failedValidation = failedValidations[i];
      // @ts-expect-error - TS18048 - 'failedValidation' is possibly 'undefined'.
      const name = failedValidation.name;
      // @ts-expect-error - TS18048 - 'failedValidation' is possibly 'undefined'.
      const fieldId = failedValidation.fieldId;
      const fieldElements = wrapper.querySelectorAll(
        '[' + USYS_DATA_ATTRS.field + '="' + fieldId + '"]'
      );
      if (fieldElements && setErrorMsg(wrapper, fieldElements, name)) {
        break;
      }
    }
  });
};

// error handling
const defaultErrorCopy =
  // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
  signUpErrorStates[SIGNUP_UI_ERROR_CODES.GENERAL_ERROR].copy;

export const userFormError =
  (
    form: HTMLElement | null,
    errorState: HTMLElement | null,
    formType: keyof typeof ERROR_ATTRIBUTE_PREFIX
  ) =>
  (error: any) => {
    if (errorState === null || form === null) return;
    const errorMsgNode = errorState.querySelector(`.${ERROR_MSG_CLASS}`);
    const failedValidations = error.graphQLErrors?.[0]?.failedValidations;

    if (failedValidations) {
      handleValidationErrors(form, failedValidations);
    }

    // if there isn't an error code, send an empty string so a generic error message appears
    const elementErrorCode = error?.graphQLErrors?.[0]?.code ?? '';
    const errorCode = getSignupErrorCode(elementErrorCode);

    handleErrorNode(
      errorMsgNode,
      errorState,
      errorCode,
      ERROR_ATTRIBUTE_PREFIX[formType],
      defaultErrorCopy
    );
  };

export const getSignupErrorCode = (error: string) => {
  let errorCode;
  switch (error) {
    case 'UsysInvalidUserData':
      errorCode = SIGNUP_UI_ERROR_CODES.VALIDATION_FAILED;
      break;
    case 'UsysUnauthorizedEmail':
      errorCode = SIGNUP_UI_ERROR_CODES.NOT_ALLOWED;
      break;
    case 'UsysMustUseInvitation':
      errorCode = SIGNUP_UI_ERROR_CODES.USE_INVITE_EMAIL;
      break;
    case 'UsysDuplicateEmail':
      errorCode = SIGNUP_UI_ERROR_CODES.EMAIL_ALREADY_EXIST;
      break;
    case 'UsysInvalidEmail':
      errorCode = SIGNUP_UI_ERROR_CODES.INVALID_EMAIL;
      break;
    case 'UsysInvalidPassword':
      errorCode = SIGNUP_UI_ERROR_CODES.INVALID_PASSWORD;
      break;
    case 'UsysInvalidToken':
      errorCode = SIGNUP_UI_ERROR_CODES.NOT_VERIFIED;
      break;
    case 'UsysExpiredToken':
      errorCode = SIGNUP_UI_ERROR_CODES.EXPIRED_TOKEN;
      break;
    default:
      errorCode = SIGNUP_UI_ERROR_CODES.GENERAL_ERROR;
  }
  return errorCode;
};
