/* globals window */
import gql from 'graphql-tag';
import {
  findElementByNodeType,
  findAllElementsByNodeType,
  findClosestElementByNodeType,
  findClosestElementWithAttribute,
  isFreeOrder,
  showElement,
  hideElement,
  fetchOrderStatusFlags,
} from './commerceUtils';
import {
  StripeStore,
  generateDisplayItemsFromOrder,
  generateShippingOptionsFromMethods,
} from './stripeStore';
import EventHandlerProxyWithApolloClient from './eventHandlerProxyWithApolloClient';
import {
  createAttemptSubmitOrderRequest,
  getOrderDataFromGraphQLResponse,
  orderRequiresAdditionalAction,
  redirectToOrderConfirmation,
  createOrderIdentityMutation,
  createOrderAddressMutation,
  createOrderShippingMethodMutation,
  createStripePaymentMethodMutation,
  createRecalcOrderEstimationsMutation,
  createUpdateObfuscatedOrderAddressMutation,
  showErrorMessageForError as showCheckoutErrorMessageForError,
} from './checkoutUtils';
import {
  isCartOpen,
  showErrorMessageForError as showCartErrorMessageForError,
} from './cartUtils';

import {
  NODE_TYPE_COMMERCE_CART_QUICK_CHECKOUT_ACTIONS,
  NODE_TYPE_COMMERCE_CART_QUICK_CHECKOUT_BUTTON,
  NODE_TYPE_COMMERCE_CART_APPLE_PAY_BUTTON,
  CART_QUERY,
  CHECKOUT_QUERY,
  STRIPE_ELEMENT_INSTANCE,
} from '@packages/systems/commerce/constants';

import debug from './debug';
import {ApolloClient, NormalizedCacheObject} from '@apollo/client';

const hasItems = (response: any) =>
  response &&
  response.data &&
  response.data.database &&
  response.data.database.commerceOrder &&
  response.data.database.commerceOrder.userItems &&
  response.data.database.commerceOrder.userItems.length > 0;

// @ts-expect-error - TS7031 - Binding element 'target' implicitly has an 'any' type.
const isWebPaymentsButtonEvent = ({target}) => {
  const cartCheckoutButton = findClosestElementByNodeType(
    NODE_TYPE_COMMERCE_CART_QUICK_CHECKOUT_BUTTON,
    target
  );
  const cartApplePayButton = findClosestElementByNodeType(
    NODE_TYPE_COMMERCE_CART_APPLE_PAY_BUTTON,
    target
  );
  if (cartCheckoutButton) {
    return cartCheckoutButton;
  } else if (cartApplePayButton) {
    return cartApplePayButton;
  } else {
    return false;
  }
};

export const updateWebPaymentsButton = (
  wrapper: Element,
  data: Record<any, any>,
  stripeStore?: StripeStore
) => {
  const webPaymentsActionsElements = findAllElementsByNodeType(
    NODE_TYPE_COMMERCE_CART_QUICK_CHECKOUT_ACTIONS,
    wrapper
  );
  if (
    !webPaymentsActionsElements ||
    webPaymentsActionsElements.length === 0 ||
    !hasItems(data)
  ) {
    return;
  }

  webPaymentsActionsElements.forEach((webPaymentsActions) => {
    hideElement(webPaymentsActions);

    if (
      !stripeStore ||
      !stripeStore.isInitialized() ||
      !data.data.site.commerce.quickCheckoutEnabled
    ) {
      return;
    }

    const stripeInstance = parseInt(
      // @ts-expect-error - TS2345 - Argument of type 'string | null' is not assignable to parameter of type 'string'.
      wrapper.getAttribute(STRIPE_ELEMENT_INSTANCE),
      10
    );
    const paymentRequest = stripeStore.updateCartPaymentRequest(
      stripeInstance,
      data.data.database.commerceOrder,
      data.data.site.commerce
    );

    if (
      !paymentRequest ||
      typeof paymentRequest.canMakePayment !== 'function'
    ) {
      return;
    }

    if (isFreeOrder(data)) {
      return;
    }

    paymentRequest
      .canMakePayment()
      // @ts-expect-error - TS7006 - Parameter 'result' implicitly has an 'any' type.
      .then((result) => {
        if (!result) {
          return;
        }
        const {applePay} = result;

        showElement(webPaymentsActions);

        const cartCheckoutButton = findElementByNodeType(
          NODE_TYPE_COMMERCE_CART_QUICK_CHECKOUT_BUTTON,
          webPaymentsActions
        );
        const cartApplePayButton = findElementByNodeType(
          NODE_TYPE_COMMERCE_CART_APPLE_PAY_BUTTON,
          webPaymentsActions
        );

        if (!cartCheckoutButton || !cartApplePayButton) {
          return;
        }

        if (applePay) {
          hideElement(cartCheckoutButton);
          showElement(cartApplePayButton);
        } else {
          hideElement(cartApplePayButton);
          showElement(cartCheckoutButton);
        }
      })
      .catch(() => {
        debug.log(
          'PaymentRequest not available in this browser – silently exiting'
        );
      });
  });
};

const getShippingMethodsQuery = gql`
  query FetchShippingMethods {
    database {
      id
      commerceOrder {
        id
        availableShippingMethods {
          id
          name
          description
          price {
            value
          }
        }
      }
    }
  }
`;

const handleWebPaymentsButton = (
  event: Event,
  apolloClient: ApolloClient<NormalizedCacheObject>,
  stripeStore: StripeStore
) => {
  event.preventDefault();
  if (window.Webflow.env('design') || window.Webflow.env('preview')) {
    if (window.Webflow.env('preview')) {
      window.alert('Web Payments is not available in preview mode.');
    }
    return;
  }

  const {currentTarget} = event;

  const stripeElement = findClosestElementWithAttribute(
    STRIPE_ELEMENT_INSTANCE,
    // @ts-expect-error - TS2345 - Argument of type 'EventTarget | null' is not assignable to parameter of type 'EventTarget'.
    currentTarget
  );
  if (!(stripeElement instanceof Element)) {
    return;
  }

  const stripeInstance = parseInt(
    // @ts-expect-error - TS2345 - Argument of type 'string | null' is not assignable to parameter of type 'string'.
    stripeElement.getAttribute(STRIPE_ELEMENT_INSTANCE),
    10
  );
  const paymentRequest = stripeStore.getCartPaymentRequest(stripeInstance);

  // @ts-expect-error - TS18048 - 'paymentRequest' is possibly 'undefined'.
  paymentRequest.show();

  // @ts-expect-error - TS18048 - 'paymentRequest' is possibly 'undefined'.
  if (paymentRequest.hasRegisteredListener('paymentmethod')) {
    // we remove any existing event listeners, which can happen if the modal
    // was closed before a successful payment occurred
    // @ts-expect-error - TS18048 - 'paymentRequest' is possibly 'undefined'.
    paymentRequest.removeAllListeners();
  }

  // @ts-expect-error - TS18048 - 'paymentRequest' is possibly 'undefined'.
  paymentRequest.on(
    'shippingaddresschange',
    // @ts-expect-error - TS7031 - Binding element 'updateWith' implicitly has an 'any' type. | TS7031 - Binding element 'shippingAddress' implicitly has an 'any' type.
    ({updateWith, shippingAddress}) => {
      // @ts-expect-error - TS7034 - Variable 'shippingMethods' implicitly has type 'any[]' in some locations where its type cannot be determined.
      let shippingMethods = [];
      const graphQlQuery =
        stripeElement.getAttribute(CART_QUERY) ||
        stripeElement.getAttribute(CHECKOUT_QUERY);
      // In some cases we receive an obfuscated address from quick checkout service (e.g. Apple pay)
      // We're using a different mutation to mark such address as 'obfuscated' in order to skip address validation when calculating taxes
      createUpdateObfuscatedOrderAddressMutation(apolloClient, {
        type: 'shipping',
        name: shippingAddress.recipient,
        address_line1: shippingAddress.addressLine[0],
        address_line2: shippingAddress.addressLine[1],
        address_city: shippingAddress.city,
        address_state: shippingAddress.region,
        address_country: shippingAddress.country,
        address_zip: shippingAddress.postalCode,
      })
        .then(() => {
          return apolloClient.query({
            query: getShippingMethodsQuery,
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
          });
        })
        .then(({data}) => {
          if (
            !data.database.commerceOrder.availableShippingMethods ||
            data.database.commerceOrder.availableShippingMethods.length === 0
          ) {
            updateWith({
              status: 'invalid_shipping_address',
            });
            return Promise.reject('No valid shipping addresses');
          } else {
            shippingMethods =
              data.database.commerceOrder.availableShippingMethods;
            return createOrderShippingMethodMutation(
              apolloClient,
              data.database.commerceOrder.availableShippingMethods[0].id
            );
          }
        })
        .then(() => {
          return createRecalcOrderEstimationsMutation(apolloClient);
        })
        .then(() => {
          return apolloClient.query({
            query: gql`
              ${graphQlQuery}
            `,
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
          });
        })
        .then(({data}) => {
          updateWith({
            status: 'success',
            displayItems: generateDisplayItemsFromOrder(
              data.database.commerceOrder,
              true
            ),
            shippingOptions:
              // @ts-expect-error - TS7005 - Variable 'shippingMethods' implicitly has an 'any[]' type.
              generateShippingOptionsFromMethods(shippingMethods),
            total: {
              amount: data.database.commerceOrder.total.value,
              label: 'Total',
              pending: false,
            },
          });
        });
    }
  );

  // @ts-expect-error - TS7031 - Binding element 'updateWith' implicitly has an 'any' type. | TS7031 - Binding element 'shippingOption' implicitly has an 'any' type.
  paymentRequest.on('shippingoptionchange', ({updateWith, shippingOption}) => {
    const graphQlQuery =
      stripeElement.getAttribute(CART_QUERY) ||
      stripeElement.getAttribute(CHECKOUT_QUERY);
    createOrderShippingMethodMutation(apolloClient, shippingOption.id)
      .then(() => {
        return createRecalcOrderEstimationsMutation(apolloClient);
      })
      .then(() => {
        return apolloClient.query({
          query: gql`
            ${graphQlQuery}
          `,
          fetchPolicy: 'network-only',
          errorPolicy: 'all',
        });
      })
      .then(({data}) => {
        updateWith({
          status: 'success',
          displayItems: generateDisplayItemsFromOrder(
            data.database.commerceOrder,
            true
          ),
          total: {
            amount: data.database.commerceOrder.total.value,
            label: 'Total',
            pending: false,
          },
        });
      });
  });

  // @ts-expect-error - TS7006 - Parameter 'ev' implicitly has an 'any' type.
  paymentRequest.on('paymentmethod', (ev) => {
    fetchOrderStatusFlags(apolloClient)
      .then(({requiresShipping}) => {
        return Promise.all([
          createOrderIdentityMutation(apolloClient, ev.payerEmail),
          requiresShipping
            ? createOrderAddressMutation(apolloClient, {
                type: 'shipping',
                name: ev.shippingAddress.recipient,
                address_line1: ev.shippingAddress.addressLine[0],
                address_line2: ev.shippingAddress.addressLine[1],
                address_city: ev.shippingAddress.city,
                address_state: ev.shippingAddress.region,
                address_country: ev.shippingAddress.country,
                address_zip: ev.shippingAddress.postalCode,
              })
            : Promise.resolve(),
          createOrderAddressMutation(apolloClient, {
            type: 'billing',
            name: ev.paymentMethod.billing_details.name,
            address_line1: ev.paymentMethod.billing_details.address.line1,
            address_line2: ev.paymentMethod.billing_details.address.line2,
            address_city: ev.paymentMethod.billing_details.address.city,
            address_state: ev.paymentMethod.billing_details.address.state,
            address_country: ev.paymentMethod.billing_details.address.country,
            address_zip: ev.paymentMethod.billing_details.address.postal_code,
          }),
          requiresShipping
            ? createOrderShippingMethodMutation(
                apolloClient,
                ev.shippingOption.id
              )
            : Promise.resolve(),
          createStripePaymentMethodMutation(apolloClient, ev.paymentMethod.id),
        ]);
      })
      .then(() => {
        return createAttemptSubmitOrderRequest(apolloClient, {
          checkoutType: 'quickCheckout',
        });
      })
      .then((data) => {
        const order = getOrderDataFromGraphQLResponse(data);
        if (orderRequiresAdditionalAction(order.status)) {
          ev.complete('success');

          const stripe = stripeStore.getStripeInstance();
          // @ts-expect-error - TS7006 - Parameter 'result' implicitly has an 'any' type.
          return stripe.handleCardAction(order.clientSecret).then((result) => {
            if (result.error) {
              return Promise.reject(new Error('payment_intent_failed'));
            }

            return createAttemptSubmitOrderRequest(apolloClient, {
              checkoutType: 'quickCheckout',
              paymentIntentId: result.paymentIntent.id,
            }).then((resp) => {
              const finishedOrder = getOrderDataFromGraphQLResponse(resp);

              if (finishedOrder.ok) {
                redirectToOrderConfirmation(finishedOrder);
              } else {
                return Promise.reject(new Error('payment_intent_failed'));
              }
            });
          });
        }
        if (order.ok) {
          ev.complete('success');
          redirectToOrderConfirmation(order);
        } else {
          return Promise.reject(new Error('order_failed'));
        }
      })
      .catch((err) => {
        const hasGraphQLErrors =
          err && err.graphQLErrors && err.graphQLErrors.length > 0;

        if (hasGraphQLErrors) {
          switch (err.graphQLErrors[0].code) {
            case 'PriceChanged': {
              ev.complete('success');
              // We have to wrap this in a small timeout or else the error won't show up and the payment dialog will time out
              setTimeout(() => {
                window.alert(
                  'The prices of one or more items in your cart have changed. Please refresh this page and try again.'
                );
              }, 100);

              return;
            }

            case 'ItemNotFound': {
              ev.complete('success');

              setTimeout(() => {
                window.alert(
                  'One or more of the products in your cart have been removed. Please refresh the page and try again.'
                );
              }, 100);

              return;
            }

            case 'OrderTotalRange': {
              ev.complete('success');
              showCheckoutErrorMessageForError(err, ev.currentTarget);

              if (isCartOpen()) {
                showCartErrorMessageForError(err, ev.currentTarget);
              }

              return;
            }

            default: // noop
          }
        }

        if (err && err.message && err.message === 'payment_intent_failed') {
          // in the case that we failed outside of the browser UI (i.e. when we had to fall back to the page for 3d secure)
          // we're going to not call the `ev.complete`, since we already had to mark it with `success` to get back to the page
          // and since we don't have an error element, we're going to pop up an alert informing them of the issue
          window.alert(
            'There was an error processing your payment. Please try again, or contact us if you continue to have problems.'
          );
        } else {
          // otherwise, since we're still in the native browser UI, we can rely on the browser to tell them that something went wrong
          ev.complete('fail');
        }
      });
  });
};

export const register = (handlerProxy: EventHandlerProxyWithApolloClient) => {
  handlerProxy.on('click', isWebPaymentsButtonEvent, handleWebPaymentsButton);
  handlerProxy.on('keydown', isWebPaymentsButtonEvent, (event, ...rest) => {
    // @ts-expect-error - TS2339 - Property 'which' does not exist on type 'Event'.
    if (event.which === 32) {
      // prevent scrolling on spacebar key press
      event.preventDefault();
    }
    // @ts-expect-error - TS2339 - Property 'which' does not exist on type 'Event'.
    if (event.which === 13) {
      // enter key press
      return handleWebPaymentsButton(event, ...rest);
    }
  });
  handlerProxy.on('keyup', isWebPaymentsButtonEvent, (event, ...rest) => {
    // @ts-expect-error - TS2339 - Property 'which' does not exist on type 'Event'.
    if (event.which === 32) {
      // spacebar key press
      return handleWebPaymentsButton(event, ...rest);
    }
  });
};

export default {register};
