/**
 * Webflow: E-commerce
 */
const Webflow = require('../BaseSiteModules/webflow-lib');
// $FlowFixMe[cannot-resolve-module] Added when migrating to TypeScript
const {design, destroy, init, preview} = require('./modules');

Webflow.define(
  'commerce',
  (module.exports = function () {
    return {design, destroy, init, preview};
  })
);
