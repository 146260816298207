import keyBy from 'lodash/keyBy';
import memoize from 'lodash/memoize';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import type {
  ValidCurrenciesType,
  ValidCurrenciesPaypalType,
  Price,
} from '@packages/systems/commerce/core';
import {
  paypalCurrencyList,
  stripeCurrencyList,
} from '@packages/systems/commerce/constants';

export type ValidCurrencyInfo = {
  code: ValidCurrenciesType;
  digits: 0 | 1 | 2;
  minCharge: number;
  name: string;
};
export type ValidCurrencyPaypalInfo = {
  code: ValidCurrenciesPaypalType;
  digits: 0 | 1 | 2;
  minCharge: number;
  name: string;
};
export type InvalidCurrencyInfo = {
  code: string;
  digits: 0 | 1 | 2;
  minCharge: number;
  name: string;
};
type CurrencyInfo = ValidCurrencyInfo | InvalidCurrencyInfo;
type CurrencyInfoPaypal = ValidCurrencyPaypalInfo | InvalidCurrencyInfo;

type CurrencyInfoByCode = Partial<
  Record<ValidCurrenciesType, ValidCurrencyInfo>
>;
type CurrencyInfoPaypalByCode = Partial<
  Record<ValidCurrenciesPaypalType, ValidCurrencyInfo>
>;
export const currencyInfoByCode: CurrencyInfoByCode = keyBy(
  stripeCurrencyList,
  'code'
);

export const currencyInfoByCodePaypal: CurrencyInfoPaypalByCode = keyBy(
  paypalCurrencyList,
  'code'
);

type RenderOpts = {
  isoFormat?: boolean;
  noCurrency?: boolean;
};

export function getCurrencyInfo(
  code?: string | null,
  platform: string = 'stripe'
): ValidCurrencyInfo | ValidCurrencyPaypalInfo | InvalidCurrencyInfo {
  if (isValidCurrency(code)) {
    // @ts-expect-error - TS2322 - Type 'ValidCurrencyInfo | undefined' is not assignable to type 'ValidCurrencyInfo | ValidCurrencyPaypalInfo | InvalidCurrencyInfo'.
    return platform === 'stripe'
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        currencyInfoByCode[code.toUpperCase()]
      : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        currencyInfoByCodePaypal[code.toUpperCase()];
  }
  return {
    code: '???',
    digits: 2,
    minCharge: 0,
    name: `Unknown currency`,
  };
}

export function getCurrencyInfoPaypal(
  code?: string | null
): ValidCurrencyPaypalInfo | InvalidCurrencyInfo {
  return getCurrencyInfo(code, 'paypal');
}

const isValidCurrency = (currencyCode: unknown): boolean =>
  typeof currencyCode === 'string' &&
  currencyInfoByCode.hasOwnProperty(currencyCode.toUpperCase());

export type FormattedPrice = {
  value: number;
  unit: string;
  string: string;
};

type PaypalBreakdown = {
  item_total: PaypalAmount;
  shipping?: PaypalAmount;
  handling?: PaypalAmount;
  tax_total?: PaypalAmount;
  insurance?: PaypalAmount;
  shipping_discount?: PaypalAmount;
  discount?: PaypalAmount;
};

export type PaypalAmount = {
  currency_code: string; // three-character ISO-4217 currency code;
  value: string;
  breakdown?: PaypalBreakdown;
};

export type CommerceCurrencySettings = {
  currencyCode: string;
  symbol?: string;
  decimal?: string;
  fractionDigits?: number;
  group?: string;
  template?: string;
  hideDecimalForWholeNumbers?: boolean;
};

class NullNumberFormat {
  format(_value: number) {
    return 'NaN';
  }
}

type CurrencyDisplayType = 'symbol' | 'code' | 'name';

// @ts-expect-error - TS2322 - Type '((unit?: string | null | undefined, currencyDisplay?: CurrencyDisplayType) => NullNumberFormat) & MemoizedFunction' is not assignable to type '(unit?: string | null | undefined, arg2?: CurrencyDisplayType | null | undefined) => NullNumberFormat | NumberFormat'.
const getNumberFormat: (
  unit?: string | null | undefined,
  arg2?: CurrencyDisplayType | null | undefined
) => Intl.NumberFormat | NullNumberFormat = memoize(
  (unit?: string | null, currencyDisplay: CurrencyDisplayType = 'symbol') =>
    // HACK: for some reason, GraphQL is returning a currency of '???' for null
    // prices; we're temporarily glossing over this fact, and will address the
    // backend at a later time..
    unit != null && isValidCurrency(unit)
      ? new Intl.NumberFormat('en-US', {
          currency: unit,
          style: 'currency',
          currencyDisplay,
        })
      : new NullNumberFormat(),

  /* cache key function **/
  (unit?: string | null, currencyDisplay: CurrencyDisplayType = 'symbol') => {
    return [String(unit), currencyDisplay].join('::');
  }
);

export const getCurrencySymbol = (unit: ValidCurrenciesType) => {
  // As Intl.Numberformat.prototype.formatToParts is still experimental
  const symbol = String(getNumberFormat(unit).format(0)).match(/^([^0-9\s]*)/);

  return symbol ? symbol[0] : unit;
};
export const unsafeFloatToInt = (
  floatValue: number,
  currency:
    | ValidCurrenciesType
    | ValidCurrenciesPaypalType
    | CurrencyInfo
    | CurrencyInfoPaypal,
  round: (arg1: number) => number = Math.round
): number /* integer value */ => {
  const currencyInfo =
    typeof currency === 'object' ? currency : getCurrencyInfo(currency);
  return round(floatValue * Math.pow(10, currencyInfo.digits));
};

export const intToUnsafeFloat = (
  intValue: number,
  currency:
    | ValidCurrenciesType
    | ValidCurrenciesPaypalType
    | CurrencyInfo
    | CurrencyInfoPaypal
): number /* float value */ => {
  const currencyInfo =
    typeof currency === 'object' ? currency : getCurrencyInfo(currency);
  return intValue / Math.pow(10, currencyInfo.digits);
};

/**
 * Will convert a Price object (ie: object with value and unit) into a formatted string. Uses Intl.NumberFormat for
 * all its number formatey goodness.
 *
 * @param  {Number}  price.value The integer value, in the currency's smallest denomination. (This smallest denomination
 *                               is defined by stripe, and documented in our SharedConfig.js)
 * @param  {String}  price.unit  An ISO 4217 currency code. We define which ones are available in SharedConfig.js
 * @param  {boolean} isoFormat   If true it will render the price with ISO 4217 currency code instead of currency symbol
 *
 * @return {String}             A formatted currency string.
 */
export function renderPrice(price: Price, opts: RenderOpts = {}): string {
  const {isoFormat = false, noCurrency = false} = opts;
  price = validatePrice(price) ? price : _invalid();
  const normal_value = Number(price.value);
  const currencyInfo = getCurrencyInfo(price.unit);
  const float_value = intToUnsafeFloat(normal_value, currencyInfo);

  if (Number.isNaN(float_value)) {
    return 'NaN';
  }

  if (noCurrency) {
    return String(float_value);
  }
  const fmt = getNumberFormat(price.unit, isoFormat ? 'code' : 'symbol');
  return fmt.format(float_value);
}

/**
 * Will convert a Price ({ value, unit }) into a FormattedPrice ({ value, unit, string }).
 *
 * @param  {Price} price A basic price object.
 * @return {FormattedPrice} That same price object, extended with a string version.
 */
export function formatPrice(price: Price): FormattedPrice {
  price = validatePrice(price) ? price : _invalid();
  const string = renderPrice(price);
  return {
    unit: price.unit,
    value: price.value,
    string,
  };
}

/**
 * Returns true IFF the provided object is a compatible price object.
 *
 * @param  {Object} a  The object under question.
 * @return {Boolean}   True IFF that object is a Price.
 */
export function validatePrice(a: unknown): boolean {
  if (!a || typeof a !== 'object') {
    return false;
  }
  // @ts-expect-error - TS2339 - Property 'value' does not exist on type 'object'.
  if (!isNumber(a.value)) {
    return false;
  }
  // @ts-expect-error - TS2339 - Property 'unit' does not exist on type 'object'.
  if (!isString(a.unit)) {
    return false;
  }
  // @ts-expect-error - TS2339 - Property 'unit' does not exist on type 'object'.
  if (!isValidCurrency(a.unit)) {
    return false;
  }
  return true;
}

/**
 * Will add two prices together. Returns invalid if they aren't the same currency.
 *
 * @param  {Price} a  One of the prices.
 * @param  {Price} b  The other of the prices.
 * @return {Price}    The sum.
 */
export function sumPrice(
  a: Price | FormattedPrice,
  b: Price | FormattedPrice
): Price {
  if (!validatePrice(a) || !validatePrice(b)) {
    return _invalid();
  }

  // No defined behavior, if the Prices don't have a common currency...
  if (a.unit !== b.unit) {
    return _invalid();
  }

  return {value: a.value + b.value, unit: a.unit};
}

/**
 * Will subtract two prices. Returns invalid if they aren't the same currency.
 *
 * @param  {Price} a  One of the prices.
 * @param  {Price} b  The other of the prices.
 * @return {Price}    The sum.
 */
export function subtractPrice(a: Price, b: Price): Price {
  if (!validatePrice(a) || !validatePrice(b)) {
    return _invalid();
  }

  // No defined behavior, if the Prices don't have a common currency...
  if (a.unit !== b.unit) {
    return _invalid();
  }

  return {value: a.value - b.value, unit: a.unit};
}

/**
 * Will multiply a Price by a scalar.
 *
 * @param  {Price}  a       The price.
 * @param  {Number} scalar  A unitless value that we're multiplying the price by.
 * @return {Price}          The multiplied price.
 */
export function scalePrice(a: Price, scalar: number): Price {
  if (!validatePrice(a) || !isNumber(scalar)) {
    return _invalid();
  }

  const value = Math.round(a.value * scalar);
  const unit = a.unit;

  return {value, unit};
}

/**
 * Returns true IFF both prices exist, and are equivalent to each other.
 *
 * @param  {Price} a  One price.
 * @param  {Price} b  The other price.
 * @return {boolean}  True IFF they both exist and are equal.
 */
export function equalPrice(a: Price, b: Price): boolean {
  return Boolean(a && b && a.value === b.value && a.unit === b.unit);
}

export function parsePrice<T>(
  priceString: string,
  unit: ValidCurrenciesType,
  fallback: T
): Price | T {
  if (typeof priceString !== 'string') {
    throw new Error('parsePrice must be called with a string');
  }
  if (!isValidCurrency(unit)) {
    throw new Error(`parsePrice called with invalid currency ${unit}`);
  }
  if (!priceString) {
    return fallback;
  }
  // TODO: Fails on
  // 1,000.00 -> NaN
  // 0,99 -> NaN
  // Also it passes numbers we shouldn't allow:
  // 0x11 -> 17
  // 0b11 -> 3
  const rawNumber = Number(priceString);
  if (Number.isNaN(rawNumber)) {
    return fallback;
  }

  return {
    value: unsafeFloatToInt(rawNumber, unit),
    unit,
  };
}

// Simple helper method, which gives the "invalid" currency. (NaN as value, "???" as currency.)
export function _invalid(): Price {
  return {value: NaN, unit: '???'};
}

/**
 * Returns a Price object with a value of zero.
 *
 * @param  {String} unit    Currency code
 * @return {Price}          Price object
 */
export function zeroUnitWF(unit: string): Price {
  return {unit, value: 0};
}

// Paypal

/**
 * Returns a Paypal Amount (price object) with a value of zero.
 *
 * @param  {String} unit    Currency code
 * @return {PaypalAmount}   Paypal price object
 */
export function zeroUnitPaypal(unit: string): PaypalAmount {
  return convertWFPriceToPaypalAmount(zeroUnitWF(unit));
}

type OrderPrices = {
  total: Price;
  subtotal?: Price;
  shipping?: Price;
  tax?: Price;
  discount?: Price;
  discountShipping?: Price;
};
/**
 * Returns a Paypal Amount with Breakdown.
 *
 * @param  {OrderPrices}    orderPrices     Object of webflow order prices.
 * @return {PaypalAmount}   Paypal price object (with breakdown)
 */
export function convertWFPriceToPaypalAmountWithBreakdown(
  orderPrices: OrderPrices
): PaypalAmount {
  const {total, subtotal, shipping, tax, discount, discountShipping} =
    orderPrices;

  const convertOrZero = (
    price?: Price,
    scalar?: number | null
  ): PaypalAmount =>
    price
      ? convertWFPriceToPaypalAmount(price, scalar)
      : zeroUnitPaypal(total.unit);

  return {
    ...convertWFPriceToPaypalAmount(total),
    breakdown: {
      item_total: convertOrZero(subtotal),
      shipping: convertOrZero(shipping),
      tax_total: convertOrZero(tax),
      discount: convertOrZero(discount, -1),
      shipping_discount: convertOrZero(discountShipping, -1),
    },
  };
}

/**
 * Returns a Paypal Amount (price object).
 *
 * @param  {Price} a        Webflow price object.
 * @param  {Number} scalar  A unitless value that we're multiplying the price by.
 * @return {PaypalAmount}   Paypal price object
 */
export function convertWFPriceToPaypalAmount(
  a: Price,
  scalar?: number | null
): PaypalAmount {
  // TODO:
  // - May have to account for in-country PayPal accounts only support for some currencies
  const unitInfo = getCurrencyInfoPaypal(a.unit);
  const wfValue = scalar ? scalePrice(a, scalar).value : a.value;
  const value = intToUnsafeFloat(wfValue, unitInfo).toFixed(unitInfo.digits);

  return {currency_code: a.unit, value};
}

/**
 * Returns a WF Price object.
 *
 * @param  {PaypalAmount} a   A paypal price object.
 * @return {Price}            Webflow price object
 */
export function convertPaypalAmountToWFPrice(a: PaypalAmount): Price {
  // TODO:
  // - May have to account for in-country PayPal accounts only support for some currencies
  const unitInfo = getCurrencyInfoPaypal(a.currency_code);
  const value = unsafeFloatToInt(parseFloat(a.value), unitInfo);

  return {unit: a.currency_code, value};
}
