import {usysFormBundle} from '@packages/systems/users/siteBundles';

/**
 * Webflow: user form components
 */

const Webflow = require('../BaseSiteModules/webflow-lib');

export default function createUsysFormBundle() {
  return usysFormBundle(Webflow.env);
}

Webflow.define('usys_form', createUsysFormBundle);
