import {
  CLASS_NAME_W_DYN_BIND_EMPTY,
  CONDITION_INVISIBLE_CLASS,
} from '@packages/systems/dynamo/constants';
import {testCondition} from '@packages/systems/dynamo/utils/ConditionUtils';

const removeClass = (node: Element, className: string): void => {
  if (node.classList.contains(className)) {
    node.classList.remove(className);
    if (node.classList.length === 0) {
      node.removeAttribute('class');
    }
  }
};

export const removeWDynBindEmptyClass = (node: Element): void =>
  removeClass(node, CLASS_NAME_W_DYN_BIND_EMPTY);

const addConditionalVisibilityClass = (node: Element): void => {
  node.classList.add(CONDITION_INVISIBLE_CLASS);
};

const removeConditionalVisibilityClass = (node: Element): void =>
  removeClass(node, CONDITION_INVISIBLE_CLASS);

export const applyConditionToNode = (
  node: Element,
  item: Record<any, any> | null,
  conditionData: any,
  graphQLSlugs: boolean = false
): void => {
  if (!conditionData) {
    return;
  }

  const {condition, timezone} = conditionData;

  if (item) {
    const isVisible = testCondition({
      item,
      contextItem: null,
      timezone,
      condition,
      graphQLSlugs,
    });
    if (isVisible) {
      removeConditionalVisibilityClass(node);
    } else {
      addConditionalVisibilityClass(node);
    }
  }
};

/**
 * Breadth-first traversal of DOM nodes
 * @param {Element} el - Root element to start with
 * @param {(node: Element) => void} fn - Function to run for every node encountered
 */
export function walkDOM(el: Element, fn: (node: Element) => void): any {
  fn(el);

  if (!el || !el.children) {
    return el;
  }

  const children = Array.from(el.children);

  if (!children.length) {
    return el;
  }

  children.forEach((child) => walkDOM(child, fn));

  return el;
}
