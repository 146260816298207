import {ApolloClient, NormalizedCacheObject} from '@apollo/client';
import {StripeStore} from './stripeStore';
export type EventHandlerWithApolloAndStripe = (
  arg1: Event,
  arg2: ApolloClient<NormalizedCacheObject>,
  arg3: StripeStore
) => void;
type EventHandlerProxy = (arg1: Event) => void;
type EventMatcher = (arg1: Event) => boolean | Element;

// @ts-expect-error - TS7023 - 'enumeratePrototypeProps' implicitly has return type 'any' because it does not have a return type annotation and is referenced directly or indirectly in one of its return expressions.
const enumeratePrototypeProps = (
  obj: null | Event | typeof Object.prototype,
  propNames = []
) => {
  if (obj == null) {
    return propNames;
  }
  return (
    propNames
      .concat(enumeratePrototypeProps(Object.getPrototypeOf(obj)))
      // @ts-expect-error - TS2769 - No overload matches this call.
      .concat(Object.keys(obj))
  );
};

const createEventProxy = (event: Event, currentTarget: Element) => {
  // @ts-expect-error - TS2347 - Untyped function calls may not accept type arguments.
  const propertyDefinitions = enumeratePrototypeProps(event)
    // @ts-expect-error - TS7006 - Parameter 'propName' implicitly has an 'any' type.
    .filter((propName) => propName !== 'currentTarget')
    // @ts-expect-error - TS7006 - Parameter 'proxies' implicitly has an 'any' type. | TS7006 - Parameter 'propName' implicitly has an 'any' type.
    .reduce<Record<string, any>>((proxies, propName) => {
      proxies[propName] =
        // @ts-expect-error Object.keys is "unsound", always infers `string` as output
        typeof event[propName] === 'function'
          ? // Proxy all the event methods so they will act on the original event:
            // @ts-expect-error Object.keys is "unsound", always infers `string` as output
            {value: (...args) => event[propName](...args)}
          : // Proxy static props/getters because invoking them directly may result in "Illegal invokation" error.
            // @ts-expect-error Object.keys is "unsound", always infers `string` as output
            {get: () => event[propName]};
      return proxies;
    }, {});

  const retargetedEvent = Object.create(event, {
    // set currentTarget to the matched node:
    currentTarget: {value: currentTarget},

    ...propertyDefinitions,
  });
  return retargetedEvent;
};

export default class EventHandlerProxyWithApolloClient {
  apolloClient: ApolloClient<NormalizedCacheObject>;
  stripeStore: StripeStore;
  eventHandlers: {
    [key: string]: Array<EventHandlerProxy>;
  };

  constructor(
    apolloClient: ApolloClient<NormalizedCacheObject>,
    stripeStore: StripeStore
  ) {
    this.eventHandlers = {};
    this.apolloClient = apolloClient;
    this.stripeStore = stripeStore;
  }

  on = (
    eventName: string,
    eventMatcher: EventMatcher,
    handler: EventHandlerWithApolloAndStripe
  ) => {
    const existingHandlers: Array<EventHandlerProxy> =
      this.eventHandlers[eventName] instanceof Array
        ? this.eventHandlers[eventName]
        : [];
    this.eventHandlers[eventName] = [
      ...existingHandlers,
      this.createHandlerProxy(eventName, eventMatcher, handler),
    ];
    return this;
  };

  createHandlerProxy =
    (
      eventName: string,
      eventMatcher: EventMatcher,
      handler: EventHandlerWithApolloAndStripe
    ): EventHandlerProxy =>
    (event: Event) => {
      const match = eventMatcher(event);
      const eventProxy =
        match instanceof Element ? createEventProxy(event, match) : event;
      if (match) {
        handler(eventProxy, this.apolloClient, this.stripeStore);
      }
    };

  attachHandlers = (target: Element) => {
    Object.keys(this.eventHandlers).forEach((eventName) => {
      const handlerProxies = this.eventHandlers[eventName];
      // @ts-expect-error - TS18048 - 'handlerProxies' is possibly 'undefined'.
      handlerProxies.forEach((handlerProxy) =>
        target.addEventListener(eventName, handlerProxy, true)
      );
    });
    return this;
  };

  removeHandlers = (target: Element) => {
    Object.keys(this.eventHandlers).forEach((eventName) => {
      const handlerProxies = this.eventHandlers[eventName];
      // @ts-expect-error - TS18048 - 'handlerProxies' is possibly 'undefined'.
      handlerProxies.forEach((handlerProxy) =>
        target.removeEventListener(eventName, handlerProxy, true)
      );
    });
    return this;
  };
}
