/* globals window, CustomEvent */
import gql from 'graphql-tag';
import qs from 'qs';
import EventHandlerProxyWithApolloClient from './eventHandlerProxyWithApolloClient';
import {
  findElementByNodeType,
  findAllElementsByNodeType,
  trackOrder,
} from './commerceUtils';
import {renderTree} from './rendering';
import {
  NODE_TYPE_COMMERCE_ORDER_CONFIRMATION_WRAPPER,
  RENDER_TREE_EVENT,
  ORDER_QUERY,
} from '@packages/systems/commerce/constants';
import {ApolloClient, NormalizedCacheObject} from '@apollo/client';

const renderOrderConfirmation = (
  orderConfirmation: Element,
  data: Record<any, any>
) => {
  renderTree(orderConfirmation, data);
};

const handleRenderOrderConfirmation = (
  event: Event | CustomEvent,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  if (window.Webflow.env('design') || window.Webflow.env('preview')) {
    return;
  }
  if (!(event instanceof CustomEvent && event.type === RENDER_TREE_EVENT)) {
    return;
  }

  const errors: Array<any> = [];
  const {detail} = event;
  if (detail != null && detail.error) {
    errors.push(detail.error);
  }

  const orderConfirmationContainer = findElementByNodeType(
    NODE_TYPE_COMMERCE_ORDER_CONFIRMATION_WRAPPER
  );
  if (!orderConfirmationContainer) {
    return;
  }

  const {
    orderId,
    token,
  }: {
    orderId?: string;
    token?: string;
  } = qs.parse(window.location.search.substring(1));
  if (!orderId || !token) {
    return;
  }

  const finalizedOrder = {orderId, token} as const;

  // runs the analytics (facebook and google pixel)
  // we don't block on it, since this isn't needed for any rendering
  // so it if fails, it can just fail silently
  trackOrder(apolloClient, finalizedOrder);

  const allOrderConfirmationContainers = findAllElementsByNodeType(
    NODE_TYPE_COMMERCE_ORDER_CONFIRMATION_WRAPPER
  );

  apolloClient
    .query({
      query: gql`
        ${orderConfirmationContainer.getAttribute(ORDER_QUERY)}
      `,
      variables: {finalizedOrder},
      fetchPolicy: 'network-only',
      // errorPolicy is set to `all` so that we continue to get the cart data when an error occurs
      // this is important in cases like when the address entered doesn't have a shipping zone, as that returns
      // a graphQL error, but we still want to render what the customer has entered
      errorPolicy: 'all',
    })
    .then((data) => {
      allOrderConfirmationContainers.forEach(
        (orderConfirmationContainerNode) => {
          renderOrderConfirmation(orderConfirmationContainerNode, {
            ...data,
            errors: errors.concat(data.errors).filter(Boolean),
          });
        }
      );
    })
    .catch((err) => {
      errors.push(err);
      allOrderConfirmationContainers.forEach(
        (orderConfirmationContainerNode) => {
          renderOrderConfirmation(orderConfirmationContainerNode, {errors});
        }
      );
    });
};

export const register = (handlerProxy: EventHandlerProxyWithApolloClient) => {
  handlerProxy.on(RENDER_TREE_EVENT, Boolean, handleRenderOrderConfirmation);
};

export default {register};
