import type {BreakpointID} from '@packages/systems/style/types';

export const BREAKPOINT_ID_XXL = 'xxl' as const;
export const BREAKPOINT_ID_XL = 'xl' as const;
export const BREAKPOINT_ID_LARGE = 'large' as const;
export const BREAKPOINT_ID_MAIN = 'main' as const;
export const BREAKPOINT_ID_MEDIUM = 'medium' as const;
export const BREAKPOINT_ID_SMALL = 'small' as const;
export const BREAKPOINT_ID_TINY = 'tiny' as const;

export const DEFAULT_BREAKPOINT_IDS = [
  BREAKPOINT_ID_MAIN,
  BREAKPOINT_ID_MEDIUM,
  BREAKPOINT_ID_SMALL,
  BREAKPOINT_ID_TINY,
] as const;

export const LARGER_BREAKPOINT_IDS: BreakpointID[] = [
  BREAKPOINT_ID_LARGE,
  BREAKPOINT_ID_XL,
  BREAKPOINT_ID_XXL,
];
