/* eslint-disable @typescript-eslint/ban-types */
import {defaultMemoize} from 'reselect';
import {LRUCache} from 'lru-cache';

// import lodash functions individually to minimize bundle size
import isBoolean from 'lodash/isBoolean';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';

const True = {'@webflow/Boolean': true} as const;
const False = {'@webflow/Boolean': false} as const;

/*
 * Inlined Immutable v3.8.1 `is` to reduce webflow.js bundle size
 * Source: https://github.com/immutable-js/immutable-js/blob/v3.8.1/src/is.js
 */
const is = (valueA: any, valueB: any): boolean => {
  // eslint-disable-next-line no-self-compare
  if (valueA === valueB || (valueA !== valueA && valueB !== valueB)) {
    return true;
  }
  if (!valueA || !valueB) {
    return false;
  }
  if (
    typeof valueA.valueOf === 'function' &&
    typeof valueB.valueOf === 'function'
  ) {
    valueA = valueA.valueOf();
    valueB = valueB.valueOf();
    // eslint-disable-next-line no-self-compare
    if (valueA === valueB || (valueA !== valueA && valueB !== valueB)) {
      return true;
    }
    if (!valueA || !valueB) {
      return false;
    }
  }
  if (
    typeof valueA.equals === 'function' &&
    typeof valueB.equals === 'function' &&
    valueA.equals(valueB)
  ) {
    return true;
  }
  return false;
};

export const isEqual = (a: any, b: any): boolean => {
  if (is(a, b)) {
    return true;
  }

  if (
    typeof a !== 'object' ||
    a === null ||
    typeof b !== 'object' ||
    b === null
  ) {
    return false;
  }

  for (const k in a) {
    if (!is(a[k], b[k])) {
      return false;
    }
  }

  return Object.keys(a).length === Object.keys(b).length;
};

export function memoize<F extends Function>(fn: F): F {
  return defaultMemoize(fn, isEqual);
}

/*
 * WARNING: `weakMemo` does not throw errors or handle invalid keys gracefully
 * on purpose. This is a case where we want the app to crash if an invalid key
 * is used. The purpose of allowing the app to crash in this situation is to
 * collect data so a root cause might be discovered and addressed.
 *
 * WARNING: `weakMemo` uses only the first fn's argument as a key. 2nd and further arguments are ignored.
 */
export function weakMemo<F extends Function>(fn: F): F {
  if (process.env.NODE_ENV === 'development') {
    if (!isFunction(fn)) {
      console.error(
        `Expected a function as argument to weakMemo but got ${fn}.`
      );
    }
  }

  const map = new WeakMap();

  // @ts-expect-error - TS2322 - Type '{ (arg: any): any; displayName: string; }' is not assignable to type 'F'. | TS7006 - Parameter 'arg' implicitly has an 'any' type.
  const memFn: F = (arg) => {
    if (!isObject(arg) && !isBoolean(arg)) {
      const errorMessage = `Expected an object or boolean as an argument to "${
        // @ts-expect-error - TS2339 - Property 'displayName' does not exist on type 'F'.
        fn.displayName || fn.name
      }()", but received ${String(arg)}.`;

      const fnString = fn.toString();

      if (process.env.NODE_ENV === 'development') {
        console.info(
          `\x1b[1m\x1b[32mFunction Source:\x1b[0m\n\n` +
            `\x1b[33mweakMemo\x1b[0m(${syntaxHighlight(fnString)})`
        );
      }

      throw new WeakMemoError({
        message: errorMessage,
        fn: fnString.slice(0, 200000),
        memFn,
      });
    }

    const key = typeof arg === 'boolean' ? (arg && True) || False : arg;

    // Flow doesn't seem to like True/False since they don't always
    // fit the expected type of `arg`
    if (!map.has(key)) {
      map.set(key, fn(arg));
    }

    const result = map.get(key);

    return result;
  };

  if (process.env.NODE_ENV === 'development') {
    // @ts-expect-error - TS2339 - Property 'displayName' does not exist on type 'F'.
    memFn.displayName =
      // @ts-expect-error - TS2339 - Property 'displayName' does not exist on type 'F'. | TS2339 - Property 'name' does not exist on type 'F'. | TS2339 - Property 'toString' does not exist on type 'F'.
      `weakMemo(\n  ${fn.displayName || fn.name || fn.toString()}\n)`;

    memFn.toString = function () {
      return `weakMemo(\n  ${fn.toString()}\n)`;
    };
  }

  return memFn;
}

/**
 * Creates a memoize factory with a cache size of the given depth.
 * Only functions that accept a single argument are valid.
 *
 * ⚠️IMPORTANT⚠️ Object and Function arguments are compared *by reference*.
 *
 * @param  {number} depth         The size of the cache.
 * @return {Function}             The resulting memoize function.
 */
export const cacheMemo = (depth: number) => {
  /**
   * Returns a memoized version of a "trivially hashable" function passed as parameter, with a fixed cache size.
   *
   * A "trivially hashable" function takes a single string as an argument and therefore does not need to be hashed.
   *
   * This is a pretty significant optimization if your function needs to be called very frequently.
   *
   * @param  {Function} fn          The function to memoize.
   * @return {Function}             The memoized function.
   */
  const memoizeFn = <F extends Function>(fn: F): F => {
    const cache = new LRUCache({
      max: depth || 1,
    });

    // @ts-expect-error - TS2322 - Type '(arg: F) => unknown' is not assignable to type 'F'.
    return function (arg: F) {
      if (!cache.has(arg)) {
        cache.set(arg, fn(arg));
      }
      return cache.get(arg);
    };
  };

  return memoizeFn;
};

/**
 * A simple memoization function for usage with primitives or single argument
 * arguments of identity equality.
 */
const defaultLastArg = Symbol();
export const singleMemo = <F extends Function>(fn: F): F => {
  let lastArg = defaultLastArg;
  // @ts-expect-error - TS7034 - Variable 'lastResult' implicitly has type 'any' in some locations where its type cannot be determined.
  let lastResult;
  // @ts-expect-error - TS2322 - Type '(arg: F) => any' is not assignable to type 'F'.
  return (arg: F) => {
    // @ts-expect-error - TS2367 - This condition will always return 'true' since the types 'F' and 'symbol' have no overlap.
    if (arg !== lastArg) {
      lastResult = fn(arg);
      // @ts-expect-error - TS2322 - Type 'F' is not assignable to type 'symbol'.
      lastArg = arg;
    }
    // @ts-expect-error - TS7005 - Variable 'lastResult' implicitly has an 'any' type.
    return lastResult;
  };
};

/**
 * A memoization function that only calls the provided nullary function
 * the first time. Subsequent calls return the first result.
 */
export const once = <a>(fn: (arg1: void) => a): ((arg1: void) => a) => {
  let result: a;
  return () => {
    if (fn) {
      result = fn();
      // @ts-expect-error - TS2322 - Type 'undefined' is not assignable to type '(arg1: undefined) => a'.
      fn = undefined;
    }
    return result;
  };
};

class WeakMemoError extends TypeError {
  fn: string;

  constructor(opts: {message: string; fn: string; memFn: Function}) {
    super();

    if (TypeError.captureStackTrace) {
      TypeError.captureStackTrace(this, opts.memFn);
    }

    this.name = 'WeakMemoError';
    this.message = opts.message;
    this.fn = opts.fn;
  }
}

function syntaxHighlight(fnSource: string) {
  const keywords =
    /\b(function|return|const|let|if|else|throw|for|while|switch|case|break|continue|default|new|delete|typeof|instanceof|void|this|class)\b/g;
  const literals = /\b(true|false|null|undefined)\b/g;
  const strings = /(['"`][^'"`]*['"`])/g; // Matches single, double, or backticks (template literals)
  const numbers = /\b(\d+(\.\d+)?(e[+-]?\d+)?|NaN|Infinity)\b/gi; // Matches integers, floats, and scientific notation
  const operators = /[=+\-*\/%<>!&|^~?]/g;
  const comments = /(\/\/[^\n]*|\/\*[\s\S]*?\*\/)/g;
  const parentheses = /[\(\)\[\]\{\}]/g; // Matches (), [], {}

  // Apply syntax highlighting via ANSI escape codes
  return fnSource
    .replace(parentheses, (match) => `\x1b[37m${match}\x1b[0m`) // white for parentheses and brackets
    .replace(comments, (match) => `\x1b[90m${match}\x1b[0m`) // gray for comments
    .replace(strings, (match) => `\x1b[32m${match}\x1b[0m`) // green for strings and template literals
    .replace(literals, (match) => `\x1b[33m${match}\x1b[0m`) // yellow for literals (true, false, null, undefined)
    .replace(keywords, (match) => `\x1b[35m${match}\x1b[0m`) // magenta for keywords
    .replace(numbers, (match) => `\x1b[36m${match}\x1b[0m`) // cyan for numbers
    .replace(operators, (match) => `\x1b[31m${match}\x1b[0m`); // red for operators
}
