import {
  DATA_ATTR_NODE_TYPE,
  NODE_TYPE_COMMERCE_ADD_TO_CART_PILL_GROUP,
  NODE_TYPE_COMMERCE_ADD_TO_CART_PILL,
  DATA_ATTR_COMMERCE_OPTION_SET_ID,
} from '@packages/systems/commerce/constants';

const KEY_CODES = Object.freeze({
  RETURN: 13,
  SPACE: 32,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
});

type OnSelect = (arg1: {
  optionId: string;
  optionSetId: string;
  groups: any; // @TODO - try and fix this type, Object.values not playing nice with classes;
}) => void;

export class PillGroups {
  form: HTMLElement;
  pillGroups: {
    [optionSetId: string]: PillGroup;
  };
  onSelect: OnSelect;

  static hasPillGroups(form: HTMLElement) {
    return (
      form.querySelectorAll(
        `[${DATA_ATTR_NODE_TYPE}="${NODE_TYPE_COMMERCE_ADD_TO_CART_PILL_GROUP}"]`
      ).length > 0
    );
  }

  constructor(form: HTMLElement, onSelect: OnSelect) {
    this.form = form;
    this.pillGroups = {};
    this.onSelect = onSelect;
  }

  init() {
    const groupNodes = this.form.querySelectorAll(
      `[${DATA_ATTR_NODE_TYPE}="${NODE_TYPE_COMMERCE_ADD_TO_CART_PILL_GROUP}"]`
    );
    for (const group of groupNodes) {
      // @ts-expect-error - TS2345 - Argument of type 'Element' is not assignable to parameter of type 'HTMLElement'.
      const pillGroup = new PillGroup(group, this.onSelect, this);
      pillGroup.init();
      this.pillGroups[pillGroup.optionSetId] = pillGroup;
    }
  }

  setSelectedPillsForSkuValues(skuValues: {[optionSetId: string]: string}) {
    for (const optionSetId of Object.keys(skuValues)) {
      const optionId = skuValues[optionSetId];
      const pillGroup = this.pillGroups[optionSetId];
      if (pillGroup) {
        const pill = pillGroup.findPillById(String(optionId));
        pillGroup.updatePillsWithNewSelected(pill);
      }
    }
  }
}

class PillGroup {
  node: HTMLElement;
  optionSetId: string;
  onSelect: OnSelect;
  pills: Array<Pill>;
  groups: PillGroups;

  constructor(node: HTMLElement, onSelect: OnSelect, groups: PillGroups) {
    this.node = node;
    this.optionSetId = String(
      node.getAttribute(DATA_ATTR_COMMERCE_OPTION_SET_ID)
    );
    this.onSelect = onSelect;
    this.pills = [];
    this.groups = groups;
  }

  get firstEnabledPill() {
    // find returns the first one it finds, and the pills are in order
    return this.pills.find((pill) => pill.disabled === false);
  }

  // hacky fake option set compat
  get value() {
    const possiblePill = this.pills.find((pill) => pill.checked === true);
    return possiblePill ? possiblePill.value : '';
  }

  // hacky fake option set compat
  get options() {
    return this.pills;
  }

  // hacky fake option set compat
  // eslint-disable-next-line accessor-pairs
  set selectedIndex(index: number) {
    const pill = this.pills[index] || null;
    // @ts-expect-error - TS2345 - Argument of type 'Pill | null' is not assignable to parameter of type 'Pill'.
    this.emitSelected(pill);
  }

  // hacky fake option set compat
  // we only want to support the one DOM attribute, which we have on this group, just not
  // directly exposed, as we don't directly expose the DOM element for the group
  getAttribute(attr: string) {
    if (attr === DATA_ATTR_COMMERCE_OPTION_SET_ID) {
      return this.optionSetId;
    } else {
      throw new Error(
        `PillGroup: Attempted to fetch unsupported attribute ${attr}`
      );
    }
  }

  init() {
    const pills = this.node.querySelectorAll(
      `[${DATA_ATTR_NODE_TYPE}="${NODE_TYPE_COMMERCE_ADD_TO_CART_PILL}"]`
    );
    this.pills = Array.from(pills).map((pillNode) => {
      // @ts-expect-error - TS2345 - Argument of type 'Element' is not assignable to parameter of type 'HTMLElement'.
      const pill = new Pill(pillNode, this);
      pill.init();
      return pill;
    });
    // if this group has any enabled pills, we set the first one's tab index so it can be focused
    if (this.firstEnabledPill) {
      this.firstEnabledPill.tabIndex = 0;
    }
    // @zach: store reference to this group on the node so we can access it later
    // we shouldn't be doing this but it's a lot easier than creating some global store
    // i tried the global store method first but the problem is our instance IDs are just the product IDs
    // so if you have two ATCs for the same product, they'd overwrite each other, and only one option list would work
    // so just storing it on the DOM element, and then grabbing it when updating all the option sets is a lot easier :)
    // @ts-expect-error - TS2339 - Property '_wfPillGroup' does not exist on type 'HTMLElement'.
    this.node._wfPillGroup = this;
  }

  findPillById(optionId: string) {
    return this.pills.find((pill) => pill.optionId === optionId);
  }

  updatePillsWithNewSelected(selectedPill?: Pill | null) {
    // we unselect all of the pills in the pill group
    for (const pill of this.pills) {
      pill.tabIndex = -1;
      pill.checked = false;
    }

    if (selectedPill instanceof Pill) {
      // if passed a pill, we give it the proper tab index, and set the aria checked
      selectedPill.tabIndex = 0;
      selectedPill.checked = true;
    } else {
      // if not passed a pill, we're deselecting any option in this group
      // so we call set the tabIndex to the first enabled pill,
      // so tab focus goes back to the first pill on the next enter
      if (this.firstEnabledPill) {
        this.firstEnabledPill.tabIndex = 0;
      }
    }
  }

  emitSelected(selectedPill: Pill) {
    this.onSelect({
      optionId: selectedPill.optionId,
      optionSetId: this.optionSetId,
      groups: Object.values(this.groups.pillGroups),
    });
  }

  traverseAndEmitSelected(currentPill: Pill, direction: 'previous' | 'next') {
    const currentIndex = this.pills.indexOf(currentPill);
    let found = false;
    let idx = currentIndex;
    let nextIndex;

    while (!found) {
      if (direction === 'previous') {
        nextIndex = idx - 1;
        // if we reached the start of the list, go to the end
        if (nextIndex < 0) {
          nextIndex = this.pills.length - 1;
        }
      } else if (direction === 'next') {
        nextIndex = idx + 1;
        // if we reached the end of the list, go to the start
        if (nextIndex === this.pills.length) {
          nextIndex = 0;
        }
      } else {
        throw new Error(
          `Unknown pill traversal direction "${direction}", use "previous" or "next"`
        );
      }

      // if we're back at the pill we started with, we went through the entire list
      // and didn't find any other enabled pills, so we keep this one selected
      if (nextIndex === currentIndex) {
        break;
      }

      const pill = this.pills[nextIndex];
      // @ts-expect-error - TS18048 - 'pill' is possibly 'undefined'.
      if (!pill.disabled) {
        // if the next pill is enabled, we emit it as selected, focus it now that we know what pill should be
        // and finally, break the loop
        // @ts-expect-error - TS2345 - Argument of type 'Pill | undefined' is not assignable to parameter of type 'Pill'.
        this.emitSelected(pill);
        // @ts-expect-error - TS18048 - 'pill' is possibly 'undefined'.
        pill.focus();
        found = true;
      } else {
        // otherwise, we increment our loop index, so we can start the loop again
        // and check the next pill
        idx = nextIndex;
      }
    }
  }
}

class Pill {
  node: HTMLElement;
  optionId: string;
  group: PillGroup;

  constructor(node: HTMLElement, group: PillGroup) {
    this.node = node;
    this.optionId = String(this.node.getAttribute('data-option-id'));
    this.group = group;
  }

  init() {
    this.tabIndex = -1;
    this.checked = false;

    this.node.addEventListener('keydown', this.handleKeyDown);
    this.node.addEventListener('click', this.handleClick);
  }

  get tabIndex() {
    return this.node.tabIndex;
  }

  set tabIndex(index: number) {
    this.node.tabIndex = index;
  }

  get value() {
    return this.optionId;
  }

  get checked() {
    return this.node.getAttribute('aria-checked') === 'true';
  }

  set checked(checked: boolean) {
    this.node.setAttribute('aria-checked', String(checked));
    if (checked) {
      this.node.classList.add('w--ecommerce-pill-selected');
    } else {
      this.node.classList.remove('w--ecommerce-pill-selected');
    }
  }

  get disabled() {
    return this.node.getAttribute('aria-disabled') === 'true';
  }

  set disabled(disabled: boolean) {
    this.node.setAttribute('aria-disabled', String(disabled));
    if (disabled) {
      // if we pragmatically disable a pill, we want to make sure it's not checked
      // and that it can't be focused by the browser
      this.node.classList.add('w--ecommerce-pill-disabled');
      this.checked = false;
      this.tabIndex = -1;
    } else {
      this.node.classList.remove('w--ecommerce-pill-disabled');
    }
  }

  get enabled() {
    return !this.disabled;
  }

  set enabled(enabled: boolean) {
    this.disabled = !enabled;
  }

  focus() {
    this.node.focus();
  }

  handleKeyDown = (ev: KeyboardEvent) => {
    let eventHandled = false;

    // we don't want to handle events when holding down alt or cmd
    // as these are navigation shortcuts that we don't want to break
    if (ev.altKey || ev.metaKey) {
      return;
    }

    switch (ev.keyCode) {
      // return and space should act like a click on the pill, selecting/deselecting as needed
      case KEY_CODES.RETURN:
      case KEY_CODES.SPACE:
        this.handleClick();
        eventHandled = true;
        break;
      // up and left go to the previous pill in the group
      case KEY_CODES.UP:
      case KEY_CODES.LEFT:
        this.group.traverseAndEmitSelected(this, 'previous');
        eventHandled = true;
        break;
      // down and right go to the next pill in the group
      case KEY_CODES.DOWN:
      case KEY_CODES.RIGHT:
        this.group.traverseAndEmitSelected(this, 'next');
        eventHandled = true;
        break;
      default:
        break;
    }

    // we only want to stop keyboard events for the keys we're trying to intercept
    if (eventHandled) {
      ev.stopPropagation();
      ev.preventDefault();
    }
  };

  handleClick = () => {
    if (this.disabled || this.checked) {
      return;
    }

    this.focus();
    this.group.emitSelected(this);
  };
}
