/*
  globals
  HTMLInputElement
  HTMLSelectElement
*/

import {
  RESERVED_USER_FIELDS,
  USYS_DATA_ATTRS,
  USYS_INPUT_TYPES,
} from '@packages/systems/users/constants';
import {
  getTempUserFileKey,
  getUserFileKey,
} from '@packages/systems/users/utils/universalUtils';

const getTextInput = (element) =>
  element instanceof HTMLInputElement ? element.value : '';

const typeGetter = {
  PlainText: getTextInput,
  Email: getTextInput,
  Bool: (element) =>
    element instanceof HTMLInputElement ? element.checked : false,
  Number: getTextInput,
  Option: (element) =>
    element instanceof HTMLSelectElement ? element.value : '',
  Link: getTextInput,
  FileRef: (element) => {
    const fileKey = getUserFileKey(element);
    const tempFileKey = getTempUserFileKey(element);
    if (tempFileKey) {
      // change file
      return {key: tempFileKey};
    }
    if (fileKey === 'DELETE') {
      // delete file
      return null;
    }
    if (fileKey) {
      // preserve existing file
      return {_id: fileKey};
    }
  },
};

const customFieldTypes = [
  'PlainText',
  'Bool',
  'Email',
  'Number',
  'Option',
  'Link',
  'FileRef',
];

export const commonFields = [
  {
    type: 'Email',
    slug: 'email',
    selector: (container) =>
      container.querySelector(
        `input[${USYS_DATA_ATTRS.inputType}="${USYS_INPUT_TYPES.email}"]`
      ),
  },
  {
    type: 'PlainText',
    slug: 'name',
    selector: (container) =>
      container.querySelector(
        `input[${USYS_DATA_ATTRS.field}="${RESERVED_USER_FIELDS.name}"]`
      ) ||
      container.querySelector(
        `input[${USYS_DATA_ATTRS.inputType}="${USYS_INPUT_TYPES.name}"]`
      ),
  },
  {
    type: 'PlainText',
    slug: 'password',
    selector: (container) =>
      container.querySelector(
        `input[${USYS_DATA_ATTRS.inputType}="${USYS_INPUT_TYPES.password}"]`
      ),
  },
  {
    type: 'Bool',
    slug: 'accept-privacy',
    selector: (container) =>
      container.querySelector(
        `input[${USYS_DATA_ATTRS.field}="${RESERVED_USER_FIELDS.acceptPrivacy}"]`
      ) ||
      container.querySelector(
        `input[${USYS_DATA_ATTRS.inputType}="${USYS_INPUT_TYPES.acceptPrivacy}"]`
      ),
  },
  {
    type: 'Bool',
    slug: 'accept-communications',
    selector: (container) =>
      container.querySelector(
        `input[${USYS_DATA_ATTRS.field}="${RESERVED_USER_FIELDS.acceptCommunications}"]`
      ),
  },
];

const toCamelCase = (str) => {
  // Handle kebab-case to PascalCase
  const pascalCase = str
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('');
  // return camelCase
  return pascalCase.charAt(0).toLowerCase() + pascalCase.slice(1);
};

export const getCommonFields = (form, requestedFields) => {
  const payload = [];
  commonFields.forEach((field) => {
    if (requestedFields && !requestedFields.includes(field.slug)) return;
    const ele = field.selector(form);
    if (!ele || !typeGetter[field.type]) return;
    payload.push({
      key: toCamelCase(field.slug),
      type: toCamelCase(field.type),
      id: field.slug,
      value: typeGetter[field.type](ele, field.id),
    });
  });
  return payload;
};

export const getCustomFields = (form, includeValue = true) => {
  const payload = [];
  customFieldTypes.forEach((fieldType) => {
    const camelFieldType = toCamelCase(fieldType);
    const inputEles = form.querySelectorAll(
      `input[${USYS_DATA_ATTRS.fieldType}="${fieldType}"], select[${USYS_DATA_ATTRS.fieldType}="${fieldType}"]`
    );

    if (inputEles.length === 0 || !typeGetter[fieldType]) return;
    inputEles.forEach((ele) => {
      const id = ele.getAttribute(USYS_DATA_ATTRS.field);
      if (!id) return;

      const elementData = {
        key: `f_${id}`,
        type: camelFieldType,
        id,
      };

      if (includeValue) {
        const value = typeGetter[fieldType](ele, id);
        if (value === '') {
          elementData.value = null;
        } else {
          elementData.value = value;
        }
      }

      payload.push(elementData);
    });
  });
  return payload;
};

export const getFieldsForFetch = (forms) => {
  const custom = [];
  const nested = [];
  const alreadyFound = (customField) => {
    return custom.find((item) => item.id === customField.id);
  };
  forms.forEach((form) => {
    nested.push([...getCommonFields(form), ...getCustomFields(form, false)]);
  });
  nested.forEach((getCustomFieldRes) => {
    getCustomFieldRes.forEach((customField) => {
      if (!alreadyFound(customField)) {
        custom.push(customField);
      }
    });
  });

  return custom;
};

export function getFieldValueById(id, fieldsArray) {
  const match = fieldsArray.find((field) => field.id === id);
  if (!match) return null;

  return match.value;
}

export function getFieldsAsTypeKeys(fieldsArray) {
  const memo = {};
  fieldsArray.forEach((field) => {
    const {key, type, value} = field;
    if (!memo[type]) memo[type] = [];
    memo[type].push({
      id: key.replace('f_', ''),
      value,
    });
  });
  return memo;
}
