import isFunction from 'lodash/isFunction';
import get from 'lodash/get';
import {
  getWfTokenPattern,
  parseTokenJson,
} from '@packages/systems/core/utils/EmbedUtils/shared';

// simpleReplaceTokens is in it's own file to fix a circular dependency

/**
 * Replaces tokens in text with relating field values. This is a simple version
 * of replaceTokens. It is a clean find/replace and doesn't include any other
 * logic.
 * @param  {String} replaceable   The string with WF tokens
 * @param  {Object} item          The item to be used to replace the tokens with
 * @return {String}               The string with the WF tokens replaced
 */
export function simpleReplaceTokens(
  replaceable: string,
  item: Record<any, any>
): string {
  return replaceable.replace(getWfTokenPattern(), function (match) {
    const token = parseTokenJson(match) || {};
    // @ts-expect-error - TS2339 - Property 'path' does not exist on type '{}'.
    const path = token.path.split('.');
    return isFunction(item.getIn) ? item.getIn(path, '') : get(item, path, '');
  });
}
