// keep this file as simple as possible and avoid any additional imports, as this can increase published site bundle size a location

import type {Property} from '@packages/systems/commerce/core';

type SimplifiedSkuValues = {
  [propertyId: string]: string;
};

export const simplifySkuValues = (
  skuValues: Array<{
    property: {
      id: string;
    };
    value: {
      id: string;
    };
  }>
): SimplifiedSkuValues =>
  skuValues.reduce<Record<string, any>>((acc, pair) => {
    acc[pair.property.id] = pair.value.id;
    return acc;
  }, {});

export const getProductOptionValueName = (
  property: Property,
  simplifiedSkuValues: SimplifiedSkuValues
): string => {
  if (property.id && property.enum) {
    const propValueId = simplifiedSkuValues[property.id];
    const propValue = property.enum.find((value) => value.id === propValueId);
    if (propValue && typeof propValue.name === 'string') {
      return propValue.name;
    }
  }
  return '';
};
