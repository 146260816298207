/* globals
  window,
  document,
  */

const WF_CSRF_COOKIE_REGEX = '(^|;)\\s*wf-csrf\\s*=\\s*([^;]+)';
const WF_CSRF_URI = '/.wf_graphql/csrf';

let hasFetchedCsrfCookie = false;

export function fetchWithCsrf(
  uri: string,
  options?: RequestInit & {
    // Override `headers: HeadersInit` with a simpler type:
    headers?: Record<string, string>;
  }
): Promise<Response> {
  if (window.Webflow.env('design') || window.Webflow.env('preview')) {
    return fetch(uri, options);
  }

  const localCsrvCookie = getLocalCsrfCookie();
  const requestHeaders: Record<string, string> = options?.headers || {};

  return new Promise(
    (
      resolve: (result: Promise<Response> | Response) => void,
      reject: (error?: any) => void
    ) => {
      if (hasFetchedCsrfCookie && localCsrvCookie) {
        requestHeaders['X-Wf-Csrf'] = localCsrvCookie;
        resolve(
          fetch(uri, {
            ...options,
            headers: requestHeaders,
          })
        );
      } else {
        fetch(WF_CSRF_URI, {
          method: 'POST',
          credentials: 'include',
          headers: {'X-Requested-With': 'XMLHttpRequest'},
        })
          .then(() => {
            const newWfCsrfCookie = getLocalCsrfCookie();
            if (newWfCsrfCookie) {
              hasFetchedCsrfCookie = true;
              requestHeaders['X-Wf-Csrf'] = newWfCsrfCookie;
              resolve(
                fetch(uri, {
                  ...options,
                  headers: requestHeaders,
                })
              );
            } else {
              reject(new Error('Did not receive CSRF token'));
            }
          })
          .catch((err) => reject(err));
      }
    }
  );
}

export function getLocalCsrfCookie() {
  const wfCsrfCookieArray = document.cookie.match(WF_CSRF_COOKIE_REGEX);
  return wfCsrfCookieArray ? wfCsrfCookieArray.pop() : null;
}
