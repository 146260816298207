/* globals window */
import StyleMapObserver from '../StyleMapObserver';
import debug from './debug';
import {
  STRIPE_ECOMMERCE_KEY,
  STRIPE_ECOMMERCE_ACCOUNT_ID,
} from '@packages/systems/commerce/constants';

export class StripeStore {
  declare store: {
    initialized: boolean;
    stripe: Record<any, any>;
    elements: Array<Record<any, any>>;
    elementInstances: Array<{
      [type: string]: Record<any, any>;
    }>;
    cartPaymentRequests: Array<Record<any, any>>;
    styleMapObservers: {
      [type: string]: StyleMapObserver;
    };
  };

  constructor(docElement: Document) {
    if (window.Webflow.env('design') || window.Webflow.env('preview')) {
      return;
    }

    const stripeJsElement = docElement.querySelector(
      `[${STRIPE_ECOMMERCE_KEY}]`
    );
    if (!stripeJsElement) {
      this.store = {
        initialized: false,
        stripe: {},
        elements: [],
        elementInstances: [],
        cartPaymentRequests: [],
        styleMapObservers: {},
      };

      // @ts-expect-error - TS2322 - Type 'void' is not assignable to type 'StripeStore'. | TS2409 - Return type of constructor signature must be assignable to the instance type of the class.
      return debug.error(
        "Stripe has not been set up for this project – Go to the project's Ecommerce Payment settings in the Designer to link Stripe."
      );
    }

    const ecommKey = stripeJsElement.getAttribute(STRIPE_ECOMMERCE_KEY);
    const ecommAccountId = stripeJsElement.getAttribute(
      STRIPE_ECOMMERCE_ACCOUNT_ID
    );
    const stripeOpts = ecommAccountId
      ? {
          stripeAccount: ecommAccountId,
          apiVersion: '2020-03-02',
        }
      : null;
    // @ts-expect-error - TS2339 - Property 'Stripe' does not exist on type 'Window & typeof globalThis'.
    const stripe = window.Stripe(ecommKey, stripeOpts);

    this.store = {
      initialized: true,
      stripe,
      elements: [],
      elementInstances: [],
      cartPaymentRequests: [],
      styleMapObservers: {},
    };
  }

  isInitialized() {
    return this.store.initialized;
  }

  getStripeInstance() {
    return this.store.stripe;
  }

  getElementsInstance(index: number) {
    return this.store.elements[index];
  }

  getElement(type: string, index: number) {
    // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
    return this.store.elementInstances[index][type];
  }

  createElementsInstance(index: number) {
    if (this.store.elements[index]) {
      throw new Error(
        `Storage already exists for checkout form instance ${index}`
      );
    } else {
      const stripeInstance = this.getStripeInstance();
      this.store.elements[index] = stripeInstance.elements();
      this.store.elementInstances[index] = {};
    }
  }

  createElement(type: string, index: number, options: Record<any, any>) {
    if (!this.isInitialized()) {
      throw new Error(
        "Stripe has not been set up for this project – Go to the project's Ecommerce Payment settings in the Designer to link Stripe."
      );
    }

    // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
    if (this.store.elementInstances[index][type]) {
      throw new Error(
        `Stripe Element of type ${type} for instance ${index} already exists on this page`
      );
    }

    // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
    const el = this.store.elements[index].create(type, options);
    // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
    this.store.elementInstances[index][type] = el;
    return el;
  }

  updateCartPaymentRequest(
    index: number,
    orderData: Record<any, any>,
    siteData: Record<any, any>
  ) {
    const stripeInstance = this.getStripeInstance();
    const requiresShipping = Boolean(orderData.statusFlags.requiresShipping);

    const options = {
      country:
        siteData.businessAddress.country || siteData.defaultCountry || 'US',
      currency: siteData.defaultCurrency.toLowerCase(),
      total: {
        amount: orderData.subtotal.value,
        label: 'Subtotal',
        pending: true,
      },
      displayItems: generateDisplayItemsFromOrder(orderData, false),
      requestPayerName: true,
      requestPayerEmail: true,
      requestPayerPhone: false,
      requestShipping: requiresShipping,
    } as const;

    try {
      this.store.cartPaymentRequests[index] =
        stripeInstance.paymentRequest(options);
    } catch (error: any) {
      let ignoreError = false;

      // Stripe errors are `IntegrationError`s
      if (error.name === 'IntegrationError') {
        const unsupportedCountryPattern =
          /country should be one of the following strings(?:.*)You specified: (.*)./;
        const matches = error.message.match(unsupportedCountryPattern);

        ignoreError = Boolean(matches);
      }

      // We want the error to carry on if it's not exactly what we're looking for.
      if (!ignoreError) {
        throw error;
      } else {
        console.error(error);
      }
    }

    return this.store.cartPaymentRequests[index];
  }

  getCartPaymentRequest(index: number) {
    return this.store.cartPaymentRequests[index];
  }
}

export const generateDisplayItemsFromOrder = (
  orderData: Record<any, any>,
  showExtraItems: boolean
) => [
  // @ts-expect-error - TS7006 - Parameter 'item' implicitly has an 'any' type.
  ...orderData.userItems.map((item) => ({
    label: `${item.product.f_name_} ${item.count > 1 ? `(${item.count})` : ''}`,
    amount: item.rowTotal.value,
  })),
  ...(showExtraItems
    ? // @ts-expect-error - TS7006 - Parameter 'item' implicitly has an 'any' type.
      orderData.extraItems.map((item) => ({
        label: item.name,
        amount: item.price.value,
      }))
    : []),
];

type ShippingMethod = {
  id: string;
  name: string;
  description: string | null | undefined;
  price: {
    value: number;
  };
};

type StripeShippingOption = {
  id: string;
  label: string;
  detail: string;
  amount: number;
};

export const generateShippingOptionsFromMethods = (
  shippingMethods: Array<ShippingMethod>
): Array<StripeShippingOption> =>
  shippingMethods.map((method: ShippingMethod) => ({
    id: method.id,
    label: method.name,
    detail: method.description || '',
    amount: method.price.value,
  }));
