import {
  BREAKPOINT_ID_XXL,
  BREAKPOINT_ID_XL,
  BREAKPOINT_ID_LARGE,
  BREAKPOINT_ID_MAIN,
  BREAKPOINT_ID_MEDIUM,
  BREAKPOINT_ID_SMALL,
  BREAKPOINT_ID_TINY,
} from '@packages/systems/style/breakpoint-ids';
import type {BreakpointsConfig} from '@packages/systems/style/types';

/*
 * Data used to store breakpoints in the backend.
 */

export const DEFAULT_BREAKPOINTS_CONFIG: BreakpointsConfig = {
  [BREAKPOINT_ID_MAIN]: {
    maxWidth: 10000,
  },
  [BREAKPOINT_ID_MEDIUM]: {
    maxWidth: 991,
  },
  [BREAKPOINT_ID_SMALL]: {
    maxWidth: 767,
  },
  [BREAKPOINT_ID_TINY]: {
    maxWidth: 479,
  },
};

export const LARGER_BREAKPOINTS_CONFIG: BreakpointsConfig = {
  [BREAKPOINT_ID_MAIN]: {
    maxWidth: 10000,
  },
  [BREAKPOINT_ID_XXL]: {
    minWidth: 1920,
  },
  [BREAKPOINT_ID_XL]: {
    minWidth: 1440,
  },
  [BREAKPOINT_ID_LARGE]: {
    minWidth: 1280,
  },
  [BREAKPOINT_ID_MEDIUM]: {
    maxWidth: 991,
  },
  [BREAKPOINT_ID_SMALL]: {
    maxWidth: 767,
  },
  [BREAKPOINT_ID_TINY]: {
    maxWidth: 479,
  },
};
