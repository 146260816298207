import unescape from 'lodash/unescape';

export type TokenTransformers = {
  name: string;
  arguments?: string[];
};

export type Token = {
  path: string;
  type?: string;
  transformers?: TokenTransformers[];
};

export const getWfTokenPattern = function () {
  return /{{\s*wf\s*({.*?})\s*}}/g;
};

export const getCatchAllTokenPattern = function () {
  return /{{\s*(.*?)\s*}}/g;
};

export const getExternalTokenPattern = function () {
  return /{\\{(\s*.*?\s*)}}/g;
};

/**
 * Takes a token string and parses it to a token object
 * @param  {String} string A token string, e.g. '{{ wf {&quot;path&quot;:&quot;name&quot;\} }}'
 * @return {Object}        A parsed token object or null if string is not a valid token string
 */
export function parseTokenJson(string: string): Token | null {
  if (string.match(getWfTokenPattern())) {
    let token;
    try {
      token = JSON.parse(unescape(extractToken(string).replace(/\\}/g, '}')));
    } catch (err: any) {
      return null;
    }

    if (!token || !token.path || !token.type) {
      // If path doesn't exist, this JSON string is not a token
      return null;
    } else {
      return token;
    }
  } else {
    return null;
  }
}

export function extractToken(
  string: string,
  {
    shortHand,
  }: {
    shortHand?: boolean;
  } = {}
) {
  return shortHand
    ? string.replace(getCatchAllTokenPattern(), (match, subMatch) => {
        return stripLegacyShorthandSuffix(subMatch);
      })
    : string.replace(getWfTokenPattern(), '$1');
}

/**
 * Takes a legacy shorthand token path and strips
 * suffices that we used to have in ImageRef and Option tokens
 *
 * This is needed for the legacy bindings:
 * Option: {{ option:name }} and {{ author:option.name }}
 * ImageRef: {{ image.url }} and {{ author:image.url }}
 */
export function stripLegacyShorthandSuffix(tokenPath: string) {
  return tokenPath
    .split(':')
    .map((part) => part.split('.')[0])
    .join(':');
}
