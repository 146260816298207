/* globals document, window, CustomEvent */
import {ApolloClient, NormalizedCacheObject} from '@apollo/client';
import gql from 'graphql-tag';
import {
  DATA_ATTR_NODE_TYPE,
  RENDER_TREE_EVENT,
} from '@packages/systems/commerce/constants';

// fbq is Facebook pixel library loaded on page load
// eslint-disable-next-line no-var
declare var fbq: any;

// gtag is Google Analytics library loaded on page load
// eslint-disable-next-line no-var
declare var gtag: any;

export const safeParseJson = (jsonString?: string | null) => {
  let json = null;
  try {
    if (jsonString != null) {
      json = JSON.parse(decodeURIComponent(jsonString));
    }
  } catch (e: any) {
    if (!(e instanceof SyntaxError && e.message.match(/\bJSON\b/i))) {
      throw e;
    }
  } finally {
    return json;
  }
};

export const findElementByNodeType = (
  type: string,
  scope: Element | Document = document
): HTMLElement | null | undefined => {
  // @ts-expect-error - TS2322 - Type 'Element | null' is not assignable to type 'HTMLElement | null | undefined'.
  return scope.querySelector(`[${DATA_ATTR_NODE_TYPE}="${type}"]`);
};

export const findAllElementsByNodeType = (
  type: string,
  scope: Element | Document = document
): Array<HTMLElement> => {
  return Array.from(
    scope.querySelectorAll(`[${DATA_ATTR_NODE_TYPE}="${type}"]`)
  );
};

export const findClosestElementByNodeType = (
  nodeType: string,
  element: EventTarget
) => {
  let target = element;

  while (target) {
    if (
      target instanceof Element &&
      target.getAttribute(DATA_ATTR_NODE_TYPE) === nodeType
    ) {
      return target;
    } else {
      // @ts-expect-error - TS2322 - Type 'HTMLElement | null' is not assignable to type 'EventTarget'.
      target = target instanceof Element ? target.parentElement : null;
    }
  }

  return target;
};

export const findClosestElementWithAttribute = (
  dataAttribute: string,
  element: EventTarget
) => {
  let target = element;

  while (target) {
    if (target instanceof Element && target.hasAttribute(dataAttribute)) {
      return target;
    } else {
      // @ts-expect-error - TS2322 - Type 'HTMLElement | null' is not assignable to type 'EventTarget'.
      target = target instanceof Element ? target.parentElement : null;
    }
  }

  return target;
};

export const findClosestElementByClassName = (
  className: string,
  element: EventTarget
) => {
  let target = element;

  while (target) {
    if (target instanceof Element && target.classList.contains(className)) {
      return target;
    } else {
      // @ts-expect-error - TS2322 - Type 'HTMLElement | null' is not assignable to type 'EventTarget'.
      target = target instanceof Element ? target.parentElement : null;
    }
  }

  return target;
};

export const triggerRender = (
  error?: Array<Record<any, any>> | null,
  isInitial: boolean = false
) => {
  const renderEvent = new CustomEvent(RENDER_TREE_EVENT, {
    detail: {
      error,
      isInitial,
    },
  });
  window.dispatchEvent(renderEvent);
};

export const isProductionLikeEnv = () =>
  process.env.NODE_ENV === 'production' ||
  process.env.NODE_ENV === 'acceptance' ||
  process.env.NODE_ENV === 'sai';

export const isProtocolHttps = () =>
  !isProductionLikeEnv() || window.location.protocol === 'https:';

// because microsoft edge doesn't support FormData.prototype.get, we are implementing our own
// partial version of it, for our specific purposes. this function follows FormData's spec, and will
// only return values from elements in a form with a `name` set. unlike FormData returning its own
// Map-like object, we just return a POJO. because that's all we need. thank you edge for this journey :)
// `toString` is used for our address data generation, as we want the values to be trimmed strings
interface ElementWithValue extends HTMLElement {
  value?: unknown;
}

export const formToObject = (
  form: HTMLFormElement,
  toString?: boolean
): Record<any, any> => {
  const values: Record<string, any> = {};
  // @ts-expect-error - TS2345 - Argument of type '(element: ElementWithValue) => void' is not assignable to parameter of type '(value: Element, index: number, array: Element[]) => void'.
  Array.from(form.elements).forEach((element: ElementWithValue) => {
    const name = element.getAttribute('name');
    if (name && name !== '') {
      const value = toString ? String(element.value).trim() : element.value;
      values[name] = value == null || value === '' ? null : value;
    }
  });
  return values;
};

export const customDataFormToArray = (form?: HTMLElement | null) => {
  // @ts-expect-error - TS7034 - Variable 'customData' implicitly has type 'any[]' in some locations where its type cannot be determined.
  const customData = [];
  if (!form || !(form instanceof HTMLFormElement)) {
    // @ts-expect-error - TS7005 - Variable 'customData' implicitly has an 'any[]' type.
    return customData;
  }

  Array.from(form.elements).forEach(
    // @ts-expect-error - TS2345 - Argument of type '(element: HTMLElement | HTMLInputElement | HTMLTextAreaElement) => void' is not assignable to parameter of type '(value: Element, index: number, array: Element[]) => void'.
    (element: HTMLElement | HTMLInputElement | HTMLTextAreaElement) => {
      const name = element.getAttribute('name');
      if (element instanceof HTMLTextAreaElement && element.value) {
        customData.push({
          name: name ? name : 'Textarea',
          textArea: element.value,
        });
      } else if (element instanceof HTMLInputElement) {
        if (element.type === 'checkbox') {
          customData.push({
            name: name ? name : 'Checkbox',
            checkbox: element.checked,
          });
        } else if (element.value) {
          customData.push({
            name: name ? name : 'Text Input',
            textInput: element.value,
          });
        }
      }
    }
  );

  // @ts-expect-error - TS7005 - Variable 'customData' implicitly has an 'any[]' type.
  return customData;
};

export const setElementLoading = (el: Element) => {
  // @ts-expect-error - TS2339 - Property 'Webflow' does not exist on type 'Window & typeof globalThis'.
  const tr = window.Webflow.tram(el);

  tr.set({opacity: 0.2});
  tr.add('opacity 500ms ease-in-out');

  const animate = () => {
    tr.start({opacity: 0.2}).then({opacity: 0.4}).then(animate);
  };
  animate();

  return () => tr.destroy();
};

const loadingCallbacks: Array<any | (() => void)> = [];

export const addLoadingCallback = (cb: () => void) => {
  loadingCallbacks.push(cb);
};

export const executeLoadingCallbacks = () => {
  let finishLoading;
  while ((finishLoading = loadingCallbacks.shift()) !== undefined) {
    finishLoading();
  }
};

export const isFreeOrder = (response: any) =>
  response &&
  response.data &&
  response.data.database &&
  response.data.database.commerceOrder &&
  response.data.database.commerceOrder.statusFlags &&
  response.data.database.commerceOrder.statusFlags.isFreeOrder === true;

export const hasSubscription = (response: any) =>
  response &&
  response.data &&
  response.data.database &&
  response.data.database.commerceOrder &&
  response.data.database.commerceOrder.statusFlags &&
  response.data.database.commerceOrder.statusFlags.hasSubscription === true;

export const showElement = (element: HTMLElement) =>
  element.style.removeProperty('display');
export const hideElement = (element: HTMLElement) =>
  element.style.setProperty('display', 'none');

// the @client directive ensures we only fetch this from the local cache so that we don't wait on network
// we can only use this safely inside a rendered cart/checkout/confirmation container
// as to render that view, a query had to be made which *always* includes the flags listed below.
// if you are adding new flags to this, please ensure that the flags are *always* included
// for all types of commerce elements in `packages/systems/dynamo/utils/DynamoQuery/DynamoQuery.js`
const orderStatusFlagsQuery = gql`
  query FetchCartInfo {
    database @client {
      id
      commerceOrder {
        id
        statusFlags {
          requiresShipping
          isFreeOrder
          hasSubscription
        }
      }
    }
  }
`;
type OrderStatusFlags = {
  requiresShipping: boolean;
  isFreeOrder: boolean;
  hasSubscription: boolean;
};
export const fetchOrderStatusFlags = (
  apolloClient: ApolloClient<NormalizedCacheObject>
): Promise<OrderStatusFlags> =>
  apolloClient
    .query({
      query: orderStatusFlagsQuery,
    })
    .then((data) => {
      return (
        data &&
        data.data &&
        data.data.database &&
        data.data.database.commerceOrder &&
        data.data.database.commerceOrder.statusFlags
      );
    });

const acceptedOrderDataQuery = gql`
  query FetchAcceptedOrderData(
    $finalizedOrder: commerce_order_finalized_order_args
  ) {
    database {
      id
      commerceOrder(finalizedOrder: $finalizedOrder) {
        id
        total {
          decimalValue
          unit
        }
        userItems {
          id
          count
          product {
            id
            f_name_
          }
          sku {
            id
          }
          price {
            decimalValue
          }
        }
      }
    }
  }
`;

const fetchAcceptedOrderData = (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  finalizedOrder: {
    orderId: string;
    token: string;
  }
): Promise<any> =>
  apolloClient
    .query({
      query: acceptedOrderDataQuery,
      variables: {finalizedOrder},
    })
    .then((data) => data?.data?.database?.commerceOrder);

export const trackOrder = (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  finalizedOrder: {
    orderId: string;
    token: string;
  }
) => {
  // early return if both facebook and google don't exist on the page
  // we don't need to fetch this data if we're not doing any analytics
  if (typeof fbq === 'undefined' && typeof gtag === 'undefined') {
    return;
  }

  // check to see if this order was tracked already, and if it was, return early
  let trackedOrders: Record<string, any> = {};
  try {
    const storedTrackedOrders = window.localStorage.getItem('wf-seen-orders');
    if (storedTrackedOrders) {
      trackedOrders = JSON.parse(storedTrackedOrders);
    }
  } catch (err: any) {
    return;
  }

  if (trackedOrders[finalizedOrder.orderId]) {
    return;
  }

  // we have to fetch the accepted order data, instead of relying on the pending order data
  // that's fetched on the order confirmation page, because we only get the purchased items
  // data if the user has an Order Items element on the page. while i would presume that most
  // people would keep that element on the page, it's not a guarantee, so we need to separately
  // fetch it here to ensure that we always send the analytics, regardless of how the user has
  // customized their order confirmation page.
  fetchAcceptedOrderData(apolloClient, finalizedOrder).then((order) => {
    if (!order) {
      return;
    }

    const {decimalValue, unit} = order.total;

    if (typeof fbq !== 'undefined' && typeof fbq === 'function') {
      fbq('track', 'Purchase', {
        value: decimalValue,
        currency: unit,
        // @ts-expect-error - TS7006 - Parameter 'item' implicitly has an 'any' type.
        content_ids: (order.userItems || []).map((item) => item.sku.id),
        content_type: 'product',
        // @ts-expect-error - TS7006 - Parameter 'item' implicitly has an 'any' type.
        contents: (order.userItems || []).map((item) => ({
          id: item.sku.id,
          quantity: item.count,
          item_price: item.price.decimalValue,
        })),
      });
    }
    if (typeof gtag !== 'undefined' && typeof gtag === 'function') {
      gtag('event', 'purchase', {
        transaction_id: order.id,
        value: decimalValue,
        currency: unit,
        // @ts-expect-error - TS7006 - Parameter 'item' implicitly has an 'any' type.
        items: (order.userItems || []).map((item) => ({
          id: item.sku.id,
          name: item.product.f_name_,
          quantity: item.count,
          price: item.price.decimalValue,
        })),
      });
    }

    trackedOrders[finalizedOrder.orderId] = true;
    try {
      window.localStorage.setItem(
        'wf-seen-orders',
        JSON.stringify(trackedOrders)
      );
    } catch (err: any) {
      return;
    }
  });
};
