import type {
  ConditionsWithTypeT,
  DeprecatedConditionsT,
} from '@packages/systems/dynamo/types';
import {flatMap} from '@packages/utilities/fp/utils';

export const convertConditionFieldsFromObjectToArray = (
  fields: DeprecatedConditionsT
): ConditionsWithTypeT => {
  const mapper = (fieldPath: string): ConditionsWithTypeT => {
    // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
    const type = fields[fieldPath].type;

    // @ts-expect-error - TS2769 - No overload matches this call.
    return Object.entries(fields[fieldPath]).reduce<ConditionsWithTypeT>(
      (conditionFields, [operatorName, value]: [string, unknown]) => {
        if (operatorName === 'type') {
          return conditionFields;
        }

        conditionFields.push({fieldPath, operatorName, value, type});

        return conditionFields;
      },
      []
    );
  };

  return flatMap(mapper)(Object.keys(fields));
};

export const normalizeConditionFields = (
  fields: DeprecatedConditionsT | ConditionsWithTypeT = []
): ConditionsWithTypeT => {
  if (Array.isArray(fields)) {
    return fields;
  }

  return convertConditionFieldsFromObjectToArray(fields);
};
