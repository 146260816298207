type StoreShape = {
  selectedSku: string;
  skuValues: SkuValues;
  requiresUserSession: boolean;
};
export type SkuValues = {
  [propertyId: string]: string;
};

interface StoreFetcher {
  (instanceId: string, key: 'selectedSku'): string | null | undefined;
  (instanceId: string, key: 'skuValues'): SkuValues | null | undefined;
  (instanceId: string, key: 'requiresUserSession'): boolean | null | undefined;
}
type StoreUpdater = (
  instanceId: string,
  newValues: Partial<StoreShape>
) => void;
type AddStoreWatcher = (
  instanceId: string,
  key: keyof StoreShape,
  cb: (currentValue?: any, previousValue?: any) => void
) => void;

const initialState: StoreShape = {
  selectedSku: '',
  skuValues: {},
  requiresUserSession: false,
};

export const createNewStore = (): {
  fetchFromStore: StoreFetcher;
  updateStore: StoreUpdater;
  addStoreWatcher: AddStoreWatcher;
} => {
  const store: Record<string, any> = {};
  const watchers: Record<string, any> = {};

  const fetchFromStore: StoreFetcher = (instanceId, key) => {
    return store[instanceId] ? store[instanceId][key] : undefined;
  };

  const updateStore: StoreUpdater = (instanceId, newValues) => {
    if (!store[instanceId]) {
      store[instanceId] = {...initialState};
    }

    for (const key of Object.keys(newValues)) {
      if (!store[instanceId].hasOwnProperty(key)) {
        continue;
      }

      const previousValue = store[instanceId][key];
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Partial<StoreShape>'.
      store[instanceId][key] = newValues[key];
      if (watchers[instanceId] && watchers[instanceId][key]) {
        for (const watcher of watchers[instanceId][key]) {
          // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Partial<StoreShape>'.
          watcher(newValues[key], previousValue);
        }
      }
    }
  };

  const addStoreWatcher: AddStoreWatcher = (instanceId, key, cb) => {
    if (!watchers[instanceId]) {
      watchers[instanceId] = {};
    }

    if (watchers[instanceId][key]) {
      watchers[instanceId][key].push(cb);
    } else {
      watchers[instanceId][key] = [cb];
    }
  };

  return {fetchFromStore, updateStore, addStoreWatcher};
};
