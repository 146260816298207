/* globals document, window, Element, HTMLElement, CustomEvent, HTMLFormElement, HTMLInputElement, HTMLCollection, HTMLAnchorElement */
import gql from 'graphql-tag';
import mergeWith from 'lodash/mergeWith';
import forEach from 'lodash/forEach';
import {
  COMMERCE_CART_PUBLISHED_SITE_ACTION_ATTR,
  COMMERCE_CART_PUBLISHED_SITE_ACTIONS,
  DATA_ATTR_COMMERCE_SKU_ID,
  DATA_ATTR_NODE_TYPE,
  NODE_TYPE_COMMERCE_CART_WRAPPER,
  NODE_TYPE_COMMERCE_CART_OPEN_LINK,
  NODE_TYPE_COMMERCE_CART_CLOSE_LINK,
  NODE_TYPE_COMMERCE_CART_CONTAINER_WRAPPER,
  NODE_TYPE_COMMERCE_CART_CONTAINER,
  DATA_ATTR_COUNT_HIDE_RULE,
  CART_COUNT_HIDE_RULES,
  CART_TYPES,
  ANIMATION_EASING_DEFAULT,
  ANIMATION_DURATION_DEFAULT,
  DATA_ATTR_ANIMATION_EASING,
  DATA_ATTR_ANIMATION_DURATION,
  NODE_TYPE_COMMERCE_CART_CHECKOUT_BUTTON,
  DATA_ATTR_LOADING_TEXT,
  CART_CHECKOUT_BUTTON_TEXT_DEFAULT,
  CART_CHECKOUT_LOADING_TEXT_DEFAULT,
  NODE_TYPE_COMMERCE_CART_ERROR,
  DATA_ATTR_PUBLISHABLE_KEY,
  NODE_TYPE_COMMERCE_CART_FORM,
  NODE_TYPE_COMMERCE_ORDER_CONFIRMATION_WRAPPER,
  CART_GENERAL_ERROR_MESSAGE,
  CART_ERROR_MESSAGE_SELECTOR,
  CART_OPEN,
  CART_TYPE,
  CART_QUERY,
  PAYPAL_ELEMENT_INSTANCE,
  getCartErrorMessageForType,
  DATA_ATTR_OPEN_ON_HOVER,
  CHANGE_CART_EVENT,
  RENDER_TREE_EVENT,
  PAYPAL_BUTTON_ELEMENT_INSTANCE,
} from '@packages/systems/commerce/constants';
import EventHandlerProxyWithApolloClient from './eventHandlerProxyWithApolloClient';
import {
  triggerRender,
  findElementByNodeType,
  isProtocolHttps,
  findAllElementsByNodeType,
  findClosestElementByNodeType,
  findClosestElementByClassName,
  setElementLoading,
  addLoadingCallback,
  executeLoadingCallbacks,
  isFreeOrder,
  hasSubscription,
  showElement,
  hideElement,
} from './commerceUtils';
import {StripeStore} from './stripeStore';
import debug from './debug';
import {updateWebPaymentsButton} from './webPaymentsEvents';

import {renderTree} from './rendering';

import defaultTo from 'lodash/defaultTo';
import {ApolloClient, NormalizedCacheObject} from '@apollo/client';

const {MODAL, LEFT_SIDEBAR, RIGHT_SIDEBAR, LEFT_DROPDOWN, RIGHT_DROPDOWN} =
  CART_TYPES;

const {REMOVE_ITEM, UPDATE_ITEM_QUANTITY} =
  COMMERCE_CART_PUBLISHED_SITE_ACTIONS;

const updateItemQuantityMutation = gql`
  mutation AddToCart($skuId: String!, $count: Int!) {
    ecommerceUpdateCartItem(sku: $skuId, count: $count) {
      ok
      itemId
      itemCount
    }
  }
`;

const forEachElementInForm = (
  form: HTMLFormElement | null | undefined,
  callback: (arg1: HTMLInputElement) => void
) => {
  if (
    form instanceof HTMLFormElement &&
    form.elements instanceof HTMLCollection
  ) {
    Array.from(form.elements).forEach((input) => {
      if (input instanceof HTMLInputElement) {
        callback(input);
      }
    });
  }
};

const disableAllFormElements = (form?: HTMLFormElement | null) => {
  forEachElementInForm(form, (input) => {
    input.disabled = true;
  });
};

const enableAllFormElements = (form?: HTMLFormElement | null) => {
  forEachElementInForm(form, (input) => {
    input.disabled = false;
  });
};

// Recursively searches up the tree to find the remove link anchor element
// @ts-expect-error - TS7023 - 'searchTreeForRemoveLink' implicitly has return type 'any' because it does not have a return type annotation and is referenced directly or indirectly in one of its return expressions.
const searchTreeForRemoveLink = (element: Element | EventTarget) => {
  if (
    element instanceof Element &&
    element.hasAttribute(COMMERCE_CART_PUBLISHED_SITE_ACTION_ATTR) &&
    element.getAttribute(COMMERCE_CART_PUBLISHED_SITE_ACTION_ATTR) ===
      REMOVE_ITEM &&
    element.hasAttribute(DATA_ATTR_COMMERCE_SKU_ID)
  ) {
    return element;
  } else {
    return element instanceof Element && element.parentElement
      ? searchTreeForRemoveLink(element.parentElement)
      : false;
  }
};

// Matchers:
const isItemRemovedEvent = (event: Event) => {
  // @ts-expect-error - TS2345 - Argument of type 'EventTarget | null' is not assignable to parameter of type 'EventTarget | Element'.
  return searchTreeForRemoveLink(event.target);
};

const isItemQuantityChangedEvent = (event: Event) =>
  event.target instanceof Element &&
  event.target.hasAttribute(COMMERCE_CART_PUBLISHED_SITE_ACTION_ATTR) &&
  event.target.getAttribute(COMMERCE_CART_PUBLISHED_SITE_ACTION_ATTR) ===
    UPDATE_ITEM_QUANTITY &&
  event.target.hasAttribute(DATA_ATTR_COMMERCE_SKU_ID) &&
  event.target;

const isItemQuantityInputEvent = (event: Event) =>
  event.target instanceof Element &&
  event.target.hasAttribute(COMMERCE_CART_PUBLISHED_SITE_ACTION_ATTR) &&
  event.target.getAttribute(COMMERCE_CART_PUBLISHED_SITE_ACTION_ATTR) ===
    UPDATE_ITEM_QUANTITY &&
  event.target.hasAttribute(DATA_ATTR_COMMERCE_SKU_ID) &&
  event.target;

// @ts-expect-error - TS7031 - Binding element 'target' implicitly has an 'any' type.
const isCartButtonEvent = ({target}) => {
  const cartOpenLink = findClosestElementByNodeType(
    NODE_TYPE_COMMERCE_CART_OPEN_LINK,
    target
  );
  const cartCloseLink = findClosestElementByNodeType(
    NODE_TYPE_COMMERCE_CART_CLOSE_LINK,
    target
  );

  if (cartOpenLink) {
    return cartOpenLink;
  } else if (cartCloseLink) {
    return cartCloseLink;
  } else {
    return false;
  }
};

// @ts-expect-error - TS7031 - Binding element 'target' implicitly has an 'any' type.
const isCartCheckoutButtonEvent = ({target}) => {
  const cartCheckoutButton = findClosestElementByNodeType(
    NODE_TYPE_COMMERCE_CART_CHECKOUT_BUTTON,
    target
  );
  if (cartCheckoutButton) {
    return cartCheckoutButton;
  } else {
    return false;
  }
};

// @ts-expect-error - TS7031 - Binding element 'target' implicitly has an 'any' type.
const isCartWrapperEvent = ({target}) =>
  target instanceof Element &&
  target.getAttribute(DATA_ATTR_NODE_TYPE) ===
    NODE_TYPE_COMMERCE_CART_WRAPPER &&
  target;

// @ts-expect-error - TS7031 - Binding element 'target' implicitly has an 'any' type.
const isCartFormEvent = ({target}) =>
  target instanceof Element &&
  target.hasAttribute(DATA_ATTR_NODE_TYPE) &&
  target.getAttribute(DATA_ATTR_NODE_TYPE) === NODE_TYPE_COMMERCE_CART_FORM;

// @ts-expect-error - TS7023 - 'getFormElement' implicitly has return type 'any' because it does not have a return type annotation and is referenced directly or indirectly in one of its return expressions.
const getFormElement = (
  element: Element | null | undefined | HTMLElement | HTMLFormElement
) => {
  if (!(element instanceof Element)) {
    return null;
  }
  return element instanceof HTMLFormElement
    ? element
    : getFormElement(element.parentElement);
};

// Event handlers:
const handleItemRemoved = (
  event: Event,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  if (window.Webflow.env('design') || window.Webflow.env('preview')) {
    return;
  }
  event.preventDefault();

  const {currentTarget} = event;

  if (!(currentTarget instanceof HTMLElement)) {
    return;
  }

  const commerceCartWrapper = findClosestElementByNodeType(
    NODE_TYPE_COMMERCE_CART_WRAPPER,
    currentTarget
  );
  if (!(commerceCartWrapper instanceof Element)) {
    return;
  }

  const errorElement = findElementByNodeType(
    NODE_TYPE_COMMERCE_CART_ERROR,
    commerceCartWrapper
  );
  if (!(errorElement instanceof Element)) {
    return;
  }
  errorElement.style.setProperty('display', 'none');

  const skuId = currentTarget.getAttribute(DATA_ATTR_COMMERCE_SKU_ID);
  const count = 0;
  const form = getFormElement(currentTarget);
  disableAllFormElements(form);

  const cartItem = findClosestElementByClassName(
    'w-commerce-commercecartitem',
    currentTarget
  );
  if (!(cartItem instanceof Element)) {
    return;
  }
  addLoadingCallback(setElementLoading(cartItem));

  // @ts-expect-error - TS2345 - Argument of type 'EventTarget | null' is not assignable to parameter of type 'EventTarget | Element'.
  const removeLinkElement = searchTreeForRemoveLink(event.target);

  // It always will be an anchor element here, but this is mostly a Flow-complaint-fixer
  if (removeLinkElement instanceof HTMLAnchorElement) {
    // Disable click events on the Remove link
    removeLinkElement.style.pointerEvents = 'none';
  }

  apolloClient
    .mutate({
      mutation: updateItemQuantityMutation,
      variables: {skuId, count},
    })
    .then(
      () => {
        triggerRender(null);
      },
      (error) => {
        debug.error(error);
        errorElement.style.removeProperty('display');
        const errorMsg = errorElement.querySelector(
          CART_ERROR_MESSAGE_SELECTOR
        );
        if (!errorMsg) {
          return;
        }
        // Only general error should be triggered when removing items
        const errorText =
          errorMsg.getAttribute(CART_GENERAL_ERROR_MESSAGE) || '';
        errorMsg.textContent = errorText;
        triggerRender(error);
      }
    )
    .then(() => {
      if (removeLinkElement instanceof HTMLAnchorElement) {
        // Re-enable click events on the Remove link
        removeLinkElement.style.pointerEvents = 'auto';
      }

      // When cart is becoming empty, focus on the first thing that can be focused
      const cartContainer = currentTarget.closest(
        '.w-commerce-commercecartcontainer'
      );
      if (cartContainer instanceof HTMLElement) {
        const itemContainer = cartContainer.getElementsByClassName(
          'w-commerce-commercecartitem'
        );
        const focusableContent = getFocusableElements(cartContainer);
        if (itemContainer.length === 1 && focusableContent.length > 0) {
          // @ts-expect-error - TS2339 - Property 'focus' does not exist on type 'Element'.
          focusableContent[0].focus();
        }
      }
    });
};

const handleItemQuantityChanged = (
  event: Event,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  if (window.Webflow.env('design') || window.Webflow.env('preview')) {
    return;
  }

  event.preventDefault();
  const {currentTarget} = event;
  if (!(currentTarget instanceof HTMLInputElement)) {
    return;
  }
  if (
    currentTarget.form instanceof HTMLFormElement &&
    currentTarget.form.reportValidity() === false
  ) {
    return;
  }

  const commerceCartWrapper = findClosestElementByNodeType(
    NODE_TYPE_COMMERCE_CART_WRAPPER,
    currentTarget
  );
  if (!(commerceCartWrapper instanceof Element)) {
    return;
  }

  const errorElement = findElementByNodeType(
    NODE_TYPE_COMMERCE_CART_ERROR,
    commerceCartWrapper
  );

  if (!(errorElement instanceof Element)) {
    return;
  }
  errorElement.style.setProperty('display', 'none');

  const cartItem = currentTarget.parentElement;
  if (!(cartItem instanceof Element)) {
    return;
  }
  addLoadingCallback(setElementLoading(cartItem));

  const skuId = currentTarget.getAttribute(DATA_ATTR_COMMERCE_SKU_ID);
  const count = currentTarget.value;
  disableAllFormElements(currentTarget.form);
  apolloClient
    .mutate({
      mutation: updateItemQuantityMutation,
      variables: {skuId, count},
    })
    .then(
      () => {
        enableAllFormElements(currentTarget.form);
        triggerRender(null);
      },
      (error) => {
        enableAllFormElements(currentTarget.form);
        debug.error(error);
        errorElement.style.removeProperty('display');
        const errorMsg = errorElement.querySelector(
          CART_ERROR_MESSAGE_SELECTOR
        );
        if (!errorMsg) {
          return;
        }
        const errorType =
          error.graphQLErrors &&
          error.graphQLErrors.length > 0 &&
          error.graphQLErrors[0].code === 'OutOfInventory'
            ? 'quantity'
            : 'general';
        const errorText =
          errorMsg.getAttribute(getCartErrorMessageForType(errorType)) || '';
        errorMsg.textContent = errorText;
        triggerRender(error);
      }
    );
};

const handleItemInputChanged = (event: Event) => {
  if (window.Webflow.env('design') || window.Webflow.env('preview')) {
    return;
  }
  event.preventDefault();
  const {currentTarget} = event;
  if (!(currentTarget instanceof HTMLInputElement)) {
    return;
  }
  if (
    currentTarget.validity.valid === false &&
    currentTarget.form instanceof HTMLFormElement
  ) {
    currentTarget.form.reportValidity();
  }
};

const handleChangeCartStateEvent = (event: Event | CustomEvent) => {
  if (
    !(event.currentTarget instanceof Element) ||
    !(event instanceof CustomEvent)
  ) {
    return;
  }

  const {currentTarget, detail} = event;
  const isOpen = currentTarget.hasAttribute(CART_OPEN);
  const shouldOpen = detail && detail.open != null ? detail.open : !isOpen;

  const wrapper = findElementByNodeType(
    NODE_TYPE_COMMERCE_CART_CONTAINER_WRAPPER,
    currentTarget
  );
  if (!wrapper) {
    return;
  }

  const cartContainer = getCartContainer(wrapper);
  if (!cartContainer) {
    return;
  }

  const cartElement = wrapper.parentElement;
  if (!cartElement) {
    return;
  }

  const cartType = cartElement.getAttribute(CART_TYPE);
  const duration =
    defaultTo(
      cartElement.getAttribute(DATA_ATTR_ANIMATION_DURATION),
      ANIMATION_DURATION_DEFAULT
    ) + 'ms';
  const containerEasing = defaultTo(
    cartElement.getAttribute(DATA_ATTR_ANIMATION_EASING),
    ANIMATION_EASING_DEFAULT
  );
  const wrapperTransition = `opacity ${duration} ease 0ms`;
  const containerOutDelay = '50ms';
  const shouldAnimate = duration !== '0ms';

  let containerStepA;
  let containerStepB;
  switch (cartType) {
    case MODAL: {
      containerStepA = {scale: 0.95};
      containerStepB = {scale: 1.0};
      break;
    }
    case LEFT_SIDEBAR: {
      containerStepA = {x: -30};
      containerStepB = {x: 0};
      break;
    }
    case RIGHT_SIDEBAR: {
      containerStepA = {x: 30};
      containerStepB = {x: 0};
      break;
    }
    case LEFT_DROPDOWN:
    case RIGHT_DROPDOWN: {
      containerStepA = {y: -10};
      containerStepB = {y: 0};
      break;
    }
  }

  if (shouldOpen) {
    document.addEventListener('keydown', handleCartFocusTrap);

    currentTarget.setAttribute(CART_OPEN, '');
    wrapper.style.removeProperty('display');

    // Ensures that the first focusable element in the cart gets focus
    // on cart launching.
    const focusableContent = getFocusableElements(cartContainer);
    if (focusableContent.length > 0) {
      // @ts-expect-error - TS2339 - Property 'focus' does not exist on type 'Element'.
      focusableContent[0].focus();
    }

    if (shouldAnimate && !isOpen) {
      // @ts-expect-error - TS2339 - Property 'Webflow' does not exist on type 'Window & typeof globalThis'.
      window.Webflow.tram(wrapper)
        .add(wrapperTransition)
        .set({opacity: 0})
        .start({opacity: 1});

      // @ts-expect-error - TS2339 - Property 'Webflow' does not exist on type 'Window & typeof globalThis'.
      window.Webflow.tram(cartContainer)
        .add(`transform ${duration} ${containerEasing} 0ms`)
        .set(containerStepA)
        .start(containerStepB);
    }
  } else {
    document.removeEventListener('keydown', handleCartFocusTrap);

    currentTarget.removeAttribute(CART_OPEN);

    if (shouldAnimate) {
      // @ts-expect-error - TS2339 - Property 'Webflow' does not exist on type 'Window & typeof globalThis'.
      window.Webflow.tram(wrapper)
        .add(wrapperTransition)
        .start({opacity: 0})
        .then(() => {
          wrapper.style.display = 'none';
          // @ts-expect-error - TS2339 - Property 'Webflow' does not exist on type 'Window & typeof globalThis'.
          window.Webflow.tram(cartContainer).stop();
        });

      // @ts-expect-error - TS2339 - Property 'Webflow' does not exist on type 'Window & typeof globalThis'.
      window.Webflow.tram(cartContainer)
        .add(`transform ${duration} ${containerEasing} ${containerOutDelay}`)
        .start(containerStepA);
    } else {
      wrapper.style.display = 'none';
    }

    const cartOpenButton = findElementByNodeType(
      NODE_TYPE_COMMERCE_CART_OPEN_LINK,
      cartElement
    );
    if (cartOpenButton instanceof Element) {
      cartOpenButton.focus();
    }
  }
};

const handleCartButton = (event: Event) => {
  // Don't handle events when we're in design mode
  if (window.Webflow.env('design')) {
    return;
  }

  const {currentTarget, type} = event;

  if (!(currentTarget instanceof Element)) {
    return;
  }

  const commerceCartWrapper = findClosestElementByNodeType(
    NODE_TYPE_COMMERCE_CART_WRAPPER,
    currentTarget
  );
  if (!(commerceCartWrapper instanceof Element)) {
    return;
  }

  const cartContainerWrapper = findElementByNodeType(
    NODE_TYPE_COMMERCE_CART_CONTAINER_WRAPPER,
    commerceCartWrapper
  );

  let evt;
  if (
    type === 'click' &&
    (currentTarget.getAttribute(DATA_ATTR_NODE_TYPE) ===
      NODE_TYPE_COMMERCE_CART_CLOSE_LINK ||
      (currentTarget.getAttribute(DATA_ATTR_NODE_TYPE) ===
        NODE_TYPE_COMMERCE_CART_OPEN_LINK &&
        !commerceCartWrapper.hasAttribute(DATA_ATTR_OPEN_ON_HOVER)))
  ) {
    evt = new CustomEvent(CHANGE_CART_EVENT, {
      bubbles: true,
    });
    if (
      cartContainerWrapper &&
      currentTarget.getAttribute(DATA_ATTR_NODE_TYPE) ===
        NODE_TYPE_COMMERCE_CART_CLOSE_LINK
    ) {
      cartContainerWrapper.removeEventListener(
        'mouseleave',
        handleCartContainerLeave
      );
      // @ts-expect-error - TS2769 - No overload matches this call.
      commerceCartWrapper.removeEventListener(
        'mouseleave',
        handleCartContainerLeave
      );
    }
  } else if (
    type === 'mouseover' &&
    commerceCartWrapper.hasAttribute(DATA_ATTR_OPEN_ON_HOVER) &&
    currentTarget.getAttribute(DATA_ATTR_NODE_TYPE) ===
      NODE_TYPE_COMMERCE_CART_OPEN_LINK
  ) {
    evt = new CustomEvent(CHANGE_CART_EVENT, {
      bubbles: true,
      detail: {
        open: true,
      },
    });
    if (cartContainerWrapper) {
      cartContainerWrapper.addEventListener(
        'mouseleave',
        handleCartContainerLeave
      );
      // @ts-expect-error - TS2769 - No overload matches this call.
      currentTarget.addEventListener('mouseleave', handleCartContainerLeave);
    }
  }

  if (evt) {
    commerceCartWrapper.dispatchEvent(evt);
  }
};

const handleCartCheckoutButton = (event: Event) => {
  // Don't want to continue with validation in preview mode
  if (window.Webflow.env('preview')) {
    return;
  }
  event.preventDefault();
  const {currentTarget: checkoutButton} = event;

  if (!(checkoutButton instanceof Element)) {
    return;
  }

  if (!isProtocolHttps()) {
    window.alert(
      'This site is currently unsecured so you cannot enter checkout.'
    );
    return;
  }

  const loadingText = checkoutButton.getAttribute(DATA_ATTR_LOADING_TEXT);
  const buttonText = checkoutButton.innerHTML;
  checkoutButton.innerHTML = loadingText
    ? loadingText
    : CART_CHECKOUT_LOADING_TEXT_DEFAULT;

  const commerceCartWrapper = findClosestElementByNodeType(
    NODE_TYPE_COMMERCE_CART_WRAPPER,
    checkoutButton
  );
  if (!(commerceCartWrapper instanceof Element)) {
    return;
  }

  // To determine if we should continue with checkout, we check for the existence of
  // either the Stripe publishable key (only added when Stripe is enabled), or the
  // PayPal script element. If neither exists, we want to block checkout, as this means
  // no payment gateway has been enabled, and therefore, checkout cannot be enabled.
  // In the future, we may need to expand this to be more comprehensive, if we allow
  // for free orders on sites without a payment gateway attached, or if/when we add
  // more possible payment gateways.
  const publishableKey = checkoutButton.getAttribute(DATA_ATTR_PUBLISHABLE_KEY);
  const paypalElement = document.querySelector(`[${PAYPAL_ELEMENT_INSTANCE}]`);

  if (!publishableKey && !paypalElement) {
    const errorElement = findElementByNodeType(
      NODE_TYPE_COMMERCE_CART_ERROR,
      commerceCartWrapper
    );

    if (!(errorElement instanceof Element)) {
      return;
    }
    errorElement.style.setProperty('display', 'none');
    errorElement.style.removeProperty('display');
    const errorMsg = errorElement.querySelector('.w-cart-error-msg');
    if (!errorMsg) {
      return;
    }
    // Render checkout error message
    const errorText = errorMsg.getAttribute(`data-w-cart-checkout-error`) || '';
    errorMsg.textContent = errorText;
    checkoutButton.innerHTML = buttonText
      ? buttonText
      : CART_CHECKOUT_BUTTON_TEXT_DEFAULT;
    return;
  }

  if (!(checkoutButton instanceof HTMLAnchorElement)) {
    checkoutButton.innerHTML = buttonText
      ? buttonText
      : CART_CHECKOUT_BUTTON_TEXT_DEFAULT;
    return;
  }

  // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'Location | (string & Location)'.
  window.location = checkoutButton.href;
};

const handleSubmitForm = (event: Event) => {
  if (window.Webflow.env('preview')) {
    return;
  }
  event.preventDefault();
};

const handleCartContainerLeave = (event: MouseEvent) => {
  const {target, relatedTarget} = event;
  if (!(target instanceof Element) || !(relatedTarget instanceof Element)) {
    return;
  }

  const {parentElement} = target;

  if (!(parentElement instanceof Element)) {
    return;
  }

  // Don't want to close cart if switching between the button and the container
  const cartWrapper = findClosestElementByNodeType(
    NODE_TYPE_COMMERCE_CART_WRAPPER,
    relatedTarget
  );
  const cartContainer = findClosestElementByNodeType(
    NODE_TYPE_COMMERCE_CART_CONTAINER,
    relatedTarget
  );
  if (cartWrapper || cartContainer) {
    return;
  }
  const evt = new CustomEvent(CHANGE_CART_EVENT, {
    bubbles: true,
    detail: {
      open: false,
    },
  });
  parentElement.dispatchEvent(evt);
  cartWrapper &&
    // @ts-expect-error - TS2358 - The left-hand side of an 'instanceof' expression must be of type 'any', an object type or a type parameter.
    cartWrapper instanceof Element &&
    // @ts-expect-error - TS2339 - Property 'removeEventListener' does not exist on type 'never'.
    cartWrapper.removeEventListener('mouseleave', handleCartContainerLeave);
  cartContainer &&
    // @ts-expect-error - TS2358 - The left-hand side of an 'instanceof' expression must be of type 'any', an object type or a type parameter.
    cartContainer instanceof Element &&
    // @ts-expect-error - TS2339 - Property 'removeEventListener' does not exist on type 'never'.
    cartContainer.removeEventListener('mouseleave', handleCartContainerLeave);
};

let cartContainerStates: Array<
  | any
  | {
      element: HTMLElement;
      wasOpen: boolean;
    }
> = [];

const handlePreviewMode = () => {
  // When we change to preview mode, we start by getting all of the cart wrappers on the page
  const cartContainerElements = findAllElementsByNodeType(
    NODE_TYPE_COMMERCE_CART_CONTAINER_WRAPPER
  );

  cartContainerElements.forEach((element) => {
    // We then store the container element and its state in the designer
    const wasOpen = element.style.display !== 'none';
    cartContainerStates.push({
      element,
      wasOpen,
    });

    // If it was open, we then dispatch the cart change event on the wrapper outside
    // to mirror what the functionality is in the `handleCartButton` function, so that
    // when the user tries to close the cart in the preview, it works as expected
    if (wasOpen) {
      const evt = new CustomEvent(CHANGE_CART_EVENT, {
        bubbles: true,
        detail: {
          open: true,
        },
      });
      const {parentElement} = element;
      if (parentElement) {
        parentElement.dispatchEvent(evt);
      }
    }
  });
};

const handleDesignMode = () => {
  // When we change back to design mode, we iterate over all the stored elements and states
  // and return them back to what they were when the user changed to preview mode.
  // While it would be nice if we could update the state that's stored in the designer,
  // this would require some ugly hacks to access the outer frame of the designer.
  cartContainerStates.forEach(({element: wrapper, wasOpen}) => {
    // Remove quick animation style data
    // @ts-expect-error - TS2339 - Property 'Webflow' does not exist on type 'Window & typeof globalThis'.
    window.Webflow.tram(wrapper).destroy();
    wrapper.style.opacity = '1';
    const cartContainer = getCartContainer(wrapper);
    if (cartContainer) {
      // @ts-expect-error - TS2339 - Property 'Webflow' does not exist on type 'Window & typeof globalThis'.
      window.Webflow.tram(cartContainer).destroy();
      cartContainer.style.transform = '';
    }

    // Reset the wrapper display property
    if (wasOpen) {
      wrapper.style.removeProperty('display');
    } else {
      wrapper.style.display = 'none';
    }

    // We reset the associated outer wrapper's state, so that we're back exactly to the state
    // of the DOM as it was in the designer.
    const cartElement = wrapper.parentElement;
    if (cartElement) {
      cartElement.removeAttribute(CART_OPEN);
    }
  });

  // We then clear out the states after the iteration has completed. It's possible we could keep them, and then
  // do some diff-ing or something so we don't have to iterate over them again in `handlePreviewMode`, but I think
  // that would be more computational work than just re-querying the DOM.
  cartContainerStates = [];
};

const doForAllMatchingClass = (
  cart: Element | HTMLElement,
  className: string,
  // @ts-expect-error - TS7006 - Parameter 'fn' implicitly has an 'any' type.
  fn
) => Array.from(cart.getElementsByClassName(className)).forEach(fn);

const showCartDefaultState = (cart: Element) => {
  doForAllMatchingClass(cart, 'w-commerce-commercecartemptystate', hideElement);
  doForAllMatchingClass(cart, 'w-commerce-commercecartform', showElement);
};

const showCartEmptyState = (cart: Element) => {
  doForAllMatchingClass(cart, 'w-commerce-commercecartemptystate', showElement);
  doForAllMatchingClass(cart, 'w-commerce-commercecartform', hideElement);
};

const hideErrorState = (cart: Element) => {
  doForAllMatchingClass(cart, 'w-commerce-commercecarterrorstate', hideElement);
};

const showErrorState = (cart: Element) => {
  doForAllMatchingClass(cart, 'w-commerce-commercecarterrorstate', showElement);
};

const hasItems = (response: any) =>
  response &&
  response.data &&
  response.data.database &&
  response.data.database.commerceOrder &&
  response.data.database.commerceOrder.userItems &&
  response.data.database.commerceOrder.userItems.length > 0;

const hasErrors = (response: any) =>
  response && response.errors && response.errors.length > 0;

const updateCartA11Y = (cart: HTMLElement) => {
  doForAllMatchingClass(
    cart,
    'w-commerce-commercecartopenlinkcount',
    // @ts-expect-error - TS7006 - Parameter 'element' implicitly has an 'any' type.
    (element) => {
      doForAllMatchingClass(
        cart,
        'w-commerce-commercecartopenlink',
        // @ts-expect-error - TS7006 - Parameter 'openLinkElement' implicitly has an 'any' type.
        (openLinkElement) => {
          openLinkElement.setAttribute(
            'aria-label',
            element.textContent === '0'
              ? 'Open empty cart'
              : `Open cart containing ${element.textContent} items`
          );
        }
      );
    }
  );
};

export const renderCart = (
  cart: Element,
  data: Record<any, any>,
  stripeStore?: StripeStore
) => {
  hideErrorState(cart);

  if (hasErrors(data)) {
    showErrorState(cart);
  }

  doForAllMatchingClass(
    cart,
    'w-commerce-commercecartopenlinkcount',
    // @ts-expect-error - TS7006 - Parameter 'element' implicitly has an 'any' type.
    (element) => {
      const hideRule = element.getAttribute(DATA_ATTR_COUNT_HIDE_RULE);

      if (
        hideRule === CART_COUNT_HIDE_RULES.ALWAYS ||
        (hideRule === CART_COUNT_HIDE_RULES.EMPTY && !hasItems(data))
      ) {
        hideElement(element);
      } else {
        showElement(element);
      }
    }
  );

  // If it is a newly published site the commerceOrder will be null, causing the cart counter to render
  // an empty div if hide cart when empty is false and userItemsCount is not set to 0
  const dataWithDefaults = mergeWith({}, data, (obj, src, key) => {
    if (key === 'commerceOrder' && src === null) {
      return {userItemsCount: 0};
    }
  });

  renderTree(cart, dataWithDefaults);

  if (hasItems(data)) {
    showCartDefaultState(cart);
  } else {
    showCartEmptyState(cart);
  }
  const cartForm = cart.querySelector('form');
  if (cartForm instanceof HTMLFormElement) {
    enableAllFormElements(cartForm);
  }

  // we hide the button when the paypal sdk is on the page (only appears when paypal linked and checkout enabled)
  // and when the stripe store reports that it's not initialized. we don't pass stripe store for some errors,
  // so this ensures that the button will be shown still if there was an error.
  const paypalElement = document.querySelector(`[${PAYPAL_ELEMENT_INSTANCE}]`);
  const checkoutButton = findElementByNodeType(
    NODE_TYPE_COMMERCE_CART_CHECKOUT_BUTTON,
    cart
  );
  if (
    checkoutButton &&
    paypalElement &&
    stripeStore &&
    !stripeStore.isInitialized()
  ) {
    if (isFreeOrder(data)) {
      showElement(checkoutButton);
    } else {
      hideElement(checkoutButton);
    }
  }

  const paypalButton = cart.querySelector(
    `[${PAYPAL_BUTTON_ELEMENT_INSTANCE}]`
  );
  if (paypalElement && paypalButton) {
    if (isFreeOrder(data) || hasSubscription(data)) {
      // @ts-expect-error - TS2345 - Argument of type 'Element' is not assignable to parameter of type 'HTMLElement'.
      hideElement(paypalButton);
    } else {
      // @ts-expect-error - TS2345 - Argument of type 'Element' is not assignable to parameter of type 'HTMLElement'.
      showElement(paypalButton);
    }
  }

  updateWebPaymentsButton(cart, data, stripeStore);

  return cart;
};

const handleRenderCart = (
  event: Event | CustomEvent,
  apolloClient: ApolloClient<NormalizedCacheObject>,
  stripeStore: StripeStore
) => {
  if (window.Webflow.env('design') || window.Webflow.env('preview')) {
    return;
  }
  if (!(event instanceof CustomEvent && event.type === RENDER_TREE_EVENT)) {
    return;
  }
  const errors: Array<any> = [];
  const {detail} = event;
  if (detail != null && detail.error) {
    errors.push(detail.error);
  }
  const orderConfirmationContainer = findElementByNodeType(
    NODE_TYPE_COMMERCE_ORDER_CONFIRMATION_WRAPPER
  );
  // stop cart render on order confirmation page as it will always be empty, this query was setting commerceOrder to null
  // and overwritting a second query in `handleRenderOrderConfirmation`
  if (orderConfirmationContainer) {
    return;
  }

  const carts = findAllElementsByNodeType(NODE_TYPE_COMMERCE_CART_WRAPPER);

  if (!carts.length) {
    executeLoadingCallbacks();
    return;
  }

  carts.forEach((cart: HTMLElement) => {
    apolloClient
      .query({
        query: gql`
          ${cart.getAttribute(CART_QUERY)}
        `,
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      })
      .then((data) => {
        executeLoadingCallbacks();
        renderCart(
          cart,
          {
            ...data,
            errors: errors.concat(data.errors).filter(Boolean),
          },
          stripeStore
        );
        updateCartA11Y(cart);
      })
      .catch((err) => {
        executeLoadingCallbacks();
        errors.push(err);
        renderCart(cart, {errors});
        updateCartA11Y(cart);
      });
  });
};

const handleCartKeyUp = (event: Event | KeyboardEvent) => {
  // Escape
  // @ts-expect-error - TS2339 - Property 'keyCode' does not exist on type 'Event | KeyboardEvent'.
  if (event.keyCode === 27) {
    const openCarts = Array.from(document.querySelectorAll(`[${CART_OPEN}]`));
    forEach(openCarts, (cart) => {
      const evt = new CustomEvent(CHANGE_CART_EVENT, {
        bubbles: true,
        detail: {
          open: false,
        },
      });
      cart.dispatchEvent(evt);
    });
  }

  // Spacebar
  // @ts-expect-error - TS2339 - Property 'keyCode' does not exist on type 'Event | KeyboardEvent'.
  if (event.keyCode === 32 && event.target instanceof HTMLElement) {
    // Flow was being a bit strange with typing and only assuming HTMLElement
    // the first time it was used. So setting it as a new variable here to
    // persist that type.
    const htmlElement = event.target;

    // Make sure element being checked is intended to work as a link or button and
    // is a child of `commerce-cart-wrapper`
    // This will prevent the keyboard trigger applying to elements that don't
    // belong to the Cart or otherwise shouldn't be interactable in this manner.
    if (
      (htmlElement.getAttribute('role') === 'button' ||
        htmlElement.getAttribute('role') === 'link' ||
        htmlElement.hasAttribute('href') ||
        htmlElement.hasAttribute('onClick')) &&
      findClosestElementByNodeType(
        NODE_TYPE_COMMERCE_CART_WRAPPER,
        event.target
      ) != null
    ) {
      event.preventDefault();
      htmlElement.click();
    }
  }
};

const getCartContainer = (
  parent: HTMLElement
): HTMLElement | null | undefined =>
  findElementByNodeType(NODE_TYPE_COMMERCE_CART_CONTAINER, parent);

const handleClickCloseCart = ({target}: Event) => {
  if (!(target instanceof Element)) {
    return;
  }

  const openCarts = Array.from(document.querySelectorAll(`[${CART_OPEN}]`));
  forEach(openCarts, (cart) => {
    // @ts-expect-error - TS2345 - Argument of type 'Element' is not assignable to parameter of type 'HTMLElement'.
    const cartContainer = getCartContainer(cart);
    const cartOpenButton = findElementByNodeType(
      NODE_TYPE_COMMERCE_CART_OPEN_LINK,
      cart
    );
    if (
      !(cartContainer instanceof Element) ||
      !(cartOpenButton instanceof Element)
    ) {
      return;
    }

    const cartType = cart.getAttribute(CART_TYPE);
    // on dropdown, we close if outside the cart is clicked, and on modal/sidebar, we close if outside the container or open button is clicked
    const isNotInside =
      cartType === LEFT_DROPDOWN || cartType === RIGHT_DROPDOWN
        ? !cart.contains(target)
        : !cartContainer.contains(target) && !cartOpenButton.contains(target);

    if (isNotInside) {
      const evt = new CustomEvent(CHANGE_CART_EVENT, {
        bubbles: true,
        detail: {
          open: false,
        },
      });
      cart.dispatchEvent(evt);
    }
  });
};

const getFocusableElements = (container: HTMLElement) => {
  const focusableElements =
    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

  return [...container.querySelectorAll(focusableElements)].filter(
    // @ts-expect-error - TS2339 - Property 'offsetHeight' does not exist on type 'Element'.
    (element) => !element.hasAttribute('disabled') && element.offsetHeight > 0
  );
};

const handleCartFocusTrap = (event: Event | KeyboardEvent) => {
  // @ts-expect-error - TS2339 - Property 'key' does not exist on type 'Event | KeyboardEvent'. | TS2339 - Property 'keyCode' does not exist on type 'Event | KeyboardEvent'.
  if (event.key !== 'Tab' && event.keyCode !== 9) {
    return;
  }

  const openCarts = Array.from(document.querySelectorAll(`[${CART_OPEN}]`));
  forEach(openCarts, (cart) => {
    // @ts-expect-error - TS2345 - Argument of type 'Element' is not assignable to parameter of type 'HTMLElement'.
    const cartContainer = getCartContainer(cart);
    if (!(cartContainer instanceof Element)) {
      return;
    }

    const focusableContent = getFocusableElements(cartContainer);
    const firstFocusableElement = focusableContent[0];
    const lastFocusableElement = focusableContent[focusableContent.length - 1];

    // @ts-expect-error - TS2339 - Property 'shiftKey' does not exist on type 'Event | KeyboardEvent'.
    if (event.shiftKey) {
      if (document.activeElement === firstFocusableElement) {
        // @ts-expect-error - TS2339 - Property 'focus' does not exist on type 'Element'.
        lastFocusableElement.focus();
        event.preventDefault();
      }
    } else {
      if (document.activeElement === lastFocusableElement) {
        // @ts-expect-error - TS2339 - Property 'focus' does not exist on type 'Element'.
        firstFocusableElement.focus();
        event.preventDefault();
      }
    }
  });
};

export const register = (handlerProxy: EventHandlerProxyWithApolloClient) => {
  handlerProxy.on('click', isItemRemovedEvent, handleItemRemoved);
  handlerProxy.on(
    'change',
    isItemQuantityChangedEvent,
    handleItemQuantityChanged
  );
  handlerProxy.on('focus', isItemQuantityInputEvent, handleItemInputChanged);
  handlerProxy.on('click', isCartButtonEvent, handleCartButton);
  handlerProxy.on('click', isCartCheckoutButtonEvent, handleCartCheckoutButton);
  handlerProxy.on('mouseover', isCartButtonEvent, handleCartButton);
  handlerProxy.on(
    CHANGE_CART_EVENT,
    isCartWrapperEvent,
    handleChangeCartStateEvent
  );
  handlerProxy.on(RENDER_TREE_EVENT, Boolean, handleRenderCart);
  // Needed to avoid submission of cart form when only one item is in cart and user hits
  // enter key while in quantity input (acts as submit if only 1 input and no submit)
  handlerProxy.on('submit', isCartFormEvent, handleSubmitForm);
  handlerProxy.on('keyup', Boolean, handleCartKeyUp);
  handlerProxy.on('click', Boolean, handleClickCloseCart);

  // These events are for handling the back and forth between preview and designer
  // and must be registered directly to the window, otherwise they are not registered
  // when the canvas is created.
  if (window.Webflow.env('design') || window.Webflow.env('preview')) {
    window.addEventListener('__wf_preview', handlePreviewMode);
    window.addEventListener('__wf_design', handleDesignMode);
  }
};

export default {register};
