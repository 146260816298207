const {create} = Object;

const returnThis = function <T>(this: T): T {
  return this;
};

const VALUE: unique symbol = Symbol();
const ERROR: unique symbol = Symbol();
const FOLD: unique symbol = Symbol();

// # Result
//
// Result is a data type for representing results from computations that may
// fail by giving the user a controllable way to propagate errors. `Result` is
// also known as `Either` in other languages and libraries.
//
// ```js
// import {Err, Ok} from '@packages/utilities/fp/result'
//
// const safeDivide = (a, b) => b === 0
//   ? Err('Cannot divide by 0.')
//   : Ok(a / b)
// ```
//
// Notice in the usage example above how in the case of a failure the error
// message is wrapped in `Err` while otherwise the result is returned wrapped
// in `Ok`. `Err` and `Ok` are the constructors for the `Result` type.
//
// Consuming a value of this type involves somehow “enhancing” a regular
// function to work on values of type `Result`. This is done by applying
// the familiar `map` function to our original function:
//
// ```js
// import {map} from '@packages/utilities/fp/utils'
//
// const originalDouble = a => a + a
// const double = map(originalDouble)
// ```
//
// This `double` function can now safely be applied to a `Result` value:
//
// ```js
// const result1 = double(safeDivide(6, 2)) // => Ok(3)
// const result2 = double(safeDivide(6, 0)) // => Err('Cannot divide by 0.')
// ```
//
// Notice how our `double` function works just fine, even if the result of the
// division is an error, by passing that error along. In cases where we only
// care about the successful result this is all we need to do. When we need to
// take the value out of the `Result` box we can make use of `either`:
//
// ```js
// const number1 = either(error => 0)(val => val)(result1) // => 3
// const number2 = either(error => 0)(val => val)(result2) // => 0
// ```
//
// The `either` function takes three arguments, a function for handling the Err
// case, a function for handling the Ok case and the result. Both handlers
// should return values of the same type.

export interface Result<E, A> {
  /**
   * Transform the value inside of a `Result` by applying a unary function to it.
   */
  map: <B>(f: (x: A) => B) => Result<E, B>;
  /**
   * Sequence computations that may fail by applying a function to the value
   * contained in the `Result`, where the function also returns a `Result`.
   */
  chain: <B>(f: (x: A) => Result<E, B>) => Result<E, B>;
  /**
   * Allows you to apply the Result's value with another Result's value,
   * returning another Result.
   */
  ap: <B, C>(this: Result<E, (x: B) => C>, x: Result<E, B>) => Result<E, C>;
  [FOLD]: <B>(err: (e: E) => B, ok: (a: A) => B) => B;
}

type ErrT<E, A> = Result<E, A> & {[ERROR]: E};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Err = <E>(error: E): Result<E, any> => {
  const object = create(ErrPrototype);
  object[ERROR] = error;
  return object;
};

type OkT<E, A> = Result<E, A> & {[VALUE]: A};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Ok = <A>(value: A): Result<any, A> => {
  const object = create(OkPrototype);
  object[VALUE] = value;
  return object;
};

const ErrPrototype = {
  map: returnThis,
  chain: returnThis,
  ap: returnThis,
  [FOLD]: function <E, A, B>(
    this: ErrT<E, A>,
    errorHandler: (e: E) => B,
    _: (a: A) => B
  ) {
    return errorHandler(this[ERROR]);
  },
};

const OkPrototype = {
  map<E, A, B>(this: OkT<E, A>, f: (x: A) => B): Result<E, B> {
    return Ok(f(this[VALUE]));
  },

  chain<E, A, B>(this: OkT<E, A>, f: (x: A) => Result<E, B>): Result<E, B> {
    return f(this[VALUE]);
  },

  ap<E, A, B>(this: OkT<E, (x: A) => B>, m: Result<E, A>): Result<E, B> {
    return m.map(this[VALUE]);
  },

  [FOLD]: function <E, A, B>(
    this: OkT<E, A>,
    errorHandler: (e: E) => B,
    valueHandler: (a: A) => B
  ) {
    return valueHandler(this[VALUE]);
  },
};

export const either =
  <E, B>(mapErr: (e: E) => B) =>
  <A>(mapVal: (a: A) => B) =>
  (result: Result<E, A>) =>
    result[FOLD](mapErr, mapVal);

export const of = Ok;
