/* globals
  window,
  document,
  WEBFLOW_FORM_API_HOST,
  WEBFLOW_FORM_OLDIE_HOST,
  WEBFLOW_EXPORT_MODE,
  turnstile
*/

/**
 * Webflow: Forms
 */

var Webflow = require('../BaseSiteModules/webflow-lib');

const renderTurnstileCaptcha = (
  siteKey, // string
  formElement, // HTMLFormElement
  cb, // (token: string) => void
  errorCallback // () => void | boolean
) => {
  const captchaContainer = document.createElement('div');
  formElement.appendChild(captchaContainer);

  // Render the captcha
  turnstile.render(captchaContainer, {
    sitekey: siteKey,
    callback: function (token) {
      cb(token);
    },
    'error-callback': function () {
      errorCallback();
    },
  });
};

Webflow.define(
  'forms',
  (module.exports = function ($, _) {
    const TURNSTILE_LOADED_EVENT = 'TURNSTILE_LOADED';
    var api = {};

    var $doc = $(document);
    var $forms;
    var loc = window.location;
    var retro = window.XDomainRequest && !window.atob;
    var namespace = '.w-form';
    var siteId;
    var emailField = /e(-)?mail/i;
    var emailValue = /^\S+@\S+$/;
    var alert = window.alert;
    var inApp = Webflow.env();
    var listening;

    var formUrl;
    var signFileUrl;

    const turnstileSiteKey = $doc
      .find('[data-turnstile-sitekey]')
      .data('turnstile-sitekey');
    let turnstileScript;

    // MailChimp domains: list-manage.com + mirrors
    var chimpRegex = /list-manage[1-9]?.com/i;

    var disconnected = _.debounce(function () {
      alert(
        'Oops! This page has improperly configured forms. Please contact your website administrator to fix this issue.'
      );
    }, 100);

    api.ready =
      api.design =
      api.preview =
        function () {
          // start by loading the turnstile script (if the user has the feature enabled)
          loadTurnstileScript();

          // Init forms
          init();

          // Wire document events on published site only once
          if (!inApp && !listening) {
            addListeners();
          }
        };

    function init() {
      siteId = $('html').attr('data-wf-site');

      formUrl = WEBFLOW_FORM_API_HOST + '/api/v1/form/' + siteId;

      // Work around same-protocol IE XDR limitation - without this IE9 and below forms won't submit
      if (retro && formUrl.indexOf(WEBFLOW_FORM_API_HOST) >= 0) {
        formUrl = formUrl.replace(
          WEBFLOW_FORM_API_HOST,
          WEBFLOW_FORM_OLDIE_HOST
        );
      }

      signFileUrl = `${formUrl}/signFile`;

      $forms = $(namespace + ' form');
      if (!$forms.length) {
        return;
      }
      $forms.each(build);
    }

    function loadTurnstileScript() {
      if (turnstileSiteKey) {
        // Create script tag for turnstile
        turnstileScript = document.createElement('script');
        turnstileScript.src =
          'https://challenges.cloudflare.com/turnstile/v0/api.js';
        document.head.appendChild(turnstileScript);
        turnstileScript.onload = () => {
          // after the script loads, emit an event that we listen to below.
          // this enables us to listen for the event on each form on the page and render the turnstile token for each of them.
          $doc.trigger(TURNSTILE_LOADED_EVENT);
        };
      }
    }

    function build(i, el) {
      // Store form state using namespace
      var $el = $(el);
      var data = $.data(el, namespace);
      if (!data) {
        data = $.data(el, namespace, {form: $el});
      } // data.form

      reset(data);
      var wrap = $el.closest('div.w-form');
      data.done = wrap.find('> .w-form-done');
      data.fail = wrap.find('> .w-form-fail');
      data.fileUploads = wrap.find('.w-file-upload');

      data.fileUploads.each(function (j) {
        initFileUpload(j, data);
      });

      if (turnstileSiteKey) {
        // while we load the script, disable the submit button
        // but do not show the "Please wait" text
        data.wait = false;
        disableBtn(data);

        // this is probably overkill, but if the turnstile script has already loaded and we reached this point then
        // we'll fire the callback below immediately. Otherwise we'll wait for the TURNSTILE_LOADED_EVENT to fire.
        $doc.on(
          typeof turnstile !== 'undefined' ? 'ready' : TURNSTILE_LOADED_EVENT,
          function () {
            // render the hidden input with the turnstile token for each form on the page
            renderTurnstileCaptcha(
              turnstileSiteKey,
              el,
              (token) => {
                // The turnstile token gets automatically attached to the form as a hidden input field & sent on submission to the server.
                // Here we are using this `data.turnstileToken` value to decide whether or not the submit button should be enabled.
                data.turnstileToken = token;
                // enable the submit button once turnstile is done rendering
                reset(data);
              },
              () => {
                disableBtn(data);
              }
            );
          }
        );
      }

      // Accessibility fixes
      var formName =
        data.form.attr('aria-label') || data.form.attr('data-name') || 'Form';
      if (!data.done.attr('aria-label')) {
        data.form.attr('aria-label', formName);
      }

      data.done.attr('tabindex', '-1');
      data.done.attr('role', 'region');
      if (!data.done.attr('aria-label')) {
        data.done.attr('aria-label', formName + ' success');
      }
      data.fail.attr('tabindex', '-1');
      data.fail.attr('role', 'region');
      if (!data.fail.attr('aria-label')) {
        data.fail.attr('aria-label', formName + ' failure');
      }

      var action = (data.action = $el.attr('action'));
      data.handler = null;
      data.redirect = $el.attr('data-redirect');

      // MailChimp form
      if (chimpRegex.test(action)) {
        data.handler = submitMailChimp;
        return;
      }

      // Custom form action
      if (action) {
        return;
      }

      // Webflow forms for hosting accounts
      if (siteId) {
        data.handler = WEBFLOW_EXPORT_MODE
          ? exportedSubmitWebflow
          : (() => {
              const hostedSubmitHandler =
                require('./webflow-forms-hosted').default;
              return hostedSubmitHandler(
                reset,
                loc,
                Webflow,
                collectEnterpriseTrackingCookies,
                preventDefault,
                findFields,
                alert,
                findFileUploads,
                disableBtn,
                siteId,
                afterSubmit,
                $,
                formUrl
              );
            })();
        return;
      }

      // Alert for disconnected Webflow forms
      disconnected();
    }

    function addListeners() {
      listening = true;

      $doc.on('submit', namespace + ' form', function (evt) {
        var data = $.data(this, namespace);
        if (data.handler) {
          data.evt = evt;
          data.handler(data);
        }
      });

      // handle checked ui for custom checkbox and radio button
      const CHECKBOX_CLASS_NAME = '.w-checkbox-input';
      const RADIO_INPUT_CLASS_NAME = '.w-radio-input';
      const CHECKED_CLASS = 'w--redirected-checked';
      const FOCUSED_CLASS = 'w--redirected-focus';
      const FOCUSED_VISIBLE_CLASS = 'w--redirected-focus-visible';
      const focusVisibleSelectors = ':focus-visible, [data-wf-focus-visible]';

      const CUSTOM_CONTROLS = [
        ['checkbox', CHECKBOX_CLASS_NAME],
        ['radio', RADIO_INPUT_CLASS_NAME],
      ];

      $doc.on(
        'change',
        namespace +
          ` form input[type="checkbox"]:not(` +
          CHECKBOX_CLASS_NAME +
          ')',
        (evt) => {
          $(evt.target)
            .siblings(CHECKBOX_CLASS_NAME)
            .toggleClass(CHECKED_CLASS);
        }
      );

      $doc.on('change', namespace + ` form input[type="radio"]`, (evt) => {
        $(`input[name="${evt.target.name}"]:not(${CHECKBOX_CLASS_NAME})`).map(
          (i, el) =>
            $(el).siblings(RADIO_INPUT_CLASS_NAME).removeClass(CHECKED_CLASS)
        );

        const $target = $(evt.target);

        if (!$target.hasClass('w-radio-input')) {
          $target.siblings(RADIO_INPUT_CLASS_NAME).addClass(CHECKED_CLASS);
        }
      });

      CUSTOM_CONTROLS.forEach(([controlType, customControlClassName]) => {
        $doc.on(
          'focus',
          namespace +
            ` form input[type="${controlType}"]:not(` +
            customControlClassName +
            ')',
          (evt) => {
            $(evt.target)
              .siblings(customControlClassName)
              .addClass(FOCUSED_CLASS);
            $(evt.target)
              .filter(focusVisibleSelectors)
              .siblings(customControlClassName)
              .addClass(FOCUSED_VISIBLE_CLASS);
          }
        );
        $doc.on(
          'blur',
          namespace +
            ` form input[type="${controlType}"]:not(` +
            customControlClassName +
            ')',
          (evt) => {
            $(evt.target)
              .siblings(customControlClassName)
              .removeClass(`${FOCUSED_CLASS} ${FOCUSED_VISIBLE_CLASS}`);
          }
        );
      });
    }

    // Reset data common to all submit handlers
    function reset(data) {
      var btn = (data.btn = data.form.find(':input[type="submit"]'));
      data.wait = data.btn.attr('data-wait') || null;
      data.success = false;
      // only enable the button if the turnstileToken has finished minting (and if the feature itself is enabled)
      btn.prop('disabled', Boolean(turnstileSiteKey && !data.turnstileToken));
      data.label && btn.val(data.label);
    }

    // Disable submit button
    function disableBtn(data) {
      var btn = data.btn;
      var wait = data.wait;
      btn.prop('disabled', true);
      // Show wait text and store previous label
      if (wait) {
        data.label = btn.val();
        btn.val(wait);
      }
    }

    // Find form fields, validate, and set value pairs
    function findFields(form, result) {
      var status = null;
      result = result || {};

      // The ":input" selector is a jQuery shortcut to select all inputs, selects, textareas
      form
        .find(':input:not([type="submit"]):not([type="file"])')
        .each(function (i, el) {
          var field = $(el);
          var type = field.attr('type');
          var name =
            field.attr('data-name') || field.attr('name') || 'Field ' + (i + 1);
          // Encoding the field name will prevent fields that have brackets
          // in their name from being parsed by `bodyParser.urlencoded` as
          // objects which would have unintended consequences like not saving
          // the content of the field.
          // https://webflow.atlassian.net/browse/CMSAUTH-2495
          name = encodeURIComponent(name);
          var value = field.val();

          if (type === 'checkbox') {
            value = field.is(':checked');
          } else if (type === 'radio') {
            // Radio group value already processed
            if (result[name] === null || typeof result[name] === 'string') {
              return;
            }

            value =
              form
                .find('input[name="' + field.attr('name') + '"]:checked')
                .val() || null;
          }

          if (typeof value === 'string') {
            value = $.trim(value);
          }
          result[name] = value;
          status = status || getStatus(field, type, name, value);
        });

      return status;
    }

    function findFileUploads(form) {
      var result = {};

      form.find(':input[type="file"]').each(function (i, el) {
        var field = $(el);
        var name =
          field.attr('data-name') || field.attr('name') || 'File ' + (i + 1);
        var value = field.attr('data-value');
        if (typeof value === 'string') {
          value = $.trim(value);
        }
        result[name] = value;
      });

      return result;
    }

    const trackingCookieNameMap = {
      _mkto_trk: 'marketo',
      // __hstc: 'hubspot',
    };

    function collectEnterpriseTrackingCookies() {
      const cookies = document.cookie.split('; ').reduce(function (
        acc,
        cookie
      ) {
        const splitCookie = cookie.split('=');
        const name = splitCookie[0];
        if (name in trackingCookieNameMap) {
          const mappedName = trackingCookieNameMap[name];
          const value = splitCookie.slice(1).join('=');
          acc[mappedName] = value;
        }
        return acc;
      }, {});

      return cookies;
    }

    function getStatus(field, type, name, value) {
      var status = null;

      if (type === 'password') {
        status = 'Passwords cannot be submitted.';
      } else if (field.attr('required')) {
        if (!value) {
          status = 'Please fill out the required field: ' + name;
        } else if (emailField.test(field.attr('type'))) {
          if (!emailValue.test(value)) {
            status = 'Please enter a valid email address for: ' + name;
          }
        }
      } else if (name === 'g-recaptcha-response' && !value) {
        status = 'Please confirm you’re not a robot.';
      }

      return status;
    }

    function exportedSubmitWebflow(data) {
      preventDefault(data);
      afterSubmit(data);
    }

    // Submit form to MailChimp
    function submitMailChimp(data) {
      reset(data);

      var form = data.form;
      var payload = {};

      // Skip Ajax submission if http/s mismatch, fallback to POST instead
      if (/^https/.test(loc.href) && !/^https/.test(data.action)) {
        form.attr('method', 'post');
        return;
      }

      preventDefault(data);

      // Find & populate all fields
      var status = findFields(form, payload);
      if (status) {
        return alert(status);
      }

      // Disable submit button
      disableBtn(data);

      // Use special format for MailChimp params
      var fullName;
      _.each(payload, function (value, key) {
        if (emailField.test(key)) {
          payload.EMAIL = value;
        }
        if (/^((full[ _-]?)?name)$/i.test(key)) {
          fullName = value;
        }
        if (/^(first[ _-]?name)$/i.test(key)) {
          payload.FNAME = value;
        }
        if (/^(last[ _-]?name)$/i.test(key)) {
          payload.LNAME = value;
        }
      });

      if (fullName && !payload.FNAME) {
        fullName = fullName.split(' ');
        payload.FNAME = fullName[0];
        payload.LNAME = payload.LNAME || fullName[1];
      }

      // Use the (undocumented) MailChimp jsonp api
      var url = data.action.replace('/post?', '/post-json?') + '&c=?';
      // Add special param to prevent bot signups
      var userId = url.indexOf('u=') + 2;
      userId = url.substring(userId, url.indexOf('&', userId));
      var listId = url.indexOf('id=') + 3;
      listId = url.substring(listId, url.indexOf('&', listId));
      payload['b_' + userId + '_' + listId] = '';

      $.ajax({
        url,
        data: payload,
        dataType: 'jsonp',
      })
        .done(function (resp) {
          data.success = resp.result === 'success' || /already/.test(resp.msg);
          if (!data.success) {
            console.info('MailChimp error: ' + resp.msg);
          }
          afterSubmit(data);
        })
        .fail(function () {
          afterSubmit(data);
        });
    }

    // Common callback which runs after all Ajax submissions
    function afterSubmit(data) {
      var form = data.form;
      var redirect = data.redirect;
      var success = data.success;

      // Redirect to a success url if defined
      if (success && redirect) {
        Webflow.location(redirect);
        return;
      }

      // Show or hide status divs
      data.done.toggle(success);
      data.fail.toggle(!success);

      if (success) {
        data.done.focus();
      } else {
        data.fail.focus();
      }

      // Hide form on success
      form.toggle(!success);

      // Reset data and enable submit button
      reset(data);
    }

    function preventDefault(data) {
      data.evt && data.evt.preventDefault();
      data.evt = null;
    }

    function initFileUpload(i, form) {
      if (!form.fileUploads || !form.fileUploads[i]) {
        return;
      }

      var file;
      var $el = $(form.fileUploads[i]);
      var $defaultWrap = $el.find('> .w-file-upload-default');
      var $uploadingWrap = $el.find('> .w-file-upload-uploading');
      var $successWrap = $el.find('> .w-file-upload-success');
      var $errorWrap = $el.find('> .w-file-upload-error');
      var $input = $defaultWrap.find('.w-file-upload-input');
      var $label = $defaultWrap.find('.w-file-upload-label');
      var $labelChildren = $label.children();
      var $errorMsgEl = $errorWrap.find('.w-file-upload-error-msg');
      var $fileEl = $successWrap.find('.w-file-upload-file');
      var $removeEl = $successWrap.find('.w-file-remove-link');
      var $fileNameEl = $fileEl.find('.w-file-upload-file-name');

      var sizeErrMsg = $errorMsgEl.attr('data-w-size-error');
      var typeErrMsg = $errorMsgEl.attr('data-w-type-error');
      var genericErrMsg = $errorMsgEl.attr('data-w-generic-error');

      // Accessiblity fixes
      // The file upload Input is not stylable by the designer, so we are
      // going to pretend the Label is the input. ¯\_(ツ)_/¯
      if (!inApp) {
        $label.on('click keydown', function (e) {
          if (e.type === 'keydown' && e.which !== 13 && e.which !== 32) {
            return;
          }

          e.preventDefault();
          $input.click();
        });
      }

      // Both of these are added through CSS
      $label.find('.w-icon-file-upload-icon').attr('aria-hidden', 'true');
      $removeEl.find('.w-icon-file-upload-remove').attr('aria-hidden', 'true');

      if (!inApp) {
        $removeEl.on('click keydown', function (e) {
          if (e.type === 'keydown') {
            if (e.which !== 13 && e.which !== 32) {
              return;
            }

            e.preventDefault();
          }

          $input.removeAttr('data-value');
          $input.val('');
          $fileNameEl.html('');
          $defaultWrap.toggle(true);
          $successWrap.toggle(false);
          $label.focus();
        });

        $input.on('change', function (e) {
          file = e.target && e.target.files && e.target.files[0];
          if (!file) {
            return;
          }

          // Show uploading
          $defaultWrap.toggle(false);
          $errorWrap.toggle(false);
          $uploadingWrap.toggle(true);
          $uploadingWrap.focus();

          // Set filename
          $fileNameEl.text(file.name);

          // Disable submit button
          if (!isUploading()) {
            disableBtn(form);
          }
          form.fileUploads[i].uploading = true;

          signFile(file, afterSign);
        });

        // Setting input width 1px and height equal label
        // This is so the browser required error will show up
        var height = $label.outerHeight();
        $input.height(height);
        $input.width(1);
      } else {
        $input.on('click', function (e) {
          e.preventDefault();
        });
        $label.on('click', function (e) {
          e.preventDefault();
        });
        $labelChildren.on('click', function (e) {
          e.preventDefault();
        });
      }

      function parseError(err) {
        var errorMsg = err.responseJSON && err.responseJSON.msg;
        var userError = genericErrMsg;
        if (
          typeof errorMsg === 'string' &&
          errorMsg.indexOf('InvalidFileTypeError') === 0
        ) {
          userError = typeErrMsg;
        } else if (
          typeof errorMsg === 'string' &&
          errorMsg.indexOf('MaxFileSizeError') === 0
        ) {
          userError = sizeErrMsg;
        }

        $errorMsgEl.text(userError);

        $input.removeAttr('data-value');
        $input.val('');
        $uploadingWrap.toggle(false);
        $defaultWrap.toggle(true);
        $errorWrap.toggle(true);
        $errorWrap.focus();

        form.fileUploads[i].uploading = false;
        if (!isUploading()) {
          reset(form);
        }
      }

      function afterSign(err, data) {
        if (err) {
          return parseError(err);
        }

        var fileName = data.fileName;
        var postData = data.postData;
        var fileId = data.fileId;
        var s3Url = data.s3Url;
        $input.attr('data-value', fileId);

        uploadS3(s3Url, postData, file, fileName, afterUpload);
      }

      function afterUpload(err) {
        if (err) {
          return parseError(err);
        }

        // Show success
        $uploadingWrap.toggle(false);
        $successWrap.css('display', 'inline-block');
        $successWrap.focus();

        form.fileUploads[i].uploading = false;
        if (!isUploading()) {
          reset(form);
        }
      }

      function isUploading() {
        var uploads = (form.fileUploads && form.fileUploads.toArray()) || [];
        return uploads.some(function (value) {
          return value.uploading;
        });
      }
    }

    function signFile(file, cb) {
      var payload = new URLSearchParams({
        name: file.name,
        size: file.size,
      });

      $.ajax({type: 'GET', url: `${signFileUrl}?${payload}`, crossDomain: true})
        .done(function (data) {
          cb(null, data);
        })
        .fail(function (err) {
          cb(err);
        });
    }

    function uploadS3(url, data, file, fileName, cb) {
      var formData = new FormData();
      for (var k in data) {
        formData.append(k, data[k]);
      }
      formData.append('file', file, fileName);

      $.ajax({
        type: 'POST',
        url,
        data: formData,
        processData: false,
        contentType: false,
      })
        .done(function () {
          cb(null);
        })
        .fail(function (err) {
          cb(err);
        });
    }

    // Export module
    return api;
  })
);
