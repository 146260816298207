/* eslint-env browser */
import find from 'lodash/find';
import get from 'lodash/get';
import size from 'lodash/size';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import forEach from 'lodash/forEach';
import throttle from 'lodash/throttle';
import {
  EventTypeConsts,
  ActionTypeConsts,
  IX2EngineConstants,
  QuickEffectIds,
  ReducedMotionTypes,
} from '@packages/systems/ix2/shared-constants';

const QuickEffectsIdList = Object.keys(QuickEffectIds);

const isQuickEffect = (actionTypeId: string) =>
  QuickEffectsIdList.includes(actionTypeId);

import {IX2VanillaUtils, IX2VanillaPlugins} from '@packages/systems/ix2/shared';

const {
  COLON_DELIMITER,
  BOUNDARY_SELECTOR,
  HTML_ELEMENT,
  RENDER_GENERAL,
  W_MOD_IX,
} = IX2EngineConstants;

const {
  getAffectedElements,
  getElementId,
  getDestinationValues,
  observeStore,
  getInstanceId,
  renderHTMLElement,
  clearAllStyles,
  getMaxDurationItemIndex,
  getComputedStyle,
  getInstanceOrigin,
  reduceListToGroup,
  shouldNamespaceEventParameter,
  getNamespacedParameterId,
  shouldAllowMediaQuery,
  cleanupHTMLElement,
  clearObjectCache,
  stringifyTarget,
  mediaQueriesEqual,
  shallowEqual,
} = IX2VanillaUtils;
const {isPluginType, createPluginInstance, getPluginDuration} =
  IX2VanillaPlugins;

import {
  rawDataImported,
  sessionInitialized,
  sessionStarted,
  sessionStopped,
  eventListenerAdded,
  eventStateChanged,
  animationFrameChanged,
  instanceAdded,
  instanceStarted,
  instanceRemoved,
  elementStateChanged,
  actionListPlaybackChanged,
  viewportWidthChanged,
  mediaQueriesDefined,
  IX2RawData,
} from '../actions/IX2EngineActions';

import * as elementApi from './IX2BrowserApi';

import IX2VanillaEvents from './IX2VanillaEvents';
import {
  IX2EngineReducerStateShape,
  IX2EngineReducerStore,
} from '../reducers/IX2Reducer';
import {ActionListId} from '@packages/systems/ix2/types-core';

const ua = navigator.userAgent;
const IS_MOBILE_SAFARI = ua.match(/iPad/i) || ua.match(/iPhone/);

// Keep throttled events at ~80fps to reduce reflows while maintaining render accuracy
const THROTTLED_EVENT_WAIT = 12;

export function observeRequests(store: IX2EngineReducerStore) {
  observeStore({
    store,
    select: ({
      ixRequest,
    }: {
      ixRequest: IX2EngineReducerStateShape['ixRequest'];
    }) => ixRequest.preview,
    onChange: handlePreviewRequest,
  });
  observeStore({
    store,
    select: ({
      ixRequest,
    }: {
      ixRequest: IX2EngineReducerStateShape['ixRequest'];
    }) => ixRequest.playback,
    onChange: handlePlaybackRequest,
  });
  observeStore({
    store,
    select: ({
      ixRequest,
    }: {
      ixRequest: IX2EngineReducerStateShape['ixRequest'];
    }) => ixRequest.stop,
    onChange: handleStopRequest,
  });
  observeStore({
    store,
    select: ({
      ixRequest,
    }: {
      ixRequest: IX2EngineReducerStateShape['ixRequest'];
    }) => ixRequest.clear,
    onChange: handleClearRequest,
  });
}

function observeMediaQueryChange(store: IX2EngineReducerStore) {
  observeStore({
    store,
    select: ({
      ixSession,
    }: {
      ixSession: IX2EngineReducerStateShape['ixSession'];
    }) => ixSession.mediaQueryKey,
    onChange: () => {
      stopEngine(store);
      clearAllStyles({store, elementApi});
      startEngine({store, allowEvents: true});
      dispatchPageUpdateEvent();
    },
  });
}

function observeOneRenderTick(
  store: IX2EngineReducerStore,
  onTick: (now: any | number) => void
) {
  const unsubscribe = observeStore({
    store,
    select: ({
      ixSession,
    }: {
      ixSession: IX2EngineReducerStateShape['ixSession'];
    }) => ixSession.tick,
    // @ts-expect-error - TS7006 - Parameter 'tick' implicitly has an 'any' type.
    onChange: (tick) => {
      onTick(tick);
      unsubscribe();
    },
  });
}

function handlePreviewRequest(
  {rawData, defer}: {rawData: IX2RawData; defer: boolean},
  store: IX2EngineReducerStore
) {
  const start = () => {
    startEngine({store, rawData, allowEvents: true});
    dispatchPageUpdateEvent();
  };
  defer ? setTimeout(start, 0) : start();
}

function dispatchPageUpdateEvent() {
  document.dispatchEvent(new CustomEvent('IX2_PAGE_UPDATE'));
}

function handlePlaybackRequest(playback: any, store: IX2EngineReducerStore) {
  const {
    actionTypeId,
    actionListId,
    actionItemId,
    eventId,
    allowEvents,
    immediate,
    testManual,
    verbose = true,
  } = playback;
  let {rawData} = playback;

  if (actionListId && actionItemId && rawData && immediate) {
    const actionList = rawData.actionLists[actionListId];

    if (actionList) {
      rawData = reduceListToGroup({
        actionList,
        actionItemId,
        rawData,
      });
    }
  }

  startEngine({store, rawData, allowEvents, testManual});

  if (
    (actionListId && actionTypeId === ActionTypeConsts.GENERAL_START_ACTION) ||
    isQuickEffect(actionTypeId)
  ) {
    // @ts-expect-error - TS2345 - Argument of type '{ store: any; actionListId: any; }' is not assignable to parameter of type '{ store: any; eventId: any; eventTarget: any; eventStateKey: any; actionListId: any; }'.
    stopActionGroup({store, actionListId});
    renderInitialGroup({store, actionListId, eventId});
    // @ts-expect-error - TS2345 - Argument of type '{ store: any; eventId: any; actionListId: any; immediate: any; verbose: any; }' is not assignable to parameter of type '{ store: any; eventId: any; eventTarget: any; eventStateKey: any; actionListId: any; groupIndex?: number | undefined; immediate: any; verbose: any; }'.
    const started = startActionGroup({
      store,
      eventId,
      actionListId,
      immediate,
      verbose,
    });
    if (verbose && started) {
      store.dispatch(
        actionListPlaybackChanged({actionListId, isPlaying: !immediate})
      );
    }
  }
}

function handleStopRequest(
  {actionListId}: {actionListId: ActionListId},
  store: IX2EngineReducerStore
) {
  if (actionListId) {
    // @ts-expect-error - TS2345 - Argument of type '{ store: any; actionListId: any; }' is not assignable to parameter of type '{ store: any; eventId: any; eventTarget: any; eventStateKey: any; actionListId: any; }'.
    stopActionGroup({store, actionListId});
  } else {
    stopAllActionGroups({store});
  }
  stopEngine(store);
}

function handleClearRequest(state: any, store: IX2EngineReducerStore) {
  stopEngine(store);
  clearAllStyles({store, elementApi});
}

export function startEngine({
  store,
  rawData,
  allowEvents,
  testManual,
}: {
  store: IX2EngineReducerStore;
  rawData?: IX2RawData;
  testManual?: boolean;
  allowEvents?: boolean;
}) {
  const {ixSession} = store.getState();
  if (rawData) {
    store.dispatch(rawDataImported(rawData));
  }
  if (!ixSession.active) {
    store.dispatch(
      sessionInitialized({
        hasBoundaryNodes: Boolean(document.querySelector(BOUNDARY_SELECTOR)),
        reducedMotion:
          document.body.hasAttribute('data-wf-ix-vacation') &&
          window.matchMedia('(prefers-reduced-motion)').matches,
      })
    );
    if (allowEvents) {
      bindEvents(store);
      addDocumentClass();

      if (store.getState().ixSession.hasDefinedMediaQueries) {
        observeMediaQueryChange(store);
      }
    }
    store.dispatch(sessionStarted());
    startRenderLoop(store, testManual);
  }
}

function addDocumentClass() {
  const {documentElement} = document;
  if (documentElement.className.indexOf(W_MOD_IX) === -1) {
    documentElement.className += ` ${W_MOD_IX}`;
  }
}

function startRenderLoop(store: IX2EngineReducerStore, testManual?: boolean) {
  const handleFrame = (now: number) => {
    const {ixSession, ixParameters} = store.getState();
    if (ixSession.active) {
      store.dispatch(animationFrameChanged(now, ixParameters));
      if (testManual) {
        observeOneRenderTick(store, handleFrame);
      } else {
        requestAnimationFrame(handleFrame);
      }
    }
  };
  handleFrame(window.performance.now());
}

export function stopEngine(store: IX2EngineReducerStore) {
  const {ixSession} = store.getState();
  if (ixSession.active) {
    const {eventListeners} = ixSession;
    eventListeners.forEach(clearEventListener);
    clearObjectCache();
    store.dispatch(sessionStopped());
  }
}

// @ts-expect-error - TS7031 - Binding element 'target' implicitly has an 'any' type. | TS7031 - Binding element 'listenerParams' implicitly has an 'any' type.
function clearEventListener({target, listenerParams}) {
  // eslint-disable-next-line prefer-spread
  target.removeEventListener.apply(target, listenerParams);
}

function createGroupInstances({
  // @ts-expect-error - TS7031 - Binding element 'store' implicitly has an 'any' type.
  store,
  // @ts-expect-error - TS7031 - Binding element 'eventStateKey' implicitly has an 'any' type.
  eventStateKey,
  // @ts-expect-error - TS7031 - Binding element 'eventTarget' implicitly has an 'any' type.
  eventTarget,
  // @ts-expect-error - TS7031 - Binding element 'eventId' implicitly has an 'any' type.
  eventId,
  // @ts-expect-error - TS7031 - Binding element 'eventConfig' implicitly has an 'any' type.
  eventConfig,
  // @ts-expect-error - TS7031 - Binding element 'actionListId' implicitly has an 'any' type.
  actionListId,
  // @ts-expect-error - TS7031 - Binding element 'parameterGroup' implicitly has an 'any' type.
  parameterGroup,
  // @ts-expect-error - TS7031 - Binding element 'smoothing' implicitly has an 'any' type.
  smoothing,
  // @ts-expect-error - TS7031 - Binding element 'restingValue' implicitly has an 'any' type.
  restingValue,
}) {
  const {ixData, ixSession} = store.getState();
  const {events} = ixData;
  const event = events[eventId];
  const {eventTypeId} = event;
  const targetCache: Record<string, any> = {};
  const instanceActionGroups: Record<string, any> = {};
  const instanceConfigs: Array<
    | any
    | {
        element: HTMLElement | any;
        key: string;
      }
  > = [];

  const {continuousActionGroups} = parameterGroup;
  let {id: parameterId} = parameterGroup;
  if (shouldNamespaceEventParameter(eventTypeId, eventConfig)) {
    parameterId = getNamespacedParameterId(eventStateKey, parameterId);
  }

  // Limit affected elements when event target is within a boundary node
  const eventElementRoot =
    ixSession.hasBoundaryNodes && eventTarget
      ? elementApi.getClosestElement(eventTarget, BOUNDARY_SELECTOR)
      : null;

  // @ts-expect-error - TS7006 - Parameter 'actionGroup' implicitly has an 'any' type.
  continuousActionGroups.forEach((actionGroup) => {
    const {keyframe, actionItems} = actionGroup;

    // @ts-expect-error - TS7006 - Parameter 'actionItem' implicitly has an 'any' type.
    actionItems.forEach((actionItem) => {
      const {actionTypeId} = actionItem;
      const {target} = actionItem.config;
      if (!target) {
        return;
      }
      const elementRoot = target.boundaryMode ? eventElementRoot : null;

      const key = stringifyTarget(target) + COLON_DELIMITER + actionTypeId;
      instanceActionGroups[key] = appendActionItem(
        instanceActionGroups[key],
        keyframe,
        actionItem
      );

      if (!targetCache[key]) {
        targetCache[key] = true;
        const {config} = actionItem;
        getAffectedElements({
          config,
          event,
          eventTarget,
          elementRoot,
          elementApi,
        }).forEach((element) => {
          instanceConfigs.push({element, key});
        });
      }
    });
  });

  instanceConfigs.forEach(({element, key}) => {
    const actionGroups = instanceActionGroups[key];
    const actionItem = get(actionGroups, `[0].actionItems[0]`, {});
    const {actionTypeId} = actionItem;
    const shouldUsePlugin =
      // If it's targeted by class, don't query the element by pluginElementId
      actionTypeId === ActionTypeConsts.PLUGIN_RIVE
        ? (actionItem.config?.target?.selectorGuids || []).length === 0
        : isPluginType(actionTypeId);

    const pluginInstance = shouldUsePlugin
      ? createPluginInstance(actionTypeId)(element, actionItem)
      : null;
    const destination = getDestinationValues(
      {element, actionItem, elementApi},
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      pluginInstance
    );
    createInstance({
      store,
      element,
      eventId,
      actionListId,
      actionItem,
      destination,
      continuous: true,
      parameterId,
      actionGroups,
      smoothing,
      restingValue,
      pluginInstance,
    });
  });
}

function appendActionItem(actionGroups = [], keyframe: any, actionItem: any) {
  const newActionGroups = [...actionGroups];
  let groupIndex;
  newActionGroups.some((group, index) => {
    // @ts-expect-error - TS2339 - Property 'keyframe' does not exist on type 'never'.
    if (group.keyframe === keyframe) {
      groupIndex = index;
      return true;
    }
    return false;
  });
  if (groupIndex == null) {
    groupIndex = newActionGroups.length;
    // @ts-expect-error - TS2345 - Argument of type '{ keyframe: any; actionItems: never[]; }' is not assignable to parameter of type 'never'.
    newActionGroups.push({
      keyframe,
      actionItems: [],
    });
  }
  // @ts-expect-error - TS2339 - Property 'actionItems' does not exist on type 'never'.
  newActionGroups[groupIndex].actionItems.push(actionItem);
  return newActionGroups;
}

function bindEvents(store: IX2EngineReducerStore) {
  const {ixData} = store.getState();
  const {eventTypeMap} = ixData;

  updateViewportWidth(store);

  forEach(eventTypeMap, (events, key) => {
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ SLIDER_ACTIVE: { handler: (options: any, state: any) => any; types: string; }; SLIDER_INACTIVE: { handler: (options: any, state: any) => any; types: string; }; DROPDOWN_OPEN: { handler: (options: any, state: any) => any; types: string; }; ... 21 more ...; PAGE_START: { ...; }; }'.
    const logic = IX2VanillaEvents[key];
    if (!logic) {
      console.warn(`IX2 event type not configured: ${key}`);
      return;
    }
    bindEventType({
      // @ts-expect-error - TS7031 - Binding element 'logic' implicitly has an 'any' type.
      logic,
      store,
      events,
    });
  });

  const {ixSession} = store.getState();
  if (ixSession.eventListeners.length) {
    bindResizeEvents(store);
  }
}

const WINDOW_RESIZE_EVENTS = ['resize', 'orientationchange'];

function bindResizeEvents(store: IX2EngineReducerStore) {
  const handleResize = () => {
    updateViewportWidth(store);
  };
  WINDOW_RESIZE_EVENTS.forEach((type) => {
    window.addEventListener(type, handleResize);
    store.dispatch(eventListenerAdded(window, [type, handleResize]));
  });
  handleResize();
}

function updateViewportWidth(store: IX2EngineReducerStore) {
  const {ixSession, ixData} = store.getState();
  const width = window.innerWidth;
  if (width !== ixSession.viewportWidth) {
    const {mediaQueries} = ixData;
    store.dispatch(viewportWidthChanged({width, mediaQueries}));
  }
}

const mapFoundValues = (
  object: any,
  iteratee: (event?: any) => Array<HTMLElement | any>
) => omitBy(mapValues(object, iteratee), isEmpty);

const forEachEventTarget = (
  eventTargets: any,
  eventCallback: (element: any, eventId: any, eventStateKey: string) => void
) => {
  forEach(eventTargets, (elements, eventId) => {
    // @ts-expect-error - TS7006 - Parameter 'element' implicitly has an 'any' type. | TS7006 - Parameter 'index' implicitly has an 'any' type.
    elements.forEach((element, index) => {
      const eventStateKey = eventId + COLON_DELIMITER + index;
      eventCallback(element, eventId, eventStateKey);
    });
  });
};

const getAffectedForEvent = (event: any) => {
  const config = {target: event.target, targets: event.targets} as const;
  return getAffectedElements({config, elementApi});
};

// @ts-expect-error - TS7031 - Binding element 'logic' implicitly has an 'any' type. | TS7031 - Binding element 'store' implicitly has an 'any' type. | TS7031 - Binding element 'events' implicitly has an 'any' type.
function bindEventType({logic, store, events}: {store: IX2EngineReducerStore}) {
  injectBehaviorCSSFixes(events);
  const {types: eventTypes, handler: eventHandler} = logic;
  const {ixData} = store.getState();
  const {actionLists} = ixData;
  const eventTargets = mapFoundValues(events, getAffectedForEvent);

  if (!size(eventTargets)) {
    return;
  }

  forEach(eventTargets, (elements, key) => {
    const event = events[key];
    const {
      action: eventAction,
      id: eventId,
      mediaQueries = ixData.mediaQueryKeys,
    } = event;
    const {actionListId} = eventAction.config;

    if (!mediaQueriesEqual(mediaQueries, ixData.mediaQueryKeys)) {
      store.dispatch(mediaQueriesDefined());
    }

    if (
      eventAction.actionTypeId === ActionTypeConsts.GENERAL_CONTINUOUS_ACTION
    ) {
      const configs = Array.isArray(event.config)
        ? event.config
        : [event.config];

      // @ts-expect-error - TS7006 - Parameter 'eventConfig' implicitly has an 'any' type.
      configs.forEach((eventConfig) => {
        const {continuousParameterGroupId} = eventConfig;
        const paramGroups = get(
          actionLists,
          `${actionListId}.continuousParameterGroups`,
          []
        );
        const parameterGroup = find(
          paramGroups,
          ({id}) => id === continuousParameterGroupId
        );
        const smoothing = (eventConfig.smoothing || 0) / 100;
        const restingValue = (eventConfig.restingState || 0) / 100;

        if (!parameterGroup) {
          return;
        }

        elements.forEach((eventTarget, index) => {
          const eventStateKey = eventId + COLON_DELIMITER + index;
          createGroupInstances({
            store,
            eventStateKey,
            eventTarget,
            eventId,
            eventConfig,
            actionListId,
            parameterGroup,
            smoothing,
            restingValue,
          });
        });
      });
    }

    if (
      eventAction.actionTypeId === ActionTypeConsts.GENERAL_START_ACTION ||
      isQuickEffect(eventAction.actionTypeId)
    ) {
      renderInitialGroup({store, actionListId, eventId});
    }
  });

  const handleEvent = (nativeEvent: any) => {
    const {ixSession} = store.getState();
    forEachEventTarget(eventTargets, (element, eventId, eventStateKey) => {
      const event = events[eventId];
      const oldState = ixSession.eventState[eventStateKey];
      const {action: eventAction, mediaQueries = ixData.mediaQueryKeys} = event;
      // Bypass event handler if current media query is not listed in event config
      if (!shouldAllowMediaQuery(mediaQueries, ixSession.mediaQueryKey)) {
        return;
      }
      const handleEventWithConfig = (eventConfig = {}) => {
        const newState = eventHandler(
          {
            store,
            element,
            event,
            eventConfig,
            nativeEvent,
            eventStateKey,
          },
          oldState
        );
        if (!shallowEqual(newState, oldState)) {
          store.dispatch(eventStateChanged(eventStateKey, newState));
        }
      };
      if (
        eventAction.actionTypeId === ActionTypeConsts.GENERAL_CONTINUOUS_ACTION
      ) {
        const configs = Array.isArray(event.config)
          ? event.config
          : [event.config];
        configs.forEach(handleEventWithConfig);
      } else {
        handleEventWithConfig();
      }
    });
  };

  const handleEventThrottled = throttle(handleEvent, THROTTLED_EVENT_WAIT);

  const addListeners = ({
    target = document,
    // @ts-expect-error - TS7031 - Binding element 'types' implicitly has an 'any' type.
    types,
    // @ts-expect-error - TS7031 - Binding element 'shouldThrottle' implicitly has an 'any' type.
    throttle: shouldThrottle,
  }) => {
    types
      .split(' ')
      .filter(Boolean)
      // @ts-expect-error - TS7006 - Parameter 'type' implicitly has an 'any' type.
      .forEach((type) => {
        const handlerFunc = shouldThrottle ? handleEventThrottled : handleEvent;
        target.addEventListener(type, handlerFunc);
        store.dispatch(eventListenerAdded(target, [type, handlerFunc]));
      });
  };

  if (Array.isArray(eventTypes)) {
    eventTypes.forEach(addListeners);
  } else if (typeof eventTypes === 'string') {
    addListeners(logic);
  }
}

/**
 * Injects CSS into the document to fix behavior issues across
 * different devices.
 */

function injectBehaviorCSSFixes(events: any) {
  if (!IS_MOBILE_SAFARI) {
    return;
  }

  const injectedSelectors: Record<string, any> = {};

  let cssText = '';
  for (const eventId in events) {
    const {eventTypeId, target} = events[eventId];

    const selector = elementApi.getQuerySelector(target);
    // @ts-expect-error - TS2538 - Type 'null' cannot be used as an index type.
    if (injectedSelectors[selector]) {
      continue;
    }

    // add a "cursor: pointer" style rule to ensure that CLICK events get fired for IOS devices
    if (
      eventTypeId === EventTypeConsts.MOUSE_CLICK ||
      eventTypeId === EventTypeConsts.MOUSE_SECOND_CLICK
    ) {
      // @ts-expect-error - TS2538 - Type 'null' cannot be used as an index type.
      injectedSelectors[selector] = true;
      cssText +=
        selector +
        '{' +
        'cursor: pointer;' +
        'touch-action: manipulation;' +
        '}';
    }
  }

  if (cssText) {
    const style = document.createElement('style');
    style.textContent = cssText;
    document.body.appendChild(style);
  }
}

function renderInitialGroup({
  store,
  actionListId,
  eventId,
}: {
  store: IX2EngineReducerStore;
  actionListId: number;
  eventId: number;
}) {
  const {ixData, ixSession} = store.getState();
  const {actionLists, events} = ixData;
  // @ts-expect-error - TS18048 - 'events' is possibly 'undefined'.
  const event = events[eventId];
  // @ts-expect-error - TS18048 - 'actionLists' is possibly 'undefined'.
  const actionList = actionLists[actionListId];

  // @ts-expect-error - Property 'useFirstGroupAsInitialState' does not exist on type 'ActionListType'.
  if (actionList && actionList.useFirstGroupAsInitialState) {
    const initialStateItems = get(
      actionList,
      'actionItemGroups[0].actionItems',
      []
    );

    // Bypass initial state render if current media query is not listed in event config
    const mediaQueries = get(event, 'mediaQueries', ixData.mediaQueryKeys);
    if (!shouldAllowMediaQuery(mediaQueries, ixSession.mediaQueryKey)) {
      return;
    }

    initialStateItems.forEach((actionItem) => {
      const {config: itemConfig, actionTypeId} = actionItem;
      const config =
        // When useEventTarget is explicitly true, use event target/targets to query elements
        // However, skip this condition when objectId is defined
        // @ts-expect-error - Property 'target' does not exist on type 'never'.
        itemConfig?.target?.useEventTarget === true &&
        // @ts-expect-error - Property 'target' does not exist on type 'never'.
        itemConfig?.target?.objectId == null
          ? // @ts-expect-error - TS18048 - 'event' is possibly 'undefined'.
            {target: event.target, targets: event.targets}
          : itemConfig;
      const itemElements = getAffectedElements({config, event, elementApi});
      const shouldUsePlugin = isPluginType(actionTypeId);

      itemElements.forEach((element) => {
        const pluginInstance = shouldUsePlugin
          ? createPluginInstance(actionTypeId)(element, actionItem)
          : null;
        createInstance({
          destination: getDestinationValues(
            {element, actionItem, elementApi},
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            pluginInstance
          ),
          immediate: true,
          store,
          element,
          eventId,
          actionItem,
          actionListId,
          pluginInstance,
        });
      });
    });
  }
}

export function stopAllActionGroups({store}: {store: IX2EngineReducerStore}) {
  const {ixInstances} = store.getState();
  forEach(ixInstances, (instance) => {
    if (!instance.continuous) {
      const {actionListId, verbose} = instance;
      removeInstance(instance, store);
      if (verbose) {
        store.dispatch(
          actionListPlaybackChanged({actionListId, isPlaying: false})
        );
      }
    }
  });
}

export function stopActionGroup({
  // @ts-expect-error - TS7031 - Binding element 'store' implicitly has an 'any' type.
  store,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  eventId,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  eventTarget,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  eventStateKey,
  // @ts-expect-error - TS7031 - Binding element 'actionListId' implicitly has an 'any' type.
  actionListId,
}) {
  const {ixInstances, ixSession} = store.getState();

  // Check for element boundary before stopping engine instances
  const eventElementRoot =
    ixSession.hasBoundaryNodes && eventTarget
      ? elementApi.getClosestElement(eventTarget, BOUNDARY_SELECTOR)
      : null;

  forEach(ixInstances, (instance) => {
    const boundaryMode = get(instance, 'actionItem.config.target.boundaryMode');
    // Validate event key if eventStateKey was provided, otherwise default to true
    const validEventKey = eventStateKey
      ? instance.eventStateKey === eventStateKey
      : true;
    // Remove engine instances that match the required ids
    if (
      instance.actionListId === actionListId &&
      instance.eventId === eventId &&
      validEventKey
    ) {
      // Avoid removal when root boundary does not contain instance element
      if (
        eventElementRoot &&
        boundaryMode &&
        !elementApi.elementContains(eventElementRoot, instance.element)
      ) {
        return;
      }
      removeInstance(instance, store);
      if (instance.verbose) {
        store.dispatch(
          actionListPlaybackChanged({actionListId, isPlaying: false})
        );
      }
    }
  });
}

export function startActionGroup({
  // @ts-expect-error - TS7031 - Binding element 'store' implicitly has an 'any' type.
  store,
  // @ts-expect-error - TS7031 - Binding element 'eventId' implicitly has an 'any' type.
  eventId,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  eventTarget,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  eventStateKey,
  // @ts-expect-error - TS7031 - Binding element 'actionListId' implicitly has an 'any' type.
  actionListId,
  groupIndex = 0,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  immediate,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  verbose,
}) {
  const {ixData, ixSession} = store.getState();
  const {events} = ixData;
  const event = events[eventId] || {};
  const {mediaQueries = ixData.mediaQueryKeys} = event;
  const actionList = get(ixData, `actionLists.${actionListId}`, {});
  const {actionItemGroups, useFirstGroupAsInitialState} = actionList;
  // Abort playback if no action groups
  if (!actionItemGroups || !actionItemGroups.length) {
    return false;
  }
  // Reset to first group when event loop is configured
  if (groupIndex >= actionItemGroups.length && get(event, 'config.loop')) {
    groupIndex = 0;
  }
  // Skip initial state group during action list playback, as it should already be applied
  if (groupIndex === 0 && useFirstGroupAsInitialState) {
    groupIndex++;
  }
  // Identify first animated group and apply the initial QuickEffect delay
  const isFirstGroup =
    groupIndex === 0 || (groupIndex === 1 && useFirstGroupAsInitialState);
  const instanceDelay =
    isFirstGroup && isQuickEffect(event.action?.actionTypeId)
      ? event.config.delay
      : undefined;

  // Abort playback if no action items exist at group index
  const actionItems = get(actionItemGroups, [groupIndex, 'actionItems'], []);
  if (!actionItems.length) {
    return false;
  }
  // Abort playback if current media query is not listed in event config
  if (!shouldAllowMediaQuery(mediaQueries, ixSession.mediaQueryKey)) {
    return false;
  }
  // Limit affected elements when event target is within a boundary node
  const eventElementRoot =
    ixSession.hasBoundaryNodes && eventTarget
      ? elementApi.getClosestElement(eventTarget, BOUNDARY_SELECTOR)
      : null;

  const carrierIndex = getMaxDurationItemIndex(actionItems);
  let groupStartResult = false;

  // @ts-expect-error - TS7006 - Parameter 'actionItem' implicitly has an 'any' type. | TS7006 - Parameter 'actionIndex' implicitly has an 'any' type.
  actionItems.forEach((actionItem, actionIndex) => {
    const {config, actionTypeId} = actionItem;
    const shouldUsePlugin = isPluginType(actionTypeId);
    const {target} = config;
    if (!target) {
      return;
    }
    const elementRoot = target.boundaryMode ? eventElementRoot : null;
    const elements = getAffectedElements({
      config,
      event,
      eventTarget,
      elementRoot,
      elementApi,
    });
    elements.forEach((element, elementIndex) => {
      const pluginInstance = shouldUsePlugin
        ? createPluginInstance(actionTypeId)(element, actionItem)
        : null;
      const pluginDuration = shouldUsePlugin
        ? getPluginDuration(actionTypeId)(element, actionItem)
        : null;
      groupStartResult = true;
      const isCarrier = carrierIndex === actionIndex && elementIndex === 0;
      const computedStyle = getComputedStyle({element, actionItem});
      const destination = getDestinationValues(
        {element, actionItem, elementApi},
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        pluginInstance
      );

      createInstance({
        store,
        element,
        actionItem,
        eventId,
        eventTarget,
        eventStateKey,
        actionListId,
        groupIndex,
        isCarrier,
        computedStyle,
        destination,
        immediate,
        verbose,
        pluginInstance,
        pluginDuration,
        instanceDelay,
      });
    });
  });
  return groupStartResult;
}

// @ts-expect-error - TS7006 - Parameter 'options' implicitly has an 'any' type.
function createInstance(options) {
  const {store, computedStyle, ...rest} = options;
  const {
    element,
    actionItem,

    immediate,
    pluginInstance,

    continuous,

    restingValue,
    eventId,
  } = rest;
  const autoStart = !continuous;
  const instanceId = getInstanceId();

  const {ixElements, ixSession, ixData} = store.getState();
  const elementId = getElementId(ixElements, element);
  const {refState} = ixElements[elementId] || {};
  const refType = elementApi.getRefType(element);

  const skipMotion =
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ readonly TRANSFORM_MOVE: true; readonly TRANSFORM_SCALE: true; readonly TRANSFORM_ROTATE: true; readonly TRANSFORM_SKEW: true; readonly STYLE_SIZE: true; readonly STYLE_FILTER: true; readonly STYLE_FONT_VARIATION: true; }'.
    ixSession.reducedMotion && ReducedMotionTypes[actionItem.actionTypeId];
  let skipToValue;
  if (skipMotion && continuous) {
    switch (ixData.events[eventId]?.eventTypeId) {
      case EventTypeConsts.MOUSE_MOVE:
      case EventTypeConsts.MOUSE_MOVE_IN_VIEWPORT:
        skipToValue = restingValue;
        break;
      default:
        skipToValue = 0.5;
        break;
    }
  }

  const origin = getInstanceOrigin(
    element,
    refState,
    computedStyle,
    actionItem,
    elementApi,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    pluginInstance
  );

  store.dispatch(
    instanceAdded({
      instanceId,
      elementId,
      origin,
      refType,
      skipMotion,
      skipToValue,
      ...rest,
    })
  );

  dispatchCustomEvent(document.body, 'ix2-animation-started', instanceId);

  if (immediate) {
    renderImmediateInstance(store, instanceId);
    return;
  }

  observeStore({
    store,
    // @ts-expect-error - TS7031 - Binding element 'ixInstances' implicitly has an 'any' type.
    select: ({ixInstances}) => ixInstances[instanceId],
    onChange: handleInstanceChange,
  });

  if (autoStart) {
    store.dispatch(instanceStarted(instanceId, ixSession.tick));
  }
}

function removeInstance(instance: any, store: IX2EngineReducerStore) {
  dispatchCustomEvent(document.body, 'ix2-animation-stopping', {
    instanceId: instance.id,
    state: store.getState(),
  });
  const {elementId, actionItem} = instance;
  const {ixElements} = store.getState();
  const {ref, refType} = ixElements[elementId] || {};
  if (refType === HTML_ELEMENT) {
    cleanupHTMLElement(ref, actionItem, elementApi);
  }
  store.dispatch(instanceRemoved(instance.id));
}

function dispatchCustomEvent(
  element: null | HTMLElement,
  eventName: string,
  detail:
    | string
    | {
        instanceId: any;
        state: any;
      }
) {
  const event = document.createEvent('CustomEvent');
  event.initCustomEvent(eventName, true, true, detail);
  // @ts-expect-error - TS18047 - 'element' is possibly 'null'.
  element.dispatchEvent(event);
}

function renderImmediateInstance(
  store: IX2EngineReducerStore,
  instanceId: string
) {
  const {ixParameters} = store.getState();
  store.dispatch(instanceStarted(instanceId, 0));
  store.dispatch(animationFrameChanged(performance.now(), ixParameters));
  const {ixInstances} = store.getState();
  handleInstanceChange(ixInstances[instanceId], store);
}

function handleInstanceChange(instance: any, store: IX2EngineReducerStore) {
  const {
    active,
    continuous,
    complete,
    elementId,
    actionItem,
    actionTypeId,
    renderType,
    current,
    groupIndex,
    eventId,
    eventTarget,
    eventStateKey,
    actionListId,
    isCarrier,
    styleProp,
    verbose,
    pluginInstance,
  } = instance;

  // Bypass render if current media query is not listed in event config
  const {ixData, ixSession} = store.getState();
  const {events} = ixData;
  const event = events && events[eventId] ? events[eventId] : {};
  // @ts-expect-error - TS2339 -  Property 'mediaQueries' does not exist on type '{} | undefined'.
  const {mediaQueries = ixData.mediaQueryKeys} = event;
  if (!shouldAllowMediaQuery(mediaQueries, ixSession.mediaQueryKey)) {
    return;
  }

  if (continuous || active || complete) {
    if (current || (renderType === RENDER_GENERAL && complete)) {
      // Render current values to ref state and grab latest
      store.dispatch(
        elementStateChanged(elementId, actionTypeId, current, actionItem)
      );
      const {ixElements} = store.getState();
      const {ref, refType, refState} = ixElements[elementId] || {};
      const actionState = refState && refState[actionTypeId];

      // Render HTML and plugin elements
      if (refType === HTML_ELEMENT || isPluginType(actionTypeId)) {
        renderHTMLElement(
          ref,
          refState,
          actionState,
          eventId,
          actionItem,
          styleProp,
          elementApi,
          renderType,
          pluginInstance
        );
      }
    }

    if (complete) {
      if (isCarrier) {
        // @ts-expect-error - TS2345 - Argument of type '{ store: any; eventId: any; eventTarget: any; eventStateKey: any; actionListId: any; groupIndex: any; verbose: any; }' is not assignable to parameter of type '{ store: any; eventId: any; eventTarget: any; eventStateKey: any; actionListId: any; groupIndex?: number | undefined; immediate: any; verbose: any; }'.
        const started = startActionGroup({
          store,
          eventId,
          eventTarget,
          eventStateKey,
          actionListId,
          groupIndex: groupIndex + 1,
          verbose,
        });
        if (verbose && !started) {
          store.dispatch(
            actionListPlaybackChanged({actionListId, isPlaying: false})
          );
        }
      }

      removeInstance(instance, store);
    }
  }
}
