import gql from 'graphql-tag';

export const getUserSubscriptions = gql`
  query FetchSubscriptions {
    database {
      id
      userSubscriptions {
        _id
        productName
        variantPrice {
          string
          unit
          value
        }
        variantImage {
          url
          alt
        }
        status
        lastInvoiced
        periodEnd
        subCreatedOn
        canceledOn
        billingAddressAddressee
        billingAddressLine1
        billingAddressLine2
        billingAddressCity
        billingAddressState
        billingAddressPostalCode
        billingAddressCountry
        cardLast4
        cardExpiresMonth
        cardExpiresYear
      }
    }
  }
`;

export function buildGetLoggedInUserQuery(
  dataFields: Array<{
    key: string;
    type: string;
    id: string;
  }> = []
) {
  return gql`
    query FetchUser {
        site {
          id
          siteUser {
            id
            createdOn
            ${
              dataFields.length > 0
                ? `
            data {
              ${dataFields
                .map((field) => {
                  const base = `${field.key}: ${field.type}(id: "${field.id}")`;
                  if (field.type === 'option') {
                    return base + '{\n slug \n}';
                  }
                  if (field.type === 'fileRef') {
                    return base + '{\n url \n \n id \n}';
                  }
                  return base;
                })
                .join('\n')}
            }`
                : ''
            }
        }
      }
    }
  `;
}

export const getFieldValidations = gql`
  query GetFieldValidations {
    site {
      id
      usysFieldSchema {
        id
        required
        validations {
          minLength
          maxLength
          min
          max
          step
          extensions
          options {
            slug
            name
          }
        }
      }
    }
  }
`;
