import gql from 'graphql-tag';

export const loginMutation = gql`
  mutation UserLoginRequest($email: String!, $authPassword: String!) {
    usysCreateSession(email: $email, authPassword: $authPassword) {
      user {
        id
        email
        createdOn
        emailVerified
      }
    }
  }
`;

export function buildUpdateUsysUserDataMutation(
  dataFields: Array<{
    key: string;
    type: string;
    id: string;
  }>
) {
  return gql`
    mutation UpdateUsysUserData(
      $data: usys_update_user_data!
    ) {
      usysUpdateUserData(
        data: $data
    ) {
      data {
      ${dataFields
        .map((field) => {
          const base = `${field.key}: ${field.type}(id: "${field.id}")`;
          if (field.type === 'option') {
            return base + '{\n slug \n}';
          }
          if (field.type === 'fileRef') {
            return base + '{\n id \n}';
          }
          return base;
        })
        .join('\n')}
        }
      }
    }
  `;
}

export const signupMutation = gql`
  mutation UserSignupRequest(
    $email: String!
    $name: String!
    $acceptPrivacy: Boolean
    $acceptCommunications: Boolean
    $authPassword: String!
    $inviteToken: String
    $captchaToken: String
    $redirectPath: String
    $data: usys_update_user_data
  ) {
    usysCreateUser(
      email: $email
      name: $name
      acceptPrivacy: $acceptPrivacy
      acceptCommunications: $acceptCommunications
      authPassword: $authPassword
      inviteToken: $inviteToken
      captchaToken: $captchaToken
      redirectPath: $redirectPath
      data: $data
    ) {
      user {
        id
        email
        name
        createdOn
        emailVerified
      }
    }
  }
`;

export const logoutMutation = gql`
  mutation UserLogoutRequest {
    usysDestroySession {
      ok
    }
  }
`;

export const resetPasswordMutation = gql`
  mutation UserResetPasswordRequest($email: String!) {
    usysResetPassword(email: $email) {
      ok
    }
  }
`;

export const updatePasswordMutation = gql`
  mutation UserUpdatePasswordRequest($authPassword: String!, $token: String!) {
    usysUpdatePassword(authPassword: $authPassword, token: $token) {
      ok
    }
  }
`;

export const verifyEmailMutation = gql`
  mutation UserVerifyEmail($verifyToken: String!, $redirectPath: String) {
    usysVerifyEmail(verifyToken: $verifyToken, redirectPath: $redirectPath) {
      ok
    }
  }
`;

export const cancelSubscriptionMutation = gql`
  mutation CancelSiteUserSubscription($subscriptionId: String!) {
    ecommerceCancelSubscriptionForSiteUser(subscriptionId: $subscriptionId) {
      ok
    }
  }
`;

export const getUploadURLMutation = gql`
  mutation getUploadURL($fieldId: String!, $filename: String!) {
    usysGetUploadURL(fieldId: $fieldId, filename: $filename) {
      presignedPOST {
        url
        fields {
          key
          value
        }
      }
      key
    }
  }
`;
