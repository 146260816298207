import camelCase from 'lodash/camelCase';

// REGEXES
export const ORDER_ID_RE = /^[0-9a-f]{5,}$/;

// @TODO - Once we extract the Commerce plugin to packages, move `pluginConstants` there.
export * from './pluginConstants';

export {
  PRODUCTS_BINDING_CONTEXT_EXTERNAL_KEY,
  ORDER_ITEMS_BINDING_CONTEXT_EXTERNAL_KEY,
} from './bindingContextConstants';
export {paypalCurrencyList} from './paypalCurrencyList';
export {stripeCurrencyList} from './stripeCurrencyList';

const SHIPPING_METHOD_FLAT = 'flat-rate';
const SHIPPING_METHOD_PERCENTAGE = 'percentage';
const SHIPPING_METHOD_PRICE = 'price';
const SHIPPING_METHOD_QUANTITY = 'quantity';
const SHIPPING_METHOD_WEIGHT = 'weight';

export const SHIPPING_METHODS = Object.freeze({
  FLAT: SHIPPING_METHOD_FLAT,
  PERCENTAGE: SHIPPING_METHOD_PERCENTAGE,
  PRICE: SHIPPING_METHOD_PRICE,
  QUANTITY: SHIPPING_METHOD_QUANTITY,
  WEIGHT: SHIPPING_METHOD_WEIGHT,
});

export const DEFAULT_TAX_CATEGORY = 'standard-taxable';

export const INVENTORY_TYPE_FINITE = 'finite';
export const INVENTORY_TYPE_INFINITE = 'infinite';
export const INFINITE_INVENTORY = {
  inventoryType: INVENTORY_TYPE_INFINITE,
  quantity: 0,
} as const;

// Stripe maximum charge. From stripe: The only limit to the maximum amount you can charge a customer
// is a technical one. The amount value supports up to eight digits (e.g., a value of 99999999 for a
// USD charge of $999,999.99).
export const MAX_TOTAL_ORDER_PRICE = 99999999;

export const MAX_PRODUCT_DIMENSION = 9000000000000000;

export const MAX_MEMBERSHIP_PRODUCTS = 20;

export const MAX_SEARCH_LIMIT = 100;

type PriceTemplateOption = {
  label: string;
  type: string;
  value: string;
  readOnly?: boolean;
  isNotAddable?: boolean;
};

function _withDerivedValue({
  label,
  type = 'PlainText',
  path = camelCase(label),

  options = {
    readOnly: false,
    isNotAddable: false,
  },
}: {
  label: string;
  type?: string;
  path?: string;
  options?: {
    readOnly: boolean;
    isNotAddable: boolean;
  };
}) {
  return {
    label,
    type,
    ...options,
    value: JSON.stringify({path, type}),
  };
}

export const PRICE_TEMPLATE_CURRENCY_SYMBOL: PriceTemplateOption =
  _withDerivedValue({
    label: 'Currency symbol',
    path: 'symbol',
  });
export const PRICE_TEMPLATE_AMOUNT: PriceTemplateOption = _withDerivedValue({
  label: 'Amount',
  type: 'CommercePrice',
  options: {
    readOnly: true,
    isNotAddable: true,
  },
});
export const PRICE_TEMPLATE_CURRENCY_CODE: PriceTemplateOption =
  _withDerivedValue({
    label: 'Currency code',
  });

export const PRICE_TEMPLATE_OPTIONS: PriceTemplateOption[] = [
  PRICE_TEMPLATE_CURRENCY_SYMBOL,
  PRICE_TEMPLATE_AMOUNT,
  PRICE_TEMPLATE_CURRENCY_CODE,
];

const _intoToken = (option: PriceTemplateOption) => `{{wf ${option.value} }}`;

export const DEFAULT_PRICE_TEMPLATE_VALUE = [
  _intoToken(PRICE_TEMPLATE_CURRENCY_SYMBOL),
  ' ',
  _intoToken(PRICE_TEMPLATE_AMOUNT),
  ' ',
  _intoToken(PRICE_TEMPLATE_CURRENCY_CODE),
].join('');

export const CSV_CURRENCY_TEMPLATE = [
  _intoToken(PRICE_TEMPLATE_CURRENCY_SYMBOL),
  _intoToken(PRICE_TEMPLATE_AMOUNT),
].join('');

export const CSV_INTEGRATION_CURRENCY_TEMPLATE = [
  _intoToken(PRICE_TEMPLATE_AMOUNT),
  ' ',
  _intoToken(PRICE_TEMPLATE_CURRENCY_CODE),
].join('');

export const DOWNLOAD_FILES_FAKE_DATA = [
  {
    id: '5d8fcb6d94dd1853060fb3b3',
    name: 'The modern web design process - Webflow Ebook.pdf',
    url: 'https://assets-global.website-files.com/5cf6b7202bf8199f50d43e6c/5e9dd8a680b972888929747b_The%20modern%20web%20design%20process%20-%20Webflow%20Ebook.pdf',
  },
  {
    id: '5d8fcb6d94dd1853060fb3b4',
    name: 'The freelance web designers guide - Webflow Ebook.pdf',
    url: 'https://assets-global.website-files.com/5cf6b7202bf8199f50d43e6c/5e9dd8e6abe52b33243a22cf_The%20freelance%20web%20designer%E2%80%99s%20guide%20-%20Webflow%20Ebook.pdf',
  },
] as const;

export const DOWNLOAD_FILES_KEY_PATH = 'download-files';
export const DOWNLOAD_FILES_EDITABLE_FIELDS = {
  name: true,
  url: true,
} as const;

// Subscriptions ----------------------------------------------------
export const SUBSCRIPTION_INTERVAL_ENUM = [
  'day',
  'week',
  'month',
  'year',
] as const;

export const SUBSCRIPTION_STATUS_ENUM = {
  active: 'active',
  pastdue: 'pastdue',
  unpaid: 'unpaid',
  canceled: 'canceled',
  cancelPending: 'cancelPending',
  incomplete: 'incomplete',
  incompleteExpired: 'incompleteExpired',
  trialing: 'trialing',
  unknown: `unknown`,
} as const;
export const SUBSCRIPTION_STATUS_PRETTY_ENUM = {
  active: 'active',
  pastdue: 'pastdue',
  unpaid: 'unpaid',
  canceled: 'canceled',
  cancelPending: 'cancelPending',
  incomplete: 'incomplete',
  incompleteExpired: 'incompleteExpired',
  trialing: 'in trial',
  unknown: `unknown`,
} as const;

export const STRIPE_SUBSCRIPTION_STATUS_ENUM = {
  active: 'active',
  past_due: 'past_due',
  unpaid: 'unpaid',
  canceled: 'canceled',
  incomplete: 'incomplete',
  incomplete_expired: 'incomplete_expired',
  trialing: 'trialing',
} as const;

export const ACTIVE_STRIPE_SUBSCRIPTION_STATUSES = [
  STRIPE_SUBSCRIPTION_STATUS_ENUM.active,
  STRIPE_SUBSCRIPTION_STATUS_ENUM.past_due,
  STRIPE_SUBSCRIPTION_STATUS_ENUM.trialing,
] as const;

export const ECOMMERCE_PROVIDER_NAME_ENUM = {
  stripe: 'stripe',
} as const;

export const BILLING_METHOD_TYPES = {
  subscription: 'subscription',
  oneTime: 'one-time',
} as const;

// Product Types

export type ProductTypeField = {
  fieldSlug: string;
  required: boolean;
};

export type ProductTypeType = {
  name: string;
  id: string;
  fields?: {
    product: Array<ProductTypeField>;
    sku: Array<ProductTypeField>;
  };
};

const DEFAULT_PRODUCT_TYPE_PRODUCT_FIELDS = [
  {fieldSlug: 'name', required: true},
  {fieldSlug: 'slug', required: true},
  {fieldSlug: 'sku-properties', required: false},
  {fieldSlug: 'category', required: false},
  {fieldSlug: 'description', required: false},
  {fieldSlug: 'tax-category', required: false},
  {fieldSlug: 'default-sku', required: false},
  {fieldSlug: 'ec-product-type', required: false},
  {fieldSlug: 'options', required: false},
];
const DEFAULT_PRODUCT_TYPE_SKU_FIELDS = [
  {fieldSlug: 'sku-values', required: false},
  {fieldSlug: 'product', required: false},
  {fieldSlug: 'main-image', required: false},
  {fieldSlug: 'more-images', required: false},
  {fieldSlug: 'price', required: true},
  {fieldSlug: 'compare-at-price', required: false},
  {fieldSlug: 'ec-sku-subscription-plan', required: false},
  {fieldSlug: 'sku', required: false},
  {fieldSlug: 'ec-sku-billing-method', required: false},
  {fieldSlug: 'track-inventory', required: false},
  {fieldSlug: 'quantity', required: false},
];

export const PHYSICAL_PRODUCT_TYPE: ProductTypeType = {
  name: 'Physical',
  id: 'ff42fee0113744f693a764e3431a9cc2',
  fields: {
    product: [
      ...DEFAULT_PRODUCT_TYPE_PRODUCT_FIELDS,
      {fieldSlug: 'shippable', required: false},
    ],
    sku: [
      ...DEFAULT_PRODUCT_TYPE_SKU_FIELDS,
      {fieldSlug: 'weight', required: false},
      {fieldSlug: 'width', required: false},
      {fieldSlug: 'height', required: false},
      {fieldSlug: 'length', required: false},
    ],
  },
};

export const DIGITAL_PRODUCT_TYPE: ProductTypeType = {
  name: 'Digital',
  id: 'f22027db68002190aef89a4a2b7ac8a1',
  fields: {
    product: [...DEFAULT_PRODUCT_TYPE_PRODUCT_FIELDS],
    sku: [
      ...DEFAULT_PRODUCT_TYPE_SKU_FIELDS,
      {fieldSlug: 'download-files', required: true},
    ],
  },
};

export const SERVICE_PRODUCT_TYPE: ProductTypeType = {
  name: 'Service',
  id: 'c599e43b1a1c34d5a323aedf75d3adf6',
  fields: {
    product: [...DEFAULT_PRODUCT_TYPE_PRODUCT_FIELDS],
    sku: [...DEFAULT_PRODUCT_TYPE_SKU_FIELDS],
  },
};

export const MEMBERSHIP_PRODUCT_TYPE: ProductTypeType = {
  name: 'Membership',
  id: 'e348fd487d0102946c9179d2a94bb613',
  fields: {
    product: [
      ...DEFAULT_PRODUCT_TYPE_PRODUCT_FIELDS,
      {fieldSlug: 'shippable', required: false},
    ],
    sku: [
      ...DEFAULT_PRODUCT_TYPE_SKU_FIELDS,
      {fieldSlug: 'weight', required: false},
      {fieldSlug: 'width', required: false},
      {fieldSlug: 'height', required: false},
      {fieldSlug: 'length', required: false},
      {fieldSlug: 'download-files', required: false},
      {fieldSlug: 'include-downloads', required: false},
    ],
  },
};

export const ADVANCED_PRODUCT_TYPE: ProductTypeType = {
  name: 'Advanced',
  id: 'b6ccc1830db4b1babeb06a9ac5f6dd76',
};

export const TEMPLATE_PRODUCT_TYPES: ProductTypeType[] = [
  PHYSICAL_PRODUCT_TYPE,
  DIGITAL_PRODUCT_TYPE,
  SERVICE_PRODUCT_TYPE,
  MEMBERSHIP_PRODUCT_TYPE,
  ADVANCED_PRODUCT_TYPE,
];

// only used to get type ProductTypeId
const templateProductTypeIds = TEMPLATE_PRODUCT_TYPES.reduce<
  Record<string, any>
>((ids, t) => {
  ids[t.id] = '';
  return ids;
}, {});
export type ProductTypeId = keyof typeof templateProductTypeIds;

export const PRODUCT_TYPE_HELP_TEXT = {
  [PHYSICAL_PRODUCT_TYPE.id]:
    'Physical products are shipped to the customer (e.g., merchandise, apparel).',
  [DIGITAL_PRODUCT_TYPE.id]:
    'Digital products are immediately downloadable by the customer after checkout (e.g., audio files, ebooks).',
  [SERVICE_PRODUCT_TYPE.id]:
    'Service products do not require a shipping address during checkout (e.g., classes, consultations).',
  [MEMBERSHIP_PRODUCT_TYPE.id]:
    'Membership products give users access to gated content through recurring or one-time payment (e.g., subscriptions, one-time membership fee). Membership products require a user login and can only be purchased once.',
  [ADVANCED_PRODUCT_TYPE.id]:
    'Advanced products provide all available customizable options.',
} as const;

export const DEFAULT_PRODUCT_TYPE_ID = PHYSICAL_PRODUCT_TYPE.id;

export const DISCOUNT_CODE_MAX_LENGTH: number = 255;

export const DISCOUNTS_CSV_IMPORT_EXPORT_COLUMNS: ReadonlyArray<string> = [
  'name',
  'code',
  'notes',
  'type',
  'percentOff',
  'amountOff',
  'validOn',
  'expiresOn',
  'enabled', // 'active' is being replaced with 'enabled'
  'orderMinimum',
  // archived is disabled until we have UI for it
  // 'archived',
  'totalUsage',
  'maxAmountOff',

  // NOTE: for dot-notation fields to be properly expanded
  // during import, you need to add the camel-case flattened property to
  // the 'KEYS_TO_EXPAND' variable in `entrypoints/server/lib/ecommerce/csvImport/discountCsvImport.js`
  // Example: 'usage.limit.total' -> 'usageLimitTotal'
  'usage.limit.total',
  'usage.limit.customer',
  'appliesTo.scope',
  'appliesTo.filter',
  'appliesTo.applyOnce',
];

export const REQUIRED_DISCOUNT_IMPORT_FIELDS = [
  'name',
  'code',
  'type',
  ['percentOff', 'amountOff'], // we want either percentOff or amountOff present or both
] as const;

export const STRIPE_DISCONNECT_SUBSCRIPTIONS_ERROR_MESSAGE =
  'Stripe disconnect attempted with non-canceled subscriptions';

export const ORDER_SORT_MODES = Object.freeze({
  '-count': '-purchasedItemsCount -_id',
  count: 'purchasedItemsCount _id',
  '-name': '-customerInfo.fullName -_id',
  name: 'customerInfo.fullName _id',
  '-orderid': '-orderId',
  orderid: 'orderId',
  '-paid': '-customerPaid.unit -customerPaid.value -_id',
  paid: 'customerPaid.unit customerPaid.value _id',
  '-status': '-statusCode -_id',
  status: 'statusCode _id',
  '-time': '-acceptedOn -_id',
  time: 'acceptedOn _id',
});

export const SUBSCRIPTION_SORT_MODES = Object.freeze({
  '-lastBilled': '-lastInvoiced -_id',
  lastBilled: 'lastInvoiced _id',
  '-nextBilling': '-paidUntil -_id',
  nextBilling: 'paidUntil _id',
  '-orderid': '-orderId',
  orderid: 'orderId',
  '-purchased': '-subCreatedOn -_id',
  purchased: 'subCreatedOn _id',
  '-status': '-status -_id',
  status: 'status _id',
  '-trialing': '-trialing -_id',
  trialing: 'trialing _id',
});
