import {
  disableSubmit,
  userSystemsRequestClient,
  addHiddenClass,
  removeHiddenClass,
} from './utils';
import {getFieldValidations} from './queries';
import {
  NO_REQUIRED_ATTRIBUTE,
  SERVER_DATA_VALIDATION_ERRORS,
  USYS_DATA_ATTRS,
} from '@packages/systems/users/constants';
import {
  uploadFileToS3,
  getUserFileKey,
  setTempUserFileKey,
  setUserFileKey,
  removeTempUserFileKey,
} from '@packages/systems/users/utils/universalUtils';
import {getUploadURLMutation} from './mutations';
/* global window, HTMLInputElement, HTMLSelectElement, document */

function asyncGetFieldValidations() {
  return userSystemsRequestClient.query({
    query: getFieldValidations,
  });
}

function signFile(file, {fieldId}) {
  return userSystemsRequestClient.mutate({
    mutation: getUploadURLMutation,
    variables: {
      fieldId,
      filename: file.name,
    },
  });
}

function setRequired(input, userField) {
  if (userField.required == null) return;
  input.required = userField.required;
}

const inputAttributeMap = {
  minLength: 'minlength',
  maxLength: 'maxlength',
  min: 'min',
  max: 'max',
  step: 'step',
  extensions: 'accept',
};

const formatValueMap = {
  extensions: function accept(value) {
    return value.join(',');
  },
};

function convertToStr(value) {
  return String(value);
}

function setValidations(input, userField) {
  if (userField.validations == null) return;

  Object.keys(userField.validations).map((attr) => {
    const val = userField.validations[attr];

    if (
      attr === 'options' &&
      Array.isArray(val) &&
      input instanceof HTMLSelectElement
    ) {
      val.forEach((option) => {
        if (option.slug && option.name) {
          const opt = document.createElement('option');
          opt.value = option.slug;
          opt.innerHTML = option.name;
          input.appendChild(opt);
        }
      });
    }

    if (val !== null && inputAttributeMap[attr]) {
      let formatValue;
      if (formatValueMap[attr]) {
        formatValue = formatValueMap[attr];
      } else {
        formatValue = convertToStr;
      }

      input.setAttribute(inputAttributeMap[attr], formatValue(val));
    }

    if (attr === 'maxLength' && val === null) {
      input.removeAttribute('maxlength');
    }
  });
}

function setUserFieldValidationAttr(input, userField) {
  const fieldType = input.getAttribute(USYS_DATA_ATTRS.fieldType);
  if (!NO_REQUIRED_ATTRIBUTE.includes(fieldType)) {
    setRequired(input, userField);
  }
  setValidations(input, userField);
}

function matchInputToData(input, userFieldData) {
  const fieldId = input.getAttribute(USYS_DATA_ATTRS.field);
  if (!fieldId) {
    return null;
  }
  for (let i = 0; i < userFieldData.length; i++) {
    if (userFieldData[i].id === fieldId) {
      return userFieldData[i];
    }
  }
  return null;
}

function setFieldValidation(customFieldInputs) {
  asyncGetFieldValidations()
    .then((response) => {
      const userFieldData = response.data.site.usysFieldSchema;
      if (!userFieldData) return;

      for (let i = 0; i < customFieldInputs.length; i++) {
        const input = customFieldInputs[i];
        if (
          !input ||
          !(
            input instanceof HTMLInputElement ||
            input instanceof HTMLSelectElement
          ) ||
          input.getAttribute(USYS_DATA_ATTRS.fieldType) === 'Bool'
        ) {
          continue;
        }
        const userField = matchInputToData(input, userFieldData);
        if (!userField) continue;
        setUserFieldValidationAttr(input, userField);
      }
    })
    .catch((err) => {
      console.error(err);
    });
}

function getMatchingSiblings(e, pred) {
  const siblings = [];
  if (e.target.parentNode === null) {
    return siblings;
  }
  [].slice.call(e.target.parentNode.children).forEach((element) => {
    if (pred(element)) {
      siblings.push(element);
    }
  });
  return siblings;
}

function isFormFileUploadWrapper(element) {
  return element.classList.contains('w-file-upload');
}

function getFirstAncestor(element, pred) {
  if (element.parentNode === null) {
    return null;
  }
  if (pred(element)) {
    return element;
  }
  return getFirstAncestor(element.parentNode, pred);
}

function handleFileRemoveLink(cancelRemoveLinkElement, inputElement, props) {
  const {deleteFile, cancelFile} = props;
  cancelRemoveLinkElement.addEventListener('click', function (e) {
    if (e.type === 'keydown') {
      if (e.which !== 13 && e.which !== 32) {
        return;
      }

      e.preventDefault();
    }
    if (getUserFileKey(inputElement)) {
      deleteFile();
      return;
    }
    cancelFile();
  });
}

function handleFileUploadInput(element, props) {
  const {
    showUploading,
    successUpload,
    errorUpload,
    changeFileNameText,
    disableSubmitButton,
    filesState,
  } = props;
  const fieldId = element.getAttribute(USYS_DATA_ATTRS.field);

  element.addEventListener('change', function (e) {
    if (filesState.isUploading) return;
    const file = e.target && e.target.files && e.target.files[0];
    if (!file) {
      return;
    }

    showUploading();
    changeFileNameText(file.name);

    filesState.isUploading = true;

    if (!filesState.isUploading) {
      disableSubmitButton();
    }

    let key = '';

    signFile(file, {fieldId})
      .then((res) => {
        if (
          !res.data ||
          !res.data.usysGetUploadURL ||
          !res.data.usysGetUploadURL.presignedPOST
        ) {
          throw Error(res);
        }
        const presignedPOST = res.data.usysGetUploadURL.presignedPOST;
        key = res.data.usysGetUploadURL.key;
        const AWSFields = {};
        presignedPOST.fields.forEach((field) => {
          const _key = field.key;
          const value = field.value;
          AWSFields[_key] = value;
        });
        return uploadFileToS3(presignedPOST.url, AWSFields, file);
      })
      .then(() => {
        successUpload(key);
      })
      .catch((err) => {
        let code = SERVER_DATA_VALIDATION_ERRORS.DefaultError;
        if (typeof err === 'string') {
          const content = new window.DOMParser().parseFromString(
            err,
            'text/xml'
          );
          const codeElements = content.getElementsByTagName('Code');
          if (codeElements) {
            code = codeElements[0].innerHTML;
          }
        }
        if (typeof err === 'object' && err.hasOwnProperty('graphQLErrors')) {
          if (err.graphQLErrors[0].code === 'UsysForbiddenFileExtension') {
            code = SERVER_DATA_VALIDATION_ERRORS.ExtensionsError;
          }
        }
        errorUpload(code);
      })
      .finally(() => {
        filesState.isUploading = false;
      });
  });
}

const WF_SUBMIT_BUTTON_VALUE = 'wf-submit-button-value';

const adaptAWSErrors = (code) => {
  if (code === 'EntityTooLarge')
    return SERVER_DATA_VALIDATION_ERRORS.MinSizeError;
  if (code === 'EntityTooSmall')
    return SERVER_DATA_VALIDATION_ERRORS.MaxSizeError;
  return code;
};

function handleFileUploadInputs(customFieldInputs, props) {
  const {disableSubmitButton, enableSubmitButton} = props;
  const filesState = {
    isUploading: false,
  };
  customFieldInputs.forEach((el) => {
    if (el.getAttribute('type') === 'file') {
      const formFileUploadWrapper = getFirstAncestor(
        el,
        isFormFileUploadWrapper
      );
      const formFileDefault = formFileUploadWrapper.querySelector(
        '.w-file-upload-default'
      );
      const formFileSuccess = formFileUploadWrapper.querySelector(
        '.w-file-upload-success'
      );
      const formFileError = formFileUploadWrapper.querySelector(
        '.w-file-upload-error'
      );
      const formFileErrorMsg = formFileError.querySelector(
        '.w-file-upload-error-msg'
      );
      const formFileUploading = formFileUploadWrapper.querySelector(
        '.w-file-upload-uploading'
      );
      const fileUploadFileName = formFileUploadWrapper.querySelector(
        '.w-file-upload-file-name'
      );

      const fileRemoveLink = formFileUploadWrapper.querySelector(
        '.w-file-remove-link'
      );

      const fileUploadLabel = formFileUploadWrapper.querySelector(
        '.w-file-upload-label'
      );

      // eslint-disable-next-line no-inner-declarations
      function showUploading() {
        addHiddenClass(formFileDefault);
        addHiddenClass(formFileError);
        addHiddenClass(formFileSuccess);
        removeHiddenClass(formFileUploading);
        formFileUploading.focus();

        disableSubmitButton();
      }

      // eslint-disable-next-line no-inner-declarations
      function successUpload(fileUrl) {
        addHiddenClass(formFileDefault);
        addHiddenClass(formFileError);
        addHiddenClass(formFileUploading);
        removeHiddenClass(formFileSuccess);
        formFileSuccess.focus();
        enableSubmitButton();
        setTempUserFileKey(el, fileUrl);
      }

      // eslint-disable-next-line no-inner-declarations
      function errorUpload(code = SERVER_DATA_VALIDATION_ERRORS.DefaultError) {
        const errorText = formFileErrorMsg.getAttribute(
          adaptAWSErrors(code).toLowerCase()
        );
        addHiddenClass(formFileSuccess);
        addHiddenClass(formFileUploading);
        removeHiddenClass(formFileDefault);
        removeHiddenClass(formFileError);
        if (errorText) {
          formFileErrorMsg.innerHTML = errorText;
        }
        formFileError.focus();
        enableSubmitButton();
      }

      // eslint-disable-next-line no-inner-declarations
      function showDefault() {
        addHiddenClass(formFileSuccess);
        addHiddenClass(formFileUploading);
        addHiddenClass(formFileError);
        removeHiddenClass(formFileDefault);
        fileUploadLabel.focus();
      }

      // eslint-disable-next-line no-inner-declarations
      function changeFileNameText(filename) {
        fileUploadFileName.innerHTML = filename;
      }

      // eslint-disable-next-line no-inner-declarations
      function cancelFile() {
        changeFileNameText('');
        removeTempUserFileKey(el);
        showDefault();
      }

      // eslint-disable-next-line no-inner-declarations
      function deleteFile() {
        setUserFileKey(el, 'DELETE');
        cancelFile();
      }

      handleFileRemoveLink(fileRemoveLink, el, {
        deleteFile,
        cancelFile,
      });

      handleFileUploadInput(el, {
        showUploading,
        successUpload,
        errorUpload,
        changeFileNameText,
        fileRemoveLink,
        filesState,
      });
    }
  });
}

export function handleFields() {
  const userForms = document.querySelectorAll(
    `form[${USYS_DATA_ATTRS.formType}]`
  );

  userForms.forEach((userForm) => {
    const customFieldInputs = userForm.querySelectorAll(
      `input[${USYS_DATA_ATTRS.field}], select[${USYS_DATA_ATTRS.field}]`
    );

    const submitButton = userForm.querySelector('input[type="submit"]');
    submitButton.setAttribute(WF_SUBMIT_BUTTON_VALUE, submitButton.value);

    function disableSubmitButton() {
      if (submitButton) {
        disableSubmit(submitButton);
      }
    }

    function enableSubmitButton() {
      if (submitButton) {
        submitButton.removeAttribute('disabled');
        submitButton.setAttribute(
          'value',
          submitButton.getAttribute(WF_SUBMIT_BUTTON_VALUE) || 'Submit'
        );
      }
    }

    if (customFieldInputs.length > 0) {
      setFieldValidation(customFieldInputs);
      handleFileUploadInputs(customFieldInputs, {
        disableSubmitButton,
        enableSubmitButton,
      });
    }

    const CHECKBOX_CLASS_NAME = 'w-checkbox-input';
    const CHECKED_CLASS = 'w--redirected-checked';

    const customCheckboxes = document.querySelectorAll(
      `form[${USYS_DATA_ATTRS.formType}] input[type="checkbox"]:not(` +
        CHECKBOX_CLASS_NAME +
        ')'
    );

    customCheckboxes.forEach((checkbox) => {
      checkbox.addEventListener('change', function (e) {
        getMatchingSiblings(e, (element) => {
          return element.classList.contains(CHECKBOX_CLASS_NAME);
        }).forEach((sibling) => {
          sibling.classList.toggle(CHECKED_CLASS);
        });
      });
    });
  });
}
