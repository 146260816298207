/* eslint-env browser */

export const getPluginConfig = (actionItemConfig) => {
  return actionItemConfig.value;
};

export const getPluginDuration = (element, actionItem) => {
  if (actionItem.config.duration !== 'auto') {
    return null;
  }

  const duration = parseFloat(element.getAttribute('data-duration'));

  if (duration > 0) {
    return duration * 1000;
  }

  return parseFloat(element.getAttribute('data-default-duration')) * 1000;
};

export const getPluginOrigin = (refState) => {
  return refState || {value: 0};
};

export const getPluginDestination = (actionItemConfig) => {
  return {value: actionItemConfig.value};
};

export const createPluginInstance = (element) => {
  const instance = window.Webflow.require('lottie').createInstance(element);
  instance.stop();
  instance.setSubframe(true);
  return instance;
};

export const renderPlugin = (pluginInstance, refState, actionItem) => {
  if (!pluginInstance) {
    return;
  }
  const percent = refState[actionItem.actionTypeId].value / 100;
  pluginInstance.goToFrame(pluginInstance.frames * percent);
};

export const clearPlugin = (element) => {
  const instance = window.Webflow.require('lottie').createInstance(element);
  instance.stop();
};
