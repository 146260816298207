import escape from 'lodash/escape';
import moment from 'moment-timezone';
import {
  type CurrencySettings,
  renderPriceFromSettings,
} from '@packages/systems/core/utils/CurrencyUtils';
import {formatNumber} from '@packages/systems/dynamo/utils/DynamoFormattingUtils';
import type {BindingTransformer} from '@packages/systems/dynamo/binding-types';

// GraphQL api returns date-times as ISO strings and simple dates as YYYY-MM-DD format
const isSimpleDateFormat = (value: any) =>
  /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/.test(value);

const date = (
  value: any,
  [format]: [any],
  {timezone = 'UTC'}: TransformersContext
) => {
  if (isSimpleDateFormat(value)) {
    timezone = 'UTC';
  }

  const momentDate = moment.utc(value, moment.ISO_8601);

  if (momentDate.isValid()) {
    return momentDate.tz(timezone).format(format);
  } else {
    return '';
  }
};

const detailPage = (
  value: any,
  [collectionIdOrLegacySlug]: [any],
  {collectionSlugMap}: TransformersContext
) => {
  // Falling back to collectionIdOrLegacySlug because in the legacy filters, it's
  // actually the collection slug. If there's a legacy filter, the collection slug
  // rename is not enabled yet for the site so the href is still accurate.
  const collectionSlug =
    collectionSlugMap[collectionIdOrLegacySlug] || collectionIdOrLegacySlug;
  return value ? `/${collectionSlug}/${value}` : null;
};

const style = (value: any, [styleProp]: [any]) => {
  if (styleProp === 'background-image') {
    return value ? `url("${value}")` : 'none';
  }
  return value;
};

const numberPrecision = (value: any, [precision]: [any]) => {
  return formatNumber(value, precision);
};

const rich = (
  value: any,
  params: any,
  {pageLinkHrefPrefix, collectionSlugMap}: TransformersContext
) => {
  if (!value) {
    return null;
  }

  if (typeof value !== 'string') {
    return value;
  }

  return value.replace(/<a\s+[^>]+/g, (linkString) => {
    const isPageLink = /\sdata-rt-link-type="page"/.test(linkString);
    const needsPrefix = pageLinkHrefPrefix && isPageLink;
    const collectionIdMatch =
      isPageLink &&
      /\sdata-rt-link-collectionid="([a-z0-9]{24})"/.exec(linkString);

    if (needsPrefix || collectionIdMatch) {
      return linkString.replace(/(\shref=")([^"]+)/, (match, begin, href) => {
        const end = collectionIdMatch
          ? replaceDetailPageHrefCollectionSlug(
              href,
              // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
              collectionIdMatch[1],
              collectionSlugMap
            )
          : href;
        // Need to escape the prefix here as dynamic RTE is added with dangerouslySetInnerHTML in RichText atom.
        const prefix = pageLinkHrefPrefix ? escape(pageLinkHrefPrefix) : '';
        return `${begin}${prefix}${end}`;
      });
    } else {
      return linkString;
    }
  });
};

// Collection slug may have been renamed so we need to make sure detail
// page links in CMS RichText fields still point to the correct page.
const replaceDetailPageHrefCollectionSlug = (
  href: any,
  collectionId: string,
  collectionSlugMap: {
    [key: string]: string;
  }
) => {
  const [emptyString, originalCollectionSlug, ...rest] = href.split('/');
  const collectionSlug =
    collectionSlugMap[collectionId] || originalCollectionSlug;
  return [emptyString, collectionSlug, ...rest].join('/');
};

const get = (obj: Record<any, any>, key: keyof CommercePricePojo) => {
  if (obj != null && typeof obj.get === 'function') {
    return obj.get(key);
  }
  return obj[key];
};

type CommercePricePojo = {
  value: number;
  unit: string;
  string: string;
};
type CommercePriceImm = {
  get: (value: keyof CommercePricePojo) => any;
};
type CommercePriceAsImmOrPojo = CommercePricePojo & CommercePriceImm;

const price = (
  obj: CommercePriceAsImmOrPojo | undefined,
  params: any,
  context: TransformersContext
) => {
  if (!obj) return null;

  return renderPriceFromSettings(
    {unit: get(obj, 'unit'), value: get(obj, 'value')},
    context.currencySettings
  );
};

type TransformerFn =
  | typeof date
  | typeof detailPage
  | typeof style
  | typeof numberPrecision
  | typeof rich
  | typeof price;

function getTransformerFn(fnKey: string): TransformerFn | null {
  switch (fnKey) {
    case 'date': {
      return date;
    }
    case 'detailPage': {
      return detailPage;
    }
    case 'style': {
      return style;
    }
    case 'numberPrecision': {
      return numberPrecision;
    }
    case 'rich': {
      return rich;
    }
    case 'price': {
      return price;
    }
    default: {
      return null;
    }
  }
}

type TransformersContext = {
  timezone?: string;
  pageLinkHrefPrefix?: string | null;
  collectionSlugMap: {
    [key: string]: string;
  };
  currencySettings?: CurrencySettings;
};

export const transformers = (
  value: unknown,
  filter: BindingTransformer,
  context: TransformersContext
) => {
  const {type: key, params} = filter;
  const fn = getTransformerFn(key);
  return fn ? fn(value, params, context) : value;
};
