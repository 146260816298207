const {create} = Object;

const VALUE: unique symbol = Symbol();

export interface ConstType<C, A> {
  [VALUE]: C;
  map: <B>(f: (x: A) => B) => ConstType<C, B>;
}

const prototype = {
  map() {
    return this;
  },
} as const;

export const Const = <C, A>(value: C): ConstType<C, A> => {
  const object = create(prototype);
  object[VALUE] = value;
  return object;
};

export const of = Const;
export const getConst = <A, C>(con: ConstType<C, A>): C => con[VALUE];
