import {FlowLang} from 'flow-to-typescript-codemod';
import {values} from '@packages/utilities/fp/utils';
import {type UserAccessMeta, USYS_ACCESS_TYPES} from './types';
import {type ExpressionPath} from '@packages/systems/wfdl';
export * from './errorStates';
export * from './types';

export type UsysUtilKeyType = keyof typeof USYS_UTILITY_KEYS;
export type UsysFormType = keyof typeof USYS_FORM_TYPES;
export type MembershipsEmailType = keyof typeof MEMBERSHIPS_EMAIL_KEYS;
export type UsersSetupGuideType = FlowLang.ObjMap<
  typeof SETUP_GUIDE_KEYS,
  () => boolean
>;
export type UserSetupGuideKeys = keyof typeof SETUP_GUIDE_KEYS;
export type UsysUtilKeyMap<V> = FlowLang.ObjMap<
  typeof USYS_UTILITY_KEYS,
  <T>(arg1: T) => V
>;
export type UserStatuses = keyof typeof USER_STATUSES;

export const RESERVED_USER_PREFIX = 'wf-user-field-';

export const RESERVED_USER_FIELDS = {
  name: RESERVED_USER_PREFIX + 'name',
  acceptPrivacy: RESERVED_USER_PREFIX + 'accept-privacy',
  acceptCommunications: RESERVED_USER_PREFIX + 'accept-communications',
} as const;

export const KEY_FROM_RESERVED_USER_FIELD = {
  [RESERVED_USER_PREFIX + 'name']: 'name',
  [RESERVED_USER_PREFIX + 'accept-privacy']: 'acceptPrivacy',
  [RESERVED_USER_PREFIX + 'accept-communications']: 'acceptCommunications',
} as const;

export const NAMES_FROM_USER_FIELDS = {
  [RESERVED_USER_PREFIX + 'name']: 'Name',
  [RESERVED_USER_PREFIX + 'accept-privacy']: 'Accept privacy policy',
  [RESERVED_USER_PREFIX + 'accept-communications']: 'Accept communications',
  PRIVACY_POLICY: 'Accept privacy policy',
  PASSWORD: 'Password',
  EMAIL: 'Email',
} as const;

export const TEXT_INPUT_TYPE_TO_FIELD_TYPE = {
  text: 'PlainText',
  password: 'Password',
  email: 'Email',
  number: 'PlainText',
  tel: 'PlainText',
} as const;

export const USYS_UTILITY_KEYS = {
  'usys-log-in': 'usys-log-in',
  'usys-sign-up': 'usys-sign-up',
  'usys-reset-password': 'usys-reset-password',
  'usys-update-password': 'usys-update-password',
  'usys-access-denied': 'usys-access-denied',
  'usys-user-account': 'usys-user-account',
} as const;

export const USYS_DATA_ATTRS = {
  formType: 'data-wf-user-form-type',
  inputType: 'data-wf-user-form-input-type',
  logout: 'data-wf-user-logout',
  login: 'data-wf-user-login',
  formError: 'data-wf-user-form-error',
  redirectUrl: 'data-wf-user-form-redirect',
  formVerification: 'data-wf-user-form-verification',
  userSubscriptions: 'data-wf-user-subscriptions-list',
  userSubscriptionsEmptyState: 'data-wf-user-subscriptions-empty',
  userAccount: 'data-wf-user-account',
  subscriptionCancel: 'data-wf-user-subscription-cancel',
  userId: 'data-wf-user-id',
  field: 'data-wf-user-field',
  fieldType: 'data-wf-user-field-type',
  fileUploadKey: 'data-wf-user-file-upload-key',
  unsavedFileUploadKey: 'data-wf-unsaved-user-file-upload-key',
} as const;

export const USYS_DOM_CLASS_NAMES = {
  formSuccess: 'w-form-success',
  formVerfication: 'w-form-verification',
  formError: 'w-form-fail',
} as const;

export const USYS_FORM_TYPES = {
  login: 'login',
  signup: 'signup',
  updatePassword: 'updatePassword',
  resetPassword: 'resetPassword',
  account: 'userAccount',
} as const;

export const USYS_INPUT_TYPES = {
  email: 'email',
  name: 'name',
  password: 'password',
  acceptPrivacy: 'accept-privacy',
} as const;

export const USYS_INPUT_SIGN_UP_IDS = {
  email: 'wf-sign-up-email',
  name: 'wf-sign-up-name',
  password: 'wf-sign-up-password',
  acceptPrivacy: 'wf-sign-up-accept-privacy',
  acceptCommunications: 'wf-sign-up-accept-communications',
} as const;

export const USYS_USER_STATES = {
  loggedIn: 'loggedIn',
  loggedOut: 'loggedOut',
} as const;

// Source of truth for all user systems template page settings
export const USYS_PAGE_SETTINGS = {
  login: {
    parent: null,
    sortPos: 0,
    utilKey: 'usys-log-in',
    slug: 'log-in',
    title: 'Log In',
  },
  signup: {
    parent: null,
    sortPos: 1,
    utilKey: 'usys-sign-up',
    slug: 'sign-up',
    title: 'Sign Up',
  },
  resetPassword: {
    parent: null,
    sortPos: 2,
    utilKey: 'usys-reset-password',
    slug: 'reset-password',
    title: 'Reset Password',
  },
  updatePassword: {
    parent: null,
    sortPos: 3,
    utilKey: 'usys-update-password',
    slug: 'update-password',
    title: 'Update Password',
  },
  accessDenied: {
    parent: null,
    sortPos: 4,
    utilKey: 'usys-access-denied',
    slug: 'access-denied',
    title: 'Access Denied',
  },
  userAccount: {
    parent: null,
    sortPos: 5,
    utilKey: 'usys-user-account',
    slug: 'user-account',
    title: 'User Account',
  },
} as const;

export const USYS_RESERVED_SLUGS = values(USYS_PAGE_SETTINGS).map<string>(
  (pageSettings) => pageSettings.slug
);

export const USYS_PAGE_UTIL_KEYS = values(USYS_PAGE_SETTINGS).map<string>(
  (pageSettings) => pageSettings.utilKey
);

// for brand styling in emails
export const DEFAULT_STYLES = {
  accentColor: '#468EE5',
  bgColor: '#F5F6F7',
  includeWfBrand: true,
} as const;

// The length expressed here is in bytes, that means that some characters will
// count as more than 1 byte, like: á, é, í, ó, ú, etc.
export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 72;

// Helper constants.
const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

export const SESSION_COOKIE_NAME = 'wf_sid';
export const LOGGEDIN_COOKIE_NAME = 'wf_loggedin';
export const DEFAULT_SESSION_DURATION_IN_MS = 7 * DAY;
export const DEFAULT_SESSION_TOKEN_DURATION_IN_MS = 4 * HOUR;
export const DEFAULT_TOKEN_AGE_MS = HOUR;

// The maximum number of users that a free site can have. A free site in this
// case means a site that doesn't have a site plan.
export const STARTER_MAX_NUM_USERS = 100;
export const BASIC_MAX_NUM_USERS = 20000;
export const BUSINESS_MAX_NUM_USERS = 20000;
export const ECOMM_STANDARD_MAX_NUM_USERS = 20000;
export const ECOMM_PLUS_MAX_NUM_USERS = 20000;
// In some cases, we might want to have some sites go over their plan limit.
// However, for safety we should always have a hard limit for our system. This
// limit can be increased but should be done gradually as we learn about our
// customers needs.
export const HARD_LIMIT_MAX_NUM_USERS = 100 * 1000;

type SubscriptionUserLimits = {
  free: number;
  static: number;
  cms: number;
  business: number;
  ecommerce_standard: number;
  ecommerce_plus: number;
  ecommerce_advanced: number;
  enterprise: number;
  enterprise_lite: number;
};

// Map user limits to siteSubscription types defined in
// packages/systems/designer/site-data/SiteDataStateType.js
export const SUBSCRIPTION_USER_LIMITS: SubscriptionUserLimits = {
  free: STARTER_MAX_NUM_USERS,
  static: BASIC_MAX_NUM_USERS,
  cms: BUSINESS_MAX_NUM_USERS,
  business: BUSINESS_MAX_NUM_USERS,
  ecommerce_standard: ECOMM_STANDARD_MAX_NUM_USERS,
  ecommerce_plus: ECOMM_PLUS_MAX_NUM_USERS,
  ecommerce_advanced: BUSINESS_MAX_NUM_USERS,
  enterprise: BUSINESS_MAX_NUM_USERS,
  enterprise_lite: BUSINESS_MAX_NUM_USERS,
};

export const MAX_NUM_GROUPS = 20;
export const MIN_GROUP_ID_LENGTH = 2;
export const MAX_GROUP_ID_LENGTH = 2;

export const USYS_TOKEN_TYPES = {
  inviteUser: 'inviteUser',
  resetPassword: 'resetPassword',
  verifyEmail: 'verifyEmail',
} as const;

export const ACCESS_GROUP_INLINE_PRODUCT_FIELD_SLUG =
  'access-group-membership-product';

export const ACCESS_GROUP_ADMISSION_TYPE = {
  free: 'free',
  paid: 'paid',
} as const;

export const ACCESS_GROUP_FREE_TYPE = {
  all: 'all',
  admin: 'admin',
} as const;

// **** Emails **** //

export const SUBSCRIPTION_EMAIL_TYPES = {
  PAYMENT_FAILED: 'paymentFailed',
  PAYMENT_SUCCESSFUL: 'paymentSuccessful',
  SUBSCRIPTION_CANCELED: 'subscriptionCanceled',
  VERIFY_PAYMENT: 'verifyPayment',
} as const;

export const MEMBERSHIPS_EMAIL_KEYS = {
  invite: 'invite',
  resetPassword: 'resetPassword',
  updatedPassword: 'updatedPassword',
  welcome: 'welcome',
  verify: 'verify',
} as const;

export const EMAIL_TEMPLATE_TYPES = {
  invite: 'MEMBERSHIPS_INVITE',
  resetPassword: 'MEMBERSHIPS_RESET_PASSWORD',
  updatedPassword: 'MEMBERSHIPS_UPDATED_PASSWORD',
  verify: 'MEMBERSHIPS_VERIFY',
  welcome: 'MEMBERSHIPS_WELCOME',
} as const;

export const CONFIRM_UNSAVED_CHANGES_COPY = {
  title: 'Continue without saving?',
  content: 'Your changes will be lost.',
  iconType: 'warning',
  submit: {label: 'Continue', intent: 'danger'},
  cancel: {label: 'Cancel', intent: 'default'},
} as const;

// **** User account settings **** //

export const USER_FIELD_FORM_ID = 'UserFieldForm';

export const NEW_USER_FIELD_ID = 'mint-user-field';

export const USER_FIELD_DEFAULTS = {
  PlainText: {
    id: NEW_USER_FIELD_ID,
    name: '',
    slug: '',
    required: false,
    type: 'PlainText',
    validations: {},
  },
  Email: {
    id: NEW_USER_FIELD_ID,
    name: '',
    slug: '',
    required: false,
    type: 'Email',
    validations: {},
  },
  Bool: {
    id: NEW_USER_FIELD_ID,
    name: '',
    slug: '',
    required: false,
    type: 'Bool',
    validations: {},
  },
  FileRef: {
    id: NEW_USER_FIELD_ID,
    name: '',
    slug: '',
    required: false,
    type: 'FileRef',
    validations: {},
  },
  Option: {
    id: NEW_USER_FIELD_ID,
    name: '',
    slug: '',
    required: false,
    type: 'Option',
    validations: {
      options: [],
    },
  },
  Password: {
    id: NEW_USER_FIELD_ID,
    name: 'Password',
    slug: '',
    required: true,
    type: 'Password',
    validations: {},
  },
  Number: {
    id: NEW_USER_FIELD_ID,
    name: '',
    slug: '',
    required: false,
    type: 'Number',
    validations: {
      min: 0,
      step: 1,
    },
  },
  Link: {
    id: NEW_USER_FIELD_ID,
    name: '',
    slug: '',
    required: false,
    type: 'Link',
    validations: {},
  },
} as const;

export const DEFAULT_USER_FIELDS = [
  {
    id: 'name',
    name: 'Name',
    required: false,
    slug: 'name',
    type: 'PlainText',
    validations: {},
  },
  {
    id: 'email',
    name: 'Email',
    required: true,
    slug: 'email',
    type: 'Email',
    validations: {},
  },
  {
    id: 'password',
    name: 'Password',
    required: true,
    slug: 'password',
    type: 'Password',
    validations: {},
  },
  {
    id: 'acceptPrivacy',
    name: 'Accept privacy',
    required: false,
    slug: 'accept-privacy',
    type: 'Bool',
    validations: {},
  },
  {
    id: 'acceptCommunications',
    name: 'Accept communications',
    required: false,
    slug: 'accept-communications',
    type: 'Bool',
    validations: {},
  },
] as const;

// Contains the keys that usysSettings stores
export const SETUP_GUIDE_KEYS = {
  hasVisitedAccessDeniedPage: 'hasVisitedAccessDeniedPage',
  hasVisitedLoginPage: 'hasVisitedLoginPage',
  hasVisitedSignUpPage: 'hasVisitedSignUpPage',
  hasVisitedUserAccountSettings: 'hasVisitedUserAccountSettings',
  hasVisitedUserAccountPage: 'hasVisitedUserAccountPage',
} as const;

// Contains all the setup guide keys, server and frontend-only
export const SETUP_GUIDE_ALL_KEYS = {
  ...SETUP_GUIDE_KEYS,
  hasHostingPlan: 'hasHostingPlan',
  hasEcommerce: 'hasEcommerce',
  hasEnabledSSL: 'hasEnabledSSL',
  hasUsers: 'hasUsers',
  hasAccessGroups: 'hasAccessGroups',
  hasRestrictedContent: 'hasRestrictedContent',
  hasRestrictedProducts: 'hasRestrictedProducts',
} as const;

export const MAX_USER_DATA_FIELDS = 20;

// `name` and `accept-communications` common fields can also be updated
// along with custom fields currently
export const MAX_UPDATE_USER_DATA_FIELDS = MAX_USER_DATA_FIELDS + 2;

export const USYS_FIELD_PATH: ExpressionPath = [
  {in: 'Record', at: 'users'},
  {in: 'Record', at: 'field'},
];

export const USYS_CONTEXT_PATH: ExpressionPath = [
  {in: 'Record', at: 'users'},
  {in: 'Record', at: 'context'},
];

const TEMP_PATH: ExpressionPath = [{in: 'Record', at: 'temp'}];
export const TEMP_STATE_PATH: ExpressionPath = [
  ...TEMP_PATH,
  {in: 'Record', at: 'state'},
];

export const USER_ACCESS_META_OPTIONS: UserAccessMeta = [
  USYS_ACCESS_TYPES.LOGGED_IN,
];

export const EXCEEDS_MAX_FILE_SIZE_ERROR =
  'Maximum size allowed for a file upload is 10000kb / 10mb.';
export const EXCEEDS_MAX_IMAGE_SIZE_ERROR =
  'Maximum size allowed for a image upload is 4000kb / 4mb.';
export const NO_REQUIRED_ATTRIBUTE = ['FileRef', 'Bool'] as const;

export const USER_STATUSES = {
  invited: 'Invited',
  verified: 'Verified',
  unverified: 'Unverified',
} as const;

export const USER_PAGE_SIZE = 100;

// User CSV Import constants
const USER_CSV_IMPORT_STATUS_POLLING_TIMEOUT = 20 * 60 * 1000;
export const USER_CSV_IMPORT_STATUS_POLLING_INTERVAL = 5000;
export const USER_CSV_IMPORT_STATUS_MAX_TRIES = Math.floor(
  USER_CSV_IMPORT_STATUS_POLLING_TIMEOUT /
    USER_CSV_IMPORT_STATUS_POLLING_INTERVAL
);
