/*
  globals
  document,
  HTMLFormElement,
  HTMLInputElement
*/

import {
  userSystemsRequestClient,
  showAndFocusElement,
  hideElement,
  handleErrorNode,
} from './utils';
import {
  USYS_DATA_ATTRS,
  USYS_DOM_CLASS_NAMES,
  USYS_FORM_TYPES,
  USYS_INPUT_TYPES,
  RESET_PASSWORD_UI_ERROR_CODES,
  resetPasswordErrorStates,
  ERROR_MSG_CLASS,
  ERROR_ATTRIBUTE_PREFIX,
} from '@packages/systems/users/constants';
import {resetPasswordMutation} from './mutations';

const resetPasswordFormQuerySelector = `form[${USYS_DATA_ATTRS.formType}="${USYS_FORM_TYPES.resetPassword}"]`;

// error handling
const errorState = document.querySelector(`[${USYS_DATA_ATTRS.formError}]`);
const defaultErrorCopy =
  // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
  resetPasswordErrorStates[RESET_PASSWORD_UI_ERROR_CODES.GENERAL_ERROR].copy;
const errorMsgNode = document.querySelector(`.${ERROR_MSG_CLASS}`);

const getResetPasswordErrorCode = (error: string) => {
  // right now we only have one error, this is for when add more
  let errorCode;
  switch (error) {
    default:
      errorCode = RESET_PASSWORD_UI_ERROR_CODES.GENERAL_ERROR;
  }
  return errorCode;
};

function getResetPasswordForms() {
  const resetPasswordForms = document.querySelectorAll(
    resetPasswordFormQuerySelector
  );
  return Array.prototype.slice
    .call(resetPasswordForms)
    .filter(
      (resetPasswordForm) => resetPasswordForm instanceof HTMLFormElement
    );
}

export function handleResetPasswordForms() {
  getResetPasswordForms().forEach((resetPasswordForm) => {
    resetPasswordForm.addEventListener('submit', (event: Event) => {
      event.preventDefault();

      const form = event.currentTarget;
      const successMessage = document.querySelector(
        `.${USYS_DOM_CLASS_NAMES.formSuccess}`
      );

      if (!(form instanceof HTMLFormElement)) {
        return;
      }

      // @ts-expect-error - TS2345 - Argument of type 'Element | null' is not assignable to parameter of type 'HTMLElement | null | undefined'.
      hideElement(errorState);

      const emailInput = form.querySelector(
        `input[${USYS_DATA_ATTRS.inputType}="${USYS_INPUT_TYPES.email}"]`
      );

      if (!(emailInput instanceof HTMLInputElement)) {
        return;
      }

      asyncRequestResetPassword(emailInput.value)
        .then(() => {
          hideElement(form);
          // @ts-expect-error - TS2345 - Argument of type 'Element | null' is not assignable to parameter of type 'HTMLElement | null | undefined'.
          showAndFocusElement(successMessage);
        })
        .catch((error) => {
          if (errorState) {
            // if there isn't an error code, send an empty string so a generic error message appears
            const elementErrorCode = error?.graphQLErrors?.[0]?.code ?? '';
            const errorCode = getResetPasswordErrorCode(elementErrorCode);
            handleErrorNode(
              errorMsgNode,
              // @ts-expect-error - TS2345 - Argument of type 'Element' is not assignable to parameter of type 'HTMLElement'.
              errorState,
              errorCode,
              ERROR_ATTRIBUTE_PREFIX.RESET_PASSWORD,
              defaultErrorCopy
            );
          }
        });
    });
  });
}

export function asyncRequestResetPassword(email: string) {
  return userSystemsRequestClient.mutate({
    mutation: resetPasswordMutation,
    variables: {
      email,
    },
  });
}
