/* globals window */
import {
  NODE_TYPE_COMMERCE_CART_ERROR,
  NODE_TYPE_COMMERCE_CART_CONTAINER_WRAPPER,
  CART_ERRORS,
  CART_ERROR_MESSAGE_SELECTOR,
  getCheckoutErrorMessageForType,
  NEEDS_REFRESH,
} from '@packages/systems/commerce/constants';
import {findElementByNodeType} from './commerceUtils';

export const isCartOpen = () => {
  const cartContainerEl = findElementByNodeType(
    NODE_TYPE_COMMERCE_CART_CONTAINER_WRAPPER
  );

  if (!cartContainerEl) return false;

  return window.getComputedStyle(cartContainerEl).display !== 'none';
};

const updateErrorMessage = (element: Element, error: Record<any, any>) => {
  const errorText = element.querySelector(CART_ERROR_MESSAGE_SELECTOR);
  if (!errorText) return;

  const errorType = getErrorType(error);

  // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly QUANTITY: { readonly id: "QUANTITY_ERROR"; readonly name: "Quantity not available"; readonly category: { readonly id: "GENERAL"; readonly label: "General Errors"; }; readonly copy: "Product is not available in this quantity."; readonly path: readonly [...]; }; readonly GENERAL: { ...; }; readonly CHECKOUT...'.
  const errorData = CART_ERRORS[errorType.toUpperCase()] || {};
  const defaultErrorMessage = errorData.msg;

  const errorMessage =
    errorText.getAttribute(getCheckoutErrorMessageForType(errorType)) ||
    defaultErrorMessage;

  errorText.textContent = errorMessage;

  if (errorData.requiresRefresh) {
    errorText.setAttribute(NEEDS_REFRESH, 'true');
  } else {
    errorText.removeAttribute(NEEDS_REFRESH);
  }
};

const errorCodeToCartErrorType = (code?: string, msg?: string): string => {
  switch (code) {
    case 'OrderTotalRange': {
      if (msg && msg.match(/too small/i)) {
        return 'cart_order_min';
      }

      return 'general';
    }

    default:
      return 'general';
  }
};

const getErrorType = (error: Record<any, any>) => {
  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    return errorCodeToCartErrorType(
      error.graphQLErrors[0].code,
      error.graphQLErrors[0].message
    );
  }

  if (error.code) {
    return errorCodeToCartErrorType(error.code, error.message);
  }

  return 'general';
};

export const showErrorMessageForError = (
  err: Record<any, any>,
  scope?: Element | Document
) => {
  const cartErrorState = findElementByNodeType(
    NODE_TYPE_COMMERCE_CART_ERROR,
    scope
  );
  if (cartErrorState) {
    cartErrorState.style.removeProperty('display');
    updateErrorMessage(cartErrorState, err);
  }
};
