// Note: These currencies are extracted by getting Stripe compatible currencies with:
//
// curl https://api.stripe.com/v1/country_specs\?limit\=100 -u $STRIPE_TEST_KEY: -G \
//   | jq '.data|.[]|.supported_payment_currencies|.[]' \
//   | sort \
//   | uniq
//
// And then populating in the names from ISO 4217:
//
// Digit counts are also added. Stripe is treated as the source-of-truth for that number, even though their
// numbers are often at odds with ISO 4217. Notes:
//
//  - CVE had a subunit (centavo), that has been discontinued. The ISO reflects this, but stripe sticks
//    with 2 digits, since the currency is still commonly formatted as "1$00". ¯\_(ツ)_/¯
//  - ISK had a subunit (eyrir) that was obsoleted in 2003, but Stripe sticks with 2 digits instead of
//    the ISO's 0.
//  - MGA is strange, since its smallest denomination is a 1/5th piece (the Iraimbilanja), but is
//    represented as a decimal, so the currency goes "1.3, 1.4, 2.0, ...". Stripe dodges this strangeness
//    by ignoring that minimum unit, and so do we, since it has so little value.
//  - UGX had a subunit (cent) that was discontinued in 2013. Stripe still counts it, tho, even though
//    that cent is work 1/350000th of a penny.
export const stripeCurrencyList = [
  /*
   * ----------------------------------------------------------------------------
   * NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE
   * NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE
   * NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE
   * NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE
   *
   * This const is duplicated to @packages/systems/core/constants/SharedConfig.js
   * to avoid bundling this entire SharedConfig file in the webflow.js published
   * site bundle. Once we have support for bundling for the Dashboard (this
   * config is injected in to window.SharedConfig for angular), we should
   * be able to delete the const in SharedConfig and use the commerce/consts
   * package whenever `stripeCurrencyList` const is needed.
   *
   * In the meantime, if you make changes to this const, please make sure to
   * update in the other location as well.
   *
   * NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE
   * NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE
   * NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE
   * NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE
   * ----------------------------------------------------------------------------
   */
  {code: 'AED', digits: 2, minCharge: 0, name: 'United Arab Emirates Dirham'},
  {code: 'AFN', digits: 2, minCharge: 0, name: 'Afghanistan Afghani'},
  {code: 'ALL', digits: 2, minCharge: 0, name: 'Albanian Lek'},
  {code: 'AMD', digits: 2, minCharge: 0, name: 'Armenia Dram'},
  {
    code: 'ANG',
    digits: 2,
    minCharge: 0,
    name: 'Netherlands Antillean Gulden',
  },
  {code: 'AOA', digits: 2, minCharge: 0, name: 'Angola Kwanza'},
  {code: 'ARS', digits: 2, minCharge: 0, name: 'Argentine Peso'},
  {code: 'AUD', digits: 2, minCharge: 50, name: 'Australian Dollar'},
  {code: 'AWG', digits: 2, minCharge: 0, name: 'Aruban Florin'},
  {code: 'AZN', digits: 2, minCharge: 0, name: 'Azerbaijan Manat'},
  {
    code: 'BAM',
    digits: 2,
    minCharge: 0,
    name: 'Bosnia and Herzegovina Convertible Marka',
  },
  {code: 'BBD', digits: 2, minCharge: 0, name: 'Barbadian Dollar'},
  {code: 'BDT', digits: 2, minCharge: 0, name: 'Bangladeshi Taka'},
  {code: 'BGN', digits: 2, minCharge: 0, name: 'Bulgaria Lev'},
  {code: 'BIF', digits: 0, minCharge: 0, name: 'Burundian Franc'},
  {code: 'BMD', digits: 2, minCharge: 0, name: 'Bermudian Dollar'},
  {code: 'BND', digits: 2, minCharge: 0, name: 'Brunei Dollar'},
  {code: 'BOB', digits: 2, minCharge: 0, name: 'Bolivian Boliviano'},
  {code: 'BRL', digits: 2, minCharge: 50, name: 'Brazilian Real'},
  {code: 'BSD', digits: 2, minCharge: 0, name: 'Bahamian Dollar'},
  {code: 'BWP', digits: 2, minCharge: 0, name: 'Botswana Pula'},
  {code: 'BZD', digits: 2, minCharge: 0, name: 'Belize Dollar'},
  {code: 'CAD', digits: 2, minCharge: 50, name: 'Canadian Dollar'},
  {code: 'CDF', digits: 2, minCharge: 0, name: 'Congo/Kinshasa Franc'},
  {code: 'CHF', digits: 2, minCharge: 50, name: 'Swiss Franc'},
  {code: 'CLP', digits: 0, minCharge: 0, name: 'Chilean Peso'},
  {code: 'CNY', digits: 2, minCharge: 0, name: 'Chinese Renminbi Yuan'},
  {code: 'COP', digits: 2, minCharge: 0, name: 'Colombian Peso'},
  {code: 'CRC', digits: 2, minCharge: 0, name: 'Costa Rican Colón'},
  {code: 'CVE', digits: 2, minCharge: 0, name: 'Cape Verdean Escudo'}, // See above.
  {code: 'CZK', digits: 2, minCharge: 0, name: 'Czech Koruna'},
  {code: 'DJF', digits: 0, minCharge: 0, name: 'Djiboutian Franc'},
  {code: 'DKK', digits: 2, minCharge: 250, name: 'Danish Krone'},
  {code: 'DOP', digits: 2, minCharge: 0, name: 'Dominican Peso'},
  {code: 'DZD', digits: 2, minCharge: 0, name: 'Algerian Dinar'},
  {code: 'EGP', digits: 2, minCharge: 0, name: 'Egyptian Pound'},
  {code: 'ETB', digits: 2, minCharge: 0, name: 'Ethiopian Birr'},
  {code: 'EUR', digits: 2, minCharge: 50, name: 'Euro'},
  {code: 'FJD', digits: 2, minCharge: 0, name: 'Fijian Dollar'},
  {code: 'FKP', digits: 2, minCharge: 0, name: 'Falkland Islands Pound'},
  {code: 'GBP', digits: 2, minCharge: 30, name: 'British Pound'},
  {code: 'GEL', digits: 2, minCharge: 0, name: 'Georgia Lari'},
  {code: 'GIP', digits: 2, minCharge: 0, name: 'Gibraltar Pound'},
  {code: 'GMD', digits: 2, minCharge: 0, name: 'Gambian Dalasi'},
  {code: 'GNF', digits: 0, minCharge: 0, name: 'Guinean Franc'},
  {code: 'GTQ', digits: 2, minCharge: 0, name: 'Guatemalan Quetzal'},
  {code: 'GYD', digits: 2, minCharge: 0, name: 'Guyanese Dollar'},
  {code: 'HKD', digits: 2, minCharge: 400, name: 'Hong Kong Dollar'},
  {code: 'HNL', digits: 2, minCharge: 0, name: 'Honduran Lempira'},
  {code: 'HRK', digits: 2, minCharge: 0, name: 'Croatian Kuna'},
  {code: 'HTG', digits: 2, minCharge: 0, name: 'Haitian Gourde'},
  {code: 'HUF', digits: 2, minCharge: 0, name: 'Hungarian Forint'},
  {code: 'IDR', digits: 2, minCharge: 0, name: 'Indonesian Rupiah'},
  {code: 'ILS', digits: 2, minCharge: 0, name: 'Israeli New Sheqel'},
  {code: 'INR', digits: 2, minCharge: 50, name: 'Indian Rupee'},
  {code: 'ISK', digits: 2, minCharge: 0, name: 'Icelandic Króna'}, // See above.
  {code: 'JMD', digits: 2, minCharge: 0, name: 'Jamaican Dollar'},
  {code: 'JPY', digits: 0, minCharge: 50, name: 'Japanese Yen'},
  {code: 'KES', digits: 2, minCharge: 0, name: 'Kenyan Shilling'},
  {code: 'KGS', digits: 2, minCharge: 0, name: 'Kyrgyzstan Som'},
  {code: 'KHR', digits: 2, minCharge: 0, name: 'Cambodian Riel'},
  {code: 'KMF', digits: 0, minCharge: 0, name: 'Comorian Franc'},
  {code: 'KRW', digits: 0, minCharge: 0, name: 'South Korean Won'},
  {code: 'KYD', digits: 2, minCharge: 0, name: 'Cayman Islands Dollar'},
  {code: 'KZT', digits: 2, minCharge: 0, name: 'Kazakhstani Tenge'},
  {code: 'LAK', digits: 2, minCharge: 0, name: 'Lao Kip'},
  {code: 'LBP', digits: 2, minCharge: 0, name: 'Lebanese Pound'},
  {code: 'LKR', digits: 2, minCharge: 0, name: 'Sri Lankan Rupee'},
  {code: 'LRD', digits: 2, minCharge: 0, name: 'Liberian Dollar'},
  {code: 'LSL', digits: 2, minCharge: 0, name: 'Lesotho Loti'},
  {code: 'MAD', digits: 2, minCharge: 0, name: 'Moroccan Dirham'},
  {code: 'MDL', digits: 2, minCharge: 0, name: 'Moldovan Leu'},
  {code: 'MGA', digits: 0, minCharge: 0, name: 'Madagascar Ariary'}, // See above.
  {code: 'MKD', digits: 2, minCharge: 0, name: 'Macedonia Denar'},
  {code: 'MMK', digits: 2, minCharge: 0, name: 'Myanmar (Burma) Kyat'},
  {code: 'MNT', digits: 2, minCharge: 0, name: 'Mongolian Tögrög'},
  {code: 'MOP', digits: 2, minCharge: 0, name: 'Macanese Pataca'},
  {code: 'MRO', digits: 2, minCharge: 0, name: 'Mauritanian Ouguiya'},
  {code: 'MUR', digits: 2, minCharge: 0, name: 'Mauritian Rupee'},
  {code: 'MVR', digits: 2, minCharge: 0, name: 'Maldivian Rufiyaa'},
  {code: 'MWK', digits: 2, minCharge: 0, name: 'Malawian Kwacha'},
  {code: 'MXN', digits: 2, minCharge: 1000, name: 'Mexican Peso'},
  {code: 'MYR', digits: 2, minCharge: 200, name: 'Malaysian Ringgit'},
  {code: 'MZN', digits: 2, minCharge: 0, name: 'Mozambique Metical'},
  {code: 'NAD', digits: 2, minCharge: 0, name: 'Namibian Dollar'},
  {code: 'NGN', digits: 2, minCharge: 0, name: 'Nigerian Naira'},
  {code: 'NIO', digits: 2, minCharge: 0, name: 'Nicaraguan Córdoba'},
  {code: 'NOK', digits: 2, minCharge: 300, name: 'Norwegian Krone'},
  {code: 'NPR', digits: 2, minCharge: 0, name: 'Nepalese Rupee'},
  {code: 'NZD', digits: 2, minCharge: 50, name: 'New Zealand Dollar'},
  {code: 'PAB', digits: 2, minCharge: 0, name: 'Panamanian Balboa'},
  {code: 'PEN', digits: 2, minCharge: 0, name: 'Peruvian Nuevo Sol'},
  {code: 'PGK', digits: 2, minCharge: 0, name: 'Papua New Guinean Kina'},
  {code: 'PHP', digits: 2, minCharge: 0, name: 'Philippine Peso'},
  {code: 'PKR', digits: 2, minCharge: 0, name: 'Pakistani Rupee'},
  {code: 'PLN', digits: 2, minCharge: 200, name: 'Polish Złoty'},
  {code: 'PYG', digits: 0, minCharge: 0, name: 'Paraguayan Guaraní'},
  {code: 'QAR', digits: 2, minCharge: 0, name: 'Qatari Riyal'},
  {code: 'RON', digits: 2, minCharge: 0, name: 'Romania Leu'},
  {code: 'RSD', digits: 2, minCharge: 0, name: 'Serbia Dinar'},
  {code: 'RUB', digits: 2, minCharge: 0, name: 'Russian Ruble'},
  {code: 'RWF', digits: 0, minCharge: 0, name: 'Rwanda Franc'},
  {code: 'SAR', digits: 2, minCharge: 0, name: 'Saudi Riyal'},
  {code: 'SBD', digits: 2, minCharge: 0, name: 'Solomon Islands Dollar'},
  {code: 'SCR', digits: 2, minCharge: 0, name: 'Seychellois Rupee'},
  {code: 'SEK', digits: 2, minCharge: 300, name: 'Swedish Krona'},
  {code: 'SGD', digits: 2, minCharge: 50, name: 'Singapore Dollar'},
  {code: 'SHP', digits: 2, minCharge: 0, name: 'Saint Helenian Pound'},
  {code: 'SLL', digits: 2, minCharge: 0, name: 'Sierra Leonean Leone'},
  {code: 'SOS', digits: 2, minCharge: 0, name: 'Somali Shilling'},
  {code: 'SRD', digits: 2, minCharge: 0, name: 'Suriname Dollar'},
  {code: 'STD', digits: 2, minCharge: 0, name: 'São Tomé and Príncipe Dobra'},
  {code: 'SZL', digits: 2, minCharge: 0, name: 'Swazi Lilangeni'},
  {code: 'THB', digits: 2, minCharge: 0, name: 'Thai Baht'},
  {code: 'TJS', digits: 2, minCharge: 0, name: 'Tajikistan Somoni'},
  {code: 'TOP', digits: 2, minCharge: 0, name: 'Tongan Paʻanga'},
  {code: 'TRY', digits: 2, minCharge: 0, name: 'Turkey Lira'},
  {code: 'TTD', digits: 2, minCharge: 0, name: 'Trinidad and Tobago Dollar'},
  {code: 'TWD', digits: 2, minCharge: 0, name: 'New Taiwan Dollar'},
  {code: 'TZS', digits: 2, minCharge: 0, name: 'Tanzanian Shilling'},
  {code: 'UAH', digits: 2, minCharge: 0, name: 'Ukrainian Hryvnia'},
  {code: 'UGX', digits: 0, minCharge: 0, name: 'Ugandan Shilling'}, // See above.
  {code: 'USD', digits: 2, minCharge: 50, name: 'United States Dollar'},
  {code: 'UYU', digits: 2, minCharge: 0, name: 'Uruguayan Peso'},
  {code: 'UZS', digits: 2, minCharge: 0, name: 'Uzbekistani Som'},
  {code: 'VND', digits: 0, minCharge: 0, name: 'Vietnamese Đồng'},
  {code: 'VUV', digits: 0, minCharge: 0, name: 'Vanuatu Vatu'},
  {code: 'WST', digits: 2, minCharge: 0, name: 'Samoan Tala'},
  {code: 'XAF', digits: 0, minCharge: 0, name: 'Central African Cfa Franc'},
  {code: 'XCD', digits: 2, minCharge: 0, name: 'East Caribbean Dollar'},
  {code: 'XOF', digits: 0, minCharge: 0, name: 'West African Cfa Franc'},
  {code: 'XPF', digits: 0, minCharge: 0, name: 'Cfp Franc'},
  {code: 'YER', digits: 2, minCharge: 0, name: 'Yemeni Rial'},
  {code: 'ZAR', digits: 2, minCharge: 0, name: 'South African Rand'},
  {code: 'ZMW', digits: 2, minCharge: 0, name: 'Zambia Kwacha'},
] as const;
