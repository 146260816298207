// Added a Flow type here since
// our bundle builder when stripping Flow types
// uses a newer version of flow-parser (0.203)
// which requires a variable declaration to have
// a type
// eslint-disable-next-line no-var
declare var process: any;

export default {
  log: (...args: Array<unknown>) => {
    if (process.env.NODE_ENV === 'development') {
      console.log(...args);
    }
  },
  error: (...args: Array<unknown>) => {
    if (process.env.NODE_ENV === 'development') {
      console.log(...args);
    }
  },
};
