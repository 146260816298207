import moment from 'moment-timezone';

import {
  getItemRefSlug,
  getValueFieldSlug,
} from '@packages/systems/dynamo/utils/ParamFieldPathUtils';
import type {
  ConditionWithTypeT,
  ConditionsWithTypeT,
} from '@packages/systems/dynamo/types';
import {normalizeConditionFields} from '@packages/systems/dynamo/utils/FilterUtils';

/**
 * Contains static utilities to deal with Dynamo conditional visibility for DOM nodes.
 */

export const EXAMPLE_IMG_URL =
  'https://d3e54v103j8qbb.cloudfront.net/img/image-placeholder.svg';

const OPERATOR_FNS = {
  eq: function (a: unknown, b: unknown) {
    return a == b; // eslint-disable-line eqeqeq
  },
  ne: function (a: unknown, b: unknown) {
    return a != b; // eslint-disable-line eqeqeq
  },
  gt: function (a: any, b: any) {
    return a > b;
  },
  lt: function (a: any, b: any): boolean {
    return a < b;
  },
  gte: function (a: any, b: any) {
    return a >= b;
  },
  lte: function (a: any, b: any) {
    return a <= b;
  },
  exists: function (a: unknown, b: unknown) {
    function getATruthiness() {
      if (a != null) {
        if (Array.isArray(a)) {
          return a.length > 0;
        } else if (typeof a === 'object') {
          return !('url' in a) || a.url !== EXAMPLE_IMG_URL;
        } else if (typeof a === 'number') {
          return !Number.isNaN(a);
        } else {
          return true;
        }
      } else {
        return false;
      }
    }

    function getBTruthiness() {
      return b === 'yes';
    }

    const aIsTruthy = getATruthiness();
    const bIsTruthy = getBTruthiness();

    return aIsTruthy === bIsTruthy;
  },
  idin: function (a: unknown, b: unknown) {
    return containsResolver(a, b);
  },
  idnin: function (a: unknown, b: unknown) {
    return !containsResolver(a, b);
  },
  type: false, // ensure the `type` property will never resolve to a function
} as const;

const containsResolver = (a: unknown, b: unknown) => {
  if (Array.isArray(a) && typeof b === 'string') {
    // A contains B
    return a.includes(b);
  }
  if (Array.isArray(a) && Array.isArray(b)) {
    // A contains any of B
    return b.some((id) => a.includes(id));
  }
  if (typeof a === 'string' && Array.isArray(b)) {
    // A equals any of B
    return b.includes(a);
  }
  return false;
};

type ItemDataT = {
  [key: string]: any;
};

export function test(
  itemData: ItemDataT,
  conditionData: {
    fields?: ItemDataT | ConditionsWithTypeT;
  },
  timezone?: string
) {
  const conditionFields = normalizeConditionFields(conditionData.fields);

  // We use for..of to exit as early as possible
  for (const conditionField of conditionFields) {
    const result = testSingleCondition({
      conditionField,
      itemData,
      timezone,
    });

    // if a condition does not pass, we return false as quickly as possible
    if (!result) {
      return false;
    }
  }

  return true;
}

function testSingleCondition({
  conditionField,
  itemData,
  timezone,
}: {
  conditionField: ConditionWithTypeT;
  itemData: ItemDataT;
  timezone?: string;
}) {
  const {fieldPath, operatorName, value, type} = conditionField;
  const opFn = OPERATOR_FNS[operatorName as keyof typeof OPERATOR_FNS];

  if (!opFn) {
    console.warn(`Ignoring unsupported condition operator: ${operatorName}`);
    // The condition "passes" because there is no operation to test
    return true;
  }

  /*
    The renderer send out item data in different forms.
    Here, we first try to retrive the item field value in the current format,
    if that fails, we fall back to the legacy format.

    The main difference is in how item references are treated in itemData
    Current: { 'author:name': 'Author Name' }
    Legacy: { author: { name: 'Author Name } }
  */
  const itemFieldValue = itemData.hasOwnProperty(fieldPath)
    ? itemData[fieldPath]
    : getItemFieldValue(itemData, fieldPath);

  // if field type is available in the condition, we use it to determine the item field type
  // otherwise we use the field value to determine item field type
  const itemFieldType = type
    ? convertFieldTypeToLegacyItemType(type)
    : _getLegacyItemType(fieldPath, itemFieldValue);

  const resolvedFieldValue = castItemFieldValue(itemFieldValue, itemFieldType);
  const resolvedConditionValue = castConditionValue(
    value,
    operatorName,
    itemFieldType,
    timezone
  );

  return opFn(resolvedFieldValue, resolvedConditionValue);
}

export function castItemValue({
  operator,
  value,
  type,
  timezone,
}: {
  operator?: string;
  value: any;
  timezone?: string;
  type: string;
}) {
  if (value !== undefined) {
    switch (type) {
      case 'Bool':
        return (function () {
          if (typeof value === 'boolean') {
            return value;
          } else if (typeof value === 'string') {
            // Yes. Some sites have "True"
            return value.toLowerCase() === 'true';
          } else {
            return Boolean(value);
          }
        })();
      case 'Number':
        return parseFloat(value);
      case 'Date':
        return parseDate({operator, value, timezone});
      default:
        return value;
    }
  } else {
    return value;
  }
}

export function castConditionValue(
  value: any,
  op: string,
  type: string,
  timezone?: string
) {
  if (op === 'exists') {
    return value;
  } else {
    return castItemValue({operator: op, timezone, type, value});
  }
}

// TODO: swap with imported constant once OperatorTypes is lifted to dynamo package
// tech debt tracked in: https://github.com/webflow/webflow/issues/29496
const OPERATOR_LTE_NAME = 'lte';

// RegEx Patterns required for `parseDate`
///////////////////////////////////////////////

const NOW_REGEX = /^now$/i;

// The END_OF_TODAY_REGEX pattern collects up to two groups of matches
// Group 1: if the string begins with "end of "
// Group 2: if the string contains "today"
const END_OF_TODAY_REGEX = /^(end of )?(today)$/i;

// The DEPRECATED_END_OF_TOMORROW_YESTERDAY_REGEX pattern is to handle parsing older date filters
// that depended up on an "excluding today" boolean. It collects two groups
// Group 1: if the string begins with "end of "
// Group 2: if the string contains "tomorrow" or "yesterday"
const DEPRECATED_END_OF_TOMORROW_YESTERDAY_REGEX =
  /^(end of )?(tomorrow|yesterday)$/i;

// The DEPRECATED_RELATIVE_TIME_COMPLEX_REGEX pattern is designed to handle parsing older complex date filters
// It captures 4 groups
// Group 1: A string combining time lengths and time intervals
//   Example: 2 days 17 hours 4 minutes
// Group 2: A string containing either "ago" or "from now"
// Group 3: A string containing "starting" with either "now" or "end of"
// Group 4: A string of either "today", "yesterday" or "tomorrow"
const DEPRECATED_RELATIVE_TIME_COMPLEX_REGEX =
  /^((?:\d+ (?:year|quarter|month|week|day|hour|minute|second)s? )+)(ago|from now)(?: (?:starting (?:now|(?:(end of )?(today|yesterday|tomorrow)))))?$/i;

// The RELATIVE_TIME_COMPLEX_REGEX pattern collects two groups of matches
// Group 1: A string combining time lengths and time intervals.
//   Example: 2 days 17 hours 4 minutes
// Group 2: a string of either 'future' or 'past'
const RELATIVE_TIME_COMPLEX_REGEX =
  /^((?:\d+ (?:year|quarter|month|week|day|hour|minute|second)s? )+)in the (future|past)$/i;

// The FULL_TIME_LENGTH_INTERVAL_STRING_REGEX pattern collects all the matches found in
// Group 1 of RELATIVE_TIME_COMPLEX_REGEX and returns them as an array
//   Example: '2 days 17 hours 4 minutes' returns a matches array
//   of ['2 days', '17 hours', '4 minutes']
const FULL_TIME_LENGTH_INTERVAL_STRING_REGEX =
  /\d+ (?:year|quarter|month|week|day|hour|minute|second)s?/gi;

const isDeprecatedDatePattern = (str: string): boolean =>
  DEPRECATED_END_OF_TOMORROW_YESTERDAY_REGEX.test(str) ||
  DEPRECATED_RELATIVE_TIME_COMPLEX_REGEX.test(str);

function handleDeprecatedParseDate({
  value,
  timezone,
  momentNowUtc,
}: {
  value: string;
  timezone: string;
  momentNowUtc: any; // a moment instance;
}): Date | null | undefined {
  function getToday() {
    return momentNowUtc.tz(timezone).startOf('day');
  }

  function getEndOfToday() {
    return momentNowUtc.tz(timezone).endOf('day');
  }

  function getNow() {
    return momentNowUtc.tz(timezone);
  }

  const simpleResults = value.match(DEPRECATED_END_OF_TOMORROW_YESTERDAY_REGEX);

  if (simpleResults) {
    // handle deprecated end of tomorrow/yesterday scenarios
    const [, endOf, relativeDate] = simpleResults;
    const getStart = endOf ? getEndOfToday : getToday;

    if (relativeDate === 'tomorrow') {
      return getStart().add(1, 'day').toDate();
    }

    if (relativeDate === 'yesterday') {
      return getStart().subtract(1, 'day').toDate();
    }
  }

  const complexResults = value.match(DEPRECATED_RELATIVE_TIME_COMPLEX_REGEX);

  if (complexResults) {
    // handle deprecated complex results
    const [, values, mode, endOf, relativeDate] = complexResults;
    const getStart = endOf ? getEndOfToday : getToday;

    let time: moment.Moment;
    switch (relativeDate) {
      case 'today':
        time = getStart();
        break;

      case 'tomorrow':
        time = getStart().add(1, 'day');
        break;

      case 'yesterday':
        time = getStart().subtract(1, 'day');
        break;

      default:
        time = getNow();
        break;
    }

    // @ts-expect-error - TS18048 - 'values' is possibly 'undefined'.
    const timeLengthIntervalItems = values.match(
      FULL_TIME_LENGTH_INTERVAL_STRING_REGEX
    );

    if (!timeLengthIntervalItems) {
      return null;
    }

    const method = mode === 'from now' ? 'add' : 'subtract';

    // Loop through each length-interval item, ex '14 days', and mutate `time` with each one
    timeLengthIntervalItems.forEach((item) => {
      const [length, interval] = item.split(' ');

      // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
      time[method](parseInt(length, 10), interval as moment.DurationInputArg2);
    });

    return time.toDate();
  }
}

export function parseDate({
  operator,
  value,
  timezone = 'UTC',
  nowUtcString,
}: {
  operator?: string;
  value: any;
  timezone?: string;
  nowUtcString?: string;
}): Date | null | undefined {
  const momentNowUtc = nowUtcString ? moment.utc(nowUtcString) : moment.utc();

  function getToday() {
    return momentNowUtc.tz(timezone).startOf('day');
  }

  function getEndOfToday() {
    return momentNowUtc.tz(timezone).endOf('day');
  }

  function getNow() {
    return momentNowUtc.tz(timezone);
  }

  const stringValue = String(value).toLowerCase();

  if (NOW_REGEX.test(stringValue)) {
    return getNow().toDate();
  }

  // Capture and handle deprecated date patterns, this code will/should eventually be removed
  if (isDeprecatedDatePattern(stringValue)) {
    return handleDeprecatedParseDate({
      value: stringValue,
      timezone,
      momentNowUtc,
    });
  }

  const simpleResults = stringValue.match(END_OF_TODAY_REGEX);
  if (simpleResults) {
    const [, endOf] = simpleResults;
    return endOf ? getEndOfToday().toDate() : getToday().toDate();
  }

  const complexResults = stringValue.match(RELATIVE_TIME_COMPLEX_REGEX);
  if (complexResults) {
    const [, fullTimeLengthIntervalString, tense] = complexResults;
    // @ts-expect-error - TS18048 - 'fullTimeLengthIntervalString' is possibly 'undefined'.
    const timeLengthIntervalItems = fullTimeLengthIntervalString.match(
      FULL_TIME_LENGTH_INTERVAL_STRING_REGEX
    );

    if (!timeLengthIntervalItems) {
      return null;
    }

    // We want LTE operators to include everything up to the end of the day
    const getStart =
      operator && operator === OPERATOR_LTE_NAME ? getEndOfToday : getToday;

    const TENSE_METHODS_MAP = {
      future: 'add',
      past: 'subtract',
    } as const;
    const tenseMethod =
      TENSE_METHODS_MAP[tense as keyof typeof TENSE_METHODS_MAP];

    // This loops through each item and accumulatively adds or subtracts
    // the length and intervals from today's date
    const reducedDateTime = timeLengthIntervalItems.reduce(
      (accumulatedMoment, item) => {
        // `item` is a string in the shape of "2 days" or "14 years"
        const [length, interval] = item.split(' ');
        return accumulatedMoment[tenseMethod](
          // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
          parseInt(length, 10),
          interval as moment.DurationInputArg2
        );
      },
      getStart()
    );

    return reducedDateTime.toDate();
  }

  // Else, fall back on standard ISO 8601 parsing:
  const isoMoment = moment.utc(value, moment.ISO_8601).tz(timezone);

  if (!isoMoment || !isoMoment.isValid()) {
    return null;
  }

  // Ok!
  return isoMoment.toDate();
}

function castItemFieldValue(fieldValue: any, fieldType: string): any {
  switch (fieldType) {
    case 'CommercePrice': {
      // typeof null equals 'object' so we need to guard against that
      return fieldValue !== null &&
        typeof fieldValue === 'object' &&
        typeof fieldValue.value === 'number'
        ? fieldValue.value / 100
        : NaN;
    }
    case 'ItemRef': {
      return fieldValue !== null && typeof fieldValue === 'object'
        ? fieldValue._id
        : fieldValue;
    }
    case 'ItemRefSet': {
      return Array.isArray(fieldValue)
        ? fieldValue.map(function (itemRef) {
            return itemRef._id;
          })
        : [];
    }
    case 'Option': {
      return fieldValue !== null && typeof fieldValue === 'object'
        ? fieldValue.id
        : fieldValue;
    }
    case 'Number': {
      return fieldValue === null ? NaN : fieldValue;
    }
    default: {
      return fieldValue;
    }
  }
}

export function getItemFieldValue(itemData: any, fieldPath: string): any {
  const itemRefSlug = getItemRefSlug(fieldPath);
  const valueFieldSlug = getValueFieldSlug(fieldPath);
  return itemRefSlug
    ? itemData[itemRefSlug] && itemData[itemRefSlug][valueFieldSlug]
    : itemData[valueFieldSlug];
}

/*
 * Converts CMS Field Type into legacy item types used in this file
 *
 * This function maps Field types to the appropriate legacy field type.
 *
 * The legacy field types are `Bool`, `CommercePrice`, `Date`, `Id`,
 * `ImageRef`, `ItemRef`, `ItemRefSet`, `Number`, `Option` and `String`.
 *
 * Of these, only `Bool`, `CommercePrice`, `Date`, `ItemRef`,
 * `ItemRefSet`, `Number` and `Option` are consequential,
 * because condition and item field values are cast based on them
 * in `castConditionValue` and `castItemValue`.
 */
function convertFieldTypeToLegacyItemType(fieldType: string): string {
  switch (fieldType) {
    case 'Bool':
    case 'CommercePrice':
    case 'Date':
    case 'ImageRef':
    case 'ItemRef':
    case 'ItemRefSet':
    case 'Number':
    case 'Option':
    case 'Set': {
      return fieldType;
    }
    case 'FileRef':
    case 'Video': {
      return 'ImageRef';
    }
    case 'Email':
    case 'Phone':
    case 'PlainText':
    case 'RichText':
    case 'Link': {
      return 'String';
    }
    default: {
      return 'String';
    }
  }
}

/*
 * LEGACY function that derives the field type from field name and value.
 *
 * This way of deriving field type is only used with the Legacy Renderer,
 * where the field type is not added to the associated condition data.
 */
function _getLegacyItemType(name: string, value: unknown) {
  if (name === '_id') {
    return 'Id';
  } else {
    switch (typeof value) {
      case 'number':
        return 'Number';
      case 'boolean':
        return 'Bool';
      case 'object':
        return (function () {
          if (value) {
            if (value instanceof Date) {
              return 'Date';
            } else if ('_id' in value && '_cid' in value) {
              return 'ItemRef';
            } else if (Array.isArray(value)) {
              // Do not need to worry about `Set` fields here,
              // because this function is used only for legacy conditions.
              return 'ItemRefSet';
            } else if ('url' in value) {
              // technically this could be a video as well; we use 'ImageRef' as a stand-in for 'Asset'-type
              return 'ImageRef';
            } else if ('value' in value && 'unit' in value) {
              return 'CommercePrice';
            } else {
              return 'Option';
            }
          } else {
            return 'Option';
          }
        })();
      default:
        return 'String';
    }
  }
}
