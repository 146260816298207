import 'promise-polyfill/src/polyfill';
import 'unfetch/polyfill';
// IE11 crashes whem URLSearchParams is used
import 'core-js/web/url-search-params';
// At a minimum, we need `Array.from` support in IE11
import 'core-js/features/array';

import {handleLogInForms, handleLoginRedirects} from './login';
import {handleSignUpForms} from './signup';
import {handleLogInLogOutButton} from './logout';
import {handleResetPasswordForms} from './resetPassword';
import {handleUpdatePasswordForms} from './updatePassword';
import {handleUserAccount, handleUserSubscriptionLists} from './account';
import {getDomParser} from './utils';
import {handleFields} from './usysForm';
export * from './utils';

export const usysSiteBundle = () => {
  function init() {
    const domParser = getDomParser();
    handleLogInForms();
    handleLoginRedirects();
    handleSignUpForms();
    handleLogInLogOutButton();
    handleResetPasswordForms();
    handleUpdatePasswordForms();
    handleUserAccount();
    handleUserSubscriptionLists(domParser);
  }

  const ready = init;
  const design = init;
  const preview = init;

  return {
    init,
    ready,
    design,
    preview,
  };
};

export const usysFormBundle = function (env) {
  function init() {
    if (env('design')) return;
    handleFields();
  }
  return {
    init,
    ready: init,
    preview: init,
  };
};
