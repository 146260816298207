export function formatNumber(n: number, formatType: string | number) {
  if (typeof n === 'number') {
    const precision =
      formatType === '' || formatType === 'none' ? NaN : Number(formatType);

    if (!isNaN(precision)) {
      return n.toFixed(precision);
    } else {
      return String(n);
    }
  } else {
    return '';
  }
}

// Format email depending on the binding property being an href and having a subject
export function formatEmail(email: string, subject: string, property: string) {
  const prefix = property === 'href' ? 'mailto:' : '';
  if (email && subject) {
    return prefix + email + '?subject=' + subject;
  } else if (email) {
    return prefix + email;
  } else {
    return null;
  }
}

// Format phone depending on the binding property being an href and having a subject
export function formatPhone(phone: string, property: string) {
  if (property === 'href') {
    let tel = phone ? phone.replace(/\s/g, '') : '';
    if (/\d/.test(tel)) {
      // Create a map to replace phonewords with the proper numbers
      const keypadMap: Array<[RegExp, number]> = [
        [/a|b|c/gi, 2],
        [/d|e|f/gi, 3],
        [/g|h|i/gi, 4],
        [/j|k|l/gi, 5],
        [/m|n|o/gi, 6],
        [/p|q|r|s/gi, 7],
        [/t|u|v/gi, 8],
        [/w|x|y|z/gi, 9],
      ];
      keypadMap.forEach(([key, value]) => {
        tel = tel.replace(key, value.toString());
      });
    } else {
      phone = '#';
    }

    phone = /\d/.test(tel) ? 'tel:' + tel : '#';
  }
  return phone;
}
