/*
  globals
  document
  window
  HTMLFormElement
*/

import {
  USYS_DATA_ATTRS,
  USYS_DOM_CLASS_NAMES,
  USYS_FORM_TYPES,
} from '@packages/systems/users/constants';
import {
  WF_TEMPLATE_ID_DATA_KEY,
  WF_TEMPLATE_TYPE,
} from '@packages/systems/commerce/constants';
import {walkDOM} from '@packages/systems/dynamo/utils/RenderingUtils';
import {
  userSystemsRequestClient,
  type DomParserType,
  showElement,
  hideElement,
  disableSubmit,
  showAndFocusElement,
  resetSubmit,
  userFormError,
} from './utils';
import {getUserSubscriptions, buildGetLoggedInUserQuery} from './queries';
import {
  cancelSubscriptionMutation,
  buildUpdateUsysUserDataMutation,
} from './mutations';
import {
  applyBindingsAndConditionalVisibility,
  applyUserAccountData,
} from './rendering';
import {
  getCommonFields,
  getCustomFields,
  getFieldsForFetch,
  getFieldsAsTypeKeys,
} from './fields';

function asyncGetUserSubscriptions() {
  return userSystemsRequestClient.query({
    query: getUserSubscriptions,
  });
}

function asyncGetUser(dataFields: any) {
  return userSystemsRequestClient.query({
    query: buildGetLoggedInUserQuery(dataFields),
  });
}

function asyncSubmitUserData(dataFields: any) {
  // get variables in correct shape
  const data = getFieldsAsTypeKeys(dataFields);

  return userSystemsRequestClient.mutate({
    mutation: buildUpdateUsysUserDataMutation(dataFields),
    variables: {data},
  });
}

const subscriptionListSelector = `[${USYS_DATA_ATTRS.userSubscriptions}]`;
const EmptyStateSelector = `[${USYS_DATA_ATTRS.userSubscriptionsEmptyState}]`;
const templateSelector = `script[type='${WF_TEMPLATE_TYPE}']`;

function getUserSubscriptionLists() {
  const subscriptionLists = document.querySelectorAll(subscriptionListSelector);
  return Array.from(subscriptionLists);
}

const userAccountFormQuerySelector = `form[${USYS_DATA_ATTRS.formType}="${USYS_FORM_TYPES.account}"]`;

function getUserAccountForms() {
  const accountForms = document.querySelectorAll(userAccountFormQuerySelector);
  return Array.prototype.slice
    .call(accountForms)
    .filter((accountForm) => accountForm instanceof HTMLFormElement);
}

export function handleUserSubscriptionLists(domParser: DomParserType) {
  if (window.Webflow.env('design') || window.Webflow.env('preview')) {
    // The usys Apollo client does not work in the Designer and will error out.
    // We are using mocked data in the Designer and Preview mode instead.
    return;
  }

  const subscriptionLists = getUserSubscriptionLists();
  // If we have a list to render, query for the data
  if (subscriptionLists.length > 0) {
    asyncGetUserSubscriptions()
      .then((response) => {
        const userSubscriptions = response?.data?.database?.userSubscriptions;
        const noUserSubscriptions = userSubscriptions.length === 0;

        if (noUserSubscriptions)
          return renderEmptySubscriptionList(subscriptionLists);

        renderUserSubscriptionLists(
          subscriptionLists,
          domParser,
          userSubscriptions
        );
      })
      .catch((error) => {
        const graphQLErrors = error?.graphQLErrors || [];
        const errorsHandled = graphQLErrors.reduce(
          // @ts-expect-error - TS7006 - Parameter 'hasUnhandledError' implicitly has an 'any' type. | TS7006 - Parameter 'graphQLError' implicitly has an 'any' type.
          (hasUnhandledError, graphQLError) => {
            if (graphQLError?.code === 'NoCommerceCustomerFound') {
              renderEmptySubscriptionList(subscriptionLists);
              return hasUnhandledError;
            }
            return false;
          },
          graphQLErrors.length > 0
        );

        if (!errorsHandled) throw error;
      });
  }
}

function renderEmptySubscriptionList(
  subscriptionListElements: Array<HTMLElement | any>
) {
  subscriptionListElements.forEach((subscriptionListElement) => {
    const EmptyStateElement =
      subscriptionListElement.querySelector(EmptyStateSelector);
    showElement(EmptyStateElement);
  });
}

function renderUserSubscriptionLists(
  subscriptionListElements: Array<HTMLElement | any>,
  domParser: DomParserType,
  userSubscriptions = []
) {
  subscriptionListElements.forEach((subscriptionListElement) => {
    const EmptyStateElement =
      subscriptionListElement.querySelector(EmptyStateSelector);
    hideElement(EmptyStateElement);

    const templateScript =
      subscriptionListElement.querySelector(templateSelector);

    if (!templateScript) {
      return;
    }

    const templateId = templateScript.getAttribute('id');

    if (!templateId) {
      return;
    }

    const listWrapperElement = document.querySelector(
      `[${WF_TEMPLATE_ID_DATA_KEY}='${templateId}']`
    );

    if (!(listWrapperElement instanceof Element)) {
      // If we don't have a wrapper to append items to, return
      return;
    }

    const templateElement = domParser.getHtmlFromString(
      templateScript.innerHTML
    );

    if (!(templateElement instanceof Element)) {
      // If there is no template content present, return
      return;
    }

    userSubscriptions.forEach((subscription) => {
      const templateClone = templateElement.cloneNode(true);

      listWrapperElement.appendChild(templateClone);

      // @ts-expect-error - TS2345 - Argument of type 'Node' is not assignable to parameter of type 'Element'.
      walkDOM(templateClone, (node: Element) => {
        applyBindingsAndConditionalVisibility(node, subscription);
        // Add handler for cancel subscription button. Done here since we have access to subscription id.
        if (node.hasAttribute(USYS_DATA_ATTRS.subscriptionCancel)) {
          // @ts-expect-error - TS2339 - Property '_id' does not exist on type 'never'.
          addCancelButtonEventListener(node, subscription._id);
        }
      });
    });
  });
}

function addCancelButtonEventListener(node: Element, subscriptionId: any) {
  node.addEventListener('click', function () {
    userSystemsRequestClient
      .mutate({
        mutation: cancelSubscriptionMutation,
        variables: {
          subscriptionId,
        },
      })
      .then(() => {
        // Refresh so can refetch and rerender with updated status of subscription after cancelation
        // Ultimately we should probably do a re fetch adn re render render, but will require replacing existing nodes
        window.location.reload();
      });
  });
}

export function handleUserAccount() {
  // UserAccountWrapper
  const userAccount = document.querySelector(
    `[${USYS_DATA_ATTRS.userAccount}]`
  );

  if (
    !userAccount ||
    window.Webflow.env('design') ||
    window.Webflow.env('preview')
  ) {
    return;
  }

  const successMessage = userAccount.querySelector(
    '.' + USYS_DOM_CLASS_NAMES.formSuccess
  );
  const errorMessage = userAccount.querySelector(
    '.' + USYS_DOM_CLASS_NAMES.formError
  );

  const userAccountForms = getUserAccountForms();

  if (userAccountForms.length > 0) {
    const fields = getFieldsForFetch(userAccountForms);
    asyncGetUser(fields).then((response) => {
      const siteUser = response?.data?.site?.siteUser;

      if (!siteUser) return;

      const userData = siteUser.data;

      userAccountForms.forEach((accountForm) => {
        walkDOM(userAccount, (node: Element) => {
          applyUserAccountData(node, userData);
        });
        if (!(accountForm instanceof HTMLFormElement)) return;

        const submit: HTMLElement | null = accountForm.querySelector(
          'input[type="submit"]'
        );

        accountForm.addEventListener('submit', (event: Event) => {
          event.preventDefault();
          const form = event.currentTarget;
          if (!(form instanceof HTMLFormElement)) {
            return;
          }

          // @ts-expect-error - TS2345 - Argument of type 'Element | null' is not assignable to parameter of type 'HTMLElement | null | undefined'.
          hideElement(successMessage);
          // @ts-expect-error - TS2345 - Argument of type 'Element | null' is not assignable to parameter of type 'HTMLElement | null | undefined'.
          hideElement(errorMessage);
          const submitText = disableSubmit(submit);

          const commonFields = getCommonFields(form, [
            'name',
            'accept-communications',
          ]);
          const customFields = getCustomFields(form);

          asyncSubmitUserData([...commonFields, ...customFields])
            .then((res) => {
              const newUserData =
                res &&
                res.data &&
                res.data.usysUpdateUserData &&
                res.data.usysUpdateUserData.data;
              if (newUserData) {
                // @ts-expect-error - TS2345 - Argument of type 'Element' is not assignable to parameter of type 'HTMLElement'.
                addResetEventListener(accountForm, userAccount, newUserData);
              }
              // @ts-expect-error - TS2345 - Argument of type 'Element' is not assignable to parameter of type 'HTMLElement'.
              successMessage && showAndFocusElement(successMessage);
            })
            // @ts-expect-error - TS2345 - Argument of type 'Element | null' is not assignable to parameter of type 'HTMLElement | null'.
            .catch(userFormError(form, errorMessage, 'ACCOUNT_UPDATE'))
            .finally(() => {
              resetSubmit(submit, submitText);
            });
        });

        accountForm.querySelectorAll('input').forEach((input) =>
          input.addEventListener('input', () => {
            // @ts-expect-error - TS2345 - Argument of type 'Element | null' is not assignable to parameter of type 'HTMLElement | null | undefined'.
            hideElement(successMessage);
            // @ts-expect-error - TS2345 - Argument of type 'Element | null' is not assignable to parameter of type 'HTMLElement | null | undefined'.
            hideElement(errorMessage);
          })
        );
        // @ts-expect-error - TS2345 - Argument of type 'Element' is not assignable to parameter of type 'HTMLElement'.
        addResetEventListener(accountForm, userAccount, userData);
      });
    });
  }
}

const addResetEventListener = (
  accountForm: any,
  userAccount: HTMLElement,
  userData: any
) => {
  accountForm.addEventListener('reset', (event: Event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (!(form instanceof HTMLFormElement)) return;

    if (userData) {
      // apply saved user account data
      walkDOM(userAccount, (node: Element) => {
        applyUserAccountData(node, userData);
      });
    }
  });
};
