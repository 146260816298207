/* globals window, document */
import 'custom-event-polyfill';

// Symbol is used in various caching/memoization implementations
import 'core-js/features/symbol';

// At a minimum, we need `Array.from` and `Array.find` support in IE11
import 'core-js/features/array';

// We need String.prototype.repeat for currency formatting (adding extra digits)
import 'core-js/features/string/repeat';

// Object.entries does not exist in IE11, and it's in the Dynamo happy path
import 'core-js/features/object/entries';

// NodeLists do not have a native forEach even though they implement Iterable
import 'core-js/features/dom-collections/for-each';

// IE11 does not support Number.isNaN, which is in the Dynamo happy path.
// We're opting for importing the whole Number polyfill here since the other
// Number prototype methods may exist in a lesser used path or in the future.
import 'core-js/features/number';

import {createApolloClient} from '@packages/utilities/apolloClient';
import EventHandlerProxyWithApolloClient from './eventHandlerProxyWithApolloClient';
import AddToCartEvents from './addToCartEvents';
import CartEvents from './cartEvents';
import CheckoutEvents from './checkoutEvents';
import OrderConfirmationEvents from './orderConfirmationEvents';
import WebPaymentsEvents from './webPaymentsEvents';
import {StripeStore} from './stripeStore';
import {triggerRender} from './commerceUtils';
import 'promise-polyfill/src/polyfill';
import 'unfetch/polyfill';
import {initializeStripeElements} from './checkoutUtils';
import PayPalEvents, {renderPaypalButtons} from './paypalEvents';

const GQL_QUERY_PATH = '/.wf_graphql/apollo';

// @ts-expect-error - TS7034 - Variable 'handlerProxy' implicitly has type 'any' in some locations where its type cannot be determined.
let handlerProxy;
// @ts-expect-error - TS7034 - Variable 'apolloClient' implicitly has type 'any' in some locations where its type cannot be determined.
let apolloClient;
let stripeStore;

function attachHandlers() {
  // @ts-expect-error - TS7005 - Variable 'handlerProxy' implicitly has an 'any' type.
  handlerProxy && handlerProxy.attachHandlers(window);
}

function detachHandlers() {
  // @ts-expect-error - TS7005 - Variable 'handlerProxy' implicitly has an 'any' type.
  handlerProxy && handlerProxy.removeHandlers(window);
}

// Can be called from published sites or the Designer engine
// Published sites: call point is defined in /models/site.js
// Designer: call point is in CommerceProxyEngine
function init({siteId}: {siteId: string}) {
  apolloClient = createApolloClient({
    path:
      window.Webflow.env('design') || window.Webflow.env('preview')
        ? `/api/v1/sites/${siteId}/apollo`
        : GQL_QUERY_PATH,
    retryConfig: {
      maxAttempts: 5,
    },
    useCsrf: true,
  });
  stripeStore = new StripeStore(document);
  handlerProxy = new EventHandlerProxyWithApolloClient(
    apolloClient,
    stripeStore
  );

  AddToCartEvents.register(handlerProxy);
  CartEvents.register(handlerProxy);
  CheckoutEvents.register(handlerProxy);
  OrderConfirmationEvents.register(handlerProxy);
  WebPaymentsEvents.register(handlerProxy);
  PayPalEvents.register(handlerProxy);

  initializeStripeElements(stripeStore);

  detachHandlers();
  attachHandlers();

  triggerRender(null, true);

  // @ts-expect-error - TS2339 - Property 'Webflow' does not exist on type 'Window & typeof globalThis'.
  if (!window.Webflow.env()) {
    // @ts-expect-error - TS2339 - Property 'Webflow' does not exist on type 'Window & typeof globalThis'.
    window.Webflow.load(renderPaypalButtons(apolloClient));
  }
}

// `preview` should always be called after `init`
function preview() {
  detachHandlers();
  attachHandlers();
  triggerRender(null, true);
}

// `design` should always be called after `init`
function design() {
  detachHandlers();
  // When variant option is changed, we need to reset the Apollo cache in oder to
  // render the right data for the selected variant (main-images, more-images, etc)
  // @ts-expect-error - TS7005 - Variable 'apolloClient' implicitly has an 'any' type.
  if (apolloClient && apolloClient.store) {
    apolloClient.resetStore();
  }
}

function destroy() {
  detachHandlers();
}

export {design, destroy, init, preview};
