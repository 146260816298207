import merge from 'lodash/merge';
import tinycolor from 'tinycolor2';
import type {BreakpointID} from '@packages/systems/style/types';
import type {PseudoStateKey} from '@packages/systems/core/utils/StyleTypes';
import {LARGER_BREAKPOINTS_CONFIG} from '@packages/systems/style/breakpoints-config';
import {
  BREAKPOINT_ID_XXL,
  BREAKPOINT_ID_XL,
  BREAKPOINT_ID_LARGE,
  BREAKPOINT_ID_MAIN,
  BREAKPOINT_ID_MEDIUM,
  BREAKPOINT_ID_SMALL,
  BREAKPOINT_ID_TINY,
} from '@packages/systems/style/breakpoint-ids';

const STYLE_MAP_ATTR = 'data-wf-style-map';

export type AppliedStylesMap = Partial<
  Record<
    PseudoStateKey,
    {
      [key: string]: string;
    }
  >
>;
type StylesMap = Partial<Record<BreakpointID, AppliedStylesMap>>;

/**
 * In order of application, where each query values
 * overrides the media queries above it.
 */

const orderedBreakpointIds = [
  BREAKPOINT_ID_MAIN,
  BREAKPOINT_ID_LARGE,
  BREAKPOINT_ID_XL,
  BREAKPOINT_ID_XXL,
  BREAKPOINT_ID_MEDIUM,
  BREAKPOINT_ID_SMALL,
  BREAKPOINT_ID_TINY,
];

const ORDERED_MEDIA_QUERIES: Array<{
  name: BreakpointID;
  query: string;
}> = orderedBreakpointIds.map((breakpointId) => {
  const config = LARGER_BREAKPOINTS_CONFIG[breakpointId];
  let prop;
  let value;

  // @ts-expect-error - TS18048 - 'config' is possibly 'undefined'.
  if ('minWidth' in config) {
    prop = 'min-width';
    value = config.minWidth;
  }
  // @ts-expect-error - TS18048 - 'config' is possibly 'undefined'.
  if ('maxWidth' in config) {
    prop = 'max-width';
    value = config.maxWidth;
  }

  if (prop === undefined || value === undefined) {
    throw new Error(
      'Bad breakpoint config, expected either "minWidth" or "maxWidth".'
    );
  }

  return {
    name: breakpointId,
    query: `(${prop}: ${value}px)`,
  };
});

type Options = {
  onChange: (arg1: AppliedStylesMap | undefined) => void;
};

export default class StyleMapObserver {
  styles: undefined | StylesMap = undefined;
  observer: undefined | any = undefined;

  mediaQueries: Array<{
    listener: any;
    name: BreakpointID;
    query: string;
  }> = [];
  options: Options = {onChange: () => {}};

  static appliedStylesToStripeElementStyles = (
    appliedStylesMap?: AppliedStylesMap
  ) => {
    if (!appliedStylesMap) {
      return {};
    }

    // Need to update color values to rgba because Stripe doesn't accept hsla format
    const appliedStylesMapWithUpdatedColorValues = Object.keys(
      appliedStylesMap
    ).reduce<Record<string, any>>((updatedStyles, styleKey) => {
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Partial<Record<"lang" | "active" | "placeholder" | "focus" | "after" | "before" | "hover" | "empty" | "noPseudo" | "nth-child(odd)" | "nth-child(even)" | "first-child" | "last-child" | "pressed" | "visited" | "focus-visible" | "focus-within", { ...; }>>'.
      const colorVal = appliedStylesMap[styleKey].color;
      const textShadowVal =
        // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Partial<Record<"lang" | "active" | "placeholder" | "focus" | "after" | "before" | "hover" | "empty" | "noPseudo" | "nth-child(odd)" | "nth-child(even)" | "first-child" | "last-child" | "pressed" | "visited" | "focus-visible" | "focus-within", { ...; }>>'.
        appliedStylesMap[styleKey].textShadow &&
        // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Partial<Record<"lang" | "active" | "placeholder" | "focus" | "after" | "before" | "hover" | "empty" | "noPseudo" | "nth-child(odd)" | "nth-child(even)" | "first-child" | "last-child" | "pressed" | "visited" | "focus-visible" | "focus-within", { ...; }>>'.
        appliedStylesMap[styleKey].textShadow.split(/(?=hsla)/);

      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Partial<Record<"lang" | "active" | "placeholder" | "focus" | "after" | "before" | "hover" | "empty" | "noPseudo" | "nth-child(odd)" | "nth-child(even)" | "first-child" | "last-child" | "pressed" | "visited" | "focus-visible" | "focus-within", { ...; }>>'.
      updatedStyles[styleKey] = appliedStylesMap[styleKey];

      // Update color to rgba string
      if (colorVal) {
        updatedStyles[styleKey].color = tinycolor(colorVal).toRgbString();
      }
      // Want to update only if hsla val is within textShaodw string
      if (textShadowVal && textShadowVal.length > 1) {
        updatedStyles[styleKey].textShadow =
          textShadowVal[0] + tinycolor(textShadowVal[1]).toRgbString();
      }

      return updatedStyles;
    }, {});

    const styles = {
      ...appliedStylesMapWithUpdatedColorValues.noPseudo,
      ':hover': appliedStylesMapWithUpdatedColorValues.hover,
      ':focus': appliedStylesMapWithUpdatedColorValues.focus,
      '::placeholder': appliedStylesMapWithUpdatedColorValues.placeholder,
    } as const;

    return {
      base: styles,
      invalid: styles,
      empty: styles,
      complete: styles,
    };
  };

  constructor(element: Element, options: Options) {
    this.options = options;
    if (element.hasAttribute(STYLE_MAP_ATTR)) {
      const styleMapJSON = element.getAttribute(STYLE_MAP_ATTR);
      if (styleMapJSON) {
        this.setStylesFromJSON(styleMapJSON);

        const doc = element.ownerDocument;
        const win = doc.defaultView;

        this.mediaQueries = ORDERED_MEDIA_QUERIES.map((mq) => ({
          ...mq,
          // @ts-expect-error - TS18047 - 'win' is possibly 'null'.
          listener: win.matchMedia(mq.query),
        }));

        // @ts-expect-error - TS18047 - 'win' is possibly 'null'.
        this.observer = new win.MutationObserver(this.handleMutationObserver);
        this.observer.observe(element, {
          attributes: true,
        });

        this.mediaQueries.forEach(({listener}) => {
          listener.addListener(this.dispatch);
        });

        this.dispatch();
      }
    }
  }

  setStylesFromJSON(styleMapJSON: string) {
    try {
      this.styles = JSON.parse(styleMapJSON) as StylesMap;
    } catch (e: any) {
      this.styles = {};
    }
  }

  getAppliedStyles() {
    if (!this.styles) {
      return;
    }

    const styles = this.styles;

    const appliedStyles: AppliedStylesMap = this.mediaQueries.reduce<
      Record<string, any>
    >(
      (stylesMap, {listener, name}) =>
        listener.matches ? merge(stylesMap, styles[name]) : stylesMap,
      {}
    );

    return appliedStyles;
  }

  dispatch = () => {
    this.options.onChange(this.getAppliedStyles());
  };

  handleMutationObserver = (mutationList: Array<any>) => {
    mutationList.forEach((mutation) => {
      if (
        mutation.type === 'attributes' &&
        mutation.attributeName === STYLE_MAP_ATTR &&
        mutation.target.hasAttribute(STYLE_MAP_ATTR)
      ) {
        const styleMapJSON = mutation.target.getAttribute(STYLE_MAP_ATTR);

        if (styleMapJSON) {
          this.setStylesFromJSON(styleMapJSON);
          this.dispatch();
        }
      }
    });
  };

  destroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
    this.mediaQueries.forEach(({listener}) => {
      listener.removeListener(this.dispatch);
    });
  }
}
