const {create} = Object;

const returnThis = function <T>(this: T): T {
  return this;
};

const VALUE: unique symbol = Symbol();
const FOLD: unique symbol = Symbol();

// # Option
//
// Option is a data type for representing values that might be missing. It is
// also known as `Maybe` or `Optional` in other languages and libraries.
//
// ```js
// import {None, Some} from '@webflow/fp-option'
//
// const first = array => array.length ? Some(array[0]) : None;
// ```
//
// Notice in the usage example above how in the case of a missing value the
// function returns `None` otherwise the value is wrapped in `Some`.
//
// Consuming a value of this type involves somehow “enhancing” a regular
// function to work on values of type `Option`. This is done by applying
// the familiar `map` function to our original function:
//
// ```js
// import {map} from '@webflow/fp'
//
// const originalDouble = a => a + a
// const double = map(originalDouble)
// ```
//
// This `double` function can now safely be applied to a `Option` value:
//
// ```js
// const option1 = double(first([6, 2])) // => Some(12)
// const option2 = double(first([])) // => None
// ```
//
// Notice how our `double` function works just fine, even if the value is
// missing, by passing the None along. In cases where we only care about
// transforming the value inside `Option` this is all we need to do.
// When we need to take the value out of the `Option` box we can make use
// of the `maybe` function:
//
// ```js
// const maybeZero = maybe(0)
// const defaultToZero = maybeZero(number => number)
//
// const number1 = defaultToZero(option1) // => 12
// const number2 = defaultToZero(option2) // => 0
// ```
//
// The `maybe` curried function takes three arguments, a fallback value that
// is returned in the case of `None`, a function that gets applied to the value
// in case the option is `Some` and thirdly the option. The fallback value and
// the return value of the function passed as the second argument must be of
// the same type.

export interface Option<A> {
  map: <B>(f: (a: A) => B) => Option<B>;
  chain: <B>(f: (a: A) => Option<B>) => Option<B>;
  ap: <B, C>(this: Option<(x: B) => C>, b: Option<B>) => Option<C>;
  alt: (x: Option<A>) => Option<A>;
  concat: <B extends {concat: (x: B) => B}>(
    this: Option<B>,
    x: Option<B>
  ) => Option<B>;
  [FOLD]: <B>(none: B, some: (x: A) => B) => B;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const None: Option<any> = create({
  map: returnThis,
  chain: returnThis,
  alt: <T>(alternativeOption: Option<T>) => alternativeOption,
  ap: returnThis,
  concat: <T>(other: Option<T>) => other,

  /**
   * Returns a default fallback value if the `Option` is a `None`.
   */
  [FOLD]: <A>(fallback: A) => fallback,
});

type SomeT<A> = Option<A> & {[VALUE]: A};

export const Some = <A>(value: A): Option<A> => {
  const object = create(SomePrototype);
  object[VALUE] = value;
  return object;
};

const SomePrototype = {
  /**
   * Transform the value inside of a `Option` by applying a unary function to it.
   */
  map<A, B>(this: SomeT<A>, f: (x: A) => B): Option<B> {
    return Some(f(this[VALUE]));
  },

  /**
   * Sequence computations by applying a function to the value
   * contained in the `Option`. The function must return an `Option`.
   */
  chain<A, B>(this: SomeT<A>, f: (x: A) => Option<B>): Option<B> {
    return f(this[VALUE]);
  },

  /**
   * Provide an alternative option that will be returned if this option is None.
   */
  alt: returnThis,

  /**
   * Allows you to apply the Option's value with another Option's value,
   * returning another Option.
   */
  ap<B, C>(this: SomeT<(x: B) => C>, m: Option<B>): Option<C> {
    return m.map(this[VALUE]);
  },

  concat<A extends {concat: (x: A) => A}>(
    this: SomeT<A>,
    other: Option<A>
  ): Option<A> {
    return other[FOLD](this, (otherValue: A) =>
      Some(this[VALUE].concat(otherValue))
    );
  },

  /**
   * Applies a function to the value contained in an `Option`
   * if the `Option` is a `Some`.
   */
  [FOLD]<A, B>(this: SomeT<A>, fallback: B, mapValue: (a: A) => B): B {
    return mapValue(this[VALUE]);
  },
} as const;

export const fromNullable = <T>(value: T): Option<NonNullable<T>> =>
  value == null ? None : Some(value);

export const maybe =
  <B>(fallback: B) =>
  <A>(mapValue: (a: A) => B) =>
  (option: Option<A>): B =>
    option[FOLD](fallback, mapValue);

export const of = Some;
