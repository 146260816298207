/* globals window, document, HTMLInputElement */
import {ApolloClient, NormalizedCacheObject} from '@apollo/client';
import gql from 'graphql-tag';
import {
  safeParseJson,
  findClosestElementByNodeType,
  findAllElementsByNodeType,
  findElementByNodeType,
  showElement,
  hideElement,
} from './commerceUtils';
import StyleMapObserver, {AppliedStylesMap} from '../StyleMapObserver';
import {StripeStore} from './stripeStore';
import {
  NODE_TYPE_COMMERCE_CHECKOUT_ERROR_STATE,
  NODE_TYPE_COMMERCE_CHECKOUT_FORM_CONTAINER,
  NODE_TYPE_COMMERCE_CART_WRAPPER,
  NODE_TYPE_COMMERCE_CHECKOUT_SHIPPING_METHODS_LIST,
  NODE_TYPE_COMMERCE_CHECKOUT_SHIPPING_METHODS_EMPTY_STATE,
  NODE_TYPE_COMMERCE_CHECKOUT_SHIPPING_ADDRESS_WRAPPER,
  NODE_TYPE_COMMERCE_CHECKOUT_SHIPPING_ADDRESS_ZIP_FIELD,
  NODE_TYPE_COMMERCE_CHECKOUT_BILLING_ADDRESS_WRAPPER,
  NODE_TYPE_COMMERCE_CHECKOUT_BILLING_ADDRESS_TOGGLE_CHECKBOX,
  NODE_TYPE_COMMERCE_CHECKOUT_BILLING_ADDRESS_ZIP_FIELD,
  CHECKOUT_ERRORS,
  CHECKOUT_QUERY,
  STRIPE_ELEMENT_INSTANCE,
  STRIPE_ELEMENT_TYPE,
  STRIPE_ELEMENT_STYLE,
  getCheckoutErrorMessageForType,
  REQUIRES_ACTION,
  NEEDS_REFRESH,
  CART_CHECKOUT_ERROR_MESSAGE_SELECTOR,
  PAYPAL_ELEMENT_INSTANCE,
  PAYPAL_BUTTON_ELEMENT_INSTANCE,
} from '@packages/systems/commerce/constants';
import {renderTree} from './rendering';
import {updateWebPaymentsButton} from './webPaymentsEvents';
import {
  updateOrderShippingMethodMutation,
  recalcOrderEstimationsMutation,
  attemptSubmitOrderMutation,
  updateOrderIdentityMutation,
  updateOrderAddressMutation,
  updateCustomData,
  updateOrderStripePaymentMethodMutation,
  updateObfuscatedOrderAddressMutation,
  applyDiscountMutation,
} from './checkoutMutations';

interface BeforeUnloadEvent extends Event {
  returnValue: any;
}

const syncStylesToStripeElement =
  (stripeElement: any) => (appliedStyles: undefined | AppliedStylesMap) => {
    stripeElement.update({
      style: StyleMapObserver.appliedStylesToStripeElementStyles(appliedStyles),
    });
  };

export const initializeStripeElements = (store: StripeStore) => {
  if (
    window.Webflow.env('design') ||
    window.Webflow.env('preview') ||
    !store.isInitialized()
  ) {
    return;
  }

  const checkoutFormContainers = findAllElementsByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_FORM_CONTAINER
  );
  const cartWrappers = findAllElementsByNodeType(
    NODE_TYPE_COMMERCE_CART_WRAPPER
  );

  const allStripeElements = [...checkoutFormContainers, ...cartWrappers];

  allStripeElements.forEach((element, index) => {
    store.createElementsInstance(index);
    element.setAttribute(STRIPE_ELEMENT_INSTANCE, String(index));
  });

  const stripeElements = document.querySelectorAll(`[${STRIPE_ELEMENT_TYPE}]`);

  Array.from(stripeElements).forEach((element) => {
    const type = element.getAttribute(STRIPE_ELEMENT_TYPE);
    if (!type) {
      throw new Error('Stripe element missing type string');
    }

    const checkoutFormContainer = findClosestElementByNodeType(
      NODE_TYPE_COMMERCE_CHECKOUT_FORM_CONTAINER,
      element
    );
    if (!checkoutFormContainer) {
      return;
    }

    const index = parseInt(
      // @ts-expect-error - TS2345 - Argument of type 'string | null' is not assignable to parameter of type 'string'.
      checkoutFormContainer.getAttribute(STRIPE_ELEMENT_INSTANCE),
      10
    );

    const el = store.createElement(type, index, {
      style: safeParseJson(element.getAttribute(STRIPE_ELEMENT_STYLE) || '{}'),
      classes: {
        focus: '-wfp-focus',
      },
    });
    el.mount(element);
    // eslint-disable-next-line unused-imports/no-unused-vars, unused-imports/no-unused-vars
    const styleMapObserver = new StyleMapObserver(element, {
      onChange: syncStylesToStripeElement(el),
    });
  });
};

const errorCodeToCheckoutErrorType = (code?: string, msg?: string) => {
  switch (code) {
    case 'OrderTotalRange':
      if (msg && msg.match(/too small/i)) {
        return 'minimum';
      } else {
        return 'info';
      }
    case 'OrderExtrasChanged':
      return 'extras';
    case 'PriceChanged':
      return 'pricing';
    case 'StripeRejected':
      return 'billing';
    case 'NeedShippingAddress':
    case 'InvalidShippingAddress':
    case 'NeedShippingMethod':
      return 'shipping';
    case 'NeedPaymentMethod':
    case 'StripeFailure':
      return 'payment';
    case 'ItemNotFound':
      return 'product';
    // 'InvalidDiscount' has been renamed to 'DiscountInvalid', but it needs
    // to be listed here to support sites that haven't been published since this change.
    case 'InvalidDiscount':
    case 'DiscountInvalid':
    case 'DiscountDoesNotExist': {
      return 'invalid-discount';
    }
    case 'DiscountExpired': {
      return 'expired-discount';
    }
    case 'DiscountUsageReached': {
      return 'usage-reached-discount';
    }
    case 'DiscountRequirementsNotMet': {
      return 'requirements-not-met';
    }
    default:
      return 'info';
  }
};

const getErrorType = (error: Record<any, any>) => {
  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    return errorCodeToCheckoutErrorType(
      error.graphQLErrors[0].extensions?.code,
      error.graphQLErrors[0].message
    );
  }

  if (error.code) {
    return errorCodeToCheckoutErrorType(error.code, error.message);
  }

  return 'info';
};

export const updateErrorMessage = (
  element: Element,
  error: Record<any, any>
) => {
  const errorText = element.querySelector(CART_CHECKOUT_ERROR_MESSAGE_SELECTOR);
  if (!errorText) {
    return;
  }

  // Handle Stripe.js client-side errors. We use Stripe.js's error message
  // as this error typically indicates that the user forgot to enter part of
  // their CC, or entered invalid data. The more specific the error, the more
  // helpful it will be for the user.
  if (error.type && error.type === 'validation_error') {
    errorText.textContent = error.message;
    return;
  }

  const errorType = getErrorType(error);

  // Get the default error message incase the node does not have the error attribute yet.
  const errorData =
    // @ts-expect-error Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{...}'
    CHECKOUT_ERRORS[errorType.toUpperCase().replace(/\W/g, '_')] || {};
  const defaultErrorMessage = errorData.copy;

  const errorMessage =
    errorText.getAttribute(getCheckoutErrorMessageForType(errorType)) ||
    defaultErrorMessage;

  errorText.textContent = errorMessage;

  if (errorData.requiresRefresh) {
    errorText.setAttribute(NEEDS_REFRESH, 'true');
  } else {
    errorText.removeAttribute(NEEDS_REFRESH);
  }

  if (errorType === 'shipping') {
    updateRequiredFields(error);
  }
};

const elementNameByGraphQLError = {
  MISSING_STATE: 'address_state',
} as const;

const updateRequiredFields = (error: any) => {
  if (!error.graphQLErrors || error.graphQLErrors.length === 0) {
    return;
  }

  const invalidShippingAddressError = error.graphQLErrors.find(
    // @ts-expect-error - TS7006 - Parameter 'gqlError' implicitly has an 'any' type.
    (gqlError) => gqlError.code === 'InvalidShippingAddress'
  );

  if (!invalidShippingAddressError) {
    return;
  }

  // @ts-expect-error - TS7006 - Parameter 'problem' implicitly has an 'any' type.
  invalidShippingAddressError.problems.forEach((problem) => {
    const {type} = problem;

    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ readonly MISSING_STATE: "address_state"; }'.
    const elementName = elementNameByGraphQLError[type];

    if (!elementName) {
      return;
    }

    const element = document.getElementsByName(elementName)[0];

    if (!(element instanceof HTMLInputElement)) {
      return;
    }

    element.required = true;

    // IE11 doesn't support the reportValidity API
    if (typeof element.reportValidity === 'function') {
      element.reportValidity();
    }
  });
};

export const showErrorMessageForError = (
  err: Error,
  scope?: Element | Document
) => {
  const errorState = findElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_ERROR_STATE,
    scope
  );
  if (errorState) {
    errorState.style.removeProperty('display');
    updateErrorMessage(errorState, err);
  }
};

export const beforeUnloadHandler = (e: BeforeUnloadEvent) => {
  e.preventDefault();
  e.returnValue = '';
};

export const createOrderIdentityMutation = (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  email: string | null
) =>
  apolloClient.mutate({
    mutation: updateOrderIdentityMutation,
    variables: {email},
  });

export const createOrderAddressMutation = (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  addressInfo: Record<any, any>
) =>
  apolloClient.mutate({
    mutation: updateOrderAddressMutation,
    variables: addressInfo,
  });

export const createOrderShippingMethodMutation = (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  id: string | null
) =>
  apolloClient.mutate({
    mutation: updateOrderShippingMethodMutation,
    variables: {id},
  });

export const createCustomDataMutation = (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  customData: []
) =>
  apolloClient.mutate({
    mutation: updateCustomData,
    variables: {customData},
  });

export const createStripePaymentMethodMutation = (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  id: string
) =>
  apolloClient.mutate({
    mutation: updateOrderStripePaymentMethodMutation,
    variables: {
      paymentMethod: id,
    },
  });

export const createRecalcOrderEstimationsMutation = (
  apolloClient: ApolloClient<NormalizedCacheObject>
) =>
  apolloClient.mutate({
    mutation: recalcOrderEstimationsMutation,
    errorPolicy: 'ignore',
  });

export const createUpdateObfuscatedOrderAddressMutation = (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  addressInfo: Record<any, any>
) =>
  apolloClient.mutate({
    mutation: updateObfuscatedOrderAddressMutation,
    variables: addressInfo,
  });

const renderCheckout = (
  checkout: Element,
  data: Record<any, any>,
  prevFocusedInput: string | null
) => {
  renderTree(checkout, data);

  const shippingMethodsList = findElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_SHIPPING_METHODS_LIST,
    checkout
  );
  const shippingMethodsEmpty = findElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_SHIPPING_METHODS_EMPTY_STATE,
    checkout
  );
  const shippingAddressWrapper = findElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_SHIPPING_ADDRESS_WRAPPER,
    checkout
  );
  const billingAddressWrapper = findElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_BILLING_ADDRESS_WRAPPER,
    checkout
  );
  const billingAddressToggle = findElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_BILLING_ADDRESS_TOGGLE_CHECKBOX,
    checkout
  );
  const paymentInfoWrapper = checkout.querySelector(
    '.w-commerce-commercecheckoutpaymentinfowrapper'
  );
  if (
    !(shippingMethodsList instanceof Element) ||
    !(shippingAddressWrapper instanceof Element) ||
    !(billingAddressWrapper instanceof Element) ||
    !(billingAddressToggle instanceof HTMLInputElement) ||
    !(paymentInfoWrapper instanceof Element)
  ) {
    return;
  }

  if (data.data && data.data.database && data.data.database.commerceOrder) {
    const {
      data: {
        database: {
          commerceOrder: {
            availableShippingMethods,
            statusFlags: {
              requiresShipping,
              isFreeOrder,
              shippingAddressRequiresPostalCode,
              billingAddressRequiresPostalCode,
              hasSubscription,
            },
          },
        },
      },
    } = data;

    const shippingZipField = findElementByNodeType(
      NODE_TYPE_COMMERCE_CHECKOUT_SHIPPING_ADDRESS_ZIP_FIELD,
      shippingAddressWrapper
    );
    if (shippingZipField instanceof HTMLInputElement) {
      shippingZipField.required = shippingAddressRequiresPostalCode;
    }

    const billingZipField = findElementByNodeType(
      NODE_TYPE_COMMERCE_CHECKOUT_BILLING_ADDRESS_ZIP_FIELD,
      billingAddressWrapper
    );
    if (billingZipField instanceof HTMLInputElement) {
      billingZipField.required = billingAddressRequiresPostalCode;
    }

    const paypalElement = document.querySelector(
      `[${PAYPAL_ELEMENT_INSTANCE}]`
    );
    const paypalButton = checkout.querySelector(
      `[${PAYPAL_BUTTON_ELEMENT_INSTANCE}]`
    );
    if (paypalElement && paypalButton) {
      if (isFreeOrder || hasSubscription) {
        // @ts-expect-error - TS2345 - Argument of type 'Element' is not assignable to parameter of type 'HTMLElement'.
        hideElement(paypalButton);
      } else {
        // @ts-expect-error - TS2345 - Argument of type 'Element' is not assignable to parameter of type 'HTMLElement'.
        showElement(paypalButton);
      }
    }

    // Edge case: need to make sure if billing address is hidden because of default "same as shipping" checked, but
    // toggle itself is hidden because doesn't require shipping, the billing address is still visible
    if (
      !requiresShipping &&
      billingAddressToggle.checked &&
      billingAddressToggle.parentElement &&
      billingAddressToggle.parentElement.classList.contains(
        'w-condition-invisible'
      )
    ) {
      showElement(billingAddressWrapper);
    }
    if (!availableShippingMethods || availableShippingMethods.length < 1) {
      hideElement(shippingMethodsList);
      // TODO: remove this ugliness when we've properly constrained & restructured the checkout form
      // It is possible to remove the empty state so we can't return early, but don't want to crash here
      if (shippingMethodsEmpty instanceof Element) {
        showElement(shippingMethodsEmpty);
      }
    } else {
      // TODO remove after migration
      if (shippingMethodsEmpty instanceof Element) {
        hideElement(shippingMethodsEmpty);
      }
      showElement(shippingMethodsList);
    }

    if (isFreeOrder) {
      // @ts-expect-error - TS2345 - Argument of type 'Element' is not assignable to parameter of type 'HTMLElement'.
      hideElement(paymentInfoWrapper);
    } else if (
      !isFreeOrder &&
      // @ts-expect-error - TS2339 - Property 'style' does not exist on type 'Element'.
      paymentInfoWrapper.style.getPropertyValue('display') === 'none'
    ) {
      // was previously hidden
      // @ts-expect-error - TS2345 - Argument of type 'Element' is not assignable to parameter of type 'HTMLElement'.
      showElement(paymentInfoWrapper);
    }
  } else {
    hideElement(shippingMethodsList);
    // TODO remove after migration
    if (shippingMethodsEmpty instanceof Element) {
      showElement(shippingMethodsEmpty);
    }
    // @ts-expect-error - TS2345 - Argument of type 'Element' is not assignable to parameter of type 'HTMLElement'.
    showElement(paymentInfoWrapper);
  }

  if (data.errors.length === 0 && prevFocusedInput) {
    let prevFocusedInputEle = document.getElementById(prevFocusedInput);
    if (!prevFocusedInputEle) {
      prevFocusedInputEle = document.querySelector(
        `[data-wf-bindings="${prevFocusedInput}"]`
      );
    }
    if (prevFocusedInputEle) {
      prevFocusedInputEle.focus();
    }
  }
};

const runRenderOnCheckoutElement = (
  checkoutFormContainer: Element,
  data: Record<any, any>,
  errors: Array<any>,
  stripeStore: StripeStore | null | undefined,
  prevFocusedInput: string | null
) => {
  renderCheckout(
    checkoutFormContainer,
    {
      ...data,
      errors: errors.concat(data.errors).filter(Boolean),
    },
    prevFocusedInput
  );

  if (stripeStore) {
    updateWebPaymentsButton(checkoutFormContainer, data, stripeStore);
  }
};

export const renderCheckoutFormContainers = (
  checkoutFormContainers: Array<HTMLElement>,
  errors: Array<any>,
  apolloClient: ApolloClient<NormalizedCacheObject>,
  stripeStore: StripeStore | null | undefined,
  prevFocusedInput: string | null
) => {
  if (checkoutFormContainers.length === 0) {
    return;
  }

  checkoutFormContainers.forEach((checkoutFormContainer) => {
    const queryOptions = {
      query: gql`
        ${checkoutFormContainer.getAttribute(CHECKOUT_QUERY)}
      `,
      fetchPolicy: 'network-only',
      // errorPolicy is set to `all` so that we continue to get the cart data when an error occurs
      // this is important in cases like when the address entered doesn't have a shipping zone, as that returns
      // a graphQL error, but we still want to render what the customer has entered
      errorPolicy: 'all',
    } as const;
    apolloClient
      .query(queryOptions)
      .then((data) => {
        if (
          data.data &&
          data.data.database &&
          data.data.database.commerceOrder &&
          data.data.database.commerceOrder.availableShippingMethods
        ) {
          const {
            data: {
              database: {
                commerceOrder: {
                  availableShippingMethods,
                  statusFlags: {requiresShipping},
                },
              },
            },
          } = data;

          const selectedMethod = availableShippingMethods.find(
            // @ts-expect-error - TS7006 - Parameter 'method' implicitly has an 'any' type.
            (method) => method.selected === true
          );
          if (!selectedMethod && requiresShipping) {
            const id = availableShippingMethods[0]
              ? availableShippingMethods[0].id
              : null;
            return createOrderShippingMethodMutation(apolloClient, id)
              .then(() => {
                return createRecalcOrderEstimationsMutation(apolloClient);
              })
              .then(() => {
                return apolloClient.query(queryOptions);
              })

              .then((newData) => {
                runRenderOnCheckoutElement(
                  checkoutFormContainer,
                  newData,
                  errors,
                  stripeStore,
                  prevFocusedInput
                );
              });
          }
        }
        if (
          data.data &&
          data.data.database &&
          data.data.database.commerceOrder &&
          data.data.database.commerceOrder.statusFlags &&
          data.data.database.commerceOrder.statusFlags.shouldRecalc
        ) {
          return createRecalcOrderEstimationsMutation(apolloClient)
            .then(() => {
              return apolloClient.query(queryOptions);
            })

            .then((newData) => {
              runRenderOnCheckoutElement(
                checkoutFormContainer,
                newData,
                errors,
                stripeStore,
                prevFocusedInput
              );
            });
        } else {
          runRenderOnCheckoutElement(
            checkoutFormContainer,
            data,
            errors,
            stripeStore,
            prevFocusedInput
          );
        }
      })
      .catch((err) => {
        errors.push(err);
        renderCheckout(checkoutFormContainer, {errors}, prevFocusedInput);
      });
  });
};

export const createAttemptSubmitOrderRequest = (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  variables: {
    checkoutType: string;
    paymentIntentId?: string;
  }
) => {
  return apolloClient.mutate({
    mutation: attemptSubmitOrderMutation,
    variables,
  });
};

export const getOrderDataFromGraphQLResponse = (data: Record<any, any>) => {
  return data && data.data && data.data.ecommerceAttemptSubmitOrder;
};

export const orderRequiresAdditionalAction = (status: string) =>
  status === REQUIRES_ACTION;

export const redirectToOrderConfirmation = (
  order: Record<any, any>,
  isPayPal: boolean = false
) => {
  const redirectUrl = `/order-confirmation?orderId=${order.orderId}&token=${order.token}`;
  if (isPayPal) {
    const message = {
      isWebflow: true,
      type: 'success',
      detail: redirectUrl,
    } as const;
    window.parent.postMessage(JSON.stringify(message), window.location.origin);
  } else {
    window.location.href = redirectUrl;
  }
};

export const applyDiscount = (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  variables: {
    discountCode: string;
  }
) => {
  return apolloClient.mutate({
    mutation: applyDiscountMutation,
    variables,
  });
};
