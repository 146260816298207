// Server email configuration
export type MembershipsDefaultEmailFieldsType = {
  subject: string;
  heading: string;
  main: string;
  fineprint: string;
  alternateExplainer?: string;
  createAccountButton?: string;
  resetButton?: string;
  siteRedirectButton?: string;
  verifyButton?: string;
};

export type MembershipsResetPasswordEmailFieldsType =
  MembershipsDefaultEmailFieldsType & {
    alternateExplainer: string;
    resetButton: string;
  };

export type MembershipsWelcomeEmailFieldsType =
  MembershipsDefaultEmailFieldsType & {
    alternateExplainer: string;
    siteRedirectButton: string;
  };

export type MembershipsVerifyEmailFieldsType =
  MembershipsDefaultEmailFieldsType & {
    alternateExplainer: string;
    verifyButton: string;
  };

export type MembershipsInviteEmailFieldsType =
  MembershipsDefaultEmailFieldsType & {
    alternateExplainer: string;
    createAccountButton: string;
  };

export type UsysEmailType = {
  resetPassword: MembershipsResetPasswordEmailFieldsType;
  welcome: MembershipsWelcomeEmailFieldsType;
  invite: MembershipsInviteEmailFieldsType;
  updatedPassword: MembershipsDefaultEmailFieldsType;
  verify: MembershipsVerifyEmailFieldsType;
};

// Template fields configuration
export type MembershipsDefaultTemplateFieldsType = {
  currentField: string;
  siteName: string;
  userEmail: string;
  bgColor: string;
  accentColor: string;
  linkContrast: string;
  backgroundContrast: string;
  includeWfBrand: boolean;
  logo?: Record<any, any>;
};

export type MembershipsExtraTemplateFieldsType = {
  resetPasswordLink?: string;
  createAccountLink?: string;
  verifyLink?: string;
  siteRedirectLink?: string;
  userName?: string;
};

export type MembershipsEmailTemplateFieldsType =
  MembershipsDefaultTemplateFieldsType &
    MembershipsDefaultEmailFieldsType &
    MembershipsExtraTemplateFieldsType;

export type MembershipsBrandingType = {
  logo: string;
  accentColor: string;
  bgColor: string;
  includeWfBrand: boolean;
};
export type UsersSetupGuideType = {
  hasVisitedAccessDeniedPage: boolean;
  hasVisitedLoginPage: boolean;
  hasVisitedSignUpPage: boolean;
  hasVisitedUserAccountSettings: boolean;
  hasVisitedUserAccountPage: boolean;
};

export type MockUsysUser = {
  commonFields: {
    email: string;
  };
};

export const USYS_ACCESS_TYPES = {
  LOGGED_IN: 'LOGGED_IN',
  ADMIN_ALWAYS_VISIBLE: 'ADMIN_ALWAYS_VISIBLE',
} as const;

export type UserAcessMetaOption = keyof typeof USYS_ACCESS_TYPES;
export type UserAccessMeta = UserAcessMetaOption[];
export type UsysCaptcha = {siteKey: string; mode: string} | undefined;
