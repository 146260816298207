// Note: These currencies are extracted by getting PayPal compatible currencies from:
//
// curl https://developer.paypal.com/docs/api/reference/currency-codes
//
// And then populating in the names from ISO 4217:

export const paypalCurrencyList = [
  /*
   * ----------------------------------------------------------------------------
   * NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE
   * NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE
   * NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE
   * NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE
   *
   * This const is duplicated to @packages/systems/core/constants/SharedConfig.js
   * to avoid bundling this entire SharedConfig file in the webflow.js published
   * site bundle. Once we have support for bundling for the Dashboard (this
   * config is injected in to window.SharedConfig for angular), we should
   * be able to delete the const in SharedConfig and use the commerce/consts
   * package whenever `paypalCurrencyList` const is needed.
   *
   * In the meantime, if you make changes to this const, please make sure to
   * update in the other location as well.
   *
   * NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE
   * NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE
   * NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE
   * NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE
   * ----------------------------------------------------------------------------
   */
  {code: 'AUD', digits: 2, minCharge: 1, name: 'Australian Dollar'},
  {code: 'BRL', digits: 2, minCharge: 1, name: 'Brazilian Real'},
  {code: 'CAD', digits: 2, minCharge: 1, name: 'Canadian Dollar'},
  {code: 'CNY', digits: 2, minCharge: 1, name: 'Chinese Renmenbi'},
  {code: 'CZK', digits: 2, minCharge: 1, name: 'Czech Koruna'},
  {code: 'DKK', digits: 2, minCharge: 1, name: 'Danish Krone'},
  {code: 'EUR', digits: 2, minCharge: 1, name: 'Euro'},
  {code: 'HKD', digits: 2, minCharge: 1, name: 'Hong Kong Dollar'},
  // HUF is currently disabled, due to issues with PayPal's 0-decimal implementation
  // See: https://github.com/webflow/webflow/issues/32865
  // {code: 'HUF', digits: 0, minCharge: 1, name: 'Hungarian Forint'},
  {code: 'INR', digits: 2, minCharge: 1, name: 'Indian Rupee'},
  {code: 'ILS', digits: 2, minCharge: 1, name: 'Israeli New Sheqel'},
  {code: 'JPY', digits: 0, minCharge: 1, name: 'Japanese Yen'},
  {code: 'MYR', digits: 2, minCharge: 1, name: 'Malaysian Ringgit'},
  {code: 'MXN', digits: 2, minCharge: 1, name: 'Mexican Peso'},
  {code: 'TWD', digits: 0, minCharge: 1, name: 'New Taiwan Dollar'},
  {code: 'NZD', digits: 2, minCharge: 1, name: 'New Zealand Dollar'},
  {code: 'NOK', digits: 2, minCharge: 1, name: 'Norwegian Krone'},
  {code: 'PHP', digits: 2, minCharge: 1, name: 'Philippine Peso'},
  {code: 'PLN', digits: 2, minCharge: 1, name: 'Polish Złoty'},
  {code: 'GBP', digits: 2, minCharge: 1, name: 'British Pound'},
  {code: 'RUB', digits: 2, minCharge: 1, name: 'Russian Ruble'},
  {code: 'SGD', digits: 2, minCharge: 1, name: 'Singapore Dollar'},
  {code: 'SEK', digits: 2, minCharge: 1, name: 'Swedish Krona'},
  {code: 'CHF', digits: 2, minCharge: 1, name: 'Swiss Franc'},
  {code: 'THB', digits: 2, minCharge: 1, name: 'Thai Baht'},
  {code: 'USD', digits: 2, minCharge: 1, name: 'United States Dollar'},
] as const;
