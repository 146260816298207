const {create} = Object;

const VALUE: unique symbol = Symbol();

export interface IdentityType<A> {
  [VALUE]: A;
  map: <B>(f: (x: A) => B) => IdentityType<B>;
}

const prototype = {
  map<A, B>(this: IdentityType<A>, f: (x: A) => B) {
    return Identity(f(this[VALUE]));
  },
} as const;

export const Identity = <A>(value: A): IdentityType<A> => {
  const object = create(prototype);
  object[VALUE] = value;
  return object;
};

export const of = Identity;
export const runIdentity = <A>(object: IdentityType<A>): A => object[VALUE];
