/* globals window, Element, CustomEvent, HTMLElement */
import debounce from 'lodash/debounce';
import {
  DATA_ATTR_NODE_TYPE,
  NODE_TYPE_COMMERCE_CHECKOUT_FORM_CONTAINER,
  NODE_TYPE_COMMERCE_CHECKOUT_CUSTOMER_INFO_WRAPPER,
  NODE_TYPE_COMMERCE_CHECKOUT_SHIPPING_ADDRESS_WRAPPER,
  NODE_TYPE_COMMERCE_CHECKOUT_BILLING_ADDRESS_WRAPPER,
  NODE_TYPE_COMMERCE_CHECKOUT_SHIPPING_METHODS_WRAPPER,
  NODE_TYPE_COMMERCE_CHECKOUT_BILLING_ADDRESS_TOGGLE_CHECKBOX,
  NODE_TYPE_COMMERCE_CHECKOUT_PLACE_ORDER_BUTTON,
  NODE_TYPE_COMMERCE_CHECKOUT_ERROR_STATE,
  NODE_TYPE_COMMERCE_CHECKOUT_ADDITIONAL_INFO,
  NODE_TYPE_COMMERCE_PAYPAL_CHECKOUT_FORM_CONTAINER,
  NODE_TYPE_COMMERCE_CHECKOUT_DISCOUNT_FORM,
  NODE_TYPE_COMMERCE_CHECKOUT_DISCOUNT_INPUT,
  DATA_ATTR_LOADING_TEXT,
  CHECKOUT_PLACE_ORDER_LOADING_TEXT_DEFAULT,
  CHECKOUT_PLACE_ORDER_BUTTON_TEXT_DEFAULT,
  RENDER_TREE_EVENT,
  NEEDS_REFRESH,
  STRIPE_ELEMENT_INSTANCE,
  CART_CHECKOUT_ERROR_MESSAGE_SELECTOR,
} from '@packages/systems/commerce/constants';
import EventHandlerProxyWithApolloClient from './eventHandlerProxyWithApolloClient';
import {
  findElementByNodeType,
  findClosestElementByNodeType,
  findAllElementsByNodeType,
  triggerRender,
  formToObject,
  customDataFormToArray,
  fetchOrderStatusFlags,
} from './commerceUtils';
import {
  showErrorMessageForError,
  updateErrorMessage,
  beforeUnloadHandler,
  renderCheckoutFormContainers,
  createAttemptSubmitOrderRequest,
  getOrderDataFromGraphQLResponse,
  orderRequiresAdditionalAction,
  redirectToOrderConfirmation,
  createOrderIdentityMutation,
  createOrderAddressMutation,
  createOrderShippingMethodMutation,
  createCustomDataMutation,
  createStripePaymentMethodMutation,
  applyDiscount,
} from './checkoutUtils';
import debug from './debug';
import {ApolloClient, NormalizedCacheObject} from '@apollo/client';
import {StripeStore} from './stripeStore';

// @ts-expect-error - TS7031 - Binding element 'target' implicitly has an 'any' type.
const isInputInsideCustomerInfoEvent = ({target}) => {
  // ensures this event's logic doesn't run on non-checkout pages
  const checkoutFormContainer = findElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_FORM_CONTAINER
  );
  if (!checkoutFormContainer) {
    return false;
  }

  const customerInfoWrapper = findClosestElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_CUSTOMER_INFO_WRAPPER,
    target
  );
  if (
    customerInfoWrapper &&
    target instanceof Element &&
    target.tagName === 'INPUT'
  ) {
    return target;
  } else {
    return false;
  }
};

// @ts-expect-error - TS7031 - Binding element 'target' implicitly has an 'any' type.
const isInputInsideAddressWrapperEvent = ({target}) => {
  // ensures this event's logic doesn't run on non-checkout pages
  const checkoutFormContainer = findElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_FORM_CONTAINER
  );
  if (!checkoutFormContainer || !(target instanceof Element)) {
    return false;
  }

  const shippingAddressWrapper = findClosestElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_SHIPPING_ADDRESS_WRAPPER,
    target
  );
  const billingAddressWrapper = findClosestElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_BILLING_ADDRESS_WRAPPER,
    target
  );

  if (shippingAddressWrapper) {
    return shippingAddressWrapper;
  } else if (billingAddressWrapper) {
    return billingAddressWrapper;
  } else {
    return false;
  }
};

// @ts-expect-error - TS7031 - Binding element 'target' implicitly has an 'any' type.
const isInputInsideShippingMethodEvent = ({target}) => {
  // ensures this event's logic doesn't run on non-checkout pages
  const checkoutFormContainer =
    findElementByNodeType(NODE_TYPE_COMMERCE_CHECKOUT_FORM_CONTAINER) ||
    findElementByNodeType(NODE_TYPE_COMMERCE_PAYPAL_CHECKOUT_FORM_CONTAINER);
  if (!checkoutFormContainer) {
    return false;
  }

  const shippingMethodWrapper = findClosestElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_SHIPPING_METHODS_WRAPPER,
    target
  );
  if (
    shippingMethodWrapper &&
    target instanceof Element &&
    target.tagName === 'INPUT'
  ) {
    return target;
  } else {
    return false;
  }
};

// @ts-expect-error - TS7031 - Binding element 'target' implicitly has an 'any' type.
const isBillingAddressToggleEvent = ({target}) => {
  if (
    target instanceof Element &&
    target.getAttribute(DATA_ATTR_NODE_TYPE) ===
      NODE_TYPE_COMMERCE_CHECKOUT_BILLING_ADDRESS_TOGGLE_CHECKBOX
  ) {
    return target;
  } else {
    return false;
  }
};

// @ts-expect-error - TS7031 - Binding element 'target' implicitly has an 'any' type.
const isPlaceOrderButtonEvent = ({target}) => {
  const placeOrderButton = findClosestElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_PLACE_ORDER_BUTTON,
    target
  );
  if (placeOrderButton && target instanceof Element) {
    return target;
  } else {
    return false;
  }
};

// @ts-expect-error - TS7031 - Binding element 'target' implicitly has an 'any' type.
const isApplyDiscountFormEvent = ({target}) => {
  if (
    target instanceof Element &&
    target.getAttribute(DATA_ATTR_NODE_TYPE) ===
      NODE_TYPE_COMMERCE_CHECKOUT_DISCOUNT_FORM
  ) {
    return target;
  } else {
    return false;
  }
};

// @ts-expect-error - TS7031 - Binding element 'target' implicitly has an 'any' type.
const isFormInsideCheckoutContainerEvent = ({target}) => {
  const checkoutForm = findClosestElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_FORM_CONTAINER,
    target
  );
  if (target instanceof HTMLFormElement && checkoutForm) {
    return target;
  } else {
    return false;
  }
};

// @ts-expect-error - TS7031 - Binding element 'target' implicitly has an 'any' type.
const isInputInsideCheckoutFormEvent = ({target}) => {
  const checkoutForm = findClosestElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_FORM_CONTAINER,
    target
  );
  if (target instanceof HTMLInputElement && checkoutForm) {
    return target;
  } else {
    return false;
  }
};

const handleRenderCheckout = (
  event: Event | CustomEvent,
  apolloClient: ApolloClient<NormalizedCacheObject>,
  stripeStore: StripeStore
) => {
  if (window.Webflow.env('design') || window.Webflow.env('preview')) {
    return;
  }
  if (!(event instanceof CustomEvent && event.type === RENDER_TREE_EVENT)) {
    return;
  }
  const errors: Array<any> = [];
  const {detail} = event;
  if (detail != null && detail.error) {
    errors.push(detail.error);
  }

  const focusedEle = window.document.activeElement;
  const checkoutForm = findClosestElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_FORM_CONTAINER,
    // @ts-expect-error - TS2345 - Argument of type 'Element | null' is not assignable to parameter of type 'EventTarget'.
    focusedEle
  );

  let prevFocusedInput = null;
  // Only trigger for focused elements in a checkout form
  if (focusedEle instanceof HTMLInputElement && checkoutForm) {
    prevFocusedInput = focusedEle.id;
    if (!prevFocusedInput) {
      prevFocusedInput = focusedEle.getAttribute('data-wf-bindings');
    }

    // Move from empty string to null
    prevFocusedInput = prevFocusedInput ? null : prevFocusedInput;
  }

  const checkoutFormContainers = findAllElementsByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_FORM_CONTAINER
  );

  renderCheckoutFormContainers(
    checkoutFormContainers,
    errors,
    apolloClient,
    stripeStore,
    prevFocusedInput
  );
};

const checkFormValidity = ({
  customerInfo,
  shippingAddress,
  shippingInfo,
  billingAddress,
  billingAddressToggle,
  additionalInfo,
  requiresShipping,
}: {
  customerInfo: HTMLFormElement;
  shippingAddress: HTMLFormElement;
  shippingInfo: HTMLFormElement;
  billingAddress: HTMLFormElement;
  billingAddressToggle: HTMLInputElement;
  additionalInfo: HTMLElement | null | undefined;
  requiresShipping: boolean;
}) => {
  // reportValidity isn't supported in IE, so we need to check if it exists
  // for our IE users. they'll have to rely on the server error, which isn't
  // too bad from a UX experience, since they'll still know something went wrong
  if (!HTMLFormElement.prototype.reportValidity) {
    return true;
  }

  // because we have multiple form elements, what we do is ask the browser to report
  // the validity of each form, which triggers the UI that would usually be seen
  // when someone submitted a regular form. we return if it's not valid, so that
  // the browser doesn't jump ahead to the next element, allowing the user to
  // fix their mistakes. we don't check the stripe elements, since we can't
  // directly get the status of those, as that's handled by stripe. however, if
  // the user is missing a field and tries to submit the form, they will get
  // an error when we try to create the token, so we'll display an error then
  if (
    !customerInfo.reportValidity() ||
    (requiresShipping && !shippingAddress.reportValidity()) ||
    (requiresShipping && !shippingInfo.reportValidity()) ||
    // only check the billing address if the toggle is off, i.e. the billing address
    // form is being shown or if it does not require shipping
    ((!requiresShipping || !billingAddressToggle.checked) &&
      !billingAddress.reportValidity()) ||
    (additionalInfo &&
      additionalInfo instanceof HTMLFormElement &&
      !additionalInfo.reportValidity())
  ) {
    return false;
  }

  return true;
};

let placingOrder = false;
const startOrderFlow = (placeOrderButton: HTMLElement) => {
  placingOrder = true;
  window.addEventListener('beforeunload', beforeUnloadHandler);

  const buttonText = placeOrderButton.innerHTML;
  const loadingText = placeOrderButton.getAttribute(DATA_ATTR_LOADING_TEXT);
  placeOrderButton.innerHTML = loadingText
    ? loadingText
    : CHECKOUT_PLACE_ORDER_LOADING_TEXT_DEFAULT;

  const finishOrderFlow = (isRedirecting: boolean = false) => {
    // we only set `placingOrder` to false if we're not redirecting to the
    // confirmation page. this is so that while waiting for the confirmation
    // page to load, the user can't attempt to submit the order again
    if (!isRedirecting) {
      placingOrder = false;
    }
    window.removeEventListener('beforeunload', beforeUnloadHandler);
    placeOrderButton.innerHTML = buttonText
      ? buttonText
      : CHECKOUT_PLACE_ORDER_BUTTON_TEXT_DEFAULT;
  };

  return finishOrderFlow;
};

const handlePlaceOrder = (
  event: Event,
  apolloClient: ApolloClient<NormalizedCacheObject>,
  stripeStore: StripeStore
) => {
  // Want to skip placing order if in design/preview mode, or an order place is in progress
  if (
    window.Webflow.env('design') ||
    window.Webflow.env('preview') ||
    placingOrder
  ) {
    return;
  }

  const {currentTarget} = event;

  if (!(currentTarget instanceof Element)) {
    return;
  }

  const checkoutFormContainer = findClosestElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_FORM_CONTAINER,
    currentTarget
  );
  if (!(checkoutFormContainer instanceof Element)) {
    return;
  }

  const errorState = findElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_ERROR_STATE,
    checkoutFormContainer
  );
  const customerInfo = findElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_CUSTOMER_INFO_WRAPPER,
    checkoutFormContainer
  );
  const shippingAddress = findElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_SHIPPING_ADDRESS_WRAPPER,
    checkoutFormContainer
  );
  const shippingInfo = findElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_SHIPPING_METHODS_WRAPPER,
    checkoutFormContainer
  );
  const billingAddress = findElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_BILLING_ADDRESS_WRAPPER,
    checkoutFormContainer
  );
  const billingAddressToggle = findElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_BILLING_ADDRESS_TOGGLE_CHECKBOX,
    checkoutFormContainer
  );
  const placeOrderButton = findElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_PLACE_ORDER_BUTTON,
    checkoutFormContainer
  );
  const additionalInfo = findElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_ADDITIONAL_INFO,
    checkoutFormContainer
  );

  if (
    !(errorState instanceof HTMLElement) ||
    !(customerInfo instanceof HTMLFormElement) ||
    !(shippingAddress instanceof HTMLFormElement) ||
    !(shippingInfo instanceof HTMLFormElement) ||
    !(billingAddress instanceof HTMLFormElement) ||
    !(billingAddressToggle instanceof HTMLInputElement) ||
    !(placeOrderButton instanceof Element)
  ) {
    return;
  }

  const errorMessage = errorState.querySelector(
    CART_CHECKOUT_ERROR_MESSAGE_SELECTOR
  );

  // If the error message has this attribute, we want to block the order from
  // being submitted as the user is being forced to refresh the checkout page.
  if (errorMessage && errorMessage.hasAttribute(NEEDS_REFRESH)) {
    return;
  }

  const hasAdditionalInfo =
    additionalInfo && additionalInfo instanceof HTMLElement;

  const finishOrderFlow = startOrderFlow(placeOrderButton);

  errorState.style.setProperty('display', 'none');

  fetchOrderStatusFlags(apolloClient).then(
    ({requiresShipping, isFreeOrder}) => {
      const isFormValid = checkFormValidity({
        customerInfo,
        shippingAddress,
        shippingInfo,
        billingAddress,
        billingAddressToggle,
        additionalInfo,
        requiresShipping,
      });

      if (!isFormValid) {
        finishOrderFlow();
        return;
      }

      // final sync with server, to ensure validity
      const customerInfoFormData = formToObject(customerInfo);
      const email = String(customerInfoFormData.email).trim();
      const shippingAddressInfo = {
        type: 'shipping',
        ...formToObject(shippingAddress, true),
      } as const;
      const billingAddressInfo = {
        type: 'billing',
        ...formToObject(
          !billingAddressToggle.checked || !requiresShipping
            ? billingAddress
            : shippingAddress,
          true
        ),
      } as const;
      const stripeBillingAddressInfo = {
        billing_details: {
          // @ts-expect-error - TS2339 - Property 'name' does not exist on type '{ readonly type: "billing"; }'.
          name: billingAddressInfo.name,
          email,
          address: {
            // @ts-expect-error - TS2339 - Property 'address_line1' does not exist on type '{ readonly type: "billing"; }'.
            line1: billingAddressInfo.address_line1,
            // @ts-expect-error - TS2339 - Property 'address_line2' does not exist on type '{ readonly type: "billing"; }'.
            line2: billingAddressInfo.address_line2,
            // @ts-expect-error - TS2339 - Property 'address_city' does not exist on type '{ readonly type: "billing"; }'.
            city: billingAddressInfo.address_city,
            // @ts-expect-error - TS2339 - Property 'address_state' does not exist on type '{ readonly type: "billing"; }'.
            state: billingAddressInfo.address_state,
            // @ts-expect-error - TS2339 - Property 'address_country' does not exist on type '{ readonly type: "billing"; }'.
            country: billingAddressInfo.address_country,
            // @ts-expect-error - TS2339 - Property 'address_zip' does not exist on type '{ readonly type: "billing"; }'.
            postal_code: billingAddressInfo.address_zip,
          },
        },
      } as const;

      let shippingMethodId = '';
      // @ts-expect-error - TS7015 - Element implicitly has an 'any' type because index expression is not of type 'number'.
      if (requiresShipping && shippingInfo.elements['shipping-method-choice']) {
        // this is an IE11-safe way of just doing shippingInfo.elements['shipping-method-choice'].value
        const shippingMethodChoice = shippingInfo.querySelector(
          'input[name="shipping-method-choice"]:checked'
        ) as HTMLInputElement | null | undefined;

        // this should never be falsey, but Flow
        if (shippingMethodChoice) {
          shippingMethodId = shippingMethodChoice.value;
        }
      }

      const customData = hasAdditionalInfo
        ? customDataFormToArray(additionalInfo)
        : [];

      const syncCheckoutForm = Promise.all([
        createOrderIdentityMutation(apolloClient, email),
        createOrderAddressMutation(apolloClient, billingAddressInfo),
        requiresShipping
          ? createOrderAddressMutation(apolloClient, shippingAddressInfo)
          : Promise.resolve(),
        requiresShipping
          ? createOrderShippingMethodMutation(apolloClient, shippingMethodId)
          : Promise.resolve(),
        hasAdditionalInfo
          ? // @ts-expect-error - TS2345 - Argument of type 'any[]' is not assignable to parameter of type '[]'.
            createCustomDataMutation(apolloClient, customData)
          : Promise.resolve(),
      ]);

      syncCheckoutForm
        .then(() => {
          if (isFreeOrder) {
            return Promise.resolve();
          }

          if (!stripeStore.isInitialized()) {
            return Promise.reject(
              new Error(
                "Stripe has not been set up for this project – Go to the project's Ecommerce Payment settings in the Designer to link Stripe."
              )
            );
          }

          const stripe = stripeStore.getStripeInstance();
          const checkoutFormInstance = parseInt(
            // @ts-expect-error - TS2345 - Argument of type 'string | null' is not assignable to parameter of type 'string'.
            checkoutFormContainer.getAttribute(STRIPE_ELEMENT_INSTANCE),
            10
          );
          const card = stripeStore.getElement(
            'cardNumber',
            checkoutFormInstance
          );
          return stripe.createPaymentMethod(
            'card',
            card,
            stripeBillingAddressInfo
          );
        })
        // @ts-expect-error - Argument of type '(data: any) => Promise<FetchResult<any>> | Promise<void>' is not assignable to parameter of type '(value: any) => FetchResult<any> | PromiseLike<FetchResult<any>>'.
        .then((data) => {
          if (!data || isFreeOrder) {
            return Promise.resolve();
          }

          if (data.error) {
            return Promise.reject(data.error);
          }

          return createStripePaymentMethodMutation(
            apolloClient,
            data.paymentMethod.id
          );
        })
        .then(() => {
          return createAttemptSubmitOrderRequest(apolloClient, {
            checkoutType: 'normal',
          });
        })
        .then((data) => {
          debug.log(data);
          const order = getOrderDataFromGraphQLResponse(data);
          if (orderRequiresAdditionalAction(order.status)) {
            const stripe = stripeStore.getStripeInstance();
            return (
              stripe
                .retrievePaymentIntent(order.clientSecret)
                // @ts-expect-error - TS7006 - Parameter 'retrieveResult' implicitly has an 'any' type.
                .then((retrieveResult) => {
                  const intent =
                    (retrieveResult && retrieveResult.paymentIntent) || {};
                  const actionPromise =
                    intent.confirmation_method === 'automatic'
                      ? stripe.confirmCardPayment(order.clientSecret)
                      : stripe.handleCardAction(order.clientSecret);

                  // @ts-expect-error - TS7006 - Parameter 'result' implicitly has an 'any' type.
                  return actionPromise.then((result) => {
                    if (result.error) {
                      return Promise.reject(result.error);
                    }

                    return createAttemptSubmitOrderRequest(apolloClient, {
                      checkoutType: 'normal',
                      paymentIntentId: result.paymentIntent.id,
                    }).then((resp) => {
                      const finishedOrder =
                        getOrderDataFromGraphQLResponse(resp);

                      if (finishedOrder.ok) {
                        finishOrderFlow(true);
                        redirectToOrderConfirmation(finishedOrder);
                      }
                    });
                  });
                })
            );
          }
          if (order.ok) {
            finishOrderFlow(true);
            redirectToOrderConfirmation(order);
          }
        })
        .catch((err) => {
          finishOrderFlow();
          debug.error(err);
          errorState.style.removeProperty('display');
          updateErrorMessage(errorState, err);
        });
    }
  );
};

// @ts-expect-error - TS2314 - Generic type 'ApolloClient<TCacheShape>' requires 1 type argument(s).
const handleApplyDiscount = (event: Event, apolloClient: ApolloClient) => {
  event.preventDefault(); // prevent submit
  event.stopImmediatePropagation(); // do not trigger submission of any other forms (we have forms in forms :())

  const {currentTarget} = event;
  if (!(currentTarget instanceof Element)) {
    return;
  }

  const inputEl = findElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_DISCOUNT_INPUT,
    currentTarget
  );
  const checkoutFormContainer =
    findClosestElementByNodeType(
      NODE_TYPE_COMMERCE_CHECKOUT_FORM_CONTAINER,
      currentTarget
    ) ||
    findClosestElementByNodeType(
      NODE_TYPE_COMMERCE_PAYPAL_CHECKOUT_FORM_CONTAINER,
      currentTarget
    );
  if (!checkoutFormContainer) {
    return;
  }
  const errorStateEl = findElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_ERROR_STATE,
    checkoutFormContainer
  );

  if (
    !(
      inputEl instanceof HTMLInputElement && errorStateEl instanceof HTMLElement
    )
  ) {
    return;
  }

  const discountCode = inputEl.value.trim().toUpperCase();
  applyDiscount(apolloClient, {discountCode})
    .then(() => {
      inputEl.value = '';
      errorStateEl.style.display = 'none';
      triggerRender(null);
    })
    .catch((error) => showErrorMessageForError(error, checkoutFormContainer));
};

// @ts-expect-error - TS2314 - Generic type 'ApolloClient<TCacheShape>' requires 1 type argument(s).
const handleUpdateCustomerInfo = (event: Event, apolloClient: ApolloClient) => {
  const {currentTarget} = event;

  if (!(currentTarget instanceof HTMLInputElement)) {
    return;
  }

  const value = currentTarget.value.trim();
  const email = value == null || value === '' ? null : value;

  createOrderIdentityMutation(apolloClient, email)
    .then(() => {
      triggerRender(null);
    })
    .catch((err) => {
      triggerRender(err);
    });
};

const handleUpdateAddress = debounce((event, apolloClient) => {
  const {currentTarget} = event;

  if (!(currentTarget instanceof HTMLFormElement)) {
    return;
  }

  const type =
    currentTarget.getAttribute(DATA_ATTR_NODE_TYPE) ===
    NODE_TYPE_COMMERCE_CHECKOUT_SHIPPING_ADDRESS_WRAPPER
      ? 'shipping'
      : 'billing';
  const addressInfo = {
    type,
    ...formToObject(currentTarget, true),
  } as const;

  createOrderAddressMutation(apolloClient, addressInfo)
    .then(() => {
      triggerRender(null);
    })
    .catch((err) => {
      triggerRender(err);
    });
}, 500);

// @ts-expect-error - TS7031 - Binding element 'currentTarget' implicitly has an 'any' type.
const handleToggleBillingAddress = ({currentTarget}) => {
  const checkoutFormContainer = findClosestElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_FORM_CONTAINER,
    currentTarget
  );
  if (!checkoutFormContainer) {
    return;
  }

  const billingAddressWrapper = findElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_BILLING_ADDRESS_WRAPPER,
    checkoutFormContainer
  );
  if (!billingAddressWrapper || !(currentTarget instanceof HTMLInputElement)) {
    return;
  }

  if (currentTarget.checked) {
    billingAddressWrapper.style.setProperty('display', 'none');
  } else {
    billingAddressWrapper.style.removeProperty('display');
  }
};

const handleChooseShippingMethod = (
  // @ts-expect-error - TS7031 - Binding element 'currentTarget' implicitly has an 'any' type.
  {currentTarget},
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  if (!(currentTarget instanceof HTMLInputElement)) {
    return;
  }

  createOrderShippingMethodMutation(apolloClient, currentTarget.id)
    .then(() => {
      triggerRender(null);
    })
    .catch((err) => {
      triggerRender(err);
    });
};

const handleSubmitFormInsideCheckoutContainer = (
  event: Event | KeyboardEvent,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  if (event.type === 'submit') {
    event.preventDefault();
  }

  if (
    // @ts-expect-error - TS2339 - Property 'keyCode' does not exist on type 'Event | KeyboardEvent'.
    (event.type === 'keyup' && event.keyCode !== 13) ||
    !(event.currentTarget instanceof Element)
  ) {
    return;
  }

  if (
    event.target ===
    findElementByNodeType(NODE_TYPE_COMMERCE_CHECKOUT_DISCOUNT_INPUT)
  ) {
    return;
  }

  const checkoutFormContainer = findClosestElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_FORM_CONTAINER,
    event.currentTarget
  );
  if (!(checkoutFormContainer instanceof Element)) {
    return;
  }

  const customerInfo = findElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_CUSTOMER_INFO_WRAPPER,
    checkoutFormContainer
  );
  const shippingAddress = findElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_SHIPPING_ADDRESS_WRAPPER,
    checkoutFormContainer
  );
  const shippingInfo = findElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_SHIPPING_METHODS_WRAPPER,
    checkoutFormContainer
  );
  const billingAddress = findElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_BILLING_ADDRESS_WRAPPER,
    checkoutFormContainer
  );
  const billingAddressToggle = findElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_BILLING_ADDRESS_TOGGLE_CHECKBOX,
    checkoutFormContainer
  );
  const additionalInfo = findElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_ADDITIONAL_INFO,
    checkoutFormContainer
  );

  if (
    !(customerInfo instanceof HTMLFormElement) ||
    !(shippingAddress instanceof HTMLFormElement) ||
    !(shippingInfo instanceof HTMLFormElement) ||
    !(billingAddress instanceof HTMLFormElement) ||
    !(billingAddressToggle instanceof HTMLInputElement)
  ) {
    return;
  }

  const hasAdditionalInfo =
    additionalInfo && additionalInfo instanceof HTMLFormElement;

  fetchOrderStatusFlags(apolloClient).then(({requiresShipping}) => {
    checkFormValidity({
      customerInfo,
      shippingAddress,
      shippingInfo,
      billingAddress,
      billingAddressToggle,
      additionalInfo: hasAdditionalInfo ? additionalInfo : null,
      requiresShipping,
    });
  });
};

export const register = (handlerProxy: EventHandlerProxyWithApolloClient) => {
  handlerProxy.on(RENDER_TREE_EVENT, Boolean, handleRenderCheckout);
  handlerProxy.on('click', isPlaceOrderButtonEvent, handlePlaceOrder);
  handlerProxy.on(
    'keydown',
    isPlaceOrderButtonEvent,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (event, apolloClient, StripeStore) => {
      // @ts-expect-error - TS2339 - Property 'which' does not exist on type 'Event'.
      if (event.which === 32) {
        // prevent scrolling on spacebar key press
        event.preventDefault();
      }
      // @ts-expect-error - TS2339 - Property 'which' does not exist on type 'Event'.
      if (event.which === 13) {
        // enter key press
        return handlePlaceOrder(event, apolloClient, StripeStore);
      }
    }
  );
  handlerProxy.on(
    'keyup',
    isPlaceOrderButtonEvent,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (event, apolloClient, StripeStore) => {
      // @ts-expect-error - TS2339 - Property 'which' does not exist on type 'Event'.
      if (event.which === 32) {
        // spacebar key press
        return handlePlaceOrder(event, apolloClient, StripeStore);
      }
    }
  );
  handlerProxy.on('submit', isApplyDiscountFormEvent, handleApplyDiscount);

  // we use blur events on the text inputs, and change event on the country select
  // so that we update faster (blur on a select element requires going into another field)
  handlerProxy.on(
    'change',
    isInputInsideCustomerInfoEvent,
    handleUpdateCustomerInfo
  );
  handlerProxy.on(
    'change',
    isInputInsideAddressWrapperEvent,
    handleUpdateAddress
  );
  handlerProxy.on(
    'change',
    isBillingAddressToggleEvent,
    handleToggleBillingAddress
  );
  handlerProxy.on(
    'change',
    isInputInsideShippingMethodEvent,
    handleChooseShippingMethod
  );
  handlerProxy.on(
    'submit',
    isFormInsideCheckoutContainerEvent,
    handleSubmitFormInsideCheckoutContainer
  );
  // we have to add a keyup event for the enter key on forms to run the validity check
  // as forms with multiple inputs but no submit input don't fire the submit event
  // however we do still need that above submit check, as the email form only has one
  // input, and a browser will fire the submit event if the form has just one input
  // even if it has no submit input
  handlerProxy.on(
    'keyup',
    isInputInsideCheckoutFormEvent,
    handleSubmitFormInsideCheckoutContainer
  );
};

export default {register};
