import gql from 'graphql-tag';

export const updateOrderIdentityMutation = gql`
  mutation CheckoutUpdateOrderIdentity($email: String) {
    ecommerceUpdateIdentity(email: $email) {
      ok
    }
  }
`;

export const updateOrderAddressMutation = gql`
  mutation CheckoutUpdateOrderAddress(
    $type: String!
    $name: String
    $address_line1: String
    $address_line2: String
    $address_city: String
    $address_state: String
    $address_country: String
    $address_zip: String
  ) {
    ecommerceUpdateAddress(
      type: $type
      addressee: $name
      line1: $address_line1
      line2: $address_line2
      city: $address_city
      state: $address_state
      country: $address_country
      postalCode: $address_zip
    ) {
      ok
    }
  }
`;

export const updateObfuscatedOrderAddressMutation = gql`
  mutation CheckoutUpdateObfuscatedOrderAddress(
    $type: String!
    $name: String
    $address_line1: String
    $address_line2: String
    $address_city: String
    $address_state: String
    $address_country: String
    $address_zip: String
  ) {
    ecommerceUpdateObfuscatedAddress(
      type: $type
      addressee: $name
      line1: $address_line1
      line2: $address_line2
      city: $address_city
      state: $address_state
      country: $address_country
      postalCode: $address_zip
    ) {
      ok
    }
  }
`;

export const updateOrderShippingMethodMutation = gql`
  mutation CheckoutUpdateShippingMethod($id: String) {
    ecommerceUpdateShippingMethod(methodId: $id) {
      ok
    }
  }
`;

export const updateOrderStripePaymentMethodMutation = gql`
  mutation CheckoutUpdateStripePaymentMethod($paymentMethod: String!) {
    ecommerceStoreStripePaymentMethod(paymentMethod: $paymentMethod) {
      ok
    }
  }
`;

export const updateCustomData = gql`
  mutation CheckoutUpdateCustomData(
    $customData: [mutation_commerce_update_custom_data]!
  ) {
    ecommerceUpdateCustomData(customData: $customData) {
      ok
    }
  }
`;

export const estimateOrderTaxesMutation = gql`
  mutation CheckoutEstimateOrderTaxes {
    ecommerceEstimateTaxes {
      ok
    }
  }
`;

export const recalcOrderEstimationsMutation = gql`
  mutation CheckoutRecalcOrderEstimations {
    ecommerceRecalcEstimations {
      ok
    }
  }
`;

export const requestPayPalOrderMutation = gql`
  mutation CheckoutRequestPayPalOrder {
    ecommercePaypalOrderRequest {
      orderId
    }
  }
`;

export const syncPayPalOrderInfo = gql`
  mutation CheckoutSyncPayPalInfo {
    ecommerceSyncPaypalOrderInfoToWF {
      ok
    }
  }
`;

export const attemptSubmitOrderMutation = gql`
  mutation CheckoutAttemptSubmitOrder(
    $checkoutType: mutation_commerce_checkout_type
    $paymentIntentId: String
  ) {
    ecommerceAttemptSubmitOrder(
      checkoutType: $checkoutType
      paymentIntentId: $paymentIntentId
    ) {
      orderId
      token
      ok
      customerPaid {
        decimalValue
        unit
      }
      purchasedItems {
        id
        name
        count
        price {
          decimalValue
        }
      }
      status
      clientSecret
      nextAction
    }
  }
`;

export const applyDiscountMutation = gql`
  mutation CheckoutApplyDiscount($discountCode: String!) {
    ecommerceApplyDiscount(discountCode: $discountCode) {
      ok
    }
  }
`;
