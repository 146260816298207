export type ErrorStateType = {
  id: string;
  copy: string;
  name: string;
  path: Array<string>;
};

export const ERROR_MSG_CLASS = 'user-form-error-msg';

export const ERROR_STATE = {
  SIGNUP: 'signup-error-state',
  LOGIN: 'login-error-state',
  UPDATE_PASSWORD: 'update-password-error-state',
  RESET_PASSWORD: 'reset-password-error-state',
  ACCOUNT_UPDATE: 'account-update-error-state',
} as const;

export const ErrorStateToCopy = (
  errorStateType: (typeof ERROR_STATE)[keyof typeof ERROR_STATE],
  id: string
): string | null => {
  if (errorStateType === 'signup-error-state') {
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Partial<Record<"GENERAL_ERROR" | "NOT_ALLOWED" | "NOT_VERIFIED" | "EMAIL_ALREADY_EXIST" | "USE_INVITE_EMAIL" | "INVALID_EMAIL" | "INVALID_PASSWORD" | "EXPIRED_TOKEN" | "VALIDATION_FAILED" | "REQUIRED", ErrorStateType>>'.
    return signUpErrorStates[id]?.copy ?? null;
  }
  if (errorStateType === 'login-error-state') {
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Partial<Record<"GENERAL_ERROR" | "INVALID_EMAIL_OR_PASSWORD", ErrorStateType>>'.
    return logInErrorStates[id]?.copy ?? null;
  }
  if (errorStateType === 'update-password-error-state') {
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Partial<Record<"GENERAL_ERROR" | "WEAK_PASSWORD", ErrorStateType>>'.
    return updatePasswordErrorStates[id]?.copy ?? null;
  }
  if (errorStateType === 'reset-password-error-state') {
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Partial<Record<"GENERAL_ERROR", ErrorStateType>>'.
    return resetPasswordErrorStates[id]?.copy ?? null;
  }
  if (errorStateType === 'account-update-error-state') {
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Partial<Record<"GENERAL_ERROR", ErrorStateType>>'.
    return updateAccountErrorStates[id]?.copy ?? null;
  }
  console.error(`copy for ${errorStateType} not found`);
  return null;
};

// error codes returned after field validation. warning: shipped js will use this so add but never remove from it.
export const SERVER_DATA_VALIDATION_ERRORS = {
  RequiredError: 'EmptyValue',
  MinSizeError: 'MinSizeError',
  MaxSizeError: 'MaxSizeError',
  ExtensionsError: 'ExtensionsError',
  DefaultError: 'DefaultError',
} as const;

export const LOGIN_UI_ERROR_CODES = {
  GENERAL_ERROR: 'GENERAL_ERROR',
  INVALID_EMAIL_OR_PASSWORD: 'INVALID_EMAIL_OR_PASSWORD',
} as const;

export const SIGNUP_UI_ERROR_CODES = {
  GENERAL_ERROR: 'GENERAL_ERROR',
  NOT_ALLOWED: 'NOT_ALLOWED',
  NOT_VERIFIED: 'NOT_VERIFIED',
  EMAIL_ALREADY_EXIST: 'EMAIL_ALREADY_EXIST',
  USE_INVITE_EMAIL: 'USE_INVITE_EMAIL',
  INVALID_EMAIL: 'INVALID_EMAIL',
  INVALID_PASSWORD: 'INVALID_PASSWORD',
  EXPIRED_TOKEN: 'EXPIRED_TOKEN',
  VALIDATION_FAILED: 'VALIDATION_FAILED',
  REQUIRED: 'REQUIRED',
} as const;

export const ERROR_ATTRIBUTE_PREFIX = {
  SIGNUP: 'wf-signup-form',
  LOGIN: 'wf-login-form',
  RESET_PASSWORD: 'wf-reset-pw-form',
  UPDATE_PASSWORD: 'wf-update-pw-form',
  ACCOUNT_UPDATE: 'wf-account-update-form',
} as const;

export const UPDATE_PASSWORD_UI_ERROR_CODES = {
  GENERAL_ERROR: 'GENERAL_ERROR',
  WEAK_PASSWORD: 'WEAK_PASSWORD',
} as const;

export const RESET_PASSWORD_UI_ERROR_CODES = {
  GENERAL_ERROR: 'GENERAL_ERROR',
} as const;

const TOO_LARGE_ERR = 'TOO_LARGE_ERROR';
const TOO_SMALL_ERR = 'TOO_SMALL_ERROR';
const TYPE_ERR = 'TYPE_ERROR';
const GENERIC_ERR = 'GENERIC_ERROR';
const REQUIRED_ERR = 'REQUIRED_ERROR';

export const USER_FILE_UPLOAD_ERRORS = {
  GENERIC: {
    id: GENERIC_ERR,
    msg: 'Upload failed. Something went wrong. Please retry.',
    path: ['data', 'form', GENERIC_ERR],
  },
  TOO_LARGE: {
    id: TOO_LARGE_ERR,
    msg: 'Upload failed. File too large.',
    path: ['data', 'form', TOO_LARGE_ERR],
  },
  TOO_SMALL: {
    id: TOO_SMALL_ERR,
    msg: 'Upload failed. File too small.',
    path: ['data', 'form', TOO_SMALL_ERR],
  },
  TYPE: {
    id: TYPE_ERR,
    msg: 'Upload failed. Invalid file type.',
    path: ['data', 'form', TYPE_ERR],
  },
  REQUIRED: {
    id: REQUIRED_ERR,
    msg: 'Please upload a file.',
    path: ['data', 'form', REQUIRED_ERR],
  },
} as const;

const FORM_PATH = [{in: 'Record', at: 'form'}];

export const FORM_TOO_LARGE_ERROR_PATH = [
  ...FORM_PATH,
  {in: 'Record', at: TOO_LARGE_ERR},
] as const;

export const FORM_TOO_SMALL_ERROR_PATH = [
  ...FORM_PATH,
  {in: 'Record', at: TOO_SMALL_ERR},
] as const;

export const FORM_TYPE_ERROR_PATH = [
  ...FORM_PATH,
  {in: 'Record', at: TYPE_ERR},
] as const;
export const FORM_GENERIC_ERROR_PATH = [
  ...FORM_PATH,
  {in: 'Record', at: GENERIC_ERR},
] as const;
export const FORM_REQUIRED_ERROR_PATH = [
  ...FORM_PATH,
  {in: 'Record', at: REQUIRED_ERR},
] as const;
export type LogInErrorKeysType = Partial<
  Record<keyof typeof LOGIN_UI_ERROR_CODES, ErrorStateType>
>;

// this exists to support sites using the UserLoginErrorMsg atom
export const __DEPRECATED__logInErrorStates: LogInErrorKeysType = {
  [LOGIN_UI_ERROR_CODES.GENERAL_ERROR]: {
    id: LOGIN_UI_ERROR_CODES.GENERAL_ERROR,
    name: 'General error',
    copy: "We're having trouble logging you in. Please try again, or contact us if you continue to have problems.",
    path: ['data', 'users', LOGIN_UI_ERROR_CODES.GENERAL_ERROR],
  },
};

export const logInErrorStates: LogInErrorKeysType = {
  [LOGIN_UI_ERROR_CODES.GENERAL_ERROR]: {
    id: LOGIN_UI_ERROR_CODES.GENERAL_ERROR,
    name: 'General error',
    copy: "We're having trouble logging you in. Please try again, or contact us if you continue to have problems.",
    path: ['data', 'users', LOGIN_UI_ERROR_CODES.GENERAL_ERROR],
  },
  [LOGIN_UI_ERROR_CODES.INVALID_EMAIL_OR_PASSWORD]: {
    id: LOGIN_UI_ERROR_CODES.INVALID_EMAIL_OR_PASSWORD,
    name: 'Wrong email or password',
    copy: 'Invalid email or password. Please try again.',
    path: ['data', 'users', LOGIN_UI_ERROR_CODES.INVALID_EMAIL_OR_PASSWORD],
  },
};

export const SIGNUP_ERROR_CATEGORY = {
  GENERAL: {id: 'GENERAL', label: 'General Errors'},
  EMAIL: {id: 'EMAIL', label: 'Email Errors'},
  PASSWORD: {id: 'PASSWORD', label: 'Password Errors'},
  INVITE: {id: 'INVITE', label: 'Invitation Errors'},
  VERFIICATION: {id: 'VERIFCATION', label: 'Verification Errors'},
  VALIDATION: {id: 'VALIDATION', label: 'Validation Errors'},
} as const;
export type SignUpErrorKeysType = Partial<
  Record<keyof typeof SIGNUP_UI_ERROR_CODES, ErrorStateType>
>;
export const signUpErrorStates: SignUpErrorKeysType = {
  [SIGNUP_UI_ERROR_CODES.GENERAL_ERROR]: {
    id: SIGNUP_UI_ERROR_CODES.GENERAL_ERROR,
    // @ts-expect-error - TS2418 - Type of computed property's value is '{ id: "GENERAL_ERROR"; category: { readonly id: "GENERAL"; readonly label: "General Errors"; }; name: string; copy: string; path: string[]; }', which is not assignable to type 'ErrorStateType'.
    category: SIGNUP_ERROR_CATEGORY.GENERAL,
    name: 'General error',
    copy: 'There was an error signing you up. Please try again, or contact us if you continue to have problems.',
    path: ['data', 'users', SIGNUP_UI_ERROR_CODES.GENERAL_ERROR],
  },
  [SIGNUP_UI_ERROR_CODES.NOT_ALLOWED]: {
    id: SIGNUP_UI_ERROR_CODES.NOT_ALLOWED,
    // @ts-expect-error - TS2418 - Type of computed property's value is '{ id: "NOT_ALLOWED"; category: { readonly id: "EMAIL"; readonly label: "Email Errors"; }; name: string; copy: string; path: string[]; }', which is not assignable to type 'ErrorStateType'.
    category: SIGNUP_ERROR_CATEGORY.EMAIL,
    name: 'Email not allowed',
    copy: "You're not allowed to access this site, please contact the admin for support.",
    path: ['data', 'users', SIGNUP_UI_ERROR_CODES.NOT_ALLOWED],
  },
  // email errors
  [SIGNUP_UI_ERROR_CODES.INVALID_EMAIL]: {
    id: SIGNUP_UI_ERROR_CODES.INVALID_EMAIL,
    // @ts-expect-error - TS2418 - Type of computed property's value is '{ id: "INVALID_EMAIL"; category: { readonly id: "EMAIL"; readonly label: "Email Errors"; }; name: string; copy: string; path: string[]; }', which is not assignable to type 'ErrorStateType'.
    category: SIGNUP_ERROR_CATEGORY.EMAIL,
    name: 'Invalid email',
    copy: 'Make sure your email exists and is properly formatted (e.g., user@domain.com).',
    path: ['data', 'users', SIGNUP_UI_ERROR_CODES.INVALID_EMAIL],
  },
  [SIGNUP_UI_ERROR_CODES.EMAIL_ALREADY_EXIST]: {
    id: SIGNUP_UI_ERROR_CODES.EMAIL_ALREADY_EXIST,
    // @ts-expect-error - TS2418 - Type of computed property's value is '{ id: "EMAIL_ALREADY_EXIST"; category: { readonly id: "EMAIL"; readonly label: "Email Errors"; }; name: string; copy: string; path: string[]; }', which is not assignable to type 'ErrorStateType'.
    category: SIGNUP_ERROR_CATEGORY.EMAIL,
    name: 'Email already exists',
    copy: 'An account with this email address already exists. Log in or reset your password.',
    path: ['data', 'users', SIGNUP_UI_ERROR_CODES.EMAIL_ALREADY_EXIST],
  },
  // invitation errors
  [SIGNUP_UI_ERROR_CODES.USE_INVITE_EMAIL]: {
    id: SIGNUP_UI_ERROR_CODES.USE_INVITE_EMAIL,
    // @ts-expect-error - TS2418 - Type of computed property's value is '{ id: "USE_INVITE_EMAIL"; category: { readonly id: "INVITE"; readonly label: "Invitation Errors"; }; name: string; copy: string; path: string[]; }', which is not assignable to type 'ErrorStateType'.
    category: SIGNUP_ERROR_CATEGORY.INVITE,
    name: 'Must use invite email',
    copy: 'Use the same email address your invitation was sent to.',
    path: ['data', 'users', SIGNUP_UI_ERROR_CODES.USE_INVITE_EMAIL],
  },
  // password errors
  [SIGNUP_UI_ERROR_CODES.INVALID_PASSWORD]: {
    id: SIGNUP_UI_ERROR_CODES.INVALID_PASSWORD,
    // @ts-expect-error - TS2418 - Type of computed property's value is '{ id: "INVALID_PASSWORD"; category: { readonly id: "PASSWORD"; readonly label: "Password Errors"; }; name: string; copy: string; path: string[]; }', which is not assignable to type 'ErrorStateType'.
    category: SIGNUP_ERROR_CATEGORY.PASSWORD,
    name: 'Invalid password',
    copy: 'Your password must be at least 8 characters.',
    path: ['data', 'users', SIGNUP_UI_ERROR_CODES.INVALID_PASSWORD],
  },
  // verification errors
  [SIGNUP_UI_ERROR_CODES.NOT_VERIFIED]: {
    id: SIGNUP_UI_ERROR_CODES.NOT_VERIFIED,
    // @ts-expect-error - TS2418 - Type of computed property's value is '{ id: "NOT_VERIFIED"; category: { readonly id: "VERIFCATION"; readonly label: "Verification Errors"; }; name: string; copy: string; path: string[]; }', which is not assignable to type 'ErrorStateType'.
    category: SIGNUP_ERROR_CATEGORY.VERFIICATION,
    name: 'Verification failed',
    copy: "We couldn't verify your account. Please try again, or contact us if you continue to have problems.",
    path: ['data', 'users', SIGNUP_UI_ERROR_CODES.NOT_VERIFIED],
  },
  [SIGNUP_UI_ERROR_CODES.EXPIRED_TOKEN]: {
    id: SIGNUP_UI_ERROR_CODES.EXPIRED_TOKEN,
    // @ts-expect-error - TS2418 - Type of computed property's value is '{ id: "EXPIRED_TOKEN"; category: { readonly id: "VERIFCATION"; readonly label: "Verification Errors"; }; name: string; copy: string; path: string[]; }', which is not assignable to type 'ErrorStateType'.
    category: SIGNUP_ERROR_CATEGORY.VERFIICATION,
    name: 'Verification expired',
    copy: 'This link has expired. A new link has been sent to your email. Please try again, or contact us if you continue to have problems.',
    path: ['data', 'users', SIGNUP_UI_ERROR_CODES.EXPIRED_TOKEN],
  },
  [SIGNUP_UI_ERROR_CODES.VALIDATION_FAILED]: {
    id: SIGNUP_UI_ERROR_CODES.VALIDATION_FAILED,
    // @ts-expect-error - TS2418 - Type of computed property's value is '{ id: "VALIDATION_FAILED"; category: { readonly id: "VALIDATION"; readonly label: "Validation Errors"; }; name: string; copy: string; path: string[]; }', which is not assignable to type 'ErrorStateType'.
    category: SIGNUP_ERROR_CATEGORY.VALIDATION,
    name: 'Validation error',
    copy: 'There was an error in some of the information provided.',
    path: ['data', 'users', SIGNUP_UI_ERROR_CODES.VALIDATION_FAILED],
  },
  [SIGNUP_UI_ERROR_CODES.REQUIRED]: {
    id: SIGNUP_UI_ERROR_CODES.REQUIRED,
    // @ts-expect-error - TS2418 - Type of computed property's value is '{ id: "REQUIRED"; category: { readonly id: "VALIDATION"; readonly label: "Validation Errors"; }; name: string; copy: string; path: string[]; }', which is not assignable to type 'ErrorStateType'.
    category: SIGNUP_ERROR_CATEGORY.VALIDATION,
    name: 'Missing information',
    copy: 'Fill out all required fields',
    path: ['data', 'users', SIGNUP_UI_ERROR_CODES.REQUIRED],
  },
};

export type UpdatePasswordErrorKeys = Partial<
  Record<keyof typeof UPDATE_PASSWORD_UI_ERROR_CODES, ErrorStateType>
>;
export const updatePasswordErrorStates: UpdatePasswordErrorKeys = {
  [UPDATE_PASSWORD_UI_ERROR_CODES.GENERAL_ERROR]: {
    id: UPDATE_PASSWORD_UI_ERROR_CODES.GENERAL_ERROR,
    name: 'General error',
    copy: 'There was an error updating your password. Please try again, or contact us if you continue to have problems.',
    path: ['data', 'users', UPDATE_PASSWORD_UI_ERROR_CODES.GENERAL_ERROR],
  },
  [UPDATE_PASSWORD_UI_ERROR_CODES.WEAK_PASSWORD]: {
    id: UPDATE_PASSWORD_UI_ERROR_CODES.WEAK_PASSWORD,
    name: 'Weak password',
    copy: 'Your password must be at least 8 characters.',
    path: ['data', 'users', UPDATE_PASSWORD_UI_ERROR_CODES.WEAK_PASSWORD],
  },
};

export type ResetPasswordErrorKeysType = Partial<
  Record<keyof typeof RESET_PASSWORD_UI_ERROR_CODES, ErrorStateType>
>;
export const resetPasswordErrorStates: ResetPasswordErrorKeysType = {
  [RESET_PASSWORD_UI_ERROR_CODES.GENERAL_ERROR]: {
    id: RESET_PASSWORD_UI_ERROR_CODES.GENERAL_ERROR,
    name: 'General error',
    copy: 'There was an error resetting your password. Please try again, or contact us if you continue to have problems.',
    path: ['data', 'users', RESET_PASSWORD_UI_ERROR_CODES.GENERAL_ERROR],
  },
};

export const UPDATE_ACCOUNT_ERROR_CODES = {
  GENERAL_ERROR: 'GENERAL_ERROR',
} as const;

type UpdateAccountErrorKeysType = Partial<
  Record<keyof typeof UPDATE_ACCOUNT_ERROR_CODES, ErrorStateType>
>;

export const updateAccountErrorStates: UpdateAccountErrorKeysType = {
  [UPDATE_ACCOUNT_ERROR_CODES.GENERAL_ERROR]: {
    id: UPDATE_ACCOUNT_ERROR_CODES.GENERAL_ERROR,
    name: 'General error',
    copy: 'There was an error updating your account. Please try again, or contact us if you continue to have problems.',
    path: ['data', 'users', UPDATE_ACCOUNT_ERROR_CODES.GENERAL_ERROR],
  },
};
