/* globals document, window, Element, HTMLElement, CustomEvent, HTMLFormElement, HTMLInputElement, HTMLSelectElement, HTMLAnchorElement*/
import type {ApolloClient, NormalizedCacheObject} from '@apollo/client';
import gql from 'graphql-tag';
import {
  DATA_ATTR_COMMERCE_SKU_ID,
  DATA_ATTR_COMMERCE_OPTION_SET_ID,
  DATA_ATTR_NODE_TYPE,
  DATA_ATTR_LOADING_TEXT,
  DATA_ATTR_OPEN_PRODUCT,
  DATA_ATTR_COMMERCE_PRODUCT_ID,
  DATA_ATTR_PUBLISHABLE_KEY,
  DATA_ATTR_PRESELECT_DEFAULT_VARIANT,
  NODE_TYPE_COMMERCE_ADD_TO_CART_FORM,
  NODE_TYPE_COMMERCE_ADD_TO_CART_ERROR,
  NODE_TYPE_COMMERCE_ADD_TO_CART_OPTION_SELECT,
  NODE_TYPE_COMMERCE_ADD_TO_CART_OPTION_LIST,
  NODE_NAME_COMMERCE_ADD_TO_CART_QUANTITY_INPUT,
  NODE_TYPE_COMMERCE_CART_WRAPPER,
  NODE_TYPE_COMMERCE_BUY_NOW_BUTTON,
  NODE_TYPE_ADD_TO_CART_ERROR,
  NODE_TYPE_COMMERCE_ADD_TO_CART_PILL_GROUP,
  WF_SKU_BINDING_DATA_KEY,
  WF_SKU_CONDITION_DATA_KEY,
  INVENTORY_TYPE_FINITE,
  INVENTORY_TYPE_INFINITE,
  ADD_TO_CART_LOADING,
  CHANGE_CART_EVENT,
  getATCErrorMessageForType,
  RENDER_TREE_EVENT,
  CHECKOUT_DISABLED_ERROR_MESSAGE,
  NODE_TYPE_COMMERCE_ADD_TO_CART_BUTTON,
  DATA_ATTR_SUBSCRIPTION_TEXT,
  DATA_ATTR_DEFAULT_TEXT,
} from '@packages/systems/commerce/constants';
import {
  LOGGEDIN_COOKIE_NAME,
  USYS_PAGE_SETTINGS,
} from '@packages/systems/users/constants';
import {CLASS_NAME_W_DYN_BIND_EMPTY} from '@packages/systems/dynamo/constants';
import {
  CLASS_NAME_DYNAMIC_LIST_ITEM,
  CLASS_NAME_DYNAMIC_LIST_REPEATER_ITEM,
  CLASS_NAME_DYNAMIC_LIST_REPEATER_REF,
} from '../../Dynamo/constants';
import {createJsonFromBoundMedia} from '../../Lightbox/utils';
import get from 'lodash/get';

import {simplifySkuValues} from '@packages/shared/site';
import EventHandlerProxyWithApolloClient from './eventHandlerProxyWithApolloClient';
import {
  triggerRender,
  isProtocolHttps,
  safeParseJson,
  formToObject,
  addLoadingCallback,
  findAllElementsByNodeType,
  findElementByNodeType,
  findClosestElementByNodeType,
} from './commerceUtils';
import {removeWDynBindEmptyClass} from '@packages/systems/dynamo/utils/RenderingUtils';
import {renderPriceFromSettings} from '@packages/systems/core/utils/CurrencyUtils';
import debug from './debug';
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import {renderTree, applySkuBoundConditionalVisibility} from './rendering';
import {createNewStore, type SkuValues} from './addToCartStore';
import {PillGroups} from './components/PillGroup';
import {redirectWithUsrdir} from '@packages/systems/users/siteBundles';
import {StripeStore} from './stripeStore';
/*::
import $ from 'jquery';
*/

// fbq is Facebook pixel library loaded on page load
// eslint-disable-next-line no-var
declare var fbq: any;

// gtag is Google Analytics library loaded on page load, no-var
// eslint-disable-next-line no-var
declare var gtag: any;

const {fetchFromStore, updateStore, addStoreWatcher} = createNewStore();

const getInstanceId = (form: HTMLFormElement): string => {
  const instanceId = form.getAttribute(DATA_ATTR_COMMERCE_PRODUCT_ID);
  if (instanceId) {
    return instanceId;
  } else {
    throw new Error('Incorrect form instance provided, has no instance ID');
  }
};

function trackAddToCartUsage(
  skuId: string,
  count: number,
  itemPrice: {
    unit: string;
    decimalValue: number;
  }
) {
  const {decimalValue, unit} = itemPrice;

  if (typeof fbq === 'function') {
    fbq('track', 'AddToCart', {
      value: count * decimalValue,
      currency: unit,
      content_ids: [skuId],
      content_type: 'product',
      contents: [{id: skuId, quantity: count, item_price: decimalValue}],
    });
  }

  if (typeof gtag === 'function') {
    gtag('event', 'add_to_cart', {
      items: [
        {
          id: skuId,
          quantity: count,
          price: decimalValue,
        },
      ],
    });
  }
}

const addToCartMutation = gql`
  mutation AddToCart($skuId: String!, $count: Int!, $buyNow: Boolean) {
    ecommerceAddToCart(sku: $skuId, count: $count, buyNow: $buyNow) {
      ok
      itemId
      itemCount
      itemPrice {
        unit
        decimalValue
      }
    }
  }
`;

const collectionsQuery = `
      collections {
        c_sku_ {
          id
          items(filter: {f_product_: {eq: $productId}}) {
            id
            f_price_ {
              value
              unit
            }
            f_weight_
            f_width_
            f_length_
            f_height_
            f_sku_
            f_main_image_4dr {
              url
            }
            f_more_images_4dr {
              url
              alt
              file {
                origFileName
              }
            }
            f_sku_values_3dr {
              value {
                id
              }
              property {
                id
              }
            }
            inventory {
              type
              quantity
            }
            f_compare_at_price_7dr10dr {
              unit
              value
            }
            f_ec_sku_billing_method_2dr6dr14dr
          }
        }
        c_product_ {
          id
          items(filter: {id: {eq: $productId}}) {
            id
            f_default_sku_7dr {
              id
            }
            f_ec_product_type_2dr10dr {
              name
            }
          }
        }
      }`;

const getAllVariants = gql`
  query FetchAllVariants($productId: BasicId!) {
    database {
      id
      ${collectionsQuery}
    }
  }
`;

const getAllVariantsAndMemberships = gql`
  query FetchAllVariantsAndMemberships($productId: BasicId!) {
    database {
      id
      ${collectionsQuery}
      commerceMemberships(productIds: [$productId]) {
        productId
        orderId
        active
      }
    }
  }
`;

// @ts-expect-error - TS7006 - Parameter 'node' implicitly has an 'any' type.
const findCollectionItemWrapper = (node) => {
  const dynamoItemSelector = `.${CLASS_NAME_DYNAMIC_LIST_ITEM}:not(.${CLASS_NAME_DYNAMIC_LIST_REPEATER_ITEM})`;

  return $(node).closest(dynamoItemSelector)[0] || document.body;
};

const addToCartFormEventTargetMatcher = (event: Event) => {
  if (
    event != null &&
    event.target instanceof HTMLElement &&
    event.target.getAttribute(DATA_ATTR_NODE_TYPE) ===
      NODE_TYPE_COMMERCE_ADD_TO_CART_FORM
  ) {
    return event.target;
  }
  return false;
};

const getErrorType = (error: any) => {
  const defaultErrorType = 'general';
  if (error && error.graphQLErrors && error.graphQLErrors.length > 0) {
    switch (error.graphQLErrors[0].code) {
      case 'OutOfInventory':
        return 'quantity';
      case 'MixedCartError':
        return 'mixed-cart';
      default:
        return defaultErrorType;
    }
  }
  return defaultErrorType;
};

const handleAtcSubmit = (
  event: Event,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  event.preventDefault();

  const eventTarget = event.currentTarget;
  if (
    !(
      eventTarget instanceof HTMLFormElement &&
      eventTarget.parentNode instanceof Element
    ) ||
    eventTarget.hasAttribute(ADD_TO_CART_LOADING)
  ) {
    return;
  }

  const {parentNode} = eventTarget;
  const inputButton = eventTarget.querySelector('input[type="submit"]');

  if (!isProtocolHttps()) {
    window.alert(
      'This site is currently unsecured so you cannot add products to your cart.'
    );
    return;
  }

  if (!(inputButton instanceof HTMLInputElement)) {
    return;
  }

  const errorElement = parentNode.querySelector(
    `[${DATA_ATTR_NODE_TYPE}="${NODE_TYPE_COMMERCE_ADD_TO_CART_ERROR}"]`
  );
  if (errorElement instanceof Element) {
    // @ts-expect-error - TS2339 - Property 'style' does not exist on type 'Element'.
    errorElement.style.display = 'none';
  }
  eventTarget.setAttribute(ADD_TO_CART_LOADING, '');
  const previousButtonValue = inputButton.value;
  // We moved the data here to the actual button node but will fallback to old value on the wrapper if not set
  const loadingTextFromButton = inputButton.getAttribute(
    DATA_ATTR_LOADING_TEXT
  );
  inputButton.value = loadingTextFromButton
    ? loadingTextFromButton
    : eventTarget.getAttribute(DATA_ATTR_LOADING_TEXT) || '';
  inputButton.setAttribute('aria-busy', 'true');

  const skuId = fetchFromStore(getInstanceId(eventTarget), 'selectedSku') || '';
  const formData = formToObject(eventTarget);
  const formCount = formData[NODE_NAME_COMMERCE_ADD_TO_CART_QUANTITY_INPUT];
  const count = formCount ? parseInt(formCount, 10) : 1;

  // if no SKU id, then all options need to be selected
  // this is only shown for pills, as dropdowns will be caught by reportValidity above
  if (!skuId && errorElement instanceof Element) {
    eventTarget.removeAttribute(ADD_TO_CART_LOADING);
    inputButton.value = previousButtonValue;
    inputButton.setAttribute('aria-busy', 'false');

    const errorMsg = errorElement.querySelector(
      `[${DATA_ATTR_NODE_TYPE}="${NODE_TYPE_ADD_TO_CART_ERROR}"]`
    );
    if (!errorMsg) {
      return;
    }
    const errorText =
      errorMsg.getAttribute(getATCErrorMessageForType('select-all-options')) ||
      'Please select an option in each set.';
    errorMsg.textContent = errorText;
    // @ts-expect-error - TS2339 - Property 'style' does not exist on type 'Element'.
    errorElement.style.removeProperty('display');
    return;
  }

  // Redirect to sign up if the purchase requires a user session and there is none
  const requiresUserSession = fetchFromStore(
    getInstanceId(eventTarget),
    'requiresUserSession'
  );
  const hasUserSession = document.cookie
    .split(';')
    .some((cookie) => cookie.indexOf(LOGGEDIN_COOKIE_NAME) > -1);
  if (requiresUserSession && !hasUserSession) {
    redirectWithUsrdir(`/${USYS_PAGE_SETTINGS.signup.slug}`);
    return;
  }

  apolloClient
    .mutate({
      mutation: addToCartMutation,
      variables: {skuId, count, buyNow: false},
    })
    .then(({data}) => {
      addLoadingCallback(() => {
        eventTarget.removeAttribute(ADD_TO_CART_LOADING);
        inputButton.value = previousButtonValue;
        inputButton.setAttribute('aria-busy', 'false');

        const cartElements = document.querySelectorAll(
          `[${DATA_ATTR_NODE_TYPE}="${NODE_TYPE_COMMERCE_CART_WRAPPER}"][${DATA_ATTR_OPEN_PRODUCT}]`
        );
        cartElements.forEach((cart) => {
          const evt = new CustomEvent(CHANGE_CART_EVENT, {
            bubbles: true,
            detail: {
              open: true,
            },
          });

          cart.dispatchEvent(evt);
        });
      });

      triggerRender(null);

      const itemPrice = data.ecommerceAddToCart.itemPrice || {};

      trackAddToCartUsage(skuId, count, itemPrice);
    })
    .catch((error) => {
      eventTarget.removeAttribute(ADD_TO_CART_LOADING);
      inputButton.value = previousButtonValue;
      inputButton.setAttribute('aria-busy', 'false');
      if (errorElement) {
        // @ts-expect-error - TS2339 - Property 'style' does not exist on type 'Element'.
        errorElement.style.removeProperty('display');
        const errorMsg = errorElement.querySelector(
          `[${DATA_ATTR_NODE_TYPE}="${NODE_TYPE_ADD_TO_CART_ERROR}"]`
        );
        if (!errorMsg) {
          return;
        }
        const errorMessage = getATCErrorMessageForType(getErrorType(error));
        const errorText = errorMsg.getAttribute(errorMessage) || '';
        errorMsg.textContent = errorText;
      }
      debug.error(error);
      triggerRender(null);
    });
};

const addToCartOptionSelectEventTargetMatcher = (event: Event) => {
  if (
    event != null &&
    event.target instanceof HTMLElement &&
    event.target.getAttribute(DATA_ATTR_NODE_TYPE) ===
      NODE_TYPE_COMMERCE_ADD_TO_CART_OPTION_SELECT
  ) {
    return event.target;
  }
  return false;
};

const queryAllWithoutOtherItemWrapperContents = (
  collectionItemWrapper: any,
  selector: string
) => {
  return Array.from(collectionItemWrapper.querySelectorAll(selector)).filter(
    (node) => findCollectionItemWrapper(node) === collectionItemWrapper
  );
};

const queryAllReferenceRepeaters = (collectionItemWrapper: any) => {
  return Array.from(
    collectionItemWrapper.querySelectorAll(
      `.${CLASS_NAME_DYNAMIC_LIST_REPEATER_REF}`
    )
  );
};

const removeClass = (element: any, className: string) => {
  element &&
    element.classList instanceof DOMTokenList &&
    element.classList.remove(className);
  if (element.classList.length === 0) {
    element.removeAttribute('class');
  }
};

const showElement = (element: any) => removeClass(element, 'w-dyn-hide');

const hideElement = (element: any | null | undefined | HTMLElement) =>
  element &&
  element.classList instanceof DOMTokenList &&
  element.classList.add('w-dyn-hide');

const updateEmptyStateVisibility = (
  node: any,
  fn1:
    | ((
        element?: any | HTMLElement | null | undefined
      ) => boolean | null | undefined)
    | ((element?: any) => void),
  fn2:
    | ((
        element?: any | HTMLElement | null | undefined
      ) => boolean | null | undefined)
    | ((element?: any) => void)
) => {
  const emptyStateNodes = Array.from(node.querySelectorAll('.w-dyn-empty'));
  const emptyStateMoreImageFieldNodes = emptyStateNodes.filter((n) => {
    // @ts-expect-error - TS18046 - 'n' is of type 'unknown'.
    const itemsList = n.parentElement.querySelector('.w-dyn-items');
    return (
      itemsList.dataset &&
      itemsList.dataset.wfCollection &&
      itemsList.dataset.wfCollection === 'f_more_images_4dr'
    );
  });
  return (
    emptyStateMoreImageFieldNodes &&
    emptyStateMoreImageFieldNodes.map((n) => {
      fn1(n);
      // @ts-expect-error - TS18046 - 'n' is of type 'unknown'.
      const itemsList = n.parentElement.querySelector('.w-dyn-items');
      if (
        itemsList &&
        itemsList.dataset &&
        itemsList.dataset.wfCollection &&
        itemsList.dataset.wfCollection === 'f_more_images_4dr' &&
        itemsList.classList instanceof DOMTokenList &&
        itemsList.parentElement.classList.contains(
          CLASS_NAME_DYNAMIC_LIST_REPEATER_REF
        )
      ) {
        return fn2(itemsList);
      }
    })
  );
};

const showEmptyStateAndHideItemsList = (node: any) => {
  updateEmptyStateVisibility(node, showElement, hideElement);
};

const hideEmptyStateAndShowItemsList = (node: any) => {
  updateEmptyStateVisibility(node, hideElement, showElement);
};

const updateDropdownsOnPage =
  (instanceId: string) => (newSkuValues: SkuValues) => {
    const dropdownsForProduct: Array<HTMLSelectElement> = Array.from(
      document.querySelectorAll(
        `[${DATA_ATTR_NODE_TYPE}="${NODE_TYPE_COMMERCE_ADD_TO_CART_OPTION_LIST}"][${DATA_ATTR_COMMERCE_PRODUCT_ID}="${instanceId}"] [${DATA_ATTR_NODE_TYPE}="${NODE_TYPE_COMMERCE_ADD_TO_CART_OPTION_SELECT}"]`
      )
    ) as Array<any>;

    for (const optionSetId of Object.keys(newSkuValues)) {
      const optionSetValue = newSkuValues[optionSetId];
      const matchingDropdownsForOptionSet = dropdownsForProduct.filter(
        (d) => d.getAttribute(DATA_ATTR_COMMERCE_OPTION_SET_ID) === optionSetId
      );
      for (const dropdown of matchingDropdownsForOptionSet) {
        dropdown.value = String(optionSetValue);
      }
    }
  };

const disableOptionsOnChange = ({
  apolloClient,
  productId,
  optionSets,
  optionSetId,
}: {
  apolloClient: ApolloClient<NormalizedCacheObject>;
  productId: string;
  // @TODO - try and type this properly... the pill groups use Object.values, which returns mixed, which causes a shwack of issues
  optionSets: Array<any>;
  optionSetId: string;
}) => {
  apolloClient
    .query({query: getAllVariants, variables: {productId}})
    .then(({data}) => {
      // That's a lot of question marks! What do they mean?
      // The `?.` is optional chaining, which lets you safely access deeply nested properties,
      // with it bailing out if the property doesn't exist: https://github.com/TC39/proposal-optional-chaining
      // The `??` is nullish coalescing, which is similar to `||` when wanting to ensure something is defined,
      // but only tripping if the value it's checking `null` or `undefined`, to prevent odd `false` and `false`-ish tripping false positives
      // https://github.com/tc39/proposal-nullish-coalescing
      const items = data?.database?.collections?.c_sku_?.items ?? [];

      // Get all the data we're going to use, we need to know which selectors were previously selected, which are currently
      // selected vs. which are not, and the most recently updated one
      const optionSetData = optionSets.reduce(
        (parsedSelectorOptionSets, selectorOptionSet) => {
          if (selectorOptionSet.value) {
            parsedSelectorOptionSets.selectedOptionSets.push(selectorOptionSet);
            if (
              selectorOptionSet.getAttribute(
                DATA_ATTR_COMMERCE_OPTION_SET_ID
              ) === optionSetId
            ) {
              parsedSelectorOptionSets.recentlySelectedOptionSet =
                selectorOptionSet;
            } else {
              parsedSelectorOptionSets.previouslySelectedOptionSets.push(
                selectorOptionSet
              );
            }
          } else {
            parsedSelectorOptionSets.unselectedOptionSets.push(
              selectorOptionSet
            );
          }
          return parsedSelectorOptionSets;
        },
        {
          selectedOptionSets: [],
          recentlySelectedOptionSet: undefined,
          previouslySelectedOptionSets: [],
          unselectedOptionSets: [],
        }
      );
      let {selectedOptionSets, unselectedOptionSets} = optionSetData;
      const {recentlySelectedOptionSet, previouslySelectedOptionSets} =
        optionSetData;

      // Deselect previously selected option sets if they are uncompatible with the current selection.
      if (recentlySelectedOptionSet && selectedOptionSets.length > 1) {
        const recentlySelectedOptionSetValue = recentlySelectedOptionSet.value;

        forEach(previouslySelectedOptionSets, (previouslySelectedOptionSet) => {
          const optionSetValueCombinationWithMostRecent = [
            recentlySelectedOptionSetValue,
            previouslySelectedOptionSet.value,
          ];

          // @ts-expect-error - TS7006 - Parameter 'item' implicitly has an 'any' type.
          const someAvailableItem = items.some((item) => {
            if (
              item.inventory.type === INVENTORY_TYPE_FINITE &&
              item.inventory.quantity <= 0
            ) {
              return false;
            }
            const itemMappedBySkuValues = item.f_sku_values_3dr.map(
              // @ts-expect-error - TS7006 - Parameter 'skuValues' implicitly has an 'any' type.
              (skuValues) => skuValues.value.id
            );
            return optionSetValueCombinationWithMostRecent.every((value) =>
              itemMappedBySkuValues.includes(value)
            );
          });

          if (!someAvailableItem) {
            previouslySelectedOptionSet.selectedIndex = 0;

            selectedOptionSets = selectedOptionSets.filter(
              // @ts-expect-error - TS7006 - Parameter 'selectedOptionSet' implicitly has an 'any' type.
              (selectedOptionSet) =>
                selectedOptionSet.getAttribute(
                  DATA_ATTR_COMMERCE_OPTION_SET_ID
                ) !==
                previouslySelectedOptionSet.getAttribute(
                  DATA_ATTR_COMMERCE_OPTION_SET_ID
                )
            );

            unselectedOptionSets = unselectedOptionSets.concat(
              previouslySelectedOptionSet
            );
          }
        });
      }

      // For the (remaining after above deselection) selected ones we want to disable any options that simply have no possible stock
      forEach(selectedOptionSets, (optionSet) => {
        const id = optionSet.getAttribute(DATA_ATTR_COMMERCE_OPTION_SET_ID);
        forEach(optionSet.options, (option) => {
          if (!option.value) {
            option.enabled = true;
          } else {
            disableVariantsWithNoStock(items, id, option);
          }
        });
      });

      // For the remaining unselected ones we want to disable any options that aren't possible given current selections
      forEach(unselectedOptionSets, (optionSet) => {
        const id = optionSet.getAttribute(DATA_ATTR_COMMERCE_OPTION_SET_ID);
        disableVariantsWithNoStockForRemainingSelections(
          items,
          selectedOptionSets,
          optionSet,
          id
        );
      });
    });
};

const handleAtcOptionSelectChange = (
  event: Event,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  const eventTarget = event.currentTarget;
  if (!(eventTarget instanceof HTMLSelectElement)) {
    return;
  }

  // @ts-expect-error - TS2339 - Property 'jQuery' does not exist on type 'Window & typeof globalThis'.
  const $ = window.jQuery;
  const optionSetId = eventTarget.getAttribute(
    DATA_ATTR_COMMERCE_OPTION_SET_ID
  );
  const optionSetValue = eventTarget.value;
  const optionListElement = $(eventTarget).closest(
    `[${DATA_ATTR_NODE_TYPE}="${NODE_TYPE_COMMERCE_ADD_TO_CART_OPTION_LIST}"]`
  )[0];
  const addToCartForm = $(eventTarget).closest(
    `[${DATA_ATTR_NODE_TYPE}="${NODE_TYPE_COMMERCE_ADD_TO_CART_FORM}"]`
  )[0];

  if (
    !(optionListElement instanceof Element) ||
    !optionSetId ||
    !(addToCartForm instanceof HTMLFormElement)
  ) {
    return;
  }
  const instanceId = getInstanceId(addToCartForm);
  const currentSkuValues = fetchFromStore(instanceId, 'skuValues');
  const newSkuValues = {
    ...currentSkuValues,
    [optionSetId]: optionSetValue,
  } as const;
  updateStore(instanceId, {
    skuValues: newSkuValues,
  });
  const productId =
    optionListElement &&
    optionListElement.getAttribute(DATA_ATTR_COMMERCE_PRODUCT_ID);

  const allVariantSelectorsInCartForm: Array<HTMLSelectElement> =
    findAllElementsByNodeType(
      NODE_TYPE_COMMERCE_ADD_TO_CART_OPTION_SELECT,
      addToCartForm
    ) as Array<any>;

  if (productId && allVariantSelectorsInCartForm.length > 0) {
    disableOptionsOnChange({
      apolloClient,
      productId,
      optionSets: allVariantSelectorsInCartForm,
      optionSetId,
    });
  }
};

const updateSkuBindings = (binding: any, node: any, newSkuItem: any) => {
  if (
    ['f_weight_', 'f_width_', 'f_length_', 'f_height_', 'f_sku_'].some(
      (slug) => binding.from === slug
    )
  ) {
    node[binding.to === 'innerHTML' ? 'innerText' : binding.to] =
      newSkuItem[binding.from] || '';
    updateTextNodeVisibility(node);
  }
  if (binding.from === 'f_price_' && newSkuItem.f_price_) {
    node[binding.to === 'innerHTML' ? 'innerText' : binding.to] =
      renderPriceFromSettings(
        newSkuItem.f_price_,
        // @ts-expect-error - TS2339 - Property '__WEBFLOW_CURRENCY_SETTINGS' does not exist on type 'Window & typeof globalThis'.
        window.__WEBFLOW_CURRENCY_SETTINGS
      );
    updateTextNodeVisibility(node);
  }
  if (binding.from === 'f_compare_at_price_7dr10dr') {
    if (newSkuItem.f_compare_at_price_7dr10dr) {
      // if compare-at is available, apply the binding
      node[binding.to === 'innerHTML' ? 'innerText' : binding.to] =
        renderPriceFromSettings(
          newSkuItem.f_compare_at_price_7dr10dr,
          // @ts-expect-error - TS2339 - Property '__WEBFLOW_CURRENCY_SETTINGS' does not exist on type 'Window & typeof globalThis'.
          window.__WEBFLOW_CURRENCY_SETTINGS
        );
    } else {
      // otherwise, we need to specifically clear it; it could have been set
      // by a previously-selected product option
      node[binding.to === 'innerHTML' ? 'innerText' : binding.to] = '';
    }
    updateTextNodeVisibility(node);
  }

  if (
    binding.from === 'f_main_image_4dr' ||
    binding.from === 'f_main_image_4dr.url'
  ) {
    const mainImage = get(newSkuItem, binding.from.replace(/\.url$/, ''));
    if (binding.to === 'style.background-image') {
      node.style.backgroundImage =
        mainImage && mainImage.url ? `url("${mainImage.url}")` : 'none';
    } else if (binding.to === 'media') {
      if (node.classList.contains('w-lightbox')) {
        updateLightboxJson(node, mainImage);
      }
    } else if (binding.to === 'src') {
      if (mainImage && mainImage.url) {
        node.src = mainImage.url;
        removeWDynBindEmptyClass(node);
        // Temporary solution for responsive images for product variants
        if (node.hasAttribute('srcset')) {
          node.removeAttribute('srcset');
        }
      } else {
        node.removeAttribute('src');
        node.classList.add(CLASS_NAME_W_DYN_BIND_EMPTY);
      }
    }
  }

  if (
    binding.from === 'f_more_images_4dr' ||
    binding.from.startsWith('f_more_images_4dr.')
  ) {
    const image = get(newSkuItem, binding.from.replace(/\.url$/, ''));
    if (binding.to === 'style.background-image') {
      node.style.backgroundImage = image ? `url("${image.url}")` : 'none';
    } else if (binding.to === 'media') {
      if (node.classList.contains('w-lightbox')) {
        updateLightboxJson(node, image);
      }
    } else if (binding.to === 'src') {
      if (image && image.url) {
        node.src = image.url;
        node.alt = image.alt || '';
        removeWDynBindEmptyClass(node);
        // Temporary solution for responsive images for product variants
        if (node.hasAttribute('srcset')) {
          node.removeAttribute('srcset');
          node.removeAttribute('sizes');
        }
      } else {
        node.removeAttribute('src');
        node.removeAttribute('srcset');
        node.removeAttribute('sizes');
        node.removeAttribute('alt');
        node.classList.add(CLASS_NAME_W_DYN_BIND_EMPTY);
      }
    }
  }

  if (binding.from === 'ecSkuInventoryQuantity') {
    const inventoryQuantity =
      get(newSkuItem, 'inventory.type') === 'infinite'
        ? null
        : get(newSkuItem, 'inventory.quantity');

    node[binding.to === 'innerHTML' ? 'innerText' : binding.to] =
      inventoryQuantity;
    updateTextNodeVisibility(node);
  }
};

const updatePageWithNewSkuValuesData =
  (instanceId: string, apolloClient: ApolloClient<NormalizedCacheObject>) =>
  (newSkuValues: SkuValues) => {
    // @ts-expect-error - TS2339 - Property 'jQuery' does not exist on type 'Window & typeof globalThis'.
    const $ = window.jQuery;
    apolloClient
      .query({query: getAllVariants, variables: {productId: instanceId}})
      .then(({data}) => {
        const items = data?.database?.collections?.c_sku_?.items ?? [];
        const products = data?.database?.collections?.c_product_?.items ?? [];
        const productType = products[0]
          ? products[0].f_ec_product_type_2dr10dr.name
          : 'Advanced';

        const newSkuItem = find(items, (item) => {
          if (item.f_sku_values_3dr && Array.isArray(item.f_sku_values_3dr)) {
            const skuValues = simplifySkuValues(item.f_sku_values_3dr);
            return Object.keys(newSkuValues).every(
              (key) => newSkuValues[key] === skuValues[key]
            );
          }
        });
        if (newSkuItem && newSkuItem.id) {
          updateStore(instanceId, {
            selectedSku: newSkuItem.id,
          });
          if (
            newSkuItem['f_ec_sku_billing_method_2dr6dr14dr'] ===
              'subscription' ||
            productType === 'Membership'
          ) {
            updateStore(instanceId, {
              requiresUserSession: true,
            });
          }

          const formsForProduct = document.querySelectorAll(
            `[${DATA_ATTR_NODE_TYPE}="${NODE_TYPE_COMMERCE_ADD_TO_CART_FORM}"][${DATA_ATTR_COMMERCE_PRODUCT_ID}="${instanceId}"]`
          );
          Array.from(formsForProduct).forEach((addToCartForm) => {
            const collectionItemWrapper =
              findCollectionItemWrapper(addToCartForm);
            const referenceRepeaters = queryAllReferenceRepeaters(
              collectionItemWrapper
            );

            // Update the state of the buy now button text to handle subscription
            const buyNowButton = findElementByNodeType(
              NODE_TYPE_COMMERCE_BUY_NOW_BUTTON,
              addToCartForm
            );
            if (buyNowButton) {
              if (
                newSkuItem['f_ec_sku_billing_method_2dr6dr14dr'] ===
                'subscription'
              ) {
                const addToCartButton = findElementByNodeType(
                  NODE_TYPE_COMMERCE_ADD_TO_CART_BUTTON,
                  addToCartForm
                );
                const buyNowSubscriptionText =
                  buyNowButton.getAttribute(DATA_ATTR_SUBSCRIPTION_TEXT) ||
                  'Subscribe now';

                hideElement(addToCartButton);
                buyNowButton.innerText = buyNowSubscriptionText;
              } else {
                const buyNowDefaultText =
                  buyNowButton.getAttribute(DATA_ATTR_DEFAULT_TEXT) ||
                  'Buy now';
                buyNowButton.innerText = buyNowDefaultText;
              }
            }

            const moreImagesFieldLength =
              (newSkuItem.f_more_images_4dr &&
                newSkuItem.f_more_images_4dr.length) ||
              0;
            if (referenceRepeaters.length > 0) {
              referenceRepeaters.forEach((referenceRepeater) => {
                // @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'Element'.
                renderTree(referenceRepeater, {data: newSkuItem});
                if (moreImagesFieldLength > 0) {
                  hideEmptyStateAndShowItemsList(referenceRepeater);
                } else {
                  showEmptyStateAndHideItemsList(referenceRepeater);
                }
              });
            }

            const skuBoundNodes = queryAllWithoutOtherItemWrapperContents(
              collectionItemWrapper,
              `[${WF_SKU_BINDING_DATA_KEY}]`
            );
            forEach(skuBoundNodes, (node) => {
              // @ts-expect-error - TS18046 - 'node' is of type 'unknown'.
              const skuBindingsData = node.getAttribute(
                WF_SKU_BINDING_DATA_KEY
              );
              if (skuBindingsData) {
                const skuBindings = safeParseJson(skuBindingsData);
                if (Array.isArray(skuBindings)) {
                  skuBindings.forEach((binding) =>
                    updateSkuBindings(binding, node, newSkuItem)
                  );
                }
              }
            });

            const skuConditionBoundNodes =
              queryAllWithoutOtherItemWrapperContents(
                collectionItemWrapper,
                `[${WF_SKU_CONDITION_DATA_KEY}]`
              );
            forEach(skuConditionBoundNodes, (node) => {
              const conditionData = safeParseJson(
                // @ts-expect-error - TS18046 - 'node' is of type 'unknown'.
                node.getAttribute(WF_SKU_CONDITION_DATA_KEY)
              );
              if (conditionData) {
                applySkuBoundConditionalVisibility({
                  conditionData,
                  newSkuItem,
                  // @ts-expect-error - TS2322 - Type 'unknown' is not assignable to type 'Element'.
                  node,
                });
              }
            });

            const errorElement = $(collectionItemWrapper).siblings(
              `[${DATA_ATTR_NODE_TYPE}="${NODE_TYPE_COMMERCE_ADD_TO_CART_ERROR}"]`
            )[0];

            if (errorElement instanceof Element) {
              // @ts-expect-error - TS2339 - Property 'style' does not exist on type 'Element'.
              errorElement.style.display = 'none';
            }
          });

          // In case there are lightboxes bound, we need to initialize the events again after each script
          // was updated with the right data in updateSkuBindings

          if (window.Webflow.require('lightbox')) {
            window.Webflow.require('lightbox').ready();
          }
        } else {
          // if we can't find a valid SKU, we've deselected one of the variant selectors
          // as such, we need to clear the selected SKU in the store
          updateStore(instanceId, {
            selectedSku: '',
          });
        }
      });
  };

const updateSkuValuesOnPillSelect =
  (instanceId: string, apolloClient: ApolloClient<NormalizedCacheObject>) =>
  // @ts-expect-error - TS7031 - Binding element 'optionId' implicitly has an 'any' type. | TS7031 - Binding element 'optionSetId' implicitly has an 'any' type. | TS7031 - Binding element 'groups' implicitly has an 'any' type.
  ({optionId, optionSetId, groups}) => {
    const currentSkuValues = fetchFromStore(instanceId, 'skuValues');
    const newSkuValues = {
      ...currentSkuValues,
      [optionSetId]: optionId,
    } as const;
    updateStore(instanceId, {
      skuValues: newSkuValues,
    });
    disableOptionsOnChange({
      apolloClient,
      productId: instanceId,
      optionSets: Object.values(groups),
      optionSetId,
    });
  };

const handleAtcPageLoad = (
  event: Event | CustomEvent,
  apolloClient: ApolloClient<NormalizedCacheObject>,
  stripeStore: StripeStore
) => {
  if (!(event instanceof CustomEvent && event.type === RENDER_TREE_EVENT)) {
    return;
  }
  const addToCartForms = document.querySelectorAll(
    `[${DATA_ATTR_NODE_TYPE}="${NODE_TYPE_COMMERCE_ADD_TO_CART_FORM}"]`
  );

  // allow pills to work only in preview
  if (window.Webflow.env('preview')) {
    if (event.detail.isInitial) {
      forEach(addToCartForms, (addToCartForm) => {
        // set up pill groups
        const groups = new PillGroups(
          // @ts-expect-error - TS2345 - Argument of type 'Element' is not assignable to parameter of type 'HTMLElement'.
          addToCartForm,
          ({optionId, optionSetId}) => {
            groups.setSelectedPillsForSkuValues({
              [optionSetId]: optionId,
            });
          }
        );
        groups.init();
      });
    }
    return;
  }

  // otherwise, return for designer
  if (window.Webflow.env('design')) {
    return;
  }
  forEach(addToCartForms, (addToCartForm) => {
    const addToCartButton = findElementByNodeType(
      NODE_TYPE_COMMERCE_ADD_TO_CART_BUTTON,
      addToCartForm
    );

    // If this form has an add to cart button, set the `aria-haspopup` to `dialog` or `false`
    // depending on at least one Cart element having the setting for "Open when product is added"
    if (addToCartButton) {
      const cartElementsThatOpenOnAdd = document.querySelectorAll(
        `[${DATA_ATTR_NODE_TYPE}="${NODE_TYPE_COMMERCE_CART_WRAPPER}"][${DATA_ATTR_OPEN_PRODUCT}]`
      );

      addToCartButton.setAttribute(
        'aria-haspopup',
        cartElementsThatOpenOnAdd.length > 0 ? 'dialog' : 'false'
      );
    }

    const buyNowButton = findElementByNodeType(
      NODE_TYPE_COMMERCE_BUY_NOW_BUTTON,
      addToCartForm
    );
    // Hide the Buy now button if Stripe is not connected
    if (stripeStore && !stripeStore.isInitialized()) {
      if (buyNowButton) {
        hideElement(buyNowButton);
      }
    }

    // @ts-expect-error - TS2345 - Argument of type 'Element' is not assignable to parameter of type 'HTMLFormElement'.
    const instanceId = getInstanceId(addToCartForm);
    if (event.detail.isInitial) {
      // only set the selected SKU from the DOM on the initial render event, as after that we want to
      // rely on the store as the source of truth. without this, the selected SKU could get reset on any render
      updateStore(instanceId, {
        // @ts-expect-error - TS2322 - Type 'string | null' is not assignable to type 'string | undefined'.
        selectedSku:
          addToCartForm instanceof Element
            ? addToCartForm.getAttribute(DATA_ATTR_COMMERCE_SKU_ID)
            : '',
      });

      // add watcher for sku values change to update the page as needed
      addStoreWatcher(
        instanceId,
        'skuValues',
        updatePageWithNewSkuValuesData(instanceId, apolloClient)
      );

      // add watcher for dropdowns to update on sku change
      addStoreWatcher(
        instanceId,
        'skuValues',
        updateDropdownsOnPage(instanceId)
      );

      // set up pill groups
      // @ts-expect-error - TS2345 - Argument of type 'Element' is not assignable to parameter of type 'HTMLElement'.
      if (PillGroups.hasPillGroups(addToCartForm)) {
        const pillGroup = new PillGroups(
          // @ts-expect-error - TS2345 - Argument of type 'Element' is not assignable to parameter of type 'HTMLElement'.
          addToCartForm,
          updateSkuValuesOnPillSelect(instanceId, apolloClient)
        );
        addStoreWatcher(instanceId, 'skuValues', (newSkuValues) => {
          pillGroup.setSelectedPillsForSkuValues(newSkuValues);
        });
        pillGroup.init();
      }
    }
    const currentSkuId = fetchFromStore(instanceId, 'selectedSku');

    if (!currentSkuId) {
      return;
    }

    const productId =
      addToCartForm &&
      addToCartForm.getAttribute(DATA_ATTR_COMMERCE_PRODUCT_ID);
    if (productId) {
      apolloClient
        .query({
          query: getAllVariantsAndMemberships,
          variables: {productId},
        })
        .then(({data}) => {
          const items = data?.database?.collections?.c_sku_?.items ?? [];
          const products = data?.database?.collections?.c_product_?.items ?? [];
          const productType = products[0]
            ? products[0].f_ec_product_type_2dr10dr.name
            : 'Advanced';
          // build the possible sku values for the given product
          // since all items have the same sku values, we just use the first
          // then we iterate over the skus, and create an object of [propertyId: string]: ''
          // to represent that by default, no property has been selected yet
          if (
            event.detail.isInitial &&
            items[0].f_sku_values_3dr &&
            items[0].f_sku_values_3dr.length > 0
          ) {
            // @ts-expect-error - TS2347 - Untyped function calls may not accept type arguments.
            const skuValuesMap = items[0].f_sku_values_3dr.reduce<
              Record<string, any>
              // @ts-expect-error - TS7006 - Parameter 'map' implicitly has an 'any' type. | TS7006 - Parameter 'sku' implicitly has an 'any' type.
            >((map, sku) => {
              map[sku.property.id] = '';
              return map;
            }, {});

            updateStore(instanceId, {
              skuValues: skuValuesMap,
            });
          }
          const memberships = data?.database?.commerceMemberships ?? [];
          const hasActiveMemebership = Boolean(memberships[0]?.active);
          if (hasActiveMemebership) {
            if (buyNowButton) {
              buyNowButton.removeAttribute('href');
              // without href <a> doesn't have an implicit role, so adding it back here for accesibility
              buyNowButton.setAttribute('role', 'link');
              buyNowButton.setAttribute('aria-disabled', 'true');
              buyNowButton.classList.add('w--ecommerce-buy-now-disabled');
            }

            if (addToCartButton) {
              addToCartButton.setAttribute('disabled', 'true');
              addToCartButton.classList.add(
                'w--ecommerce-add-to-cart-disabled'
              );
            }
          }

          // @ts-expect-error - TS7006 - Parameter 'item' implicitly has an 'any' type.
          const currentSku = items.find((item) => item.id === currentSkuId);
          if (currentSku) {
            if (
              currentSku['f_ec_sku_billing_method_2dr6dr14dr'] ===
                'subscription' ||
              productType === 'Membership'
            ) {
              updateStore(instanceId, {
                requiresUserSession: true,
              });
            }
            // Set buy now text to subscription state if subscription
            if (
              currentSku['f_ec_sku_billing_method_2dr6dr14dr'] ===
              'subscription'
            ) {
              hideElement(addToCartButton);

              if (buyNowButton) {
                const buyNowSubscriptionText =
                  buyNowButton.getAttribute(DATA_ATTR_SUBSCRIPTION_TEXT) ||
                  'Subscribe now';
                buyNowButton.innerText = buyNowSubscriptionText;
              }
            } else if (buyNowButton) {
              const buyNowDefaultText =
                buyNowButton.getAttribute(DATA_ATTR_DEFAULT_TEXT) || 'Buy now';
              buyNowButton.innerText = buyNowDefaultText;
            }

            const addToCartWrapper = addToCartForm.parentElement;
            const optionListElement = findElementByNodeType(
              NODE_TYPE_COMMERCE_ADD_TO_CART_OPTION_LIST,
              // @ts-expect-error - TS2345 - Argument of type 'HTMLElement | null' is not assignable to parameter of type 'Document | Element | undefined'.
              addToCartWrapper
            );
            const outOfStockState =
              addToCartWrapper &&
              addToCartWrapper.getElementsByClassName(
                'w-commerce-commerceaddtocartoutofstock'
              )[0];
            // Check if exist a variant with stock
            const hasVariantsWithStock = items.some(
              // @ts-expect-error - TS7006 - Parameter 'variant' implicitly has an 'any' type.
              (variant) =>
                (variant.inventory.type === INVENTORY_TYPE_FINITE &&
                  variant.inventory.quantity > 0) ||
                variant.inventory.type === INVENTORY_TYPE_INFINITE
            );
            if (!hasVariantsWithStock && outOfStockState) {
              // @ts-expect-error - TS2339 - Property 'style' does not exist on type 'Element'.
              outOfStockState.style.display = '';
              // @ts-expect-error - TS2339 - Property 'style' does not exist on type 'Element'.
              addToCartForm.style.display = 'none';
            }

            // Update select options based on stock
            const optionSetsToUpdate = items[0].f_sku_values_3dr.map(
              // @ts-expect-error - TS7006 - Parameter 'skuValue' implicitly has an 'any' type.
              (skuValue) => skuValue.property.id
            );
            // @ts-expect-error - TS7006 - Parameter 'optionToUpdateSetId' implicitly has an 'any' type.
            optionSetsToUpdate.forEach((optionToUpdateSetId) => {
              // Get the select we will update
              let optionSet = addToCartForm.querySelector(
                `[${DATA_ATTR_COMMERCE_OPTION_SET_ID}="${optionToUpdateSetId}"]`
              );
              if (!(optionSet instanceof HTMLElement)) {
                return;
              }
              const optionSetId = optionSet.getAttribute(
                DATA_ATTR_COMMERCE_OPTION_SET_ID
              );
              if (
                optionSet.getAttribute(DATA_ATTR_NODE_TYPE) ===
                NODE_TYPE_COMMERCE_ADD_TO_CART_PILL_GROUP
              ) {
                // if we're using a pill group, we need to get the PillGroup class for the given element
                // so that we have access to the getters we defined to give us compatibility with the `select` API
                // @ts-expect-error - TS2339 - Property '_wfPillGroup' does not exist on type 'HTMLElement'.
                optionSet = optionSet._wfPillGroup;
              }
              // @ts-expect-error - TS18047 - 'optionSet' is possibly 'null'. | TS2339 - Property 'options' does not exist on type 'Element'.
              forEach(optionSet.options, (option) => {
                // Get the variant that has the current option combined with other selected options that in this case, since it's in the on load
                // it will be the default variant
                if (!option.value) {
                  // The default options should be always enabled
                  option.enabled = true;
                } else {
                  disableVariantsWithNoStock(items, optionSetId, option);
                }
              });

              // Make sure that if this is render occurs with option sets already selected that we disable based on available options
              const selectedOptionSets = optionSetsToUpdate.filter(
                // @ts-expect-error - TS7006 - Parameter 'optionSetToUpdate' implicitly has an 'any' type.
                (optionSetToUpdate) => optionSetToUpdate.value
              );
              disableVariantsWithNoStockForRemainingSelections(
                items,
                selectedOptionSets,
                optionSet,
                optionSetId
              );

              // Preselect the default variant if setting has been enabled and initial render
              if (
                event.detail.isInitial &&
                optionListElement &&
                optionListElement.getAttribute(
                  DATA_ATTR_PRESELECT_DEFAULT_VARIANT
                ) === 'true'
              ) {
                const defaultSkuId = get(data, [
                  'database',
                  'collections',
                  'c_product_',
                  'items',
                  0,
                  'f_default_sku_7dr',
                  'id',
                ]);
                const defaultSku = items.find(
                  // @ts-expect-error - TS7006 - Parameter 'item' implicitly has an 'any' type.
                  (item) => item.id === defaultSkuId
                );
                // Ensure the default variant is in stock before preselecting
                if (
                  defaultSku &&
                  !(
                    defaultSku.inventory.type === INVENTORY_TYPE_FINITE &&
                    defaultSku.inventory.quantity <= 0
                  )
                ) {
                  const defaultSkuIndex = Array.from(
                    // @ts-expect-error - TS18047 - 'optionSet' is possibly 'null'. | TS2339 - Property 'options' does not exist on type 'Element'.
                    optionSet.options
                  ).findIndex((option) =>
                    defaultSku.f_sku_values_3dr.some(
                      // @ts-expect-error - TS7006 - Parameter 'value' implicitly has an 'any' type. | TS18046 - 'option' is of type 'unknown'.
                      (value) => value.value.id === option.value
                    )
                  );
                  if (defaultSkuIndex > -1) {
                    // @ts-expect-error - TS18047 - 'optionSet' is possibly 'null'. | TS2339 - Property 'selectedIndex' does not exist on type 'Element'.
                    optionSet.selectedIndex = defaultSkuIndex;
                    updateStore(instanceId, {
                      selectedSku: defaultSku.id,
                      // update the sku values map to set each property id to the value id
                      // for the current variant
                      skuValues: simplifySkuValues(defaultSku.f_sku_values_3dr),
                    });
                  }
                }
              }
            });
          }
        });
    }
  });
};

const disableVariantsWithNoStockForRemainingSelections = (
  items: any,
  // @ts-expect-error - TS7006 - Parameter 'selectedOptionSets' implicitly has an 'any' type.
  selectedOptionSets,
  optionSet: any,
  id: any
) => {
  // Get all the possible variants given our current selections
  // @ts-expect-error - TS7006 - Parameter 'item' implicitly has an 'any' type.
  let possibleVariantSelections = items.filter((item) => {
    const itemMappedBySkuValues = item.f_sku_values_3dr.map(
      // @ts-expect-error - TS7006 - Parameter 'skuValues' implicitly has an 'any' type.
      (skuValues) => skuValues.value.id
    );
    const currentlySelectedSkuValues = selectedOptionSets.map(
      // @ts-expect-error - TS7006 - Parameter 'selectedOptionSet' implicitly has an 'any' type.
      (selectedOptionSet) => selectedOptionSet.value
    );
    // @ts-expect-error - TS7006 - Parameter 'selectedValue' implicitly has an 'any' type.
    return currentlySelectedSkuValues.every((selectedValue) =>
      itemMappedBySkuValues.includes(selectedValue)
    );
  });
  // Reset them if there is only 1 left i.e. user has selected all 3 options
  if (possibleVariantSelections.length === 1) {
    possibleVariantSelections = items;
  }

  forEach(optionSet.options, (option) => {
    // Get the variant that has the current option combined with other selected options that in this case, since it's in the on load
    // it will be the default variant
    if (!option.value) {
      // The default options should be always enabled
      option.enabled = true;
    } else {
      // @ts-expect-error - TS7006 - Parameter 'variant' implicitly has an 'any' type.
      const variantsFiltered = possibleVariantSelections.filter((variant) => {
        const sku = variant.f_sku_values_3dr.find(
          // @ts-expect-error - TS7006 - Parameter 'value' implicitly has an 'any' type.
          (value) => value.property.id === id
        );
        return sku.value.id === option.value;
      });
      const hasVariantsWithStock = variantsFiltered.some(
        // @ts-expect-error - TS7006 - Parameter 'variant' implicitly has an 'any' type.
        (variant) =>
          (variant.inventory.type === INVENTORY_TYPE_FINITE &&
            variant.inventory.quantity > 0) ||
          variant.inventory.type === INVENTORY_TYPE_INFINITE
      );

      if (!hasVariantsWithStock) {
        option.disabled = true;
      } else {
        option.disabled = false;
      }
    }
  });
};

const disableVariantsWithNoStock = (
  items: any,
  optionSetId: any,
  option: any
) => {
  if (!option.value) {
    return;
  }
  // @ts-expect-error - TS7006 - Parameter 'variant' implicitly has an 'any' type.
  const variantsFiltered = items.filter((variant) => {
    const sku = variant.f_sku_values_3dr.find(
      // @ts-expect-error - TS7006 - Parameter 'value' implicitly has an 'any' type.
      (value) => value.property.id === optionSetId
    );
    return sku.value.id === option.value;
  });
  const hasVariantsWithStock = variantsFiltered.some(
    // @ts-expect-error - TS7006 - Parameter 'variant' implicitly has an 'any' type.
    (variant) =>
      (variant.inventory.type === INVENTORY_TYPE_FINITE &&
        variant.inventory.quantity > 0) ||
      variant.inventory.type === INVENTORY_TYPE_INFINITE
  );

  if (!hasVariantsWithStock) {
    option.disabled = true;
  } else {
    option.disabled = false;
  }
};

const updateTextNodeVisibility = (node: any) => {
  if (node.innerText) {
    removeWDynBindEmptyClass(node);
  }

  if (
    !node.innerText &&
    !node.classList.contains(CLASS_NAME_W_DYN_BIND_EMPTY)
  ) {
    node.classList.add(CLASS_NAME_W_DYN_BIND_EMPTY);
  }
};

const updateLightboxJson = (node: any, binding: any) => {
  const lightboxScript = node.querySelector('script.w-json');
  if (lightboxScript) {
    const nodeJsonData = JSON.parse(lightboxScript.innerHTML);
    // if the JSON created from bound media is `null`,
    // we replace `script` tag contents with placeholder data
    // that retains the `group` property
    lightboxScript.innerHTML = JSON.stringify(
      createJsonFromBoundMedia(binding, nodeJsonData) || {
        items: [],
        group: nodeJsonData && nodeJsonData.group,
      }
    );
  }
};

// @ts-expect-error - TS7031 - Binding element 'target' implicitly has an 'any' type.
const isBuyNowButtonEvent = ({target}) =>
  target instanceof Element &&
  target.getAttribute(DATA_ATTR_NODE_TYPE) ===
    NODE_TYPE_COMMERCE_BUY_NOW_BUTTON;

// @ts-expect-error - TS2314 - Generic type 'ApolloClient<TCacheShape>' requires 1 type argument(s).
const handleBuyNow = (event: Event, apolloClient: ApolloClient) => {
  event.preventDefault();

  // Don't try and do anything in preview mode
  if (window.Webflow.env('preview')) {
    return;
  }

  const buyNowButton = event.target;
  const addToCartForm = findClosestElementByNodeType(
    NODE_TYPE_COMMERCE_ADD_TO_CART_FORM,
    // @ts-expect-error - TS2345 - Argument of type 'EventTarget | null' is not assignable to parameter of type 'EventTarget'.
    buyNowButton
  );
  if (
    !(buyNowButton instanceof HTMLAnchorElement) ||
    !(addToCartForm instanceof HTMLFormElement)
  ) {
    return;
  }

  if (buyNowButton.classList.contains('w--ecommerce-buy-now-disabled')) {
    return;
  }

  const addToCartWrapper = addToCartForm.parentElement;
  if (!(addToCartWrapper instanceof Element)) {
    return;
  }

  const addToCartErrorElement = findElementByNodeType(
    NODE_TYPE_COMMERCE_ADD_TO_CART_ERROR,
    addToCartWrapper
  );
  if (!(addToCartErrorElement instanceof Element)) {
    return;
  }

  addToCartErrorElement.style.display = 'none';

  if (!isProtocolHttps()) {
    window.alert(
      'This site is currently unsecured so you cannot purchase this item.'
    );
    return;
  }

  // Confirm atc selection is valid
  if (!addToCartForm.reportValidity()) {
    return;
  }

  // Redirect to sign up if the purchase requires a user session and there is none
  const requiresUserSession = fetchFromStore(
    getInstanceId(addToCartForm),
    'requiresUserSession'
  );
  const hasUserSession = document.cookie
    .split(';')
    .some((cookie) => cookie.indexOf(LOGGEDIN_COOKIE_NAME) > -1);
  if (requiresUserSession && !hasUserSession) {
    redirectWithUsrdir(`/${USYS_PAGE_SETTINGS.signup.slug}`);
    return;
  }

  const publishableKey = buyNowButton.getAttribute(DATA_ATTR_PUBLISHABLE_KEY);
  // If no publishable key checkout is not enabled
  if (!publishableKey) {
    const errorMsg = addToCartErrorElement.querySelector(
      `[${DATA_ATTR_NODE_TYPE}="${NODE_TYPE_ADD_TO_CART_ERROR}"]`
    );
    if (!errorMsg) {
      return;
    }
    const errorText =
      errorMsg.getAttribute(CHECKOUT_DISABLED_ERROR_MESSAGE) ||
      'Checkout is disabled.';
    errorMsg.textContent = errorText;
    addToCartErrorElement.style.removeProperty('display');
    return;
  }

  const skuId =
    fetchFromStore(getInstanceId(addToCartForm), 'selectedSku') || '';
  const formData = formToObject(addToCartForm);
  const formCount = formData[NODE_NAME_COMMERCE_ADD_TO_CART_QUANTITY_INPUT];
  const count = formCount ? parseInt(formCount, 10) : 1;

  // if no SKU id, then all options need to be selected
  // this is only shown for pills, as dropdowns will be caught by reportValidity above
  if (!skuId) {
    const errorMsg = addToCartErrorElement.querySelector(
      `[${DATA_ATTR_NODE_TYPE}="${NODE_TYPE_ADD_TO_CART_ERROR}"]`
    );
    if (!errorMsg) {
      return;
    }
    const errorText =
      errorMsg.getAttribute(getATCErrorMessageForType('select-all-options')) ||
      'Please select an option in each set.';
    errorMsg.textContent = errorText;
    addToCartErrorElement.style.removeProperty('display');
    return;
  }

  apolloClient
    .mutate({
      mutation: addToCartMutation,
      variables: {skuId, count, buyNow: true},
    })
    // @ts-expect-error - TS7031 - Binding element 'data' implicitly has an 'any' type.
    .then(({data}) => {
      const itemPrice = data.ecommerceAddToCart.itemPrice || {};

      trackAddToCartUsage(skuId, count, itemPrice);

      // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'Location | (string & Location)'.
      window.location = buyNowButton.href;
    })
    // @ts-expect-error - TS7006 - Parameter 'error' implicitly has an 'any' type.
    .catch((error) => {
      if (addToCartErrorElement) {
        addToCartErrorElement.style.removeProperty('display');

        const errorMsg = addToCartErrorElement.querySelector(
          `[${DATA_ATTR_NODE_TYPE}="${NODE_TYPE_ADD_TO_CART_ERROR}"]`
        );
        if (!errorMsg) {
          return;
        }
        const errorType =
          error.graphQLErrors &&
          error.graphQLErrors.length > 0 &&
          error.graphQLErrors[0].code === 'OutOfInventory'
            ? 'quantity'
            : 'buy-now';
        const errorText =
          errorMsg.getAttribute(getATCErrorMessageForType(errorType)) || '';
        errorMsg.textContent = errorText;
      }
      debug.error(error);
      triggerRender(null);
    });
};

export const register = (handlerProxy: EventHandlerProxyWithApolloClient) => {
  handlerProxy.on('submit', addToCartFormEventTargetMatcher, handleAtcSubmit);
  handlerProxy.on(
    'change',
    addToCartOptionSelectEventTargetMatcher,
    handleAtcOptionSelectChange
  );
  handlerProxy.on('click', isBuyNowButtonEvent, handleBuyNow);
  handlerProxy.on(RENDER_TREE_EVENT, Boolean, handleAtcPageLoad);
};

export default {register};
