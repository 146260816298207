/* globals window, document */
/* eslint-disable no-var */
/**
 * Webflow: Auto-select links to current page or section
 */

var Webflow = require('./webflow-lib');

Webflow.define(
  'links',
  (module.exports = function ($, _) {
    var api = {};
    var $win = $(window);
    var designer;
    var inApp = Webflow.env();
    var location = window.location;
    var tempLink = document.createElement('a');
    var linkCurrent = 'w--current';
    var indexPage = /index\.(html|php)$/;
    var dirList = /\/$/;
    var anchors;
    var slug;

    // -----------------------------------
    // Module methods

    api.ready = api.design = api.preview = init;

    // -----------------------------------
    // Private methods

    function init() {
      designer = inApp && Webflow.env('design');
      slug = Webflow.env('slug') || location.pathname || '';

      // Reset scroll listener, init anchors
      Webflow.scroll.off(scroll);
      anchors = [];

      // Test all links for a selectable href
      var links = document.links;
      for (var i = 0; i < links.length; ++i) {
        select(links[i]);
      }

      // Listen for scroll if any anchors exist
      if (anchors.length) {
        Webflow.scroll.on(scroll);
        scroll();
      }
    }

    function select(link) {
      // Ignore localized links
      if (link.getAttribute('hreflang')) {
        return;
      }

      var href =
        (designer && link.getAttribute('href-disabled')) ||
        link.getAttribute('href');
      tempLink.href = href;

      // Ignore any hrefs with a colon to safely avoid all uri schemes
      if (href.indexOf(':') >= 0) {
        return;
      }

      var $link = $(link);

      // Check for all links with hash (eg (this-host)(/this-path)#section) to this page
      if (
        tempLink.hash.length > 1 &&
        tempLink.host + tempLink.pathname === location.host + location.pathname
      ) {
        // Ignore any hrefs with Google Translate type hash
        // Example: jQuery can't parse $('#googtrans(en|es)')
        // https://forum.webflow.com/t/dropdown-menus-not-working-on-site/87140
        if (!/^#[a-zA-Z0-9\-\_]+$/.test(tempLink.hash)) {
          return;
        }

        var $section = $(tempLink.hash);
        $section.length &&
          anchors.push({
            link: $link,
            sec: $section,
            active: false,
          });
        return;
      }

      // Ignore empty # links
      if (href === '#' || href === '') {
        return;
      }

      // Determine whether the link should be selected
      var match =
        tempLink.href === location.href ||
        href === slug ||
        (indexPage.test(href) && dirList.test(slug));
      setClass($link, linkCurrent, match);
    }

    function scroll() {
      var viewTop = $win.scrollTop();
      var viewHeight = $win.height();

      // Check each anchor for a section in view
      _.each(anchors, function (anchor) {
        // Ignore localized links
        if (anchor.link.attr('hreflang')) {
          return;
        }

        var $link = anchor.link;
        var $section = anchor.sec;
        var top = $section.offset().top;
        var height = $section.outerHeight();
        var offset = viewHeight * 0.5;
        var active =
          $section.is(':visible') &&
          top + height - offset >= viewTop &&
          top + offset <= viewTop + viewHeight;
        if (anchor.active === active) {
          return;
        }
        anchor.active = active;
        setClass($link, linkCurrent, active);
      });
    }

    function setClass($elem, className, add) {
      var exists = $elem.hasClass(className);
      if (add && exists) {
        return;
      }
      if (!add && !exists) {
        return;
      }
      add ? $elem.addClass(className) : $elem.removeClass(className);
    }

    // Export module
    return api;
  })
);
