import {Const, getConst} from './Const';
import {Identity, runIdentity} from './Identity';
import {None, Some, maybe} from '@packages/utilities/fp/option';
import {either as resultEither, Err, Ok} from '@packages/utilities/fp/result';

import type {Option} from '@packages/utilities/fp/option';
import type {Result} from '@packages/utilities/fp/result';

export const objectKeys: <K extends string, V>(arg1: Record<K, V>) => Array<K> =
  Object.keys;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export let emptyArray: Array<any> = [];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export let emptyObject: Record<string, any> = {};

if (process.env.NODE_ENV === 'development') {
  const proxy = <T>(sharedConstantName: string) => ({
    set: (target: T, prop: PropertyKey, value: unknown) => {
      console.error(
        'Invalid mutation of shared constant. Property "%s" was set on "%s".',
        prop,
        sharedConstantName
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (target as any)[prop] = value;
      return true;
    },
  });
  emptyArray = new Proxy(emptyArray, proxy('emptyArray'));
  emptyObject = new Proxy(emptyObject, proxy('emptyObject'));
}

// Combinators

export const identity = <A>(x: A): A => x;

export const constant =
  <A>(x: A) =>
  <B>(_?: B): A =>
    x;

export const compose =
  <B, C>(f: (x: B) => C) =>
  <A>(g: (y: A) => B) =>
  (x: A): C =>
    f(g(x));

export const blackbird =
  <C, D>(f: (z: C) => D) =>
  <A, B>(g: (x: A) => (y: B) => C) =>
  (x: A) =>
  (y: B) =>
    f(g(x)(y));

export const flip =
  <a, b, c>(f: (y: a) => (x: b) => c) =>
  (x: b) =>
  (y: a) =>
    f(y)(x);

export const thrush =
  <A>(x: A) =>
  <B>(f: (y: A) => B): B =>
    f(x);

/**
 * Returns the first argument applied to the third, which is then applied
 * to the result of the second argument applied to the third.
 */
export const substitution =
  <A, B, C>(f: (x: A) => (y: B) => C) =>
  (g: (x: A) => B) =>
  (x: A): C =>
    f(x)(g(x));

// Logic utilities

type Predicate<A> = (x: A) => boolean;

export const constantFalse = constant(false);
export const constantTrue = constant(true);

export const not = (x: boolean) => !x;
export const complement: <A>(f: Predicate<A>) => Predicate<A> = compose(not);

export const anyPass =
  <a>(preds: Array<Predicate<a>>): Predicate<a> =>
  (value) =>
    preds.some(thrush(value));

export const allPass =
  <A>(preds: Array<Predicate<A>>): Predicate<A> =>
  (value) =>
    preds.every(thrush(value));

export const optionToBool: <A>(o: Option<A>) => boolean =
  maybe(false)(constantTrue);
export const resultToBool: <T, F>(r: Result<T, F>) => boolean =
  resultEither(constantFalse)(constantTrue);

/**
 * Returns true if the two values are referentially equal.
 */
export const equals =
  <a>(x: a) =>
  (y: a) =>
    x === y;

/**
 * Returns true if the two values are not referentially equal.
 */
export const notEqual =
  <a>(x: a) =>
  (y: a) =>
    x !== y;

/**
 * Returns true if the provided `value` is `undefined` or `null`.
 */
export const isNil = <a>(value: a): boolean => value == null;

/**
 * Returns true if the provided `value` is not `undefined` or `null`.
 */
export const notNil: <a>(arg1: a) => boolean = complement(isNil);

/**
 * Takes two predicate functions and returns a new function that
 * evaluates to true when either of the predicates evaluate to true.
 *
 * @param {Function} predicateA
 * @param {Function} predicateB
 * @return {Boolean}
 */

export const either =
  (predicateA: any, predicateB: any) =>
  (...args: Array<any>) =>
    predicateA(...args) || predicateB(...args);

/**
 * Takes two predicate functions and returns a new function that evaluates
 * to true if both predicates evaluate to true.
 *
 * @param  {Function} predicateA
 * @param  {Function} predicateB
 * @return {Boolean}
 */
export const both =
  (predicateA: any, predicateB: any) =>
  (...args: Array<any>) =>
    predicateA(...args) && predicateB(...args);

/**
 * Executes `predicate` with the provided `value`. If the result is true,
 * returns the result of applying `whenTrueFn` to the `value`. If it is false,
 * just returns the `value` itself.
 */
export const when =
  <a>(predicate: Predicate<a>) =>
  (whenTrueFn: (x: a) => a) =>
  (value: a) =>
    predicate(value) ? whenTrueFn(value) : value;

export const has =
  <a extends string>(key: a) =>
  <b>(object: Partial<Record<a, b>>): boolean =>
    Object.hasOwn(object, key);

export const prop =
  <O extends Record<string, any>, K extends keyof O>(key: K) =>
  (object: O): O[K] =>
    object[key];

const assocReducer = (
  acc:
    | {
        result: Record<any, any>;
        source: any;
      }
    | {
        result: Record<any, any>;
        source: never;
      },
  key: any
) => {
  acc.result[key] = acc.source[key];
  return acc;
};

export const assoc = (key: string) => {
  const hasKey = has(key);
  return <A>(value: A) =>
    <O extends Record<any, any>>(object: O): O => {
      if (hasKey(object) && object[key] === value) {
        return object;
      }

      const result = objectKeys(object).reduce(assocReducer, {
        source: object,
        result: {},
      }).result;

      result[key] = value;

      return result as O;
    };
};

const dissocReducer = (
  acc: {
    exclude: string;
    result: Record<any, any>;
    source: {
      [key: string]: any;
    };
  },
  key: string
) => {
  if (acc.exclude !== key) {
    acc.result[key] = acc.source[key];
  }
  return acc;
};

type Dissoc<a> = (arg1: string) => (arg2: {[key: string]: a}) => {
  [key: string]: a;
};
export const dissoc: Dissoc<any> = (key) => {
  const hasKey = has(key);
  return (object: {[key: string]: any}) =>
    hasKey(object)
      ? objectKeys(object).reduce(dissocReducer, {
          source: object,
          result: {},
          exclude: key,
        }).result
      : object;
};

export const adjust =
  <a>(f: (x: a) => a) =>
  <k extends string>(key: k) => {
    const hasKey = has(key);
    return (obj: Record<k, a>) =>
      hasKey(obj) ? assoc(key)(f(obj[key]))(obj) : obj;
  };

export const unionWith =
  <a>(combine: (x: a) => (y: a) => a) =>
  <k extends string>(
    first: Record<k, a>
  ): ((x: Record<k, a>) => Record<k, a>) =>
    first === emptyObject
      ? identity
      : (second: Record<k, a>) => {
          if (second === emptyObject) return first;

          let changedFromFirst = false;
          let changedFromSecond = false;
          const result: Record<string, a> = {};

          for (const key in second) {
            const secondVal = second[key];

            if (key in first) {
              const firstVal = first[key];
              const finalVal = combine(firstVal)(secondVal);

              if (finalVal !== secondVal) {
                changedFromSecond = true;
              }
              if (finalVal !== firstVal) {
                changedFromFirst = true;
              }
              result[key] = finalVal;
            } else {
              changedFromFirst = true;
              result[key] = secondVal;
            }
          }

          for (const key in first) {
            if (key in result) continue;
            changedFromSecond = true;
            result[key] = first[key];
          }

          if (!changedFromFirst) return first;
          if (!changedFromSecond) return second;
          return result;
        };

export const union: <k extends string, a>(
  x: Record<k, a>
) => (y: Record<k, a>) => Record<k, a> = unionWith(constant);

export const unionTo: <k extends string, a>(
  arg1: Record<k, a>
) => (arg2: Record<k, a>) => Record<k, a> = flip(union);

const omitReducer = <a>(
  acc: {
    changed: boolean;
    exclude: Array<string>;
    result: Record<string, a>;
    source: Record<string, a>;
  },
  key: string
) => {
  if (acc.exclude.includes(key)) {
    acc.changed = true;
  } else {
    // @ts-expect-error - TS2322 - Type 'a | undefined' is not assignable to type 'a'.
    acc.result[key] = acc.source[key];
  }
  return acc;
};

export const omit = (keys: Array<string>) => {
  const len = keys.length;

  if (len === 0) {
    return identity;
  }

  if (len === 1) {
    // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
    return dissoc(keys[0]);
  }

  return <a>(object: Record<string, a>): Record<string, a> => {
    const {result, changed} = objectKeys(object).reduce(omitReducer, {
      source: object,
      exclude: keys,
      changed: false,
      result: {},
    });

    return changed ? result : object;
  };
};

const pickReducer = (
  acc: {
    result: Record<any, any>;
    source: Partial<Record<any, never>>;
  },
  key: any
) => {
  if (Object.hasOwn(acc.source, key)) {
    acc.result[key] = acc.source[key];
  }
  return acc;
};

type Pick<a, b> = (
  arg1: Array<b>
  // @ts-expect-error - TS2344 - Type 'b' does not satisfy the constraint 'string | number | symbol'. | TS2344 - Type 'b' does not satisfy the constraint 'string | number | symbol'.
) => (arg2: Partial<Record<b, a>>) => Partial<Record<b, a>>;
export const pick: Pick<any, any> =
  (keys) => (source: Partial<Record<any, never>>) =>
    keys.reduce(pickReducer, {source, result: {}}).result;

const pickByReducer = (
  acc: {
    changed: boolean;
    predicate: (arg1: never) => boolean;
    result: Record<any, any>;
    source: Partial<Record<any, never>>;
  },
  key: any
) => {
  const value = acc.source[key];
  // @ts-expect-error - TS2345 - Argument of type 'undefined' is not assignable to parameter of type 'never'.
  if (acc.predicate(value)) {
    acc.result[key] = value;
  } else {
    acc.changed = true;
  }
  return acc;
};

type PickBy<a, b> = (
  arg1: (arg2: a) => boolean
  // @ts-expect-error - TS2344 - Type 'b' does not satisfy the constraint 'string | number | symbol'. | TS2344 - Type 'b' does not satisfy the constraint 'string | number | symbol'.
) => (arg3: Partial<Record<b, a>>) => Partial<Record<b, a>>;
export const pickBy: PickBy<any, any> =
  (predicate) => (object: Partial<Record<any, never>>) => {
    const {result, changed} = objectKeys(object).reduce(pickByReducer, {
      source: object,
      predicate,
      changed: false,
      result: {},
    });
    return changed ? result : object;
  };

export const lookup = <k extends string>(key: k) => {
  const hasKey = has(key);
  return <a>(object: Record<k, a>): Option<a> =>
    hasKey(object) ? Some(object[key]) : (None as Option<a>);
};

/**
 * Returns the value at `key` or returns `defaultValue` if no value is present
 * at `key` in `object` map.
 */
export const lookupWithDefault =
  <a>(defaultValue: a) =>
  <k extends string>(key: k) => {
    const hasKey = has(key);
    return (object: Record<k, a>) =>
      hasKey(object) ? object[key] : defaultValue;
  };

export const find =
  <a>(pred: Predicate<a>): ((arg1: a[]) => Option<a>) =>
  // @ts-expect-error - TS2322 - Type '(array: a[]) => Option<a> | Option<a | undefined>' is not assignable to type '(arg1: a[]) => Option<a>'.
  (array) => {
    const index = array.findIndex(pred);
    return index === -1 ? (None as Option<a>) : Some(array[index]);
  };

type Pipe = <T = any>(fns: Array<(arg: T) => T>) => (initialValue: T) => T;

export const pipe: Pipe = (fns) => (initialValue) =>
  fns.reduce((value, fn) => fn(value), initialValue);

export const zipWith =
  <a, b, c>(f: (x: a) => (y: b) => c) =>
  (xs: Array<a>) =>
  (ys: Array<b>): Array<c> => {
    const rv: Array<c> = [];
    let idx = 0;
    const len = Math.min(xs.length, ys.length);

    while (idx < len) {
      // @ts-expect-error - TS2345 - Argument of type 'a | undefined' is not assignable to parameter of type 'a'. | TS2345 - Argument of type 'b | undefined' is not assignable to parameter of type 'b'.
      rv[idx] = f(xs[idx])(ys[idx]);
      idx += 1;
    }
    return rv;
  };

type Zip = <a, b>(x: Array<a>) => (y: Array<b>) => Array<[a, b]>;

export const zip: Zip = zipWith((x) => (y) => [x, y]);

function getMinLength<T>(arrays: ReadonlyArray<ReadonlyArray<T>>): number {
  if (arrays.length === 0) return 0;
  // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
  if (arrays.length === 1) return arrays[0].length;

  // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
  let min = arrays[0].length;
  for (let i = 1, len = arrays.length; i < len; i++) {
    // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
    const arr_len = arrays[i].length;
    if (arr_len < min) min = arr_len;
  }
  return min;
}

/*
 * Usage:
 *  const multiply = (a: number) => (b: number) => a * b;
 *  const arrays = [
 *    [1, 2, 3, 4],
 *    [5, 6],
 *    [7, 8, 9],
 *  ];
 *  const result = zipCat(multiply)(arrays); // => [35, 96, 3, 4, 9]
 */
export function zipCat<T>(
  fn: (arg1: T) => (arg2: T) => T
): (arg1: ReadonlyArray<ReadonlyArray<T>>) => Array<T> {
  return function zipCat_inner(
    arrays: ReadonlyArray<ReadonlyArray<T>>
  ): Array<T> {
    // Find the length of the shortest array to know the max number of zip items.
    const zipLength = getMinLength(arrays);
    // Create a single array reference to store our items into.
    const rv: Array<T> = [];
    // For each array
    for (let i = 0, len = arrays.length; i < len; i++) {
      const array = arrays[i];
      // For each index in the current array
      // @ts-expect-error - TS18048 - 'array' is possibly 'undefined'.
      for (let j = 0, array_len = array.length; j < array_len; j++) {
        // @ts-expect-error - TS18048 - 'array' is possibly 'undefined'.
        const item = array[j];
        // For each index that falls in the zip range
        if (j < zipLength) {
          const existing = rv[j];
          // Check if we have a previously calculated value for this zip index.
          // If we do then we call the zip fn on the existing result and the current item,
          // then save it back at the zip index.
          if (typeof existing !== 'undefined') {
            // @ts-expect-error - TS2345 - Argument of type 'T | undefined' is not assignable to parameter of type 'T'.
            rv[j] = fn(existing)(item);
          }
          // If we don't then we save the current item at the zip index.
          else {
            // @ts-expect-error - TS2322 - Type 'T | undefined' is not assignable to type 'T'.
            rv[j] = item;
          }
        }
        // If the index falls outside the zip range, then push the item onto the
        // end of result array. We use `push` here to ensure it is inserted after
        // the zip range indices.
        else {
          // @ts-expect-error - TS2345 - Argument of type 'T | undefined' is not assignable to parameter of type 'T'.
          rv.push(item);
        }
      }
    }
    return rv;
  };
}

export const map =
  <
    a,
    b,
    c extends {
      readonly map: (arg1: (arg2: a) => b) => any;
    },
  >(
    f: (arg1: a) => b
  ) =>
  (xs: c) =>
    xs.map(f);

export const mapArray =
  <a, b>(f: (arg1: a) => b): ((arg1: Array<a>) => Array<b>) =>
  (xs: Array<a>) => {
    let changed = false;
    const ys = xs.reduce<Array<any>>((res, x) => {
      const newX = f(x);
      // @ts-expect-error - TS2367 - This comparison appears to be unintentional because the types 'b' and 'a' have no overlap.
      if (newX !== x) {
        changed = true;
      }
      res.push(newX);
      return res;
    }, []);
    // @ts-expect-error - TS2352 - Conversion of type 'a[]' to type 'b[]' may be a mistake because neither type sufficiently overlaps with the other. If this was intentional, convert the expression to 'unknown' first.
    return changed ? ys : (xs as Array<b>);
  };

type Filter<a> = (arg1: (arg2: a) => boolean) => (arg2: Array<a>) => Array<a>;
export const filter: Filter<any> = (f) => (xs: Array<any>) => xs.filter(f);

export const reduce =
  <a, b>(
    reducer: (arg1: b, arg2: a) => b
  ): ((arg1: b) => (arg2: Array<a>) => b) =>
  (init: b) =>
  (xs: Array<a>) =>
    xs.reduce(reducer, init);

export const reduceObject =
  <a, b>(
    reducer: (arg1: b) => (arg2: a) => b
  ): ((arg1: b) => <k extends string>(arg2: Partial<Record<k, a>>) => b) =>
  (init: b) =>
  <k extends string>(obj: Partial<Record<k, a>>) =>
    // @ts-expect-error - TS2345 - Argument of type 'a | undefined' is not assignable to parameter of type 'a'.
    objectKeys(obj).reduce((result, key) => reducer(result)(obj[key]), init);

type ObjOf<a> = (arg1: string) => (arg2: a) => {
  [key: string]: a;
};
export const objOf: ObjOf<any> = (key) => (value: any) => ({[key]: value});

/**
 *  concat concatenates the first onto the second
 *  concat([1,2,3])([4,5,6]) becomes [4,5,6,1,2,3]
 */
export const concat = <a>(ys: Array<a>): ((arg1: Array<a>) => Array<a>) =>
  ys.length ? (xs: Array<a>) => (xs.length ? xs.concat(ys) : ys) : identity;

/**
 *  concatTo concatenates the second onto the first
 *  concatTo([1,2,3])([4,5,6]) becomes [1,2,3,4,5,6]
 */
export const concatTo: (arg1: Array<any>) => (arg2: Array<any>) => Array<any> =
  flip(concat);

export const append = <a>(value: a): ((arg1: Array<a>) => Array<a>) =>
  concat([value]);

export const constantIdentity = constant(identity);

// Math

export const add = (x: number) => (y: number) => x + y;

export const inc = (x: number) => x + 1;

export const max = (x: number) => (y: number) => (x > y ? x : y);

export const parseIntWithRadix =
  (radix: number) =>
  (num: string): Option<number> => {
    const parsed = parseInt(num, radix);
    return isNaN(parsed) ? (None as Option<number>) : Some(parsed);
  };

export const safeParseInt = parseIntWithRadix(10);

// Array

export const head = <a>(xs: Array<a>): Option<a> =>
  // @ts-expect-error - TS2322 - Type 'Option<any> | Option<a | undefined>' is not assignable to type 'Option<a>'.
  xs.length ? Some(xs[0]) : None;

export const last = <a>(xs: Array<a>): Option<a> =>
  // @ts-expect-error - TS2322 - Type 'Option<any> | Option<a | undefined>' is not assignable to type 'Option<a>'.
  xs.length ? Some(xs[xs.length - 1]) : None;

export const tail = <a>(xs: Array<a>): Array<a> => xs.slice(1);

export const length = (xs: Array<any> | string): number => xs.length;

export const flatMap = <a, b>(
  f: (arg1: a) => Array<b>
): ((arg1: Array<a>) => Array<b>) =>
  reduce((result: Array<b>, item: a) => {
    const ys = f(item);
    if (!ys.length) {
      return result;
    }
    const nextResult = result.length ? result : [];
    nextResult.push.apply(nextResult, ys); // eslint-disable-line prefer-spread
    return nextResult;
  })(emptyArray);

export const flat: <a>(arg1: Array<Array<a>>) => Array<a> = (flatMap as any)(
  identity
);

// String

export const test = (regex: RegExp) => (string: string) => {
  regex.lastIndex = 0;
  const result = regex.test(string);
  regex.lastIndex = 0;
  return result;
};

export const match =
  (regex: RegExp) =>
  (string: string): Option<string> => {
    const result = string.match(regex);
    return result ? Some(result[0]) : (None as Option<string>);
  };

export const replace =
  (pattern: string | RegExp) => (replacement: string) => (string: string) =>
    string.replace(pattern, replacement);

export const split = (pattern: string | RegExp) => (string: string) =>
  string.split(pattern);

export type Functor<A> = {
  map: <B>(arg1: (arg2: A) => B) => Functor<B>;
};
export type Lens<S, T, A, B> = (
  arg1: (arg2: A) => Functor<B>
) => (arg2: S) => Functor<T>;

// See https://github.com/webflow/webflow/blob/dev/docs/app/fp-module/lenses.md
export const lens =
  <S, A>(
    getter: (arg1: S) => A
  ): (<B, T>(arg1: (arg2: B) => (arg3: S) => T) => Lens<S, T, A, B>) =>
  <B, T>(setter: (arg1: B) => (arg2: S) => T): Lens<S, T, A, B> =>
  (toFunctor: (arg1: A) => Functor<B>): ((arg1: S) => Functor<T>) =>
  (target: S) =>
    toFunctor(getter(target)).map((focus) => setter(focus)(target));

export const lensProp = (key: string) => lens(prop(key))(assoc(key));

// @ts-expect-error - TS2322 - Type '(arg1: Lens<s, t, a, b>) => unknown' is not assignable to type '<s, t, a, b>(arg1: Lens<s, t, a, b>) => (arg1: s) => a'.
export const view: <s, t, a, b>(arg1: Lens<s, t, a, b>) => (arg2: s) => a =
  // @ts-expect-error Argument of type '<A>(arg1: (arg2: A) => unknown) => (arg3: A) => unknown' is not assignable to parameter of type '(arg1: unknown) => (arg3: unknown) => unknown'.  Types of parameters 'arg1' and 'arg1' are incompatible.  Type 'unknown' is not assignable to type '(arg2: unknown) => unknown'.
  compose(compose(getConst))(thrush(Const));

// See https://github.com/webflow/webflow/blob/dev/docs/app/fp-module/lenses.md#writing-data
export const over =
  <s, t, a, b>(
    l: Lens<s, t, a, b>
  ): ((arg1: (arg2: a) => b) => (arg2: s) => t) =>
  (f: (arg1: a) => b): ((arg1: s) => t) => {
    const toFunctor = compose(Identity)(f);
    // @ts-expect-error - TS2322 - Type '(arg1: s) => unknown' is not assignable to type '(arg1: s) => t'. | TS2345 - Argument of type '<a>(object: IdentityType<a>) => a' is not assignable to parameter of type '(arg1: unknown) => unknown'. | TS2345 - Argument of type '(arg1: a) => IdentityType<unknown>' is not assignable to parameter of type '(arg1: a) => Functor<b>'.
    return compose(runIdentity)(l(toFunctor));
  };

export const set = <s, t, a, b>(
  l: Lens<s, t, a, b>
): ((arg1: b) => (arg2: s) => t) => compose(over(l))(constant);

export const constantNone: <a, b>(_: a) => Option<b> = constant(None);

export const noneToErr = <e>(
  error: e
): (<a>(arg1: Option<a>) => Result<e, a>) => maybe(Err(error))(Ok);

export const okToOption: <e, a>(r: Result<e, a>) => Option<a> =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resultEither(constantNone<unknown, any>)(Some);
export const errToOption: <e, a>(r: Result<e, a>) => Option<e> =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resultEither(Some)(constantNone) as any;

// Miscellaneous utilities.

/**
 * Executes the provided `unsafeFn` with the provided `value` and then returns
 * `value`. Useful for performing side effects in pure chains of transformations.
 *
 * @param {Function} unsafeFn
 * @param {*} value
 * @return {*}
 */
export const tap =
  <a>(unsafeFn: (arg1: a) => any): ((arg1: a) => a) =>
  (value) => {
    unsafeFn(value);
    return value;
  };

export const extractBool: (arg1: Option<boolean>) => boolean =
  maybe(false)(identity);

export const extractArray: <T>(arg1: Option<Array<T>>) => Array<T> =
  maybe(emptyArray)(identity);

// Get a function out of an Option, falling back to `identity`
// in the case of None.
export const extractFunctionFromOption: <F>(
  arg1: Option<F>
  // @ts-expect-error - TS2345 - Argument of type '<a>(x: a) => a' is not assignable to parameter of type '(a: F) => <a>(x: a) => a'.
) => F | typeof identity = maybe(identity)(identity);

// Get a function out of a Result, falling back to `identity`
// in the case of Err.
export const extractFunctionFromResult: <e, a>(
  r: Result<e, (x: a) => a>
) => (x: a) => a = resultEither(constantIdentity)(identity<any>);

export const optionToArray: <T>(arg1: Option<T>) => Array<T> = maybe(
  emptyArray
)(Array.of);

const optionOfEmptyArray = Some(emptyArray);

export const traverseOptions =
  <a, b>(f: (arg1: a) => Option<b>): ((arg1: Array<a>) => Option<Array<b>>) =>
  (xs: Array<a>) =>
    xs.reduce(
      (option, x) => f(x).map(append).ap(option),
      optionOfEmptyArray as Option<Array<b>>
    );

const resultOfEmptyArray = Ok(emptyArray);

export const traverseResults =
  <a, b, e>(
    f: (arg1: a) => Result<e, b>
  ): ((arg1: Array<a>) => Result<e, Array<b>>) =>
  (xs: Array<a>) =>
    xs.reduce(
      (result, x) => f(x).map(append).ap(result),
      resultOfEmptyArray as Result<e, Array<b>>
    );

const resultOfEmptyObject = Ok(emptyObject);

export const traverseObjectResults =
  <a, b, e>(f: (x: a) => Result<e, b>) =>
  <k extends string>(obj: Record<k, a>): Result<e, Record<k, b>> =>
    objectKeys(obj).reduce(
      (result, key) => f(obj[key]).map(assoc(key)).ap(result),
      resultOfEmptyObject
    );

export const mapValues =
  <a, b>(
    f: (arg1: a) => b
  ): ((arg1: {[key: string]: a}) => {
    [key: string]: b;
  }) =>
  (obj: {[key: string]: a}) => {
    let changed = false;
    const newObj = objectKeys(obj).reduce<Record<string, any>>(
      (result, key) => {
        const oldVal = obj[key];
        // @ts-expect-error - TS2345 - Argument of type 'a | undefined' is not assignable to parameter of type 'a'.
        const newVal = f(oldVal);
        if (oldVal !== newVal) {
          changed = true;
        }
        result[key] = newVal;
        return result;
      },
      {}
    );
    return changed
      ? newObj
      : // @ts-expect-error - TS2352 - Conversion of type '{ [key: string]: a; }' to type '{ [key: string]: b; }' may be a mistake because neither type sufficiently overlaps with the other. If this was intentional, convert the expression to 'unknown' first.
        (obj as {
          [key: string]: b;
        });
  };

// This function is useful to replace Object.values, as Flow has trouble with the native version
// https://github.com/facebook/flow/issues/2221
export const values = <T>(obj: {[key: string]: T}): Array<T> =>
  // @ts-expect-error - TS2322 - Type '(T | undefined)[]' is not assignable to type 'T[]'.
  Object.keys(obj).map((k) => obj[k]);

// This function is useful to replace Object.entries, as Flow has trouble with the native version
// https://github.com/facebook/flow/issues/2174#issuecomment-326783350
export const entries = <T, V extends string>(
  obj: Partial<Record<V, T>>
): Array<[V, T]> => {
  const keys = Object.keys(obj);
  // @ts-expect-error - TS2322 - Type '[string, any][]' is not assignable to type '[V, T][]'. | TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Partial<Record<V, T>>'.
  return keys.map((key) => [key, obj[key]]);
};

export const getDeepestValues = <T>(obj: {[key: string]: T}): Array<T> => {
  // @ts-expect-error - TS2322 - Type '(T | undefined)[]' is not assignable to type 'T[]'.
  return Object.keys(obj).flatMap((k) =>
    obj[k] && typeof obj[k] === 'object'
      ? getDeepestValues(
          obj[k] as unknown as {
            [key: string]: T;
          }
        )
      : [obj[k]]
  );
};

export const nth =
  (index: number) =>
  <T>(a: Array<T>): Option<T> =>
    index < 0 || index >= a.length ? None : Some(a[index] as T);
