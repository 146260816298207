// Avoid adding unnecessary code or imports to this file,
// because it will directly affect the webflow.js bundle size.
import {
  test as dynamoTest,
  getItemFieldValue,
} from '@packages/systems/dynamo/utils/DynamoConditionUtils';
import moment from 'moment-timezone';
import {
  fieldSlug,
  isDynamoGraphQLFieldSlug,
} from '@packages/systems/dynamo/utils/SlugUtils';
import {weakMemo} from '@packages/utilities/memo';
import {
  getItemRefSlug,
  createFieldPath,
  getValueFieldSlug,
} from '@packages/systems/dynamo/utils/ParamFieldPathUtils';
import {NON_EXISTING_ITEM_ID} from '@packages/systems/dynamo/constants';
import {type ConditionsWithTypeT} from '@packages/systems/dynamo/types';
import {normalizeConditionFields} from '@packages/systems/dynamo/utils/FilterUtils';
import {type Map} from 'immutable';

// inlined from `@packages/systems/core/utils/RecordUtils` to keep bundle size minimal
const getId = (record: any): string | null | undefined => {
  return (
    record._id ||
    record.id ||
    (record.get ? record.get('_id', record.get('id')) : null)
  );
};

const isDateStringWithoutTime = (dateString: string): boolean =>
  /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(dateString);

const toGraphQLSlug = (originalSlug: string): string => {
  const slug = handleId(originalSlug);
  return slug === 'id' ||
    isDynamoGraphQLFieldSlug(slug) ||
    // Don't want to namespace field slug when retrieving product inventory data
    slug === 'ecSkuInventoryQuantity'
    ? slug
    : fieldSlug(slug);
};

const handleId = (slug: string) => (slug === '_id' ? 'id' : slug);

const isObj = (x: unknown) =>
  x !== null && typeof x === 'object' && !Array.isArray(x);

// A simple, non-comprehensive way of detecting Maps and Lists
const isMap = (x: any) => x && Boolean(x['@@__IMMUTABLE_MAP__@@']);
const isList = (x: any) => x && Boolean(x['@@__IMMUTABLE_LIST__@@']);
const isRecord = (x: any) => x && Boolean(x['@@__IMMUTABLE_RECORD__@@']);

const memoizedToJS = weakMemo((imm: any) => imm.toJS());

// This is purposefully an `any`. It avoids about 9 Flow errors below
// that derive from 1) the impossibility of inferring that many different
// data types and 2) the challenge of enumerating all the possible ones.
// `mixed` is not very well suited for this, because it still requires enumeration
// for types before operating on those values.
const convertImmutableDataStructure = (value: any): any => {
  if (isMap(value) || isList(value) || isRecord(value)) {
    return memoizedToJS(value);
  }

  return value;
};

const getFieldsFromConditions = (conditions: any) =>
  isMap(conditions) ? conditions.get('fields') : conditions.fields;

type Item = Readonly<{
  [key: string]: unknown;
}>;

export type TestConditionArgsT = {
  condition:
    | Map<string, any>
    | {
        [key: string]: any;
      };
  graphQLSlugs: boolean;
  item: Item;
  contextItem: Item | null;
  timezone: string;
};

export const testCondition = ({
  item,
  contextItem,
  timezone,
  condition,
  graphQLSlugs,
}: TestConditionArgsT) => {
  const cleanSlug = graphQLSlugs ? toGraphQLSlug : handleId;
  const plainCondition = convertImmutableDataStructure(condition);
  const plainItem = withCleanedSlugs(
    convertImmutableDataStructure(item),
    cleanSlug
  );
  const conditionData = reifyConditions(plainCondition, contextItem, cleanSlug);
  const conditionFields = normalizeConditionFields(conditionData.fields);

  const itemData = conditionFields.reduce<Record<string, any>>((acc, field) => {
    const {fieldPath, type} = field;
    const itemFieldValue = getItemFieldValue(plainItem, fieldPath);

    if (itemFieldValue == null) {
      return acc;
    }

    acc[fieldPath] = castFieldValue(itemFieldValue, type, timezone);

    return acc;
  }, {});

  return dynamoTest(itemData, conditionData, timezone);
};

const fieldConditionsUpdater =
  (
    contextItem: null | Item,
    cleanSlug: ((originalSlug: string) => string) | ((slug: string) => string)
  ) =>
  (fields: any): ConditionsWithTypeT | Record<any, any> => {
    const plainFields = convertImmutableDataStructure(fields);

    // Handles the new data shape of `data.dyn.query.fields`
    if (Array.isArray(fields)) {
      return plainFields.map(reifyQueryField(contextItem, cleanSlug));
    }

    return Object.entries(plainFields).reduce<Record<string, any>>(
      (acc, plainField) => {
        const [path, item] = reifyCondition(contextItem, cleanSlug)(plainField);
        // @ts-expect-error - TS2538 - Type 'Record<string, any>' cannot be used as an index type.
        acc[path] = item;
        return acc;
      },
      {}
    );
  };

const withCleanedSlugs = (
  obj: any,
  cleanSlug: ((originalSlug: string) => string) | ((slug: string) => string)
) => {
  return Object.keys(obj).reduce<Record<string, any>>(
    (objWithCleanSlugs, slug) => {
      objWithCleanSlugs[cleanSlug(slug)] = obj[slug];
      return objWithCleanSlugs;
    },
    {}
  );
};

const reifyConditions = (
  conditions: any,
  contextItem: null | Item,
  cleanSlug: ((originalSlug: string) => string) | ((slug: string) => string)
): {
  fields: Record<any, any> | ConditionsWithTypeT | undefined;
} => ({
  ...conditions,
  fields: fieldConditionsUpdater(
    contextItem,
    cleanSlug
  )(getFieldsFromConditions(conditions)),
});

const createNewFieldPath = (
  fieldPath: string,
  cleanSlug: ((originalSlug: string) => string) | ((slug: string) => string)
) => {
  const itemRefFieldSlug = getItemRefSlug(fieldPath);
  const valueFieldSlug = getValueFieldSlug(fieldPath);

  return itemRefFieldSlug
    ? createFieldPath(cleanSlug(itemRefFieldSlug), cleanSlug(valueFieldSlug))
    : createFieldPath(cleanSlug(valueFieldSlug));
};

const reifyCondition =
  (
    contextItem: null | Item,
    cleanSlug: ((originalSlug: string) => string) | ((slug: string) => string)
  ) =>
  (fieldEntry: [string, any]) => {
    const [fieldPath, operation] = fieldEntry;
    const newFieldPath = createNewFieldPath(fieldPath, cleanSlug);

    const pageItemDataReducer = replacePageItemData(contextItem, cleanSlug);

    return [
      newFieldPath,
      Object.entries(operation).reduce<Record<string, any>>((acc, entry) => {
        const [key, value] = entry;
        return pageItemDataReducer(acc, value, key);
      }, {}),
    ];
  };

const reifyQueryField =
  (
    contextItem: null | Item,
    cleanSlug: ((originalSlug: string) => string) | ((slug: string) => string)
  ) =>
  (field: any) => {
    const {fieldPath, value} = field;
    const newFieldPath = createNewFieldPath(fieldPath, cleanSlug);

    return {
      ...field,
      fieldPath: newFieldPath,
      value: replaceValueBasedOnPageItemData(contextItem, cleanSlug, value),
    };
  };

const replacePageItemData =
  (
    contextItem: Item | null,
    cleanSlug: ((originalSlug: string) => string) | ((slug: string) => string)
  ) =>
  (
    acc: {
      [key: string]: unknown;
    },
    value: unknown,
    key: string
  ) => {
    acc[key] = replaceValueBasedOnPageItemData(contextItem, cleanSlug, value);
    return acc;
  };

const replaceValueBasedOnPageItemData = (
  contextItem: null | Item,
  cleanSlug: ((originalSlug: string) => string) | ((slug: string) => string),
  value: unknown
) => {
  const plainPageItem = convertImmutableDataStructure(contextItem);
  const pageItemId = plainPageItem ? getId(plainPageItem) : null;

  if (typeof value === 'string') {
    if (value === 'DYN_CONTEXT') {
      if (pageItemId) {
        return pageItemId;
      }
    }

    if (/^DYN_CONTEXT/.test(value)) {
      const dynContextFieldSlug = value.replace(/^DYN_CONTEXT\./, '');
      const dynContextFieldValue =
        plainPageItem && plainPageItem[cleanSlug(dynContextFieldSlug)];
      const conditionValue = Array.isArray(dynContextFieldValue)
        ? dynContextFieldValue.map(dynContextFieldValueId)
        : dynContextFieldValueId(dynContextFieldValue);

      if (plainPageItem) {
        return conditionValue || NON_EXISTING_ITEM_ID;
      }
    }
  }

  return value;
};

const dynContextFieldValueId = (dynContextFieldValue: any) => {
  return isObj(dynContextFieldValue)
    ? getId(dynContextFieldValue)
    : dynContextFieldValue;
};

const castFieldValue = (
  fieldValue: unknown,
  fieldType: string,
  timezone: string
): unknown => {
  switch (fieldType) {
    case 'Date': {
      // GraphQL api returns date-times as ISO strings and simple dates as YYYY-MM-DD format
      // We know that fieldValue is a string if the itemType is date
      const dateString = fieldValue as string;

      const dateStringWithoutTime = isDateStringWithoutTime(dateString);
      return dateStringWithoutTime
        ? moment.tz(fieldValue, timezone).toDate()
        : moment.utc(dateString).toDate();
    }
    case 'Option':
    case 'ItemRef': {
      return isObj(fieldValue) ? getId(fieldValue) : fieldValue;
    }
    case 'ItemRefSet': {
      return Array.isArray(fieldValue) && fieldValue.length
        ? Object.values(fieldValue as any).map((ref) => {
            if (typeof ref === 'string') {
              return {_id: ref};
            }

            // @ts-expect-error Unknown type
            const {id: _, ...restOfRef} = ref;

            return {
              ...restOfRef,
              _id: getId(ref),
            };
          })
        : null;
    }
    default: {
      return fieldValue;
    }
  }
};
