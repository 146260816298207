// Please keep this file small: it gets imported by published site code.

export const CLASS_NAME_DYNAMIC_WRAPPER = 'w-dyn-list';
export const CLASS_NAME_DYNAMIC_LIST = 'w-dyn-items';
export const CLASS_NAME_DYNAMIC_LIST_REPEATER_REF = 'w-dyn-items-repeater-ref';
export const CLASS_NAME_DYNAMIC_LIST_ITEM = 'w-dyn-item';
export const CLASS_NAME_DYNAMIC_LIST_REPEATER_ITEM = 'w-dyn-repeater-item';
export const CLASS_NAME_DYNAMIC_LIST_ROW = 'w-row';
export const CLASS_NAME_DYNAMIC_LIST_COLUMN = 'w-col';

export const getColumnNumberClassName = (cols: string) => `w-col-${cols}`;
