/* global window, document, Element, CustomEvent, HTMLElement, HTMLFormElement */

import EventHandlerProxyWithApolloClient from './eventHandlerProxyWithApolloClient';
import {
  safeParseJson,
  findAllElementsByNodeType,
  findClosestElementByNodeType,
  findElementByNodeType,
  customDataFormToArray,
  fetchOrderStatusFlags,
} from './commerceUtils';
import {
  renderCheckoutFormContainers,
  beforeUnloadHandler,
  updateErrorMessage,
  redirectToOrderConfirmation,
  createAttemptSubmitOrderRequest,
  getOrderDataFromGraphQLResponse,
  createOrderShippingMethodMutation,
  createCustomDataMutation,
  showErrorMessageForError as showCheckoutErrorMessageForError,
} from './checkoutUtils';
import {
  isCartOpen,
  showErrorMessageForError as showCartErrorMessageForError,
} from './cartUtils';
import debug from './debug';
import {
  syncPayPalOrderInfo,
  requestPayPalOrderMutation,
} from './checkoutMutations';
import {
  NODE_TYPE_COMMERCE_PAYPAL_CHECKOUT_FORM_CONTAINER,
  NODE_TYPE_COMMERCE_CHECKOUT_PLACE_ORDER_BUTTON,
  NODE_TYPE_COMMERCE_PAYPAL_CHECKOUT_ERROR_STATE,
  NODE_TYPE_COMMERCE_CHECKOUT_SHIPPING_METHODS_WRAPPER,
  DATA_ATTR_LOADING_TEXT,
  CHECKOUT_PLACE_ORDER_LOADING_TEXT_DEFAULT,
  CHECKOUT_PLACE_ORDER_BUTTON_TEXT_DEFAULT,
  NODE_TYPE_COMMERCE_CHECKOUT_ADDITIONAL_INFO,
  CART_CHECKOUT_ERROR_MESSAGE_SELECTOR,
  RENDER_TREE_EVENT,
  PAYPAL_ELEMENT_INSTANCE,
  PAYPAL_BUTTON_ELEMENT_INSTANCE,
  NEEDS_REFRESH,
} from '@packages/systems/commerce/constants';
import {ApolloClient, NormalizedCacheObject} from '@apollo/client';

// @ts-expect-error - TS7031 - Binding element 'target' implicitly has an 'any' type.
const isPlaceOrderButtonEvent = ({target}) => {
  const placeOrderButton = findClosestElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_PLACE_ORDER_BUTTON,
    target
  );
  if (placeOrderButton && target instanceof Element) {
    return target;
  } else {
    return false;
  }
};

let hasSyncedWithPaypal = false;
const handleRenderPayPalCheckout = (
  event: Event | CustomEvent,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  if (window.Webflow.env('design') || window.Webflow.env('preview')) {
    return;
  }
  if (!(event instanceof CustomEvent && event.type === RENDER_TREE_EVENT)) {
    return;
  }
  const checkoutFormContainers = findAllElementsByNodeType(
    NODE_TYPE_COMMERCE_PAYPAL_CHECKOUT_FORM_CONTAINER
  );
  if (!checkoutFormContainers || checkoutFormContainers.length === 0) {
    return;
  }
  const errors: Array<any> = [];
  const {detail} = event;
  if (detail != null && detail.error) {
    errors.push(detail.error);
  }

  const focusedEle = window.document.activeElement;
  const checkoutForm = findClosestElementByNodeType(
    NODE_TYPE_COMMERCE_PAYPAL_CHECKOUT_FORM_CONTAINER,
    // @ts-expect-error - TS2345 - Argument of type 'Element | null' is not assignable to parameter of type 'EventTarget'.
    focusedEle
  );

  let prevFocusedInput = null;
  // Only trigger for focused elements in a checkout form
  if (focusedEle instanceof HTMLInputElement && checkoutForm) {
    prevFocusedInput = focusedEle.id;
    if (!prevFocusedInput) {
      prevFocusedInput = focusedEle.getAttribute('data-wf-bindings');
    }

    // Move from empty string to null
    prevFocusedInput = prevFocusedInput ? null : prevFocusedInput;
  }

  const syncWithPayPalIfNeeded = !hasSyncedWithPaypal
    ? apolloClient.mutate({
        mutation: syncPayPalOrderInfo,
      })
    : Promise.resolve();

  syncWithPayPalIfNeeded.then(() => {
    hasSyncedWithPaypal = true;

    renderCheckoutFormContainers(
      checkoutFormContainers,
      errors,
      apolloClient,
      undefined,
      prevFocusedInput
    );
  });
};

let placingOrder = false;
const startOrderFlow = (placeOrderButton: HTMLElement) => {
  placingOrder = true;
  window.addEventListener('beforeunload', beforeUnloadHandler);

  const buttonText = placeOrderButton.innerHTML;
  const loadingText = placeOrderButton.getAttribute(DATA_ATTR_LOADING_TEXT);
  placeOrderButton.innerHTML = loadingText
    ? loadingText
    : CHECKOUT_PLACE_ORDER_LOADING_TEXT_DEFAULT;

  const finishOrderFlow = (isRedirecting: boolean = false) => {
    // we only set `placingOrder` to false if we're not redirecting to the
    // confirmation page. this is so that while waiting for the confirmation
    // page to load, the user can't attempt to submit the order again
    if (!isRedirecting) {
      placingOrder = false;
    }
    window.removeEventListener('beforeunload', beforeUnloadHandler);
    placeOrderButton.innerHTML = buttonText
      ? buttonText
      : CHECKOUT_PLACE_ORDER_BUTTON_TEXT_DEFAULT;
  };

  return finishOrderFlow;
};

const checkFormValidity = ({
  shippingInfo,
  additionalInfo,
  requiresShipping,
}: {
  shippingInfo: HTMLFormElement;
  additionalInfo: HTMLElement | null | undefined;
  requiresShipping: boolean;
}) => {
  if (!HTMLFormElement.prototype.reportValidity) {
    return true;
  }
  if (
    (requiresShipping && !shippingInfo.reportValidity()) ||
    (additionalInfo &&
      additionalInfo instanceof HTMLFormElement &&
      !additionalInfo.reportValidity())
  ) {
    return false;
  }
  return true;
};

// @ts-expect-error - TS2314 - Generic type 'ApolloClient<TCacheShape>' requires 1 type argument(s).
const handlePlaceOrder = (event: Event, apolloClient: ApolloClient) => {
  // Want to skip placing order if in design/preview mode, or an order place is in progress
  if (
    window.Webflow.env('design') ||
    window.Webflow.env('preview') ||
    placingOrder
  ) {
    return;
  }

  const {currentTarget} = event;

  if (!(currentTarget instanceof Element)) {
    return;
  }

  const checkoutFormContainer = findClosestElementByNodeType(
    NODE_TYPE_COMMERCE_PAYPAL_CHECKOUT_FORM_CONTAINER,
    currentTarget
  );
  if (!(checkoutFormContainer instanceof Element)) {
    return;
  }

  const errorState = findElementByNodeType(
    NODE_TYPE_COMMERCE_PAYPAL_CHECKOUT_ERROR_STATE,
    checkoutFormContainer
  );
  const shippingInfo = findElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_SHIPPING_METHODS_WRAPPER,
    checkoutFormContainer
  );
  const placeOrderButton = findElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_PLACE_ORDER_BUTTON,
    checkoutFormContainer
  );
  const additionalInfo = findElementByNodeType(
    NODE_TYPE_COMMERCE_CHECKOUT_ADDITIONAL_INFO,
    checkoutFormContainer
  );
  if (
    !(errorState instanceof HTMLElement) ||
    !(shippingInfo instanceof HTMLFormElement) ||
    !(placeOrderButton instanceof Element)
  ) {
    return;
  }

  const errorMessage = errorState.querySelector(
    CART_CHECKOUT_ERROR_MESSAGE_SELECTOR
  );

  // If the error message has this attribute, we want to block the order from
  // being submitted as the user is being forced to refresh the checkout page.
  if (errorMessage && errorMessage.hasAttribute(NEEDS_REFRESH)) {
    return;
  }

  const hasAdditionalInfo =
    additionalInfo && additionalInfo instanceof HTMLElement;

  const finishOrderFlow = startOrderFlow(placeOrderButton);

  errorState.style.setProperty('display', 'none');

  fetchOrderStatusFlags(apolloClient).then(({requiresShipping}) => {
    const isFormValid = checkFormValidity({
      shippingInfo,
      additionalInfo,
      requiresShipping,
    });

    if (!isFormValid) {
      finishOrderFlow();
      return;
    }

    // final sync with server, to ensure validity
    let shippingMethodId = '';
    // @ts-expect-error - TS7015 - Element implicitly has an 'any' type because index expression is not of type 'number'.
    if (requiresShipping && shippingInfo.elements['shipping-method-choice']) {
      // this is an IE11-safe way of just doing shippingInfo.elements['shipping-method-choice'].value
      const shippingMethodChoice = shippingInfo.querySelector(
        'input[name="shipping-method-choice"]:checked'
      ) as HTMLInputElement | null | undefined;

      // this should never be falsey, but Flow
      if (shippingMethodChoice) {
        shippingMethodId = shippingMethodChoice.value;
      }
    }

    const customData = hasAdditionalInfo
      ? customDataFormToArray(additionalInfo)
      : [];

    const syncPayPalCheckoutForm = Promise.all([
      requiresShipping
        ? createOrderShippingMethodMutation(apolloClient, shippingMethodId)
        : Promise.resolve(),
      hasAdditionalInfo
        ? // @ts-expect-error - TS2345 - Argument of type 'any[]' is not assignable to parameter of type '[]'.
          createCustomDataMutation(apolloClient, customData)
        : Promise.resolve(),
    ]);

    syncPayPalCheckoutForm
      .then(() => {
        return createAttemptSubmitOrderRequest(apolloClient, {
          checkoutType: 'paypal',
        });
      })
      .then((data) => {
        debug.log(data);
        const order = getOrderDataFromGraphQLResponse(data);
        if (order.ok) {
          finishOrderFlow(true);
          redirectToOrderConfirmation(order, true);
        }
      })
      .catch((err) => {
        finishOrderFlow();
        debug.error(err);
        errorState.style.removeProperty('display');
        updateErrorMessage(errorState, err);
        if (
          err.graphQLErrors &&
          err.graphQLErrors[0] &&
          err.graphQLErrors[0].message
        ) {
          const parsedError = safeParseJson(err.graphQLErrors[0].message);
          if (!parsedError) {
            return;
          }

          if (
            parsedError.details &&
            parsedError.details[0] &&
            parsedError.details[0].issue === 'INSTRUMENT_DECLINED'
          ) {
            const message = {
              isWebflow: true,
              type: 'error',
              detail: parsedError,
            } as const;
            window.parent.postMessage(
              JSON.stringify(message),
              window.location.origin
            );
          }
        }
      });
  });
};

// width and height is defined twice, so that on older browsers that
// don't support vw/vh, we get the 100% instead
const iframeStyle = `
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  width: 100vw;
  height: 100vh;
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  z-index: 2147483647;
  border: 0;
  background-color: #ffffff;
`;

const createConfirmationIframe = (actions: any) => {
  const documentRoot = document.documentElement;
  // we use this instead of document.body to appease the flow gods
  const documentBody = document.querySelector('body');
  if (!documentRoot || !documentBody) {
    return;
  }

  const iframe = document.createElement('iframe');
  iframe.setAttribute('style', iframeStyle);
  iframe.setAttribute('src', '/paypal-checkout');

  if (!documentBody.parentNode) {
    return;
  }

  documentBody.parentNode.appendChild(iframe);
  const previousRootOverflow = documentRoot.style.overflow;
  documentRoot.style.overflow = 'hidden';
  const previousBodyDisplay = documentBody.style.display;
  documentBody.style.display = 'none';

  const paypalMessageHandler = (event: MessageEvent) => {
    if (event.origin !== window.location.origin) {
      return;
    }

    const data = safeParseJson(String(event.data));
    // we include an `isWebflow` since paypal sends some messages on the page
    // and we want to make sure that we don't accidentally intercept one of their messages
    if (!data || data.isWebflow !== true || !data.type || !data.detail) {
      return;
    }

    if (data.type === 'success') {
      window.removeEventListener('message', paypalMessageHandler);
      window.location.href = data.detail;
    }

    if (data.type === 'error') {
      window.removeEventListener('message', paypalMessageHandler);
      if (previousRootOverflow) {
        documentRoot.style.overflow = previousRootOverflow;
      } else {
        documentRoot.style.overflow = '';
      }
      if (previousBodyDisplay) {
        documentBody.style.display = previousBodyDisplay;
      } else {
        documentBody.style.display = '';
      }
      if (documentBody.parentNode) {
        documentBody.parentNode.removeChild(iframe);
      }
      actions.restart();
    }
  };

  window.addEventListener('message', paypalMessageHandler);
};

export const renderPaypalButtons =
  (apolloClient: ApolloClient<NormalizedCacheObject>) => () => {
    const paypalElement = document.querySelector(
      `[${PAYPAL_ELEMENT_INSTANCE}]`
    );
    const buttons = Array.from(
      document.querySelectorAll(`[${PAYPAL_BUTTON_ELEMENT_INSTANCE}]`)
    );

    if (paypalElement && buttons && buttons.length > 0) {
      buttons.forEach((button) => {
        const style = safeParseJson(
          button.getAttribute(PAYPAL_BUTTON_ELEMENT_INSTANCE)
        );

        // @ts-expect-error - TS2551 - Property 'paypal' does not exist on type 'Window & typeof globalThis'. Did you mean 'Papa'?
        window.paypal
          .Buttons({
            style,
            createOrder() {
              return apolloClient
                .mutate({
                  mutation: requestPayPalOrderMutation,
                })
                .then((data) => {
                  const {
                    data: {
                      ecommercePaypalOrderRequest: {orderId},
                    },
                  } = data;
                  return orderId;
                })
                .catch((err) => {
                  showCheckoutErrorMessageForError(err);

                  if (isCartOpen()) {
                    showCartErrorMessageForError(err);
                  }

                  throw err;
                });
            },
            onApprove(data: any, actions: any) {
              createConfirmationIframe(actions);
            },
          })
          .render(button);
      });
    }
  };

const register = (handlerProxy: EventHandlerProxyWithApolloClient) => {
  handlerProxy.on(RENDER_TREE_EVENT, Boolean, handleRenderPayPalCheckout);
  handlerProxy.on('click', isPlaceOrderButtonEvent, handlePlaceOrder);
  handlerProxy.on('keydown', isPlaceOrderButtonEvent, (event, apolloClient) => {
    // @ts-expect-error - TS2339 - Property 'which' does not exist on type 'Event'.
    if (event.which === 32) {
      // prevent scrolling on spacebar key press
      event.preventDefault();
    }
    // @ts-expect-error - TS2339 - Property 'which' does not exist on type 'Event'.
    if (event.which === 13) {
      // enter key press
      return handlePlaceOrder(event, apolloClient);
    }
  });
  handlerProxy.on('keyup', isPlaceOrderButtonEvent, (event, apolloClient) => {
    // @ts-expect-error - TS2339 - Property 'which' does not exist on type 'Event'.
    if (event.which === 32) {
      // spacebar key press
      return handlePlaceOrder(event, apolloClient);
    }
  });
};

export default {register};
