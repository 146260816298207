import {RetryLink} from '@apollo/client/link/retry';
import {ApolloMetricsLogger} from './apolloMetricsLogger';
import {ApolloClientStateType} from '@packages/systems/designer/apollo-client';

const ERROR_METRIC_NAME = 'webflow.renderer.apollo.request.error';

export const waitForInFlightQueries = (
  apolloClient: ApolloClientStateType
): Promise<null> => {
  // @ts-expect-error - Property 'queryManager' is private and only accessible within class 'ApolloClient<TCacheShape>'
  if (!apolloClient || !apolloClient.queryManager) {
    return Promise.resolve(null);
  }

  const {
    // @ts-expect-error - Property 'queryManager' is private and only accessible within class 'ApolloClient<TCacheShape>'
    queryManager: {queries},
  } = apolloClient;

  const promises = Array.from(queries.values()).reduce<Array<any>>(
    // @ts-expect-error - TS2345 - Argument of type '(memo: any[], { observableQuery }: { observableQuery: any; }) => any[]' is not assignable to parameter of type '(previousValue: any[], currentValue: unknown, currentIndex: number, array: unknown[]) => any[]'.
    (memo, {observableQuery}) => {
      const loading =
        (observableQuery && observableQuery.getCurrentResult().loading) ||
        false;
      return loading ? memo.concat(observableQuery.result()) : memo;
    },
    []
  );

  // @ts-expect-error - TS2769 - No overload matches this call.
  return Promise.all(promises).then(() => null);
};

export type RetryLinkConfig = {
  maxAttempts?: number;
  retryOnCorsErrors?: boolean;
  retriedServerErrors?: 'bad-gateway' | 'all';
  metricsLogger?: ApolloMetricsLogger;
};

export const createRetryLink = ({
  maxAttempts = 1,
  retryOnCorsErrors = true,
  retriedServerErrors = 'all',
  metricsLogger,
}: RetryLinkConfig): RetryLink => {
  return new RetryLink({
    attempts: (count, operation, error) => {
      const tags = [];
      let ret = false;
      if (count >= maxAttempts) {
        tags.push('max_attempts:true');
      } else {
        tags.push('max_attempts:false');
        if (
          error &&
          ((retriedServerErrors === 'all' && error.statusCode >= 500) ||
            (retriedServerErrors === 'bad-gateway' && error.statusCode === 502))
        ) {
          // if the request fails, let's try it again because it was probably
          // a temporary issue.
          tags.push('reason:server_error');
          tags.push(`status_code:${error.statusCode}`);
          ret = true;
        } else if (
          retryOnCorsErrors &&
          error &&
          error.result &&
          error.result.code === 'BadCrossOriginRequest'
        ) {
          // if CORS fails, it means we used a stale token so we can try it again
          // with the correct one
          tags.push('reason:cors_error');
          ret = true;
        }
      }

      tags.push(`attempt:${count}`, `retry:${ret}`);
      metricsLogger?.logDistributionMetric(ERROR_METRIC_NAME, 1, ...tags);
      return ret;
    },
    delay: (count) => {
      // delay in ms
      return count * 500 + Math.random() * 500;
    },
  });
};
