import {
  collSlug,
  fieldSlug as _fieldSlug,
} from '@packages/systems/dynamo/utils/SchemaEncoder';

export {restoreSlug} from '@packages/systems/dynamo/utils/SchemaEncoder';

export const fieldSlug = (slug: string): string => _fieldSlug({slug});

const DYNAMO_GQL_FIELD_SLUG = 'f_';
export const isDynamoGraphQLFieldSlug: (arg1: string) => boolean = (str) =>
  str.startsWith(DYNAMO_GQL_FIELD_SLUG);

export const collectionSlug = (slug: string): string => collSlug({slug});
