/* eslint-disable no-var */
/**
 * Webflow: Forms (Hosted)
 */

export default function hostedSubmitWebflow(
  reset,
  loc,
  Webflow,
  collectEnterpriseTrackingCookies,
  preventDefault,
  findFields,
  alert,
  findFileUploads,
  disableBtn,
  siteId,
  afterSubmit,
  $,
  formUrl
) {
  return function (data) {
    reset(data);

    var form = data.form;
    var payload = {
      name: form.attr('data-name') || form.attr('name') || 'Untitled Form',
      pageId: form.attr('data-wf-page-id') || '',
      elementId: form.attr('data-wf-element-id') || '',

      domain: $('html').attr('data-wf-domain') || null,
      source: loc.href,
      test: Webflow.env(),
      fields: {},
      fileUploads: {},
      dolphin: /pass[\s-_]?(word|code)|secret|login|credentials/i.test(
        form.html()
      ),
      trackingCookies: collectEnterpriseTrackingCookies(),
    };

    const wfFlow = form.attr('data-wf-flow');

    if (wfFlow) {
      payload.wfFlow = wfFlow;
    }

    preventDefault(data);

    // Find & populate all fields
    var status = findFields(form, payload.fields);
    if (status) {
      return alert(status);
    }

    payload.fileUploads = findFileUploads(form);

    // Disable submit button
    disableBtn(data);

    // Read site ID
    // NOTE: If this site is exported, the HTML tag must retain the data-wf-site attribute for forms to work
    if (!siteId) {
      afterSubmit(data);
      return;
    }

    $.ajax({
      url: formUrl,
      type: 'POST',
      data: payload,
      dataType: 'json',
      crossDomain: true,
    })
      .done(function (response) {
        if (response && response.code === 200) {
          data.success = true;
        }

        afterSubmit(data);
      })
      .fail(function () {
        afterSubmit(data);
      });
  };
}
