import type {AbstractNodeType} from '@packages/systems/core/AbstractNode';
import type {ClassesList} from '@packages/systems/wfdl';

import {
  type UserAccessMeta,
  type UserAcessMetaOption,
  USER_ACCESS_META_OPTIONS,
  USYS_DATA_ATTRS,
} from '@packages/systems/users/constants';

export const appendUserInputClasses = (
  node: AbstractNodeType,
  classes: ClassesList
) => {
  const updatedClasses = classes.push('w-input');
  if (node.getIn(['data', 'attr', 'disabled'])) {
    return updatedClasses.push('w-input-disabled');
  }
  return updatedClasses;
};

export const parseWfUsysVariant = (wfUsysVariant?: string): UserAccessMeta => {
  if (!wfUsysVariant) return [];

  let results: Array<UserAcessMetaOption> = [];

  for (const wfUsysVariantKey of wfUsysVariant.split(',')) {
    if (
      // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type '"LOGGED_IN" | "ADMIN_ALWAYS_VISIBLE"'.
      USER_ACCESS_META_OPTIONS.includes(wfUsysVariantKey) &&
      // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type '"LOGGED_IN" | "ADMIN_ALWAYS_VISIBLE"'.
      !results.includes(wfUsysVariantKey)
    ) {
      results.push(wfUsysVariantKey as UserAcessMetaOption);
    } else {
      // This is the error case
      console.error(
        `UnexpectedWfUsysVariant: Renderer received unexpected wf-usys-variant`
      );
      results = [];
      break;
    }
  }

  return results;
};

export function uploadFileToS3(
  url: string,
  fields: any,
  file: File
): Promise<string | null | undefined> {
  return new Promise(
    (
      resolve: (result: Promise<undefined> | undefined) => void,
      reject: (error?: any) => void
    ) => {
      const formData = new FormData();
      Object.entries(fields).forEach(([key, value]: [any, any]) => {
        formData.append(key, value);
      });

      formData.append('file', file);
      fetch(url, {
        method: 'POST',
        body: formData,
      })
        .then((response) => {
          if (response.ok) {
            // @ts-expect-error - TS2794 - Expected 1 arguments, but got 0. Did you forget to include 'void' in your type argument to 'Promise'?
            resolve();
          } else {
            return response.text();
          }
        })
        .then((text) => {
          reject(text);
        });
    }
  );
}

export const getUserFileKey = (element: HTMLElement) => {
  return element.getAttribute(USYS_DATA_ATTRS.fileUploadKey);
};

export const setUserFileKey = (element: Element, value: string) => {
  element.setAttribute(USYS_DATA_ATTRS.fileUploadKey, value);
};

export const getTempUserFileKey = (element: HTMLElement) => {
  return element.getAttribute(USYS_DATA_ATTRS.unsavedFileUploadKey);
};

export const setTempUserFileKey = (element: HTMLElement, value: string) => {
  element.setAttribute(USYS_DATA_ATTRS.unsavedFileUploadKey, value);
};

export const removeTempUserFileKey = (element: HTMLElement) => {
  element.removeAttribute(USYS_DATA_ATTRS.unsavedFileUploadKey);
};
