import {type BetaFeatureFlag} from '@packages/systems/core-constants';

export const DATA_ATTR_COMMERCE_SKU_ID = 'data-commerce-sku-id';
export const DATA_ATTR_COMMERCE_PRODUCT_CURRENT_SKU_VALUES =
  'data-commerce-product-sku-values';
export const DATA_ATTR_COMMERCE_OPTION_SET_ID = 'data-commerce-option-set-id';
export const DATA_ATTR_COMMERCE_PRODUCT_ID = 'data-commerce-product-id';
// @TODO - update the usage of these constants in PreviewUtils
// our ideal solution would be doing this on a component level
// RE: https://github.com/webflow/webflow/pull/15806#discussion_r169906866
export const DATA_ATTR_NODE_TYPE = 'data-node-type';
export const DATA_ATTR_LOADING_TEXT = 'data-loading-text';
export const DATA_ATTR_OPEN_PRODUCT = 'data-open-product';
export const DATA_ATTR_OPEN_ON_HOVER = 'data-open-on-hover';
export const DATA_ATTR_COUNT_HIDE_RULE = 'data-count-hide-rule';
export const DATA_ATTR_PRESELECT_DEFAULT_VARIANT =
  'data-preselect-default-variant';
export const DATA_ATTR_DEFAULT_TEXT = 'data-default-text';
export const DATA_ATTR_SUBSCRIPTION_TEXT = 'data-subscription-text';

export const NODE_TYPE_COMMERCE_ADD_TO_CART_FORM = 'commerce-add-to-cart-form';
export const NODE_TYPE_COMMERCE_ADD_TO_CART_ERROR =
  'commerce-add-to-cart-error';
export const NODE_TYPE_COMMERCE_CART_ERROR = 'commerce-cart-error';
export const NODE_TYPE_COMMERCE_ADD_TO_CART_OPTION_SELECT =
  'commerce-add-to-cart-option-select';
export const NODE_TYPE_COMMERCE_ADD_TO_CART_OPTION_LIST =
  'commerce-add-to-cart-option-list';
export const NODE_TYPE_COMMERCE_ADD_TO_CART_PILL_GROUP =
  'commerce-add-to-cart-pill-group';
export const NODE_TYPE_COMMERCE_ADD_TO_CART_PILL = 'commerce-add-to-cart-pill';
export const NODE_TYPE_COMMERCE_CART_WRAPPER = 'commerce-cart-wrapper';
export const NODE_TYPE_COMMERCE_CART_OPEN_LINK = 'commerce-cart-open-link';
export const NODE_TYPE_COMMERCE_CART_CLOSE_LINK = 'commerce-cart-close-link';
export const NODE_TYPE_COMMERCE_CART_CONTAINER_WRAPPER =
  'commerce-cart-container-wrapper';
export const NODE_TYPE_COMMERCE_CART_CONTAINER = 'commerce-cart-container';
export const NODE_TYPE_COMMERCE_CART_CHECKOUT_BUTTON = 'cart-checkout-button';
export const NODE_TYPE_COMMERCE_CART_FORM = 'commerce-cart-form';
export const NODE_NAME_COMMERCE_ADD_TO_CART_QUANTITY_INPUT =
  'commerce-add-to-cart-quantity-input';
export const NODE_TYPE_COMMERCE_CHECKOUT_FORM_CONTAINER =
  'commerce-checkout-form-container';
export const NODE_TYPE_COMMERCE_CHECKOUT_CUSTOMER_INFO_WRAPPER =
  'commerce-checkout-customer-info-wrapper';
export const NODE_TYPE_COMMERCE_CHECKOUT_SHIPPING_ADDRESS_WRAPPER =
  'commerce-checkout-shipping-address-wrapper';
export const NODE_TYPE_COMMERCE_CHECKOUT_SHIPPING_ADDRESS_ZIP_FIELD =
  'commerce-checkout-shipping-zip-field';
export const NODE_TYPE_COMMERCE_CHECKOUT_BILLING_ADDRESS_ZIP_FIELD =
  'commerce-checkout-billing-zip-field';
export const NODE_TYPE_COMMERCE_CHECKOUT_BILLING_ADDRESS_WRAPPER =
  'commerce-checkout-billing-address-wrapper';
export const NODE_TYPE_COMMERCE_CHECKOUT_SHIPPING_METHODS_WRAPPER =
  'commerce-checkout-shipping-methods-wrapper';
export const NODE_TYPE_COMMERCE_CHECKOUT_SHIPPING_METHODS_LIST =
  'commerce-checkout-shipping-methods-list';
export const NODE_TYPE_COMMERCE_CHECKOUT_SHIPPING_METHODS_EMPTY_STATE =
  'commerce-checkout-shipping-methods-empty-state';
export const NODE_TYPE_COMMERCE_CHECKOUT_BILLING_ADDRESS_TOGGLE_CHECKBOX =
  'commerce-checkout-billing-address-toggle-checkbox';
export const NODE_TYPE_COMMERCE_CHECKOUT_PLACE_ORDER_BUTTON =
  'commerce-checkout-place-order-button';
export const NODE_TYPE_COMMERCE_CHECKOUT_ERROR_STATE =
  'commerce-checkout-error-state';
export const NODE_TYPE_COMMERCE_ORDER_CONFIRMATION_WRAPPER =
  'commerce-order-confirmation-wrapper';
export const NODE_TYPE_COMMERCE_CART_QUICK_CHECKOUT_ACTIONS =
  'commerce-cart-quick-checkout-actions';
export const NODE_TYPE_COMMERCE_CART_QUICK_CHECKOUT_BUTTON =
  'commerce-cart-quick-checkout-button';
export const NODE_TYPE_COMMERCE_CART_APPLE_PAY_BUTTON =
  'commerce-cart-apple-pay-button';
export const NODE_TYPE_COMMERCE_CHECKOUT_ADDITIONAL_INFO =
  'commerce-checkout-additional-info';
export const NODE_TYPE_COMMERCE_PAYPAL_CHECKOUT_FORM_CONTAINER =
  'commerce-paypal-checkout-form-container';
export const NODE_TYPE_COMMERCE_PAYPAL_CHECKOUT_ERROR_STATE =
  'commerce-checkout-error-state';
export const NODE_TYPE_COMMERCE_CHECKOUT_DISCOUNT_FORM =
  'commerce-checkout-discount-form';
export const NODE_TYPE_COMMERCE_CHECKOUT_DISCOUNT_INPUT =
  'commerce-checkout-discount-input';
export const NODE_TYPE_COMMERCE_BUY_NOW_BUTTON = 'commerce-buy-now-button';
export const NODE_TYPE_COMMERCE_DOWNLOADS_BUTTON = 'commerce-downloads-button';
export const NODE_TYPE_COMMERCE_ADD_TO_CART_BUTTON =
  'commerce-add-to-cart-button';
export const NODE_TYPE_ADD_TO_CART_ERROR = 'commerce-add-to-cart-error';

export const ADD_TO_CART_STATES = {
  DEFAULT: 'DEFAULT',
  OUT_OF_STOCK: 'OUT_OF_STOCK',
  ERROR: 'ERROR',
} as const;

export const CART_STATES = {
  DEFAULT: 'DEFAULT',
  EMPTY: 'EMPTY',
  ERROR: 'ERROR',
} as const;

export const CART_STATES_AUTOMATION = {
  DEFAULT: 'cart-default-button',
  EMPTY: 'cart-empty-button',
  ERROR: 'cart-error-button',
} as const;

export const CHECKOUT_STATES = {
  DEFAULT: 'DEFAULT',
  ERROR: 'ERROR',
} as const;

export const SHIPPING_METHODS_STATES = {
  DEFAULT: 'DEFAULT',
  EMPTY: 'EMPTY',
} as const;

export const CART_TYPE_DROPDOWN_ON_OPEN = {
  CLICK: 'CLICK',
  HOVER: 'HOVER',
} as const;

// Need to be in lower case to coincide with the keys in config.style.conditional
// LEFT_DROPDOWN and RIGHT_DROPDOWN are no longer used in the Cart Types Enum
// but are needed to keep the old logic in site modules file
export const CART_TYPES = {
  MODAL: 'modal',
  LEFT_SIDEBAR: 'leftSidebar',
  RIGHT_SIDEBAR: 'rightSidebar',
  LEFT_DROPDOWN: 'leftDropdown',
  RIGHT_DROPDOWN: 'rightDropdown',
  DROPDOWN: 'dropdown',
} as const;

export const CART_COUNT_HIDE_RULES = {
  ALWAYS: 'always',
  EMPTY: 'empty',
} as const;

export const QUICK_CHECKOUT_STATES = {
  NONE: 'NONE',
  PAY_NOW: 'PAY_NOW',
  APPLE_PAY: 'APPLE_PAY',
} as const;

export const QUICK_CHECKOUT_AUTOMATION = {
  PAY_NOW: 'quick-checkout-default-button',
  APPLE_PAY: 'quick-checkout-apple-pay-button',
} as const;

export const PREVIEW_ITEMS_DEFAULT = 3;
export const PREVIEW_ITEMS_KEY = 'previewItems';
export const PREVIEW_ITEMS_KEYPATH = [
  'data',
  'temp',
  PREVIEW_ITEMS_KEY,
] as const;

export const QUANTITY_ENABLED = 'quantityEnabled';

export const STATE = 'state';

export const CART_STATE = 'state';

export const QUICK_CHECKOUT_STATE = 'state';
export const QUICK_CHECKOUT_STATE_KEYPATH = [
  'data',
  'temp',
  QUICK_CHECKOUT_STATE,
] as const;

export const CHECKOUT_STATE = 'state';

export const SHIPPING_METHODS_STATE = 'shippingMethodsState';

export const OPEN_STATE_KEY = 'isOpen';
export const OPEN_STATE_KEYPATH = ['data', 'temp', OPEN_STATE_KEY] as const;
export const OPEN_STATE_DEFAULT = false;

export const BILLING_ADDRESS_TOGGLE_KEY = 'isBillingAddressOpen';
export const BILLING_ADDRESS_TOGGLE_KEYPATH = [
  'data',
  'temp',
  BILLING_ADDRESS_TOGGLE_KEY,
] as const;
export const BILLING_ADDRESS_TOGGLE_DEFAULT = true;

export const HIDE_CART_WHEN_EMPTY_KEY = 'hideCartWhenEmpty';
export const HIDE_CART_WHEN_EMPTY_KEYPATH = [
  'data',
  'commerce',
  HIDE_CART_WHEN_EMPTY_KEY,
] as const;
export const HIDE_CART_WHEN_EMPTY_DEFAULT = false;

export const HIDE_CART_COUNT_KEY = 'hideCartCount';

export const HIDE_CART_COUNT_DEFAULT = false;

export const LOADING_TEXT = 'loadingText';
export const LOADING_TEXT_DEFAULT = 'Adding to cart...';

export const CART_CHECKOUT_BUTTON_TEXT_DEFAULT = 'Continue to Checkout';
export const CART_CHECKOUT_LOADING_TEXT_DEFAULT = 'Hang Tight...';

export const CHECKOUT_PLACE_ORDER_BUTTON_TEXT_DEFAULT = 'Place Order';
export const CHECKOUT_PLACE_ORDER_LOADING_TEXT_DEFAULT = 'Placing Order...';

export const CART_TYPE_KEY = 'cartType';
export const ALIGN_KEY = 'align';
export const ALIGN_DEFAULT = 'rightDropdown';
export const CART_TYPE_DROPDOWN_ON_OPEN_KEY = 'openOn';

export const CART_PRODUCT_ADDED_KEY = 'openWhenProductAdded';
export const CART_PRODUCT_ADDED_KEYPATH = [
  'data',
  'commerce',
  CART_PRODUCT_ADDED_KEY,
] as const;
export const CART_PRODUCT_ADDED_DEFAULT = true;

export type AddToCartState = keyof typeof ADD_TO_CART_STATES;
export type CartState = keyof typeof CART_STATES;
export type ShippingMethodsState = keyof typeof SHIPPING_METHODS_STATES;
export type CartType = (typeof CART_TYPES)[keyof typeof CART_TYPES];
export type CartTypeDropdownOnOpen =
  (typeof CART_TYPE_DROPDOWN_ON_OPEN)[keyof typeof CART_TYPE_DROPDOWN_ON_OPEN];
export const COMMERCE_CART_PUBLISHED_SITE_ACTION_ATTR = 'data-wf-cart-action';
export const COMMERCE_CART_PUBLISHED_SITE_ACTIONS = {
  UPDATE_ITEM_QUANTITY: 'update-item-quantity',
  REMOVE_ITEM: 'remove-item',
} as const;
export const COMMERCE_CART_ITEM_ID_ATTR = 'data-wf-item-id';

export const COMMERCE_SKU_COLLECTION_SLUG = 'sku';
export const COMMERCE_SKU_FIELD_SLUG = 'sku';
export const COMMERCE_PRODUCT_COLLECTION_SLUG = 'product';
export const COMMERCE_PRODUCT_FIELD_SLUG = 'product';
export const COMMERCE_CATEGORY_COLLECTION_SLUG = 'category';
export const COMMERCE_PLUGIN_KEY = 'ecommerce';
export const DEFAULT_SKU_SLUG = 'default-sku';

export const WF_BINDING_DATA_KEY = 'data-wf-bindings';
export const WF_CONDITION_DATA_KEY = 'data-wf-conditions';
export const WF_COLLECTION_DATA_KEY = 'data-wf-collection';
export const WF_TEMPLATE_ID_DATA_KEY = 'data-wf-template-id';
export const WF_SKU_BINDING_DATA_KEY = 'data-wf-sku-bindings';
export const WF_SKU_CONDITION_DATA_KEY = 'data-wf-sku-conditions';

export const WF_TEMPLATE_TYPE = 'text/x-wf-template';

export type ErrorType = {
  id: string;
  name: string;
  copy: string;
  note?: {
    copy: string;
    cta?: {
      copy: string;
      link: string;
    };
  };
  requiresRefresh?: boolean;
  beta?: BetaFeatureFlag;
  category: {
    id: string;
    label: string;
  };
};

const INFO_ERR = 'INFO_ERROR';
const SHIPPING_ERR = 'SHIPPING_ERROR';
const BILLING_ERR = 'BILLING_ERROR';
const PAYMENT_ERR = 'PAYMENT_ERROR';
const PRICING_ERR = 'PRICING_ERROR';
const ORDER_MIN_ERR = 'ORDER_MINIMUM_ERROR';
const ORDER_EXTRAS_ERR = 'ORDER_EXTRAS_ERROR';
const PRODUCT_ERR = 'PRODUCT_ERROR';
const INVALID_DISCOUNT_ERR = 'INVALID_DISCOUNT_ERROR';
const EXPIRED_DISCOUNT_ERR = 'EXPIRED_DISCOUNT_ERROR';
const USAGE_REACHED_DISCOUNT_ERR = 'USAGE_REACHED_DISCOUNT_ERROR';
const REQUIREMENTS_NOT_MET_DISCOUNT_ERR = 'REQUIREMENTS_NOT_MET_DISCOUNT_ERROR';

export const COMMERCE_ERROR_CATEGORY = {
  GENERAL: {id: 'GENERAL', label: 'General Errors'},
  PRODUCT: {id: 'PRODUCT', label: 'Product Errors'},
  BILLING: {id: 'BILLING', label: 'Billing Errors'},
  DISCOUNT: {id: 'DISCOUNT', label: 'Discount Errors'},
  SUBSCRIPTION: {id: 'SUBSCRIPTION', label: 'Subscription Errors'},
} as const;

// The keys for these errors need to be stable since they're used in checkoutUtils.js::updateErrorMessage
export const CHECKOUT_ERRORS = {
  INFO: {
    id: INFO_ERR,
    name: 'General customer info error',
    category: COMMERCE_ERROR_CATEGORY.GENERAL,
    copy: 'There was an error processing your customer info. Please try again, or contact us if you continue to have problems.',
    path: ['data', 'commerce', INFO_ERR],
  },
  SHIPPING: {
    id: SHIPPING_ERR,
    category: COMMERCE_ERROR_CATEGORY.GENERAL,
    name: 'Shipping not available',
    copy: 'Sorry. We can’t ship your order to the address provided.',
    path: ['data', 'commerce', SHIPPING_ERR],
  },
  EXTRAS: {
    id: ORDER_EXTRAS_ERR,
    category: COMMERCE_ERROR_CATEGORY.GENERAL,
    name: 'Merchant setting changed',
    copy: 'A merchant setting has changed that impacts your cart. Please refresh and try again.',
    path: ['data', 'commerce', ORDER_EXTRAS_ERR],
    requiresRefresh: true,
  },
  PRICING: {
    id: PRICING_ERR,
    category: COMMERCE_ERROR_CATEGORY.PRODUCT,
    name: 'Product price changed',
    copy: 'The prices of one or more items in your cart have changed. Please refresh this page and try again.',
    path: ['data', 'commerce', PRICING_ERR],
    requiresRefresh: true,
  },
  PRODUCT: {
    id: PRODUCT_ERR,
    category: COMMERCE_ERROR_CATEGORY.PRODUCT,
    name: 'Product removed',
    copy: 'One or more of the products in your cart have been removed. Please refresh the page and try again.',
    path: ['data', 'commerce', PRODUCT_ERR],
    requiresRefresh: true,
  },
  PAYMENT: {
    id: PAYMENT_ERR,
    category: COMMERCE_ERROR_CATEGORY.BILLING,
    name: 'General payment error',
    copy: 'There was an error processing your payment. Please try again, or contact us if you continue to have problems.',
    path: ['data', 'commerce', PAYMENT_ERR],
  },
  BILLING: {
    id: BILLING_ERR,
    category: COMMERCE_ERROR_CATEGORY.BILLING,
    name: 'Card declined',
    copy: 'Your payment could not be completed with the payment information provided. Please make sure that your card and billing address information is correct, or try a different payment card, to complete this order. Contact us if you continue to have problems.',
    path: ['data', 'commerce', BILLING_ERR],
  },
  MINIMUM: {
    id: ORDER_MIN_ERR,
    category: COMMERCE_ERROR_CATEGORY.BILLING,
    name: 'Order minimum not met',
    copy: 'The order minimum was not met. Add more items to your cart to continue.',
    path: ['data', 'commerce', ORDER_MIN_ERR],
    note: {
      copy: "You can customize this message with the exact minimum based on your Stripe account's settlement currency.",
      cta: {
        copy: 'Go to Stripe docs',
        link: 'https://stripe.com/docs/currencies#minimum-and-maximum-charge-amounts',
      },
    },
  },
  INVALID_DISCOUNT: {
    id: INVALID_DISCOUNT_ERR,
    category: COMMERCE_ERROR_CATEGORY.DISCOUNT,
    name: 'Invalid discount error',
    copy: 'This discount is invalid.',
    path: ['data', 'commerce', INVALID_DISCOUNT_ERR],
  },
  EXPIRED_DISCOUNT: {
    id: EXPIRED_DISCOUNT_ERR,
    category: COMMERCE_ERROR_CATEGORY.DISCOUNT,
    name: 'Discount expired',
    copy: 'This discount is no longer available.',
    path: ['data', 'commerce', EXPIRED_DISCOUNT_ERR],
  },
  USAGE_REACHED_DISCOUNT: {
    id: USAGE_REACHED_DISCOUNT_ERR,
    category: COMMERCE_ERROR_CATEGORY.DISCOUNT,
    name: 'Discount usage limit reached',
    copy: 'This discount is no longer available.',
    path: ['data', 'commerce', USAGE_REACHED_DISCOUNT_ERR],
  },
  REQUIREMENTS_NOT_MET_DISCOUNT: {
    id: REQUIREMENTS_NOT_MET_DISCOUNT_ERR,
    category: COMMERCE_ERROR_CATEGORY.DISCOUNT,
    name: 'Discount requirements not met',
    copy: 'Your order does not meet the requirements for this discount.',
    path: ['data', 'commerce', REQUIREMENTS_NOT_MET_DISCOUNT_ERR],
  },
} as const;

const QUANTITY_ERR = 'QUANTITY_ERROR';
const CHECKOUT_ERR = 'CHECKOUT_ERROR';
const GENERAL_ERR = 'GENERAL_ERROR';
const CART_ORDER_MIN_ERR = 'CART_ORDER_MIN_ERROR';
const SUBSCRIPTION_ERR = 'SUBSCRIPTION_ERR';

export const CART_ERRORS = {
  QUANTITY: {
    id: QUANTITY_ERR,
    name: 'Quantity not available',
    category: COMMERCE_ERROR_CATEGORY.GENERAL,
    copy: 'Product is not available in this quantity.',
    path: ['data', 'commerce', QUANTITY_ERR],
  },
  GENERAL: {
    id: GENERAL_ERR,
    category: COMMERCE_ERROR_CATEGORY.GENERAL,
    name: 'General error',
    copy: 'Something went wrong when adding this item to the cart.',
    path: ['data', 'commerce', GENERAL_ERR],
  },
  CHECKOUT: {
    id: CHECKOUT_ERR,
    category: COMMERCE_ERROR_CATEGORY.GENERAL,
    name: 'Checkout disabled',
    copy: 'Checkout is disabled on this site.',
    path: ['data', 'commerce', CHECKOUT_ERR],
  },
  CART_ORDER_MIN: {
    id: CART_ORDER_MIN_ERR,
    category: COMMERCE_ERROR_CATEGORY.BILLING,
    name: 'Order minimum not met',
    copy: 'The order minimum was not met. Add more items to your cart to continue.',
    path: ['data', 'commerce', CART_ORDER_MIN_ERR],
  },
  SUBSCRIPTION_ERROR: {
    id: SUBSCRIPTION_ERR,
    category: COMMERCE_ERROR_CATEGORY.SUBSCRIPTION,
    name: 'Subscription not verified',
    copy: 'Before you purchase, please use your email invite to verify your address so we can send order updates.',
    path: ['data', 'commerce', SUBSCRIPTION_ERR],
  },
} as const;

const ADD_TO_CART_QUANTITY_ERR = 'ADD_TO_CART_QUANTITY_ERROR';
const ADD_TO_CART_GENERAL_ERR = 'ADD_TO_CART_GENERAL_ERROR';
const ADD_TO_CART_MIXED_ERR = 'ADD_TO_CART_MIXED_ERROR';

export const ADD_TO_CART_ERRORS = {
  QUANTITY: {
    id: ADD_TO_CART_QUANTITY_ERR,
    category: COMMERCE_ERROR_CATEGORY.GENERAL,
    name: 'Quantity not available',
    copy: 'Product is not available in this quantity.',
    path: ['data', 'commerce', ADD_TO_CART_QUANTITY_ERR],
  },
  GENERAL: {
    id: ADD_TO_CART_GENERAL_ERR,
    category: COMMERCE_ERROR_CATEGORY.GENERAL,
    name: 'Add to Cart error',
    copy: 'Something went wrong when adding this item to the cart.',
    path: ['data', 'commerce', ADD_TO_CART_GENERAL_ERR],
  },
  MIXED_CART: {
    id: ADD_TO_CART_MIXED_ERR,
    category: COMMERCE_ERROR_CATEGORY.GENERAL,
    name: 'Add to mixed Cart error',
    copy: 'You can’t purchase another product with a subscription.',
  },
  BUY_NOW: {
    id: 'BUY_NOW_ERROR',
    category: COMMERCE_ERROR_CATEGORY.GENERAL,
    name: 'Buy now error',
    copy: 'Something went wrong when trying to purchase this item.',
  },
  CHECKOUT_DISABLED: {
    id: 'CHECKOUT_DISABLED_ERROR',
    category: COMMERCE_ERROR_CATEGORY.GENERAL,
    name: 'Checkout disabled',
    copy: 'Checkout is disabled on this site.',
  },
  SELECT_ALL_OPTIONS: {
    id: 'SELECT_ALL_OPTIONS',
    category: COMMERCE_ERROR_CATEGORY.GENERAL,
    name: 'Option selection required',
    copy: 'Please select an option in each set.',
    path: ['data', 'commerce', 'SELECT_ALL_OPTIONS'],
  },
} as const;

export const DATA_ATTR_ANIMATION_EASING = 'data-wf-cart-easing';
export const ANIMATION_EASING_DEFAULT = 'ease-out-quad';
export const ANIMATION_EASING_KEY = 'easingType';
export const ANIMATION_EASING_KEYPATH = [
  'data',
  'commerce',
  ANIMATION_EASING_KEY,
] as const;
export const DATA_ATTR_ANIMATION_DURATION = 'data-wf-cart-duration';
export const ANIMATION_DURATION_DEFAULT = 300;
export const ANIMATION_DURATION_KEY = 'duration';
export const ANIMATION_DURATION_KEYPATH = [
  'data',
  'commerce',
  ANIMATION_DURATION_KEY,
] as const;

export const DATA_ATTR_PUBLISHABLE_KEY = 'data-publishable-key';

// this is all the styles that are immediately editable from the styles panel
// this doesn't include stuff like grid specific sections, as we lock out `display`
// and, assuming we don't have default display as grid, those sections wouldn't
// be accessible in the first place. when we inevitably have a default grid style,
// we can update this list
export const EDITABLE_STYLE_NAMES = [
  'backgroundColor',
  'backgroundSize',
  'backgroundPosition',
  'backgroundImage',
  'backgroundRepeat',
  'border',
  'borderRadius',
  'boxShadow',
  'clear',
  'color',
  'cursor',
  'direction',
  'display',
  'filter',
  'float',
  'fontFamily',
  'fontSize',
  'fontStyle',
  'fontWeight',
  'height',
  'lineHeight',
  'letterSpacing',
  'listStyleType',
  'marginBottom',
  'marginLeft',
  'marginRight',
  'marginTop',
  'maxHeight',
  'minHeight',
  'maxWidth',
  'minWidth',
  'mixBlendMode',
  'opacity',
  'overflow',
  'outlineColor',
  'outlineOffset',
  'outlineStyle',
  'outlineWidth',
  'paddingBottom',
  'paddingLeft',
  'paddingRight',
  'paddingTop',
  'position',
  'textAlign',
  'textColumns',
  'textDecoration',
  'textIndent',
  'textTransform',
  'textShadow',
  'transform',
  'transition',
  'whiteSpace',
  'width',
] as const;

export const symbolMap = {
  aed: 'د.إ',
  afn: '؋',
  all: 'L',
  amd: '֏',
  ang: 'ƒ',
  aoa: 'Kz',
  ars: '$',
  aud: '$',
  awg: 'ƒ',
  azn: '₼',
  bam: 'KM',
  bbd: '$',
  bdt: '৳',
  bgn: 'лв',
  bhd: '.د.ب',
  bif: 'FBu',
  bmd: '$',
  bnd: '$',
  bob: '$b',
  brl: 'R$',
  bsd: '$',
  btc: '฿',
  btn: 'Nu.',
  bwp: 'P',
  byr: 'Br',
  byn: 'Br',
  bzd: 'BZ$',
  cad: '$',
  cdf: 'FC',
  chf: 'CHF',
  clp: '$',
  cny: '¥',
  cop: '$',
  crc: '₡',
  cuc: '$',
  cup: '₱',
  cve: '$',
  czk: 'Kč',
  djf: 'Fdj',
  dkk: 'kr',
  dop: 'RD$',
  dzd: 'دج',
  eek: 'kr',
  egp: '£',
  ern: 'Nfk',
  etb: 'Br',
  eth: 'Ξ',
  eur: '€',
  fjd: '$',
  fkp: '£',
  gbp: '£',
  gel: '₾',
  ggp: '£',
  ghc: '₵',
  ghs: 'GH₵',
  gip: '£',
  gmd: 'D',
  gnf: 'FG',
  gtq: 'Q',
  gyd: '$',
  hkd: '$',
  hnl: 'L',
  hrk: 'kn',
  htg: 'G',
  huf: 'Ft',
  idr: 'Rp',
  ils: '₪',
  imp: '£',
  inr: '₹',
  iqd: 'ع.د',
  irr: '﷼',
  isk: 'kr',
  jep: '£',
  jmd: 'J$',
  jod: 'JD',
  jpy: '¥',
  kes: 'KSh',
  kgs: 'лв',
  khr: '៛',
  kmf: 'CF',
  kpw: '₩',
  krw: '₩',
  kwd: 'KD',
  kyd: '$',
  kzt: 'лв',
  lak: '₭',
  lbp: '£',
  lkr: '₨',
  lrd: '$',
  lsl: 'M',
  ltc: 'Ł',
  ltl: 'Lt',
  lvl: 'Ls',
  lyd: 'LD',
  mad: 'MAD',
  mdl: 'lei',
  mga: 'Ar',
  mkd: 'ден',
  mmk: 'K',
  mnt: '₮',
  mop: 'MOP$',
  mro: 'UM',
  mru: 'UM',
  mur: '₨',
  mvr: 'Rf',
  mwk: 'MK',
  mxn: '$',
  myr: 'RM',
  mzn: 'MT',
  nad: '$',
  ngn: '₦',
  nio: 'C$',
  nok: 'kr',
  npr: '₨',
  nzd: '$',
  omr: '﷼',
  pab: 'B/.',
  pen: 'S/.',
  pgk: 'K',
  php: '₱',
  pkr: '₨',
  pln: 'zł',
  pyg: 'Gs',
  qar: '﷼',
  rmb: '￥',
  ron: 'lei',
  rsd: 'Дин.',
  rub: '₽',
  rwf: 'R₣',
  sar: '﷼',
  sbd: '$',
  scr: '₨',
  sdg: 'ج.س.',
  sek: 'kr',
  sgd: '$',
  shp: '£',
  sll: 'Le',
  sos: 'S',
  srd: '$',
  ssp: '£',
  std: 'Db',
  stn: 'Db',
  svc: '$',
  syp: '£',
  szl: 'E',
  thb: '฿',
  tjs: 'SM',
  tmt: 'T',
  tnd: 'د.ت',
  top: 'T$',
  trl: '₤',
  try: '₺',
  ttd: 'TT$',
  tvd: '$',
  twd: 'NT$',
  tzs: 'TSh',
  uah: '₴',
  ugx: 'USh',
  usd: '$',
  uyu: '$U',
  uzs: 'лв',
  vef: 'Bs',
  vnd: '₫',
  vuv: 'VT',
  wst: 'WS$',
  xaf: 'FCFA',
  xbt: 'Ƀ',
  xcd: '$',
  xof: 'CFA',
  xpf: '₣',
  yer: '﷼',
  zar: 'R',
  zwd: 'Z$',
} as const;

export const CHECKOUT_BINDING_ROOT_QUERY_PATH = [
  'database',
  'commerceOrder',
] as const;

export const ORDER_TYPE = {
  REQUIRE_SHIPPING: 'shipping',
  NO_SHIPPING: 'noShipping',
} as const;

// All of the below constants are pulled in to published site code
// checkoutEvents
export const RENDER_TREE_EVENT = 'wf-render-tree';
export const NEEDS_REFRESH = 'data-wf-needs-refresh';
export const REQUIRES_SHIPPING = 'data-wf-order-requires-shipping';
export const STRIPE_ELEMENT_INSTANCE = 'data-wf-stripe-element-instance';
export const STRIPE_ELEMENT_TYPE = 'data-wf-stripe-element-type';
export const STRIPE_ELEMENT_STYLE = 'data-wf-stripe-style';

// addToCartEvents
export const ADD_TO_CART_LOADING = 'data-wf-atc-loading';
export const CHANGE_CART_EVENT = 'wf-change-cart-state';
export const ADD_TO_CART_ERROR_MESSAGE = '.w-add-to-cart-error-msg';
export const getATCErrorMessageForType = (errorType: string) =>
  `data-w-add-to-cart-${errorType}-error`;
export const CHECKOUT_DISABLED_ERROR_MESSAGE =
  'data-w-add-to-cart-checkout-disabled-error';

// checkoutUtils
export const CHECKOUT_QUERY = 'data-wf-checkout-query';
export const getCheckoutErrorMessageForType = (errorType: string) =>
  `data-w-${errorType}-error`;
export const REQUIRES_ACTION = 'requires_action';

// cartEvents
export const CART_GENERAL_ERROR_MESSAGE = 'data-w-cart-general-error';
export const CART_CHECKOUT_ERROR_MESSAGE = 'data-w-cart-checkout-error';
export const CART_CHECKOUT_ERROR_MESSAGE_SELECTOR = '.w-checkout-error-msg';
export const CART_ERROR_MESSAGE = 'cart-error-msg';
export const CART_ERROR_MESSAGE_SELECTOR = `.w-${CART_ERROR_MESSAGE}`;
export const CART_OPEN = 'data-cart-open';
export const CART_TYPE = 'data-wf-cart-type';
export const CART_QUERY = 'data-wf-cart-query';
export const PAYPAL_ELEMENT_INSTANCE = 'data-wf-paypal-element';
export const PAYPAL_BUTTON_ELEMENT_INSTANCE = 'data-wf-paypal-button';
export const getCartErrorMessageForType = (errorType: string) =>
  `data-w-cart-${errorType}-error`;

// orderConfirmationEvents
export const ORDER_QUERY = 'data-wf-order-query';

// stripeStore
export const STRIPE_ECOMMERCE_KEY = 'data-wf-ecomm-key';
export const STRIPE_ECOMMERCE_ACCOUNT_ID = 'data-wf-ecomm-acct-id';

export const EASINGS = {
  ease: 'Ease',
  'ease-in': 'Ease In',
  'ease-out': 'Ease Out',
  'ease-in-out': 'Ease In Out',
  linear: 'Linear',
  'ease-in-quad': 'Ease In Quad',
  'ease-in-cubic': 'Ease In Cubic',
  'ease-in-quart': 'Ease In Quart',
  'ease-in-quint': 'Ease In Quint',
  'ease-in-sine': 'Ease In Sine',
  'ease-in-expo': 'Ease In Expo',
  'ease-in-circ': 'Ease In Circ',
  'ease-in-back': 'Ease In Back',
  'ease-out-quad': 'Ease Out Quad',
  'ease-out-cubic': 'Ease Out Cubic',
  'ease-out-quart': 'Ease Out Quart',
  'ease-out-quint': 'Ease Out Quint',
  'ease-out-sine': 'Ease Out Sine',
  'ease-out-expo': 'Ease Out Expo',
  'ease-out-circ': 'Ease Out Circ',
  'ease-out-back': 'Ease Out Back',
  'ease-in-out-quad': 'Ease In Out Quad',
  'ease-in-out-cubic': 'Ease In Out Cubic',
  'ease-in-out-quart': 'Ease In Out Quart',
  'ease-in-out-quint': 'Ease In Out Quint',
  'ease-in-out-sine': 'Ease In Out Sine',
  'ease-in-out-expo': 'Ease In Out Expo',
  'ease-in-out-circ': 'Ease In Out Circ',
  'ease-in-out-back': 'Ease In Out Back',
} as const;
export const EASE_DEFAULT = 'ease-out-quad';

export const SECTION_NAMES = {
  ECOMMERCE: 'Ecommerce',
  CHECKOUT_PAGE: 'Checkout Page',
  ORDER_CONFIRMATION_PAGE: 'Order Confirmation Page',
  PAYPAL_CHECKOUT_PAGE: 'Checkout (PayPal) Page',
} as const;

export const COMMERCE_DEFAULT_COPY = {
  INFO_ERROR: CHECKOUT_ERRORS.INFO.copy,
  SHIPPING_ERROR: CHECKOUT_ERRORS.SHIPPING.copy,
  ORDER_EXTRAS_ERROR: CHECKOUT_ERRORS.EXTRAS.copy,
  PRICING_ERROR: CHECKOUT_ERRORS.PRICING.copy,
  PRODUCT_ERROR: CHECKOUT_ERRORS.PRODUCT.copy,
  PAYMENT_ERROR: CHECKOUT_ERRORS.PAYMENT.copy,
  BILLING_ERROR: CHECKOUT_ERRORS.BILLING.copy,
  ORDER_MINIMUM_ERROR: CHECKOUT_ERRORS.MINIMUM.copy,
  INVALID_DISCOUNT_ERROR: CHECKOUT_ERRORS.INVALID_DISCOUNT.copy,
  EXPIRED_DISCOUNT_ERROR: CHECKOUT_ERRORS.EXPIRED_DISCOUNT.copy,
  USAGE_REACHED_DISCOUNT_ERROR: CHECKOUT_ERRORS.USAGE_REACHED_DISCOUNT.copy,
  REQUIREMENTS_NOT_MET_DISCOUNT_ERROR:
    CHECKOUT_ERRORS.REQUIREMENTS_NOT_MET_DISCOUNT.copy,
  COMMERCE_ADD_TO_CART_BUTTON_DEFAULT: 'Add to Cart',
  COMMERCE_ADD_TO_CART_BUTTON_WAITING: 'Adding to cart...',
  COMMERCE_BUY_NOW_BUTTON_DEFAULT: 'Buy now',
  SUBSCRIPTION_BUTTON_DEFAULT: 'Subscribe now',
  QUANTITY_ERROR: 'Product is not available in this quantity.',
  GENERAL_ERROR: 'Something went wrong when adding this item to the cart.',
  CHECKOUT_ERROR: 'Checkout is disabled on this site.',
  CART_ORDER_MIN_ERROR:
    'The order minimum was not met. Add more items to your cart to continue.',
  SUBSCRIPTION_ERR:
    'Before you purchase, please use your email invite to verify your address so we can send order updates.',
  ADD_TO_CART_QUANTITY_ERROR: 'Product is not available in this quantity.',
  ADD_TO_CART_GENERAL_ERROR:
    'Something went wrong when adding this item to the cart.',
  ADD_TO_CART_MIXED_ERROR:
    'You can’t purchase another product with a subscription.',
  BUY_NOW_ERROR: 'Something went wrong when trying to purchase this item.',
  CHECKOUT_DISABLED_ERROR: 'Checkout is disabled on this site.',
  SELECT_ALL_OPTIONS: 'Please select an option in each set.',
} as const;
