import invert from 'lodash/invert';

/**
 * This file exists because GraphQL only allows /^[a-z_][a-z_0-9]$/i identifiers, but we allow things to be slugs,
 * like: "foo-bar". Since we allow characters that are not allowed by GraphQL, we need to convert these slugs into an
 * accepted format.
 *
 * Unfortunately, simple conversions (like what the prototype did: slug.replace(/\W/g, '_')) don't work, because this
 * would encode the slugs "foo-bar" and "foo_bar" to the same value. Both are valid slugs according to our system, and
 * can totally co-exist in a collection schema. So if a site existed that accidentally did something like that, schema
 * generation would fail for that user, which is a big "No bueno".
 */

// Consonants are used instead of the traditional hex characters, since there's
// a lower likelihood of it accidentally spelling something...
const hex_lookup: Readonly<Record<string, string>> = {
  '0': 'b',
  '1': 'c',
  '2': 'd',
  '3': 'f',
  '4': 'g',
  '5': 'h',
  '6': 'j',
  '7': 'k',
  '8': 'l',
  '9': 'm',
  a: 'n',
  b: 'p',
  c: 'q',
  d: 'r',
  e: 's',
  f: 't',
};

const reverse_hex_lookup: Readonly<Record<string, string>> = invert(hex_lookup);

// Crap code encodes any string to a /[_a-z0-9]*/i version, in a way that is
// still kinda readable, and doesn't have any collisions. Allows numeric first
// chars, since the c_/f_ prefix protects us from that case...
export function _crapCode(str?: string | null) {
  str = String(str);

  // Right is all rejected chars, in a {idx}{letterhex} format. Letter hex is just hex,
  // but all values are shifted into consonants to avoid ambiguities with idx / the hex
  // data. Left is the original string, with all rejected characters replaced with '_'.
  // This stragegy should have a good 1:1 mapping from source to encoded values. It
  // will produce keys that can be used as identifiers in GraphQL. And it also leaves
  // many of the readable characters in the source string alone, so that the slug is
  // still kinda readable:
  const right: Array<any | string> = [];

  // Left is all acceptable chars, for "readability".
  const left = str.replace(/[^a-z0-9]/gi, (substr, idx) => {
    // Get hex of invalid character:
    const hex = substr.charCodeAt(0).toString(16);

    // Map to letters, so there's no ambiguity between indexes and char data.
    // @ts-expect-error - TS2769 - No overload matches this call.
    const letters = hex.replace(/./g, (ch) => hex_lookup[ch]);

    // Push this {idx}{kinda-hex} combo for later concat:
    right.push(String(idx) + letters);

    // ... and replace the invalid char with '_':
    return '_';
  });

  return left + '_' + right.join('');
}

export const collSlug = (coll: {slug: string}) => 'c_' + _crapCode(coll.slug);
export const fieldSlug = (field: {slug: string}) =>
  'f_' + _crapCode(field.slug);
export const _test = {_crapCode} as const;

// takes a slug that has been run through prefixing and _crapCode,
// reverses crapification and prefixing, and reconstructs the original slug
export const restoreSlug = (slugWithPrefixAndCrapCode: string): string => {
  const results = slugWithPrefixAndCrapCode.match(
    /^[fc]_([_A-Za-z0-9]+)_([0-9bcdfghjklmnpqrst]*)$/
  );

  if (!results || results.length < 3) {
    // slug is not a valid Dynamo collection or field GraphQL slug,
    // so it does not need to be restored
    return slugWithPrefixAndCrapCode;
  }

  const left = results[1];
  const right = results[2];

  if (!right) {
    // @ts-expect-error - TS2322 - Type 'string | undefined' is not assignable to type 'string'.
    return left;
  }

  // we use a while loop and mutable variables,
  // because node 10 does not support `matchAll`
  // @ts-expect-error - TS18048 - 'left' is possibly 'undefined'.
  const decrapified = left.split('');
  const re = /(\d+)([bcdfghjklmnpqrst]+)/g;
  let matches = re.exec(right);

  while (matches !== null && matches.length > 2) {
    const idx = Number(matches[1]);
    const letters = matches[2];

    // @ts-expect-error - TS18048 - 'letters' is possibly 'undefined'. | TS2769 - No overload matches this call.
    const hex = letters.replace(/./g, (ch) => reverse_hex_lookup[ch]);
    const char = String.fromCharCode(parseInt(hex, 16));

    decrapified[idx] = char;
    matches = re.exec(right);
  }

  return decrapified.join('');
};
