/*
  globals
  window,
  document,
  HTMLButtonElement
*/

import {userSystemsRequestClient} from './utils';
import {
  USYS_DATA_ATTRS,
  LOGGEDIN_COOKIE_NAME,
} from '@packages/systems/users/constants';
import {logoutMutation} from './mutations';

const logoutButtonQuerySelector = `[${USYS_DATA_ATTRS.logout}]`;

function getLogoutButtons() {
  const logoutButtons = document.querySelectorAll(logoutButtonQuerySelector);
  return Array.prototype.slice
    .call(logoutButtons)
    .filter((logoutButton) => logoutButton instanceof HTMLButtonElement);
}

function handleGoToLoginClick() {
  if (window.Webflow.env('preview')) {
    return;
  }
  // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'Location | (string & Location)'.
  window.location = '/log-in';
}

function handleLogOutButtonClick(event: Event) {
  event.preventDefault();

  asyncLogOutUser().then(() => {
    // @ts-expect-error - TS2339 - Property 'Webflow' does not exist on type 'Window & typeof globalThis'.
    window.Webflow.location('/');
  });
}

export function handleLogInLogOutButton() {
  getLogoutButtons().forEach((logoutButton) => {
    if (
      document.cookie
        .split(';')
        .some((cookie) => cookie.indexOf(LOGGEDIN_COOKIE_NAME) > -1)
    ) {
      logoutButton.innerHTML =
        logoutButton.getAttribute(USYS_DATA_ATTRS.logout) || 'Log out';
      logoutButton.removeEventListener('click', handleGoToLoginClick);
      logoutButton.addEventListener('click', handleLogOutButtonClick);
    } else if (!window.Webflow.env('design')) {
      logoutButton.innerHTML =
        logoutButton.getAttribute(USYS_DATA_ATTRS.login) || 'Log in';
      logoutButton.removeEventListener('click', handleLogOutButtonClick);
      logoutButton.addEventListener('click', handleGoToLoginClick);
    }
  });
}

export function asyncLogOutUser() {
  return userSystemsRequestClient.mutate({
    mutation: logoutMutation,
  });
}
