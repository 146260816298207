/*
  globals
  document,
  window,
  HTMLFormElement,
  HTMLInputElement
*/

import {
  userSystemsRequestClient,
  hideElement,
  handleErrorNode,
  disableSubmit,
  resetSubmit,
  handleRedirect,
} from './utils';
import {
  USYS_DATA_ATTRS,
  USYS_FORM_TYPES,
  USYS_INPUT_TYPES,
  LOGIN_UI_ERROR_CODES,
  ERROR_MSG_CLASS,
  ERROR_ATTRIBUTE_PREFIX,
  logInErrorStates,
} from '@packages/systems/users/constants';
import {loginMutation} from './mutations';

function getLoginLinks() {
  return Array.prototype.slice
    .call(document.links)
    .filter((link) => link.getAttribute('href') === '/log-in');
}

// Grabs each log-in href on the page. If the page has redirect params from the proxy
// we persist that param  when the user clicks on log-in. This way after log-in
// we can redirect them to the content they were previously trying to view.
export function handleLoginRedirects() {
  getLoginLinks().forEach((link) => {
    const queryString = window.location.search;
    const redirectParam = queryString.match(/\?usredir=([^&]+)/g);

    if (redirectParam) {
      link.href = link.href.concat(redirectParam[0]);
    }
  });
}

const loginFormQuerySelector = `form[${USYS_DATA_ATTRS.formType}="${USYS_FORM_TYPES.login}"]`;

// error handling
const errorState = document.querySelector(`[${USYS_DATA_ATTRS.formError}]`);
const defaultErrorCopy =
  // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
  logInErrorStates[LOGIN_UI_ERROR_CODES.GENERAL_ERROR].copy;
const errorMsgNode = document.querySelector(`.${ERROR_MSG_CLASS}`);
const getLogInErrorCode = (error: string) => {
  let errorCode;
  switch (error) {
    case 'UsysInvalidCredentials':
      errorCode = LOGIN_UI_ERROR_CODES.INVALID_EMAIL_OR_PASSWORD;
      break;
    default:
      errorCode = LOGIN_UI_ERROR_CODES.GENERAL_ERROR;
  }
  return errorCode;
};

function getLoginForms() {
  const loginForms = document.querySelectorAll(loginFormQuerySelector);
  return Array.prototype.slice
    .call(loginForms)
    .filter((loginForm) => loginForm instanceof HTMLFormElement);
}

export function handleLogInForms() {
  getLoginForms().forEach((loginForm) => {
    loginForm.addEventListener('submit', (event: Event) => {
      event.preventDefault();

      const form = event.currentTarget;

      if (!(form instanceof HTMLFormElement)) {
        return;
      }

      const submit = form.querySelector('input[type="submit"]');
      const submitText = disableSubmit(submit);
      // @ts-expect-error - TS2345 - Argument of type 'Element | null' is not assignable to parameter of type 'HTMLElement | null | undefined'.
      hideElement(errorState);

      const emailInput = form.querySelector(
        `input[${USYS_DATA_ATTRS.inputType}="${USYS_INPUT_TYPES.email}"]`
      );
      const passwordInput = form.querySelector(
        `input[${USYS_DATA_ATTRS.inputType}="${USYS_INPUT_TYPES.password}"]`
      );

      if (
        !(emailInput instanceof HTMLInputElement) ||
        !(passwordInput instanceof HTMLInputElement)
      ) {
        return;
      }

      const onSuccessRedirectUrl = form.getAttribute(
        USYS_DATA_ATTRS.redirectUrl
      );

      asyncLogInUser(emailInput.value, passwordInput.value)
        .then(() => {
          // If there is a redirect param, redirect to that href after log-in.
          handleRedirect(onSuccessRedirectUrl);
        })
        .catch((error) => {
          // @ts-expect-error - TS2345 - Argument of type 'Element | null' is not assignable to parameter of type 'HTMLElement | null'.
          resetSubmit(submit, submitText);
          if (errorState) {
            // if there isn't an error code, send an empty string so a generic error message appears
            const elementErrorCode = error?.graphQLErrors?.[0]?.code ?? '';
            const errorCode = getLogInErrorCode(elementErrorCode);
            handleErrorNode(
              errorMsgNode,
              // @ts-expect-error - TS2345 - Argument of type 'Element' is not assignable to parameter of type 'HTMLElement'.
              errorState,
              errorCode,
              ERROR_ATTRIBUTE_PREFIX.LOGIN,
              defaultErrorCopy
            );
          }
        });
    });
  });
}

export function asyncLogInUser(email: string, password: string) {
  return userSystemsRequestClient.mutate({
    mutation: loginMutation,
    variables: {
      email,
      authPassword: password,
    },
  });
}
